import { useAppSelector } from "../../../../../app/hooks";
import { selectProductTitle } from "../../../../common/commonSlice";
import "./categoryStyles.scss";

export interface CategoriesProps {
  categories: Array<JSX.Element>;
}
export const CategoriesComponent = (props: CategoriesProps) => {
  let productTitle = useAppSelector(selectProductTitle);
  const getName = () => {
    if (productTitle === "Alertas") {
      return "Componentes de CoreId";
    } else if (productTitle === "Ayuda y Documentacion") {
      return "Secciones de Ayuda";
    } else {
      return `Componentes de ${productTitle}`;
    }
  };
  return (
    <div className="flex-col" style={{ width: "95%" }}>
      <div
        className="flex-col text-medium text-bold primary-color"
        style={{
          border: "none",
          borderRadius: "8px 8px 0px 0px",
          height: "30px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1px",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        {getName()}
      </div>
      {props.categories &&
        props.categories.map((category) => {
          return category;
        })}
    </div>
  );
};
