import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ProductTitleText,
  SecondaryProductTitle,
  TerciaryProductTitle,
} from "../common/components/texts/ProductTitles";
import "./loginStyles.scss";
import validator from "validator";
import {
  selectPassword,
  selectUsername,
  changePassword,
  changeUsername,
  tryLogin,
  selectLoginLoading,
  selectTokenString,
  selectLoginErrorMessage,
  emptyErrorMessage,
  changeEmailRecovery,
  selectEmailRecovery,
  recovery,
  selectSentEmailRecovery,
  changeSentEmailRecovery,
  selectTokenJson,
  getClientToken,
  selectClientBrand,
  selectLoadingClientBrand,
  setLoadingClientBrand,
  getClientBrandByCode,
} from "./loginSlice";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { addHours } from "date-fns";
import {
  getSubClients,
  selectClientsLogin,
  selectLoadingClientsLogin,
} from "../configuration/clients/clientsSlice";
import {
  getClientAndUserData,
  resetState,
  selectClientName,
  setMetaClientId,
} from "../common/commonSlice";
import { ReactComponent as RightMenu } from "../../Assets/common/images/rightMenu.svg";
import axiosApiInstance from "../../helpers/interceptor";
import localforage from "localforage";
import { useQueryParams } from "../../helpers/utils";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    width: "30%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.5%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

const Login = () => {
  const [, setCookie] = useCookies(["token"]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContraIsOpen, setIsContraOpen] = useState(false);
  const [multiClient, setMultiClient] = useState(false);

  // get code from queryparams

  const query = useQueryParams();
  let code = query.get("code");

  const navigate = useNavigate();
  // Variables
  let username = useAppSelector(selectUsername);
  let password = useAppSelector(selectPassword);
  let loading = useAppSelector(selectLoginLoading);
  let tokenString = useAppSelector(selectTokenString);
  let errorMessage = useAppSelector(selectLoginErrorMessage);
  let emailRecovery = useAppSelector(selectEmailRecovery);
  let sentEmailRecovery = useAppSelector(selectSentEmailRecovery);
  let jsonToken = useAppSelector(selectTokenJson);
  let clients = useAppSelector(selectClientsLogin);
  let loadingClients = useAppSelector(selectLoadingClientsLogin);
  let clientName = useAppSelector(selectClientName);

  // Logo
  let clientBrandData = useAppSelector(selectClientBrand);
  let loadingBrandData = useAppSelector(selectLoadingClientBrand);
  // Dispatcher
  let dispatch = useAppDispatch();
  // functions
  let usernameChange = (inputText: string) => {
    dispatch(changeUsername(inputText));
  };

  let passwordChange = (inputText: string) => {
    dispatch(changePassword(inputText));
  };

  let login = () => {
    dispatch(tryLogin());
  };
  let recoveryEmail = () => {
    dispatch(recovery());
  };

  const getBrandRender = () => {
    if (
      clientBrandData === null &&
      (loadingBrandData === "rejected" || loadingBrandData === "resolved")
    ) {
      return (
        <div className="titles-section-center">
          <SecondaryProductTitle />
        </div>
      );
    }
    if (loadingBrandData === "pending" || loadingBrandData === "idle") {
      return <div></div>;
    } else if (loadingBrandData === "rejected") {
      return (
        <div className="titles-section-center">
          <SecondaryProductTitle />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <img
            src={clientBrandData?.logoUrl}
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "80%",
              maxHeight: "150px",
            }}
            alt="Logo Empresa"
          />
        </div>
      );
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    dispatch(emptyErrorMessage());
  };
  const listProducts = [
    "HyperFlow",
    "Core ID",
    "Firma electrónica",
    "Smart Contracts",
    "Biometrix Pay",
    "Authenticator",
  ];

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      //Save cookie
      let now = new Date();
      let inAnHour = addHours(now, 1);
      if (jsonToken.metaclientId && !multiClient) {
        dispatch(getSubClients());
        dispatch(getClientAndUserData());
        dispatch(setMetaClientId(jsonToken.metaclientId));
        setMultiClient(true);
      } else {
        dispatch(getClientAndUserData());
        setCookie(
          "token",
          { token: tokenString, expiry: inAnHour.getTime() },
          {
            path: "/",
            expires: inAnHour,
            sameSite: "lax",
            secure: true,
          }
        );
        navigate("/intro");
      }
    }
    if (errorMessage !== "") {
      setIsOpen(true);
    }
    if (!code) {
      dispatch(setLoadingClientBrand("rejected"));
    }
    if (
      code &&
      !clientBrandData &&
      loadingBrandData !== "rejected" &&
      loadingBrandData !== "resolved"
    ) {
      dispatch(getClientBrandByCode(code));
    }
  }, [
    tokenString,
    navigate,
    setCookie,
    errorMessage,
    loadingBrandData,
    code,
    clientBrandData,
  ]);

  return (
    <LoadingOverlay
      active={loading || loadingClients == "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalContraIsOpen && !sentEmailRecovery}
        onRequestClose={() => {
          setIsContraOpen(false);
        }}
        style={customStylesModal}
        contentLabel="Recuperar contraseña"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{ justifyContent: "start", alignItems: "center" }}
        >
          <h6 style={{ fontWeight: 700, fontSize: "22px" }}>
            Restablece tu contraseña
          </h6>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex-col"
            style={{ width: "100%", alignItems: "center" }}
          >
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <p style={{ fontWeight: 500 }}>Ingresa tu correo electrónico.</p>
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(0,0,0,0.7)",
                  fontWeight: 600,
                  width: "70%",
                  textAlign: "start",
                }}
              >
                Te enviaremos las instrucciones para restablecer la contraseña
                allí
              </p>
              <input
                style={{
                  width: "70%",
                  height: "40px",

                  border:
                    emailRecovery.length > 0 &&
                    !validator.isEmail(emailRecovery)
                      ? "1px solid rgba(200,0,12,0.3)"
                      : "1px solid rgba(0,0,0,0.3)",
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "6px",
                  textAlign: "start",
                }}
                type="email"
                value={emailRecovery}
                disabled={loading}
                onChange={(e) => {
                  dispatch(changeEmailRecovery(e.target.value));
                }}
              />
              {emailRecovery.length > 0 &&
                !validator.isEmail(emailRecovery) && (
                  <p
                    style={{
                      width: "70%",
                      fontSize: "10px",
                      color: "#cf4259",
                      margin: "2px 0 0 0",
                      textAlign: "start",
                    }}
                  >
                    El correo ingresado no es válido
                  </p>
                )}
            </label>

            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                padding: "10px 20px",
                textTransform: "uppercase",
                marginTop: "20px",
              }}
              onClick={recoveryEmail}
              disabled={
                loading ||
                emailRecovery.length === 0 ||
                !validator.isEmail(emailRecovery)
              }
            >
              Restablecer contraseña
            </button>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Error de login"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "25%",
              paddingRight: "25%",
            }}
          >
            {errorMessage}
          </div>
          <button
            className="text-medium text-bold primary-color"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              background: "#1A617D",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Intente nuevamente
          </button>
        </div>
      </Modal>
      <div className="flex-row">
        <div className="flex-col title-section">
          <div className="titles-section-list">
            {listProducts.map((e, ind) => {
              return (
                <ProductTitleText
                  text={e}
                  order={5 - ind}
                  multiple={true}
                  list={"list"}
                  key={ind}
                />
              );
            })}
            <ProductTitleText
              text="back"
              bootom={"bottom"}
              list="titles-section-list-back"
            />
          </div>
        </div>
        {!multiClient ? (
          <div className="flex-col login-section">
            {loadingBrandData && (
              <div className="titles-section-text-left">
                <TerciaryProductTitle />
              </div>
            )}

            {getBrandRender()}
            {loadingBrandData === "rejected" && (
              <div className="text-normal text-xxx-small primary-color-40 paragraphdescription ">
                <p>
                  <b>anteia</b> ha creado la primera plataforma que automatiza
                  de extremo a extremo procesos de vinculación, registros y
                  ventas, teniendo como cerebro la identidad digital avanzada y
                  un sólido modelo de ciberseguridad
                </p>
              </div>
            )}
            <form
              action="POST"
              onSubmit={(e) => e.preventDefault()}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
              }}
              className="login-section-form"
            >
              <label
                className="text-normal text-small primary-color "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%",
                  alignItems: "center",
                  margin: "0 0 40px 0",
                }}
              >
                <p style={{ margin: "0", alignSelf: "end" }}>Usuario</p>
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                    background: "rgba(255,255,255,0.1)",
                    border: "0.5px solid rgba(255,255,255,0.1)",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    textAlign: "center",
                  }}
                  type="email"
                  value={username}
                  disabled={loading}
                  onChange={(e) => {
                    usernameChange(e.target.value);
                  }}
                  className="text-normal text-small primary-color-80"
                />{" "}
              </label>

              <label
                className="text-normal text-small primary-color "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "75%",
                  alignItems: "center",
                  margin: "0 0 40px 0",
                }}
              >
                <div
                  className="flex-row"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <a
                    href="#"
                    className="text-normal text-small login-label-password primary-color-35"
                    onClick={() => {
                      dispatch(changeSentEmailRecovery(false));
                      setIsContraOpen(!modalContraIsOpen);
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                  </a>

                  <p className="text-normal text-small primary-color login-label">
                    Contraseña
                  </p>
                </div>
                <input
                  style={{
                    width: "100%",
                    height: "40px",
                    background: "rgba(255,255,255,0.1)",
                    border: "0.5px solid rgba(255,255,255,0.1)",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    textAlign: "center",
                  }}
                  type="password"
                  value={password}
                  disabled={loading}
                  onChange={(e) => {
                    passwordChange(e.target.value);
                  }}
                  className="text-normal text-small primary-color-80 "
                />
              </label>

              <button
                className="text-bold text-medium primary-color login-button"
                onClick={(e) => {
                  e.preventDefault();
                  login();
                }}
                disabled={loading}
              >
                INGRESAR
              </button>
            </form>
          </div>
        ) : (
          <div className="flex-col login-section">
            {loadingBrandData && (
              <div className="titles-section-text-left">
                <TerciaryProductTitle />
              </div>
            )}
            {getBrandRender()}
            {loadingBrandData === "rejected" && (
              <div className="text-normal text-xxx-small primary-color-40 paragraphdescription ">
                <p>
                  <b>anteia</b> ha creado la primera plataforma que automatiza
                  de extremo a extremo procesos de vinculación, registros y
                  ventas, teniendo como cerebro la identidad digital avanzada y
                  un sólido modelo de ciberseguridad
                </p>
              </div>
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  login();
                }}
                style={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "white",
                  minHeight: "40px",
                  width: "60%",
                  background: "rgba(255,255,255,0.05)",
                  borderRadius: "6px",
                  border: "0.5px solid rgba(255,255,255,0.2)",
                  padding: "10px",
                }}
                disabled={loadingClients === "pending"}
              >
                Ingresar como {clientName}
              </button>
              <h5
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "700",
                  margin: "30px 0 30px 0 ",
                }}
              >
                Ó
              </h5>
              <div
                className="flex-col"
                style={{
                  width: "80%",
                  background: "rgba(255,255,255,0.05)",
                  borderRadius: "6px ",
                  border: "0.5px solid rgba(255,255,255,0.2)",
                }}
              >
                <div
                  className="flex-col text-medium text-bold primary-color"
                  style={{
                    height: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Ingresa como un subcliente
                </div>
                {clients && clients.length > 0 ? (
                  <div style={{ height: "220px", overflow: "auto" }}>
                    {clients.map((client, index) => {
                      return (
                        <button
                          key={index}
                          onClick={() => {
                            dispatch(getClientToken(client.clientId));
                            dispatch(setMetaClientId(""));
                          }}
                          className="flex-row text-medium text-bold primary-color"
                          style={{
                            borderBottom: "none",
                            borderTop: "0.5px solid rgba(255,255,255,0.05)",
                            borderLeft: "none",
                            borderRight: "none",
                            height: "40px",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "transparent",
                            boxSizing: "border-box",
                            paddingLeft: "5%",
                            paddingRight: "5%",
                          }}
                        >
                          <div className="text-medium text-bold primary-color">
                            {client.name}
                          </div>
                          <div
                            className="flex-col"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <RightMenu />
                          </div>
                        </button>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100px",
                      overflow: "auto",
                      padding: "4%",
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Aún no tienes subclientes <br />
                    Puedes crear uno desde el menú de clientes en configuración
                  </div>
                )}
              </div>

              <button
                onClick={async (e) => {
                  setMultiClient(false);
                  e.preventDefault();
                  dispatch(resetState());
                  delete axiosApiInstance.defaults.headers.common[
                    "Authorization"
                  ];
                  await localforage.clear();
                }}
                style={{
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "white",
                  minHeight: "40px",
                  width: "60%",
                  background: "rgba(255,255,255,0.05)",
                  borderRadius: "6px",
                  border: "0.5px solid rgba(255,255,255,0.2)",
                  padding: "10px",
                  margin: "10% 0 0 0 ",
                }}
                disabled={loadingClients === "pending"}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default Login;
