import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface RNDCProps {
  loading: LoadingStatus;
  items: Array<RNDCItem>;
}

export interface RNDCItem {
  fechaExpedicion: string;
  nroRadicado: string;
  cedulaConductor: string;
  nroManifiesto: string;
  origen: string;
  destino: string;
  placaRemolque: string;
  fechaRadicacion: string;
  nombreEmpresa: string;
  placa: string;
  tipoDocumentoNoValido: string;
}

export const RNDCResult = (props: RNDCProps) => {
  const showMessage = () => {
    if (
      props &&
      props.items &&
      props.items[0] &&
      props.items[0].tipoDocumentoNoValido
    ) {
      return "Tipo de documento no válido";
    } else {
      return "No se encontraron registros, intente de nuevo más tarde";
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {props.items &&
      props.items.length > 0 &&
      !props.items[0].tipoDocumentoNoValido ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.875rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Registro nacional de carga
          </div>
          {props.items.length > 0 ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    No. Manifiesto
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Cedula Conductor
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha Expedicion
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    No. Radicado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Origen
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Destino
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Placa Remolque
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha Radicación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Nombre Empresa
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Placa
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.items.map((rndc, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        height: "40px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "4px",
                      }}
                    >
                      <td></td>
                      <td>{rndc.nroManifiesto}</td>
                      <td>{rndc.cedulaConductor}</td>
                      <td>{rndc.fechaExpedicion}</td>
                      <td>{rndc.nroRadicado}</td>
                      <td>{rndc.origen}</td>
                      <td>{rndc.destino}</td>
                      <td>{rndc.placaRemolque}</td>
                      <td>{rndc.fechaRadicacion}</td>
                      <td>{rndc.nombreEmpresa}</td>
                      <td>{rndc.placa}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Registro nacional de carga
              </div>
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {props.items[0].tipoDocumentoNoValido
                  ? "Tipo de documento no válido"
                  : "No se encontraron registros"}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <>
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Registro nacional de carga
              </div>
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {showMessage()}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
