import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface SofiaPlusProps {
  loading: LoadingStatus;
  item: SofiaPlus | null;
}

export interface SofiaPlus {
  lista: string;
  tipo: string;
  presentaAdvertencia: boolean;
  Resultado: Array<SofiaPlusResult>;
  error: string;
}

export interface SofiaPlusResult {
  idDatoConsultado: number;
  fechaCertificado: string;
  descarga: string;
  programa: string;
  id: number;
  tipoCertificado: string;
  estadoCertificacion: string;
  estadoAprendiz: string;
}

export const SofiaPlusComponent = (props: SofiaPlusProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.875rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Sena Sofia Plus
          </div>
          <div
            style={{
              color: "white",
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {props.item.presentaAdvertencia
              ? "Presenta advertencia"
              : "No presenta advertencias"}
          </div>

          <div
            style={{
              color: "white",
              fontSize: "1.25rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Certificados
          </div>
          {props.item.Resultado ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha Certificado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Enlace de descarga
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Programa
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Tipo de certificado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado de certificación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado de aprendiz
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.Resultado.map((certificado, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        height: "40px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "4px",
                      }}
                    >
                      <td></td>
                      <td>{certificado.fechaCertificado}</td>
                      <td>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={certificado.descarga}
                          style={{
                            color: "rgba(255, 255, 255, 0.35)",
                            textDecoration: "underline",
                          }}
                        >
                          {certificado.descarga}
                        </a>
                      </td>
                      <td>{certificado.programa}</td>
                      <td>{certificado.tipoCertificado}</td>
                      <td>{certificado.estadoCertificacion}</td>
                      <td>{certificado.estadoAprendiz}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {props.item.error}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
