import axiosApiInstance from "../../../helpers/interceptor";
import { File, GeneratedDocuments } from "../firma/firmaAPI";

let url = "https://dev.api.firma.anteia.co";
//let url = "https://api.sign.anteia.co";
let getFilteredPath = "api/BackOffice/getFiltered";
let getByIdPath = "api/BackOffice/getById";
let getGeneratedDocumentsPath = "api/BackOffice/getGeneratedDocumentsById";
let getAuthImagesPath = (signatureId: string, signerId: string) =>
  `api/BackOffice/getAuthenticationImageById/${signatureId}/${signerId}`;
let getOptionsPath = "api/BackOffice/getFilteredOptions";

export const getFilteredSignaturesAPI = async (
  request: GetFilteredSignatureRequest
): Promise<{
  error: string;
  filteredSignatures: GetFilteredSignatureResponse | null;
}> => {
  try {
    let filteredSignatures = await axiosApiInstance.post(
      `${url}/${getFilteredPath}`,
      request
    );
    return {
      error: "",
      filteredSignatures: {
        ...filteredSignatures.data,
        currentPage: request.page,
      },
    };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { filteredSignatures: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { filteredSignatures: null, error: e.response.data };
    } else {
      return { filteredSignatures: null, error: e.response.data };
    }
  }
};

export const getSignatureByIdAPI = async (
  id: string
): Promise<{ signature: GetSignatureResponse | null; error: string }> => {
  try {
    let signature = await axiosApiInstance.get(`${url}/${getByIdPath}/${id}`);
    return { signature: signature.data, error: "" };
  } catch (e: any) {
    if (e.response.status === 500) {
      return { signature: null, error: e.response.data };
    } else if (e.response.status === 404) {
      return { signature: null, error: e.response.data };
    } else {
      return { signature: null, error: e.response.data };
    }
  }
};

export const getGeneratedDocumentsNewAPI = async (
  id: string
): Promise<{ documents: GeneratedDocuments | null; error: string }> => {
  try {
    let documents = await axiosApiInstance.get(
      `${url}/${getGeneratedDocumentsPath}/${id}`
    );
    return { documents: documents.data, error: "" };
  } catch (err: any) {
    return { documents: null, error: err && err.message };
  }
};

export const getAuthImageAPI = async (
  signatureId: string,
  signerId: string
): Promise<GetAuthImageResponse> => {
  try {
    let authImage = await axiosApiInstance.get(
      `${url}/${getAuthImagesPath(signatureId, signerId)}`
    );
    return authImage.data;
  } catch (err: any) {
    return {
      authImages: [],
    };
  }
};

export const getFilterOptionsAPI = async (): Promise<{
  error: string;
  filterOptions: GetFilterOptionsResponse;
}> => {
  try {
    let filterOptions = await axiosApiInstance.get<GetFilterOptionsResponse>(
      `${url}/${getOptionsPath}`
    );
    return {
      filterOptions: filterOptions.data,
      error: "",
    };
  } catch (err: any) {
    return {
      filterOptions: {
        projectOptions: [],
      },

      error: err && err.message,
    };
  }
};

export interface GetFilteredSignatureRequest {
  page: number;
  pageSize?: number;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  project: string | null;
  documentType: string | null;
  tag: string | null;
  searchQuery: string | null;
}

export interface GetFilteredSignatureResponse {
  filteredSignatures: Array<FilteredSignature>;
  numPages: number;
  currentPage: number;
}

export interface FilteredSignature {
  documentName: string;
  numSigners: number;
  signers: Array<string>;
  startedDate: string;
  status: string;
  numAlerts: number;
  documentId: string;
}

export interface GetSignatureResponse {
  status: string;
  documentStartedDate: string;
  numAlerts: number;
  numSigners: number;
  signers: Array<BackOfficeSigner>;
  documentId: string;
  history?: Array<History>;
  hasPagare?: boolean;
  pagareRootId?: string;
  documents?: GeneratedDocuments | null;
}
export interface History {
  action: string;
  date: string;
}
export interface BackOfficeSigner {
  status: string;
  startedDate: string;
  numAlerts: number;
  validationMethod: string;
  name: string;
  lastName: string;
  userId: string;
  email: string;
  phoneNumber: string;
  identification: string;
  identificationType: string;
  signerId: string;
}

export interface GetFilterOptionsResponse {
  projectOptions: Array<ProjectOption>;
}

export interface ProjectOption {
  name: string;
  id: string;
}

export interface GetAuthImageResponse {
  authImages: Array<File>;
}
