import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  SignatureDetail,
  FirmasFiltered,
  getSignatureByIdAPI,
  getSignaturesFilteredAPI,
  FirmaFilteredRequest,
  getGeneratedDocumentsAPI,
  getAuthenticationImageByIdAPI,
  GeneratedDocuments,
  VerificationImage,
} from "./firmaAPI";

export type FirmaActiveComponent = "Firmas" | "none";

export interface FirmaState {
  loadingStatus: LoadingStatus;
  loadingDocumentStatus: LoadingStatus;
  loadingImages: LoadingStatus;
  firmas: FirmasFiltered | null;
  notFoundMessage: string;
  currentPage: number;
  currentNumPages: number;
  activeComponent: FirmaActiveComponent;
  currentFilteredRequest: FirmaFilteredRequest;
  currentFirma: SignatureDetail | null;
  currentDocuments: GeneratedDocuments | null;
  currentImage: VerificationImage | null;
}

const initialState: FirmaState = {
  activeComponent: "none",
  currentFirma: null,
  currentNumPages: 0,
  currentPage: 1,
  firmas: null,
  loadingStatus: "idle",
  loadingDocumentStatus: "idle",
  loadingImages: "idle",
  notFoundMessage: "",
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    startDate: null,
    status: null,
    verificationMethod: null,
  },
  currentDocuments: null,
  currentImage: null,
};

export const getFilteredSignatures = createAsyncThunk(
  "firma/getFilteredSignatures",
  async (params: FirmaFilteredRequest, { rejectWithValue }) => {
    try {
      const filteredSignatures = await getSignaturesFilteredAPI(params);
      if (filteredSignatures.filteredFirmas) {
        return filteredSignatures;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getSignatureById = createAsyncThunk(
  "firma/getSignatureById",
  async (id: string, { rejectWithValue }) => {
    try {
      const firma = await getSignatureByIdAPI(id);
      return firma.signature;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGeneratedDocuments = createAsyncThunk(
  "firma/getGenerateDocuments",
  async (id: string, { rejectWithValue }) => {
    try {
      const documents = await getGeneratedDocumentsAPI(id);
      return documents.documents;
    } catch (err: any) {
      return rejectWithValue(err.messag);
    }
  }
);

export const getAuthenticationImage = createAsyncThunk(
  "firma/getAuthenticationImage",
  async (id: string, { rejectWithValue }) => {
    try {
      const image = await getAuthenticationImageByIdAPI(id);
      return image.image;
    } catch (err: any) {
      return rejectWithValue(err.messag);
    }
  }
);

export const firmaSlice = createSlice({
  name: "firmaSlice",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<FirmaActiveComponent>
    ) => {
      state.activeComponent = action.payload;
    },
    changeCurrentFilters: (
      state,
      action: PayloadAction<FirmaFilteredRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredSignatures.fulfilled, (state, action) => {
        let signatures = action.payload;

        if (signatures.filteredFirmas) {
          state.firmas = signatures.filteredFirmas;
          state.currentPage = signatures.filteredFirmas.currentPage;
          state.currentNumPages = signatures.filteredFirmas.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredSignatures.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.currentNumPages = 0;
      })
      .addCase(getFilteredSignatures.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getSignatureById.fulfilled, (state, action) => {
        let signature = action.payload;
        if (signature) {
          state.currentFirma = signature;
          state.loadingStatus = "resolved";
          state.loadingDocumentStatus = "idle";
          state.loadingImages = "idle";
        } else {
          state.loadingStatus = "rejected";
          state.loadingDocumentStatus = "rejected";
          state.loadingImages = "rejected";
        }
      })
      .addCase(getSignatureById.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.loadingDocumentStatus = "rejected";
        state.loadingImages = "rejected";
      })
      .addCase(getSignatureById.pending, (state) => {
        state.loadingStatus = "pending";
        state.loadingDocumentStatus = "pending";
        state.loadingImages = "pending";
      })
      .addCase(getGeneratedDocuments.fulfilled, (state, action) => {
        let documents = action.payload;
        if (documents) {
          state.currentDocuments = documents;
          state.loadingDocumentStatus = "resolved";
        } else {
          state.loadingDocumentStatus = "rejected";
        }
      })
      .addCase(getGeneratedDocuments.rejected, (state) => {
        state.loadingDocumentStatus = "rejected";
      })
      .addCase(getGeneratedDocuments.pending, (state) => {
        state.loadingDocumentStatus = "pending";
      })
      .addCase(getAuthenticationImage.fulfilled, (state, action) => {
        let image = action.payload;
        if (image) {
          state.currentImage = image;
          state.loadingImages = "resolved";
        } else {
          state.loadingImages = "rejected";
        }
      })
      .addCase(getAuthenticationImage.rejected, (state) => {
        state.loadingImages = "rejected";
      })
      .addCase(getAuthenticationImage.pending, (state) => {
        state.loadingImages = "pending";
      });
  },
});

export const { changeActiveComponent, changeCurrentFilters, changePageState } =
  firmaSlice.actions;

export const selectNotFoundMessageFirma = (state: RootState) =>
  state.firma.notFoundMessage;
export const selectActiveFirmaComponent = (state: RootState) =>
  state.firma.activeComponent;
export const selectCurrentPageFirma = (state: RootState) =>
  state.firma.currentPage;
export const selectCurrentNumPagesFirma = (state: RootState) =>
  state.firma.currentNumPages;
export const selectCurrentFirma = (state: RootState) =>
  state.firma.currentFirma;
export const selectFirmas = (state: RootState) => state.firma.firmas;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.firma.currentFilteredRequest;
export const selectCurrentImage = (state: RootState) =>
  state.firma.currentImage;
export const selectCurrentDocuments = (state: RootState) =>
  state.firma.currentDocuments;
export const selectLoading = (state: RootState) => state.firma.loadingStatus;
export const selectImageLoading = (state: RootState) =>
  state.firma.loadingImages;
export const selectDocumentsLoading = (state: RootState) =>
  state.firma.loadingDocumentStatus;

export default firmaSlice.reducer;
