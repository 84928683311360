import React from "react";
import { Pie } from "../DashboardApi";

interface GaugeOption {
  title: string;
  scoreIndicator: number;
  deferenceIndicator: number;
  scoreUmbral: number;
  deferenceUmbral: number;
}

interface FunnelChartData {
  value: number;
  name: string;
  extra: number;
}

export const getGaugeOption = ({
  title,
  scoreIndicator,
  deferenceIndicator,
  scoreUmbral,
  deferenceUmbral,
}: GaugeOption) => {
  const gaugeData = [
    {
      value: scoreIndicator,
      name: "score",
      title: {
        offsetCenter: ["0%", "50%"],
        color: "white",
        fontSize: 15,
      },
      detail: {
        valueAnimation: false,
        offsetCenter: ["0%", "75%"],
        fontSize: 20,
        color: "white",
        formatter: "{value}%",
      },
    },
  ];

  let colorConfig = [
    [0.7, "#ee6666"],
    [0.85, "#fac858"],
    [1, "#3ba272"],
  ];

  if (title === "Users confirm data") {
    colorConfig = [
      [0.15, "#3ba272"],
      [0.25, "#fac858"],
      [1, "#ee6666"],
    ];
  }

  return {
    series: [
      {
        type: "gauge",
        radius: "72%",
        center: ["50%", "28%"],
        axisLine: {
          lineStyle: {
            width: 11,
            color: colorConfig,
          },
        },
        axisTick: {
          distance: -21,
          length: 11,
          lineStyle: {
            color: "#fff",
            width: 0.3,
          },
        },
        splitLine: {
          distance: -22,
          length: 10,
          lineStyle: {
            color: "#fff",
            width: 1,
          },
        },
        axisLabel: {
          color: "white",
          distance: -18,
          fontSize: 15,
        },
        pointer: {
          itemStyle: {
            color: "auto",
          },
        },
        detail: {
          valueAnimation: true,
          formatter: "{value}",
          color: "inherit",
        },
        data: gaugeData,
      },
    ],
    graphic: [
      {
        type: "group",
        left: "center",
        bottom: 130,
        textAlign: "center",
        children: [
          {
            type: "text",

            style: {
              text: `${
                deferenceIndicator > 0 ? "↑" : deferenceIndicator < 0 ? "↓" : ""
              } ${deferenceIndicator}%`,
              fill:
                deferenceIndicator > 0
                  ? "#3ba272"
                  : deferenceIndicator < 0
                  ? "#ee6666"
                  : "white",
              fontSize: 15,
              textAlign: "center",
            },
            top: 2,
          },
          {
            type: "text",
            style: {
              text: "% Success Users",
              fontSize: 14,
              fill: "white",
              textAlign: "center",
            },
            top: 24,
          },
          {
            type: "text",
            style: {
              text: `${scoreUmbral}%`,
              fontWeight: 550,
              fontSize: 19,
              fill: "white",
              textAlign: "center",
            },
            top: 40,
          },
          {
            type: "text",
            style: {
              text: `${
                deferenceUmbral > 0 ? "↑" : deferenceUmbral < 0 ? "↓" : ""
              } ${deferenceUmbral}%`,
              fill:
                deferenceUmbral > 0
                  ? "#3ba272"
                  : deferenceUmbral < 0
                  ? "#ee6666"
                  : "inherit",
              textAlign: "center",
              fontSize: 15,
            },
            top: 60,
          },
        ],
      },
    ],
  };
};

export const getPieOption = (porcentage: boolean, data: Pie[]) => {
  const pieConfig = data.map((item) => {
    const colorMapping: { [key: string]: string } = {
      approved: "#3ba272",
      reviwed: "#885255",
      inProgress: "#fac858",
      rejected: "#ee6666",
      noOpenCamera: "#800080",
      noInitialData: "#0778f0",
    };

    const [stateKey, value] = Object.entries(item).find(
      ([key, val]) => key !== "title" && val !== undefined
    ) || ["unknown", 0];

    return {
      value: value as number,
      name: item.title,
      itemStyle: { color: colorMapping[stateKey] || "#cccccc" },
    };
  });

  return {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} | ({d}%)",
    },
    legend: {
      orient: "vertical",
      bottom: 70,
      left: "right",
      data: data.map((item) => item.title),
      textStyle: {
        color: "white",
        fontSize: 10,
      },
    },
    series: [
      {
        name: "Estados de los registros",
        type: "pie",
        radius: "70%",
        center: ["50%", "39%"],
        selectedMode: "single",
        data: pieConfig,
        emphasis: {
          itemStyle: {
            shadowBlur: 15,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.6)",
            shadowOpacity: 1,
          },
        },
        label: {
          position: "inside",
          color: "white",
          fontWeight: 400,
          formatter: porcentage ? "{d}%" : "{c}",
          fontSize: 13,
        },
      },
    ],
  };
};

const formattedData = (numberFormatted: number | undefined) =>
  typeof numberFormatted === "number"
    ? parseFloat(numberFormatted.toPrecision(4))
    : undefined;

export const getFunnelChartCoreID = (
  metrics: Array<{ metricTitle: string; value: number; percentage: number }>
) => {
  const data = metrics.map((metric) => ({
    value: formattedData(metric.percentage) || 0, 
    name: metric.metricTitle,
  }));

  const rawValuesData = metrics.map((metric) => ({
    value: metric.value || 0,
    name: `${metric.metricTitle}:`,
  }));

  return {
    title: {
      top: 50,
      left: 55,
    },
    tooltip: {
      trigger: "item",
      formatter: (params: { seriesName: string; name: string; value: number }) =>
        `${params.seriesName} <br/>${params.name} ${params.value}`,
    },
    legend: {
      data: metrics.map((metric) => metric.metricTitle),
      textStyle: {
        color: "#color",
      },
    },
    series: [
      {
        name: "Convertibilidad coreID",
        type: "funnel",
        left: "25%",
        top: 80,
        bottom: 50,
        width: "55%",
        sort: "descending",
        gap: 7,
        label: {
          show: true,
          position: "inside",
          formatter: (params: { name: string; value: number }) =>
            `${params.value} %`,
          fontSize: 15,
          color: "white",
        },
        itemStyle: {
          margin: 10,
          borderColor: "black",
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data,
      },
      {
        name: "Convertibilidad coreID (Valores Brutos)",
        type: "funnel",
        left: "25%",
        top: 90,
        bottom: 40,
        width: "30%",
        sort: "descending",
        gap:8,
        label: {
          show: true,
          position: "leftTop",
          formatter: (params: { name: string; value: number }) =>
            `${params.name} ${params.value}`,
          fontSize: 13,
          color: "white",
        },
        itemStyle: {
          borderColor: "rgba(0, 0, 0, 0.0)",
          borderWidth: 1,
          color: "rgba(255, 255, 255, 0.0)",
        },
        emphasis: {
          label: {
            fontSize: 15,
          },
        },
        data: rawValuesData,
      },
    ],
  };
};


export const getFunnelChartHyperFlow = (
  step1?: number,
  step3?: number,
  step4?: number
) => {
  return {
    title: {
      top: 25,
      left: 55,
    },
    tooltip: {
      trigger: "item",
      formatter: " {a} <br/>{b}",
    },
    toolbox: {
      feature: {
        dataView: { readOnly: true },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      data: ["Crear Identidad Digital", "Formulario", "Finalizo"],
      textStyle: {
        color: "#color",
      },
    },

    series: [
      {
        name: "Funnel HyperFlow",
        type: "funnel",
        left: "25%",
        top: 50,
        bottom: 60,
        width: "55%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "descending",
        gap: 5,
        label: {
          show: true,
          position: "inside",
          formatter: (params: {
            name: string;
            value: number;
            data: FunnelChartData;
          }) => {
            return `${params.value}`;
          },
          fontSize: 15,
          color: "white",
        },
        labelLine: {
          length: 20,
          lineStyle: {
            width: 1,
            type: "dashed",
          },
        },
        itemStyle: {
          borderColor: "black",
          borderWidth: 1,
        },

        emphasis: {
          label: {
            fontSize: 25,
          },
        },

        data: [
          { value: step1 ? step1 : 0, name: "Crear Identidad Digital" },
          { value: step3 ? step3 : 0, name: "Formulario" },
          { value: step4 ? step4 : 0, name: "Finalizo" },
        ],
      },
      {
        name: "Funnel HyperFlow",
        type: "funnel",
        left: "25%",
        top: 50,
        bottom: 60,
        width: "55%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "descending",
        gap: 5,
        label: {
          show: true,
          position: "left",
          formatter: (params: {
            name: string;
            value: number;
            data: FunnelChartData;
          }) => {
            return `${params.name} ${params.value}`;
          },
          fontSize: 15,
          color: "white",
          padding: 100,
        },
        labelLine: {
          length: 20,
          lineStyle: {
            width: 1,
            type: "dashed",
          },
        },
        itemStyle: {
          borderColor: "rgba(0, 0, 0, 0.0)", // Semitransparente para las fronteras
          borderWidth: 1,
          color: "rgba(255, 255, 255, 0.0)", // Transparente para el área del embudo
        },

        emphasis: {
          label: {
            fontSize: 18,
          },
        },

        data: [
          { value: step1, name: "Crear Identidad Digital" },
          { value: step3, name: "Formulario" },
          { value: step4, name: "Finalizo" },
        ],
      },
    ],
  };
};
