import "./categoryStyles.scss";
import "../../../../common/commonStyles.scss";

export interface CategoriesProps {
  categories: Array<JSX.Element | null>;
}
export const CategoriesServicesComponent = (props: CategoriesProps) => {
  return (
    <div className="flex-col" style={{ width: "95%" }}>
      <div
        className="text-normal text-xxx-small primary-color-30"
        style={{ marginBottom: "5px", textAlign: "center" }}
      >
        | MENU DESPLEGABLE |
      </div>
      <div
        className="flex-col text-medium text-bold primary-color"
        style={{
          border: "none",
          borderRadius: "8px 8px 0px 0px",
          height: "30px",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1px",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        Servicios ACTIVOS
      </div>
      {props.categories &&
        props.categories.map((category) => {
          return category;
        })}
    </div>
  );
};
