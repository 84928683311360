export const ConfiguracionHelp = () => {
  return (
    <div
      style={{
        overflow: "auto",
        color: "white",
        padding: "2% 2%",
        width: "100%",
        height: "100%",
      }}
    >
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "52px",
        }}
      >
        Introducción
      </h2>
      <p>
        Este manual permite entender como configurar y administrar lo
        relacionado a esta misma plataforma, es decir, el backoffice en el que
        se encuentra.
      </p>
      <p>
        Esta sección le enseñará como navegar en la configuración y podrá
        realizar acciones como:
        <ul>
          <li>
            <strong>Configuración de la cuenta:</strong> Lo cual permite
            configurar el perfil y cuenta del usuario, como cambio de nombre,
            contraseña, etc.
          </li>
          <li>
            <strong>Configuración de usuarios:</strong> Lo cual permite, si es
            administrador, ver, crear, editar, suspender y eliminar usuarios de
            su compañía, así como asignar roles y permisos según sus
            necesidades.
          </li>
          <li>
            <strong>Historial de Acciones:</strong>Herramienta que permite ver y
            analizar las acciones que los usuarios del backoffice han realizado
            sobre el.
          </li>
        </ul>
      </p>
      <p>Todo lo anterior lo encontrarán en el siguiente manual.</p>

      <object
        data="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20CONFIGURACI%C3%93N%20Y%20ADMINISTRACI%C3%93N.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Descarga alternativa:{" "}
          <a href="https://cdn.anteia.co/back-office/manuales/MANUAL%20DE%20USUARIO%20BACKOFFICE%20-%20CONFIGURACI%C3%93N%20Y%20ADMINISTRACI%C3%93N.pdf">
            Si no se ve puede dar click aquí para descargar el PDF
          </a>
        </p>
      </object>
    </div>
  );
};
