import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface MultaProps {
  loading: LoadingStatus;
  item: MultasInfracciones | null;
}

export interface MultasInfracciones {
  encabezado: string;
  resoluciones: Array<ResolucionMulta>;
  comparendos: Array<Comparendo>;
  acuerdos: Array<Acuerdo>;
}

export interface ResolucionMulta {
  estado: string;
  resolucion: string;
  infraccion: string;
  interesMora: string;
  nombreInfractor: string;
  fechaComparendo: string;
  valorMulta: string;
  secretaria: string;
  fechaResolucion: string;
  comparendo: string;
  valorAdicional: string;
  valorAPagar: string;
}

export interface Comparendo {
  fecha: string;
  estado: string;
  total: string;
  infraccion: string;
  nombreInfractor: string;
  valorMulta: string;
  secretaria: string;
  fechaNotificacion: string;
  comparendo: string;
  valorAdicional: string;
  valorAPagar: string;
}

export interface Acuerdo {
  estado: string;
  resolucion: string;
  nombreInfractor: string;
  saldo: string;
  fechaResolucion: string;
  valorAdicional: string;
  valorAPagar: string;
  fechaComparendo: string;
  total: string;
  infraccion: string;
  valorMulta: string;
  secretaria: string;
  comparendo: string;
}

export const MultaResult = (props: MultaProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0.5rem",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Comparendos e infracciones
          </div>
          {props.item.encabezado && (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Encabezado: {props.item.encabezado}
            </div>
          )}

          {props.item.resoluciones && (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  {[
                    "Nombre",
                    "Infraccion",
                    "Interes mora",
                    "Fecha",
                    "Estado",
                    "Secretaria",
                    "Comparendo",
                    "Valor multa",
                    "Valor Adicional",
                    "Valor a Pagar",
                  ].map((header) => (
                    <th
                      key={header}
                      style={{
                        textAlign: "center",
                        padding: "0.5rem 0",
                        fontSize: "0.875rem",
                        color: "rgba(255, 255, 255, 0.35)",
                        fontWeight: 600,
                      }}
                    >
                      {header}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>&nbsp;</td>
                </tr>
                {props.item.resoluciones.map((resolucion, i) => (
                  <tr
                    key={i}
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      height: "2.5rem",
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <td></td>
                    <td>{resolucion.nombreInfractor}</td>
                    <td>{resolucion.infraccion}</td>
                    <td>{resolucion.interesMora}</td>
                    <td>{resolucion.fechaComparendo}</td>
                    <td>{resolucion.estado}</td>
                    <td>{resolucion.secretaria}</td>
                    <td>{resolucion.comparendo}</td>
                    <td>{resolucion.valorMulta}</td>
                    <td>{resolucion.valorAdicional}</td>
                    <td>{resolucion.valorAPagar}</td>
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={12}>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          )}

          {props.item.comparendos && (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  {[
                    "Nombre",
                    "Infraccion",
                    "Fecha",
                    "Fecha de notificación",
                    "Estado",
                    "Secretaria",
                    "Comparendo",
                    "Valor multa",
                    "Valor Adicional",
                    "Valor a Pagar",
                  ].map((header) => (
                    <th
                      key={header}
                      style={{
                        textAlign: "center",
                        padding: "0.5rem 0",
                        fontSize: "0.875rem",
                        color: "rgba(255, 255, 255, 0.35)",
                        fontWeight: 600,
                      }}
                    >
                      {header}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12}>&nbsp;</td>
                </tr>
                {props.item.comparendos.map((comparendo, i) => (
                  <tr
                    key={i}
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      height: "2.5rem",
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <td></td>
                    <td>{comparendo.nombreInfractor}</td>
                    <td>{comparendo.infraccion}</td>
                    <td>{comparendo.fecha}</td>
                    <td>{comparendo.fechaNotificacion}</td>
                    <td>{comparendo.estado}</td>
                    <td>{comparendo.secretaria}</td>
                    <td>{comparendo.comparendo}</td>
                    <td>{comparendo.valorMulta}</td>
                    <td>{comparendo.valorAdicional}</td>
                    <td>{comparendo.valorAPagar}</td>
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={12}>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          )}

          {props.item.acuerdos && (
            <>
              <div
                style={{
                  color: "#fbbf24",
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Acuerdos
              </div>
              <table style={{ position: "relative", width: "100%" }}>
                <thead>
                  <tr>
                    <th></th>
                    {[
                      "Resolución",
                      "Fecha Resolución",
                      "Comparendo",
                      "Fecha Comparendo",
                      "Secretaría",
                      "Nombre infractor",
                      "Estado",
                      "Valor multa",
                      "Valor Adicional",
                      "Total",
                      "Saldo",
                      "Valor a Pagar",
                    ].map((header) => (
                      <th
                        key={header}
                        style={{
                          textAlign: "center",
                          padding: "0.5rem 0",
                          fontSize: "0.875rem",
                          color: "rgba(255, 255, 255, 0.35)",
                          fontWeight: 600,
                        }}
                      >
                        {header}
                      </th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={13}>&nbsp;</td>
                  </tr>
                  {props.item.acuerdos.map((acuerdo, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        height: "2.5rem",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "0.375rem",
                      }}
                    >
                      <td></td>
                      <td>{acuerdo.resolucion}</td>
                      <td>{acuerdo.fechaResolucion}</td>
                      <td>{acuerdo.comparendo}</td>
                      <td>{acuerdo.fechaComparendo}</td>
                      <td>{acuerdo.secretaria}</td>
                      <td>{acuerdo.nombreInfractor}</td>
                      <td>{acuerdo.estado}</td>
                      <td style={{ color: "#fbbf24" }}>{acuerdo.valorMulta}</td>
                      <td>{acuerdo.valorAdicional}</td>
                      <td style={{ color: "#fbbf24" }}>{acuerdo.total}</td>
                      <td style={{ color: "#fbbf24" }}>{acuerdo.saldo}</td>
                      <td style={{ color: "#fbbf24" }}>
                        {acuerdo.valorAPagar}
                      </td>
                      <td></td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={13}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      ) : (
        <div>
          {(props.loading === "rejected" || props.loading === "resolved") && (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          )}
        </div>
      )}
    </div>
  );
};
