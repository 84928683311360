import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changeCurrentListsPageState,
  generateListsReport,
  getFilteredLists,
  getManualInfo,
  selectActiveCoreIdComponent,
  selectCurrentFilterListsRequest,
  selectCurrentManual,
  selectDownloadingReport,
  selectFilteredLists,
  selectListsCurrentPage,
  selectListsNumPages,
  selectLoadingFilteredLists,
  selectManualConsultId,
  selectManualLoading,
} from "../coreIdSlice";
import "../../../../index.scss";
import { BlackListReport, ListsRenderResult } from "../coreIdAPI";
import { AlertDot } from "../../alerts/components/AlertDot";
import Modal, { Styles } from "react-modal";
import { formatDateStandar } from "../../../../helpers/utils";
import {
  getAllUsers,
  selectAllUsers,
} from "../../../configuration/configurationSlice";

Modal.setAppElement("#root");
export const ConsultaManual = () => {
  const customStylesListDetail: Styles = {
    content: {
      top: "50%",
      left: "60%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "40%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.3)",
    },
  };
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let currentManual = useAppSelector(selectCurrentManual);
  let loading = useAppSelector(selectManualLoading);
  const loadingStatus = useAppSelector(selectLoadingFilteredLists);
  const users = useAppSelector(selectAllUsers);
  const currentListsRequest = useAppSelector(selectCurrentFilterListsRequest);

  const consultManualId = useAppSelector(selectManualConsultId);

  let currentPage = useAppSelector(selectListsCurrentPage);
  let currentNumPages = useAppSelector(selectListsNumPages);

  const changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages - 1) {
      dispatch(changeCurrentListsPageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changeCurrentListsPageState(currentPage - 1));
    }
  };

  let dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [identification, setIdentification] = useState("");

  const [listDetailOpen, setListDetailOpen] = useState(false);
  const [consultar, setConsultar] = useState(false);

  const [listDetail, setListDetail] = useState<Array<string>>([]);
  const [currentListConsult, setCurrentListConsult] =
    useState<BlackListReport | null>(null);

  const downloadingReport = useAppSelector(selectDownloadingReport);

  const filteredLists = useAppSelector(selectFilteredLists);

  const getUserName = (id: string | null) => {
    let user = users.find((user) => user.id === id);
    if (user) {
      return user.name;
    } else {
      return "No encontrado";
    }
  };

  const getListRisk = (risk: boolean, warning: boolean) => {
    if (risk) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="HIGH" />
        </div>
      );
    } else if (warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="MEDIUM" />
        </div>
      );
    } else if (!risk && !warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5 style={{ fontSize: "14px", fontWeight: 700, color: "white" }}>
            No
          </h5>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            No
          </h5>
          <AlertDot levelAlert="NONE" />
        </div>
      );
    }
  };

  const listResults = () => {
    let listas = currentManual;
    let renderListas: ListsRenderResult = {
      lists: [],
      numConsultedLists: 0,
      numResultsWithRisk: 0,
      numResultsWithWarning: 0,
    };
    if (listas) {
      for (let listaItem of listas) {
        for (let list of listaItem.listResults) {
          if (list.risk) {
            renderListas.numResultsWithRisk++;
          }
          if (list.warning) {
            renderListas.numResultsWithWarning++;
          }
          if (list.risk || list.warning) {
            renderListas.lists.push(list);
          }
        }
      }
      for (let listaItem of listas) {
        for (let list of listaItem.listResults) {
          renderListas.numConsultedLists++;

          if (!list.risk && !list.warning) {
            renderListas.lists.push(list);
          }
        }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "100%",
          justifyContent: "start",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: "14px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: "white", fontSize: "13px", fontWeight: 700 }}>
              Listas consultadas
            </h5>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: 700,
                  margin: "0 5px 0 0 ",
                }}
              >
                {renderListas.numConsultedLists}
              </h5>
              {renderListas.numConsultedLists > 0 && (
                <AlertDot levelAlert="NONE" />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: "white", fontSize: "13px", fontWeight: 700 }}>
              Listas con riesgo
            </h5>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: 700,
                  margin: "0 5px 0 0 ",
                }}
              >
                {renderListas.numResultsWithRisk}
              </h5>
              {renderListas.numResultsWithRisk > 0 && (
                <AlertDot levelAlert="HIGH" />
              )}
            </div>
          </div>
        </div>

        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "20%",
            marginBottom: "14px",
          }}
        >
          <table style={{ borderCollapse: "collapse" }}>
            <thead
              className="text-normal text-medium primary-color-35"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>
                <th></th>
                <th>Nombre lista</th>
                <th>Tiene riesgo</th>
                <th>Coincidencia por documento</th>
                <th>Coincidencia por nombre</th>
                <th>Detalle</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              className="text-normal text-x-small primary-color"
              style={{
                textAlign: "center",
              }}
            >
              <tr>
                <td></td>
                <td style={{ width: "40%" }}></td>
                <td></td> <td></td>
                <td></td> <td></td>
                <td></td>
                <td></td>
              </tr>
              {renderListas.lists.map((list, i) => {
                return (
                  <tr key={i} className="list-hover" style={{ height: "30px" }}>
                    <td></td>
                    <td>{list.listName}</td>
                    <td>{getListRisk(list.risk, false)}</td>
                    <td>{list.documentMatch ? "Sí" : "No"}</td>
                    <td>{list.nameMatch ? "Sí" : "No"}</td>
                    <td>
                      {list.resultDetails.length > 0 ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setListDetail(list.resultDetails);
                            setListDetailOpen(true);
                          }}
                          style={{
                            color: "#fff",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            border: "none",
                            borderRadius: "8px",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 10px",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          Ver detalle
                        </button>
                      ) : (
                        <>No tiene</>
                      )}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (currentManual === null && loading === "rejected") {
      return (
        <h4 style={{ color: "white", fontSize: "20px" }}>
          Hubo un error consultando las listas <br />
          Intentelo de nuevo más tarde
        </h4>
      );
    } else if (loading === "pending") {
      return <ClipLoader color="#fff" size={"120px"} />;
    } else if (currentManual) {
      return (
        <div style={{ overflow: "auto" }}>
          <p style={{ fontSize: "14px", color: "#fff", fontWeight: 600 }}>
            A continuación van a aparecer todas las listas consultadas y se
            destacaran las listas que tienen algún tipo de riesgo.
            <br /> Para generar un reporte en PDF puedes darle click al
            siguiente botón
          </p>
          {consultManualId && (
            <button
              onClick={() => {
                dispatch(generateListsReport(consultManualId!));
              }}
              style={{
                color: "#fff",
                backgroundColor: "rgba(0,0,0,0.4)",
                border: "none",
                borderRadius: "8px",
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
                padding: "4px 10px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {downloadingReport === "pending" ? (
                <ClipLoader color="#fff" size={"25px"} />
              ) : (
                "Descargar reporte"
              )}
            </button>
          )}
          {listResults()}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  useEffect(() => {
    if (activeComponent !== "Manual") {
      dispatch(changeActiveComponent("Manual"));
    }
    dispatch(getAllUsers());

    dispatch(getFilteredLists({ ...currentListsRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentListsRequest, consultar]);

  const renderToConsult = () => {
    return (
      <>
        <Modal
          isOpen={listDetailOpen}
          style={customStylesListDetail}
          onRequestClose={() => {
            setListDetailOpen(false);
            setListDetail([]);
          }}
        >
          <div className="flex-col">
            <div
              className="text-large text-bold"
              style={{ textAlign: "center" }}
            >
              Detalle de la lista
            </div>
            {listDetail.map((detail, i) => {
              return (
                <div
                  key={i}
                  className="text-small text-normal"
                  style={{
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {detail}
                </div>
              );
            })}
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setListDetailOpen(false);
                  setListDetail([]);
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "20%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        </Modal>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <h4 style={{ color: "white", fontWeight: 700, fontSize: "20px" }}>
              Consulta manual
            </h4>
            <p style={{ color: "white", fontSize: "14px" }}>
              Ingresa a continuación la información solicitada para consultar en
              listas restrictivas
            </p>
            <div
              className="flex-row"
              style={{ marginBottom: "25px", alignItems: "end" }}
            >
              <label className="flex-col" style={{ marginLeft: "12px" }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    marginBottom: "2px",
                  }}
                >
                  Nombre o razón social
                </p>
                <input
                  style={{ width: "250px" }}
                  type="text"
                  placeholder="opcional"
                  value={name}
                  onChange={(e) => {
                    if (e.target.value || e.target.value.length === 0) {
                      setName(e.target.value);
                    }
                  }}
                  className="text-normal text-small primary-color-80 login-input"
                />
              </label>
              <label className="flex-col" style={{ marginLeft: "12px" }}>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    marginBottom: "2px",
                  }}
                >
                  Identificación
                </p>
                <input
                  style={{ width: "200px" }}
                  type="text"
                  placeholder="Ej: 1014574574"
                  value={identification}
                  onChange={(e) => {
                    setIdentification(e.target.value.toLocaleUpperCase());
                  }}
                  className="text-normal text-small primary-color-80 login-input"
                />
              </label>
              <div style={{ width: "250px", marginLeft: "20px" }}>
                <button
                  disabled={identification === "" && name === ""}
                  className="text-bold text-medium primary-color login-button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (identification === "") {
                      dispatch(
                        getManualInfo({
                          identification: "N/A",
                          name,
                        })
                      );
                    } else if (name === "") {
                      dispatch(
                        getManualInfo({
                          identification,
                          name: "N/A",
                        })
                      );
                    } else {
                      dispatch(
                        getManualInfo({
                          identification,
                          name,
                        })
                      );
                    }
                  }}
                >
                  Consultar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-col"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "70%",
            overflow: "auto",
          }}
        >
          {renderContent()}
        </div>
      </>
    );
  };

  const renderToView = () => {
    return (
      <LoadingOverlay
        active={loadingStatus === "pending"}
        spinner={<ClipLoader color="white" size="150px" />}
      >
        {filteredLists && filteredLists.reports.length > 0 ? (
          <div className="flex-col" style={{ width: "100%", height: "100%" }}>
            <div
              className="flex-row"
              style={{
                height: "10%",
                justifyContent: "space-between",
                alignItems: "start",
                margin: "2% 4% 1% 1%",
              }}
            >
              <div
                className="text-xx-large text-bold primary-color"
                style={{ margin: "0 0 0 2%" }}
              >
                Historial de acciones
              </div>
            </div>

            {filteredLists.reports ? (
              <div
                className="flex-col"
                style={{ height: "50%", margin: "1% 2% 0 0 " }}
              >
                <div style={{ overflow: "auto" }}>
                  <table
                    style={{
                      width: "98%",
                      borderCollapse: "collapse",
                      marginLeft: "1%",
                    }}
                  >
                    <thead
                      className="text-normal text-medium primary-color-35"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "30px",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th></th>
                        <th>Nombre consultado</th>
                        <th>Documento Consultado</th>
                        <th>Fecha de consulta</th>
                        <th>Tiene Coincidencias</th>
                        <th>Nombre del usuario</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody
                      className="text-normal text-x-small primary-color"
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {filteredLists.reports.map((report, i) => {
                        return (
                          <tr
                            key={i}
                            className="list-hover"
                            style={{ height: "30px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentListConsult(report);
                            }}
                          >
                            <td></td>
                            <td>{report.name}</td>

                            <td>{report.document}</td>
                            <td>
                              {formatDateStandar(report.consultationDate)}
                            </td>
                            <td>{report.matches ? "Si" : "No"}</td>
                            <td>{getUserName(report.userId)}</td>

                            <td></td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex-col" style={{ height: "70%" }}>
                <h1 className="text-huge text-bold primary-color">
                  {loadingStatus === "pending"
                    ? ""
                    : "No hay más acciones en esta página"}
                </h1>
              </div>
            )}
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                height: "8%",
                margin: "1% 0 0 5%",
              }}
            >
              <div
                className="flex-row text-bold primary-color"
                style={{ marginRight: "100px", fontSize: "13px" }}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    changePage("before");
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {"<"}
                </div>
                Página {currentPage + 1} de {currentNumPages}
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    changePage("after");
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {">"}
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                marginTop: "2%",
              }}
            >
              <div
                className="flex-col"
                style={{
                  width: "80%",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    alignItems: "start",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="text-normal text-small primary-color-40"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      Detalle de la consulta
                    </div>
                    <div
                      className="flex-col"
                      style={{
                        height: "100%",
                        width: "100%",
                        background: "rgba(0,0,0,0.2)",
                        justifyContent: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                        padding: "10px",
                        borderRadius: "8px",
                      }}
                    >
                      {currentListConsult ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              width: "100%",
                              justifyContent: "space-evenly",
                              alignItems: "start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Nombre consultado
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {currentListConsult.name}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Documento Consultado
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {currentListConsult.document}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color:
                                    currentListConsult.numListsMatched > 0
                                      ? "#FFC9C9"
                                      : "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Listas con coincidencia
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {currentListConsult.numListsMatched}
                                <div style={{ marginLeft: "10px" }}>
                                  {currentListConsult.numListsMatched > 0 && (
                                    <AlertDot levelAlert="HIGH" />
                                  )}
                                </div>
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Listas consultadas
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {currentListConsult.numListsConsulted}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              width: "100%",
                              justifyContent: "space-evenly",
                              alignItems: "start",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Usuario que consultó
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {getUserName(currentListConsult.userId)}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "#FFFFFF",
                                  fontSize: "12px",
                                  fontWeight: 800,
                                }}
                              >
                                Fecha y Hora de consulta
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {formatDateStandar(
                                  currentListConsult.consultationDate
                                )}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              dispatch(
                                generateListsReport(currentListConsult.id)
                              );
                            }}
                            style={{
                              color: "#fff",
                              backgroundColor: "rgba(0,0,0,0.4)",
                              border: "none",
                              borderRadius: "8px",
                              alignItems: "center",
                              alignSelf: "center",
                              justifyContent: "center",
                              padding: "4px 10px",
                              fontSize: "14px",
                              fontWeight: 700,
                            }}
                          >
                            {downloadingReport === "pending" ? (
                              <ClipLoader color="#fff" size={"25px"} />
                            ) : (
                              "Descargar reporte"
                            )}
                          </button>
                        </div>
                      ) : (
                        <div
                          className="text-normal primary-color-40"
                          style={{
                            fontSize: "13px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          SELECCIONE UNA CONSULTA PARA VER SU DETALLE
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 className="text-huge text-bold primary-color">
              {loadingStatus === "pending"
                ? ""
                : "No hay más consultas en esta página"}
            </h1>
          </div>
        )}
      </LoadingOverlay>
    );
  };

  return (
    <div
      className="flex-col"
      style={{ width: "100%", height: "100%", padding: "2%" }}
    >
      <div
        className="flex-row"
        style={{ width: "100%", justifyContent: "end" }}
      >
        <div style={{ width: "250px", marginLeft: "20px" }}>
          <button
            style={{
              padding: "5px 0px 5px 0px",
              textAlign: "center",
              color: "#FFF",
              background: "rgba(0,0,0,0.3)",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              alignSelf: "center",
            }}
            className="text-bold text-medium primary-color login-button"
            onClick={(e) => {
              e.preventDefault();
              setConsultar((prev) => !prev);
            }}
          >
            {consultar ? "Ver consultas realizadas" : "Realizar nueva consulta"}
          </button>
        </div>
      </div>
      {consultar ? renderToConsult() : renderToView()}
    </div>
  );
};
