import { ClipLoader } from "react-spinners";
import { LoadingStatus } from "../../../common/commonSlice";
import { UserData, UserDataErrorMessage } from "../../configurationAPI";
import isEmail from "validator/lib/isEmail";

export interface CreateUserFormProps {
  accountData: UserData | null;
  setAccountData: React.Dispatch<React.SetStateAction<UserData>>;
  errorAccountData: UserDataErrorMessage;
  setErrorAccountData: React.Dispatch<
    React.SetStateAction<UserDataErrorMessage>
  >;
  width: string;
  loading: LoadingStatus;
}

export const CreateUserForm = (props: CreateUserFormProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: props.width,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 0px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.4)",
          color: "rgba(255,255,255, 0.35)",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        Información básica
      </div>
      <div
        style={{
          width: "100%",
          padding: "8px 0px 8px 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          fontSize: "13px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "280px",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        {props.loading === "pending" ? (
          <ClipLoader color="#FFF" size="120px" />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <label
                  htmlFor="name"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Nombre
                  </p>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={(e) => {
                      props.setAccountData({
                        ...props.accountData!,
                        name: e.target.value,
                      });
                    }}
                    value={props.accountData?.name || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: props.errorAccountData.nameError
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    {props.errorAccountData.nameError}
                  </p>
                </label>
                <label
                  htmlFor="email"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Correo electrónico
                  </p>
                  <input
                    onChange={(e) => {
                      if (isEmail(e.target.value)) {
                        props.setErrorAccountData({
                          ...props.errorAccountData,
                          emailError: "",
                        });
                      } else {
                        props.setErrorAccountData({
                          ...props.errorAccountData,
                          emailError: "Ingresa un correo electrónico válido",
                        });
                      }
                      props.setAccountData({
                        ...props.accountData!,
                        email: e.target.value,
                      });
                    }}
                    id="email"
                    name="email"
                    type="email"
                    value={props.accountData?.email || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />

                  <p
                    style={{
                      fontSize: "10px",
                      color: "rgba(255, 105, 105, 0.7)",
                      fontWeight: "400",
                      textAlign: "end",
                      margin: "2px 2px 0 0",
                      visibility: !props.errorAccountData.emailError
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    {props.errorAccountData.emailError}
                  </p>
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <label
                  htmlFor="identification"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    No. Documento
                  </p>
                  <input
                    onChange={(e) => {
                      props.setAccountData({
                        ...props.accountData!,
                        identification: e.target.value,
                      });
                    }}
                    id="identification"
                    name="identification"
                    type="text"
                    value={props.accountData?.identification || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  {props.errorAccountData.identificationError && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: "rgba(255, 105, 105, 0.7)",
                        fontWeight: "400",
                        textAlign: "end",
                        margin: "2px 2px 0 0",
                      }}
                    >
                      {props.errorAccountData.identificationError}
                    </p>
                  )}
                </label>
                <label
                  htmlFor="phoneNumber"
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#FFF",
                      fontWeight: "400",
                      textAlign: "start",
                      margin: "0 0 2px 2px",
                    }}
                  >
                    Número celular
                  </p>
                  <input
                    onChange={(e) => {
                      let reg = /^\d+$/;
                      if (
                        (e.target.value === "" || reg.test(e.target.value)) &&
                        e.target.value.length < 12
                      ) {
                        props.setAccountData({
                          ...props.accountData!,
                          phoneNumber: e.target.value,
                        });
                      }
                    }}
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    value={props.accountData?.phoneNumber || ""}
                    style={{
                      border: "0.5px solid rgba(255,255,255,0.2)",
                      borderRadius: "6px",
                      background: "rgba(255,255,255,0.05)",
                      padding: "9px 0px 9px 0px",
                      minHeight: "24px",
                      fontSize: "12px",
                      fontWeight: "600",
                      textAlign: "center",
                      color: "#FFF",
                      width: "100%",
                    }}
                  />
                  {props.errorAccountData.phoneNumberError && (
                    <p
                      style={{
                        fontSize: "10px",
                        color: "rgba(255, 105, 105, 0.7)",
                        fontWeight: "400",
                        textAlign: "end",
                        margin: "2px 2px 0 0",
                      }}
                    >
                      {props.errorAccountData.phoneNumberError}
                    </p>
                  )}
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
