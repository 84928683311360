import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface ListaProps {
  loading: LoadingStatus;
  item: Lista | null;
  name: string;
}

export interface Lista {
  lista: string;
  tipo: string;
  respuestaSin: string;
  tipoDocumentoConsulta: string;
  antecedentes: Array<Antecedente | any>;
  duracion: number;
  presentaRiesgo: boolean;
}

export interface Antecedente {
  country: string;
  placeOfBirth: string;
  idType: string;
  address: string;
  city: string;
  dateOfBirth: string;
  program: string;
  type: string;
  idNumber: string;
  idCountry: string;
  stateOrProvince: string;
  nationalityCountry: string;
}

export const ListaRestrictiva = (props: ListaProps) => {
  const getColor = (presentaRiesgo: boolean) => {
    if (presentaRiesgo) {
      return "rgba(248, 113, 113, 1)";
    } else {
      return "rgba(74, 222, 128, 1)";
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          <div
            style={{
              color: getColor(props.item.presentaRiesgo),
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {props.name}
          </div>
          <div
            style={{
              color: "white",
              fontSize: "1.125rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Datos de la lista:
          </div>
          <div
            style={{
              color: "white",
              fontSize: "1.125rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Tiene antecedentes: {props.item.presentaRiesgo ? "Sí" : "No"}
          </div>
          {!props.item.presentaRiesgo && (
            <div
              style={{
                color: "white",
                fontSize: "1.125rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No tiene antecedentes
            </div>
          )}
          {props.item.antecedentes && (
            <div
              style={{
                color: "white",
                fontSize: "1.125rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Antecedentes
            </div>
          )}
          {props.item.antecedentes && props.item.antecedentes.length > 0 ? (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    País
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Lugar de nacimiento
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Tipo de identificacion
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Nombre
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Nacionalidad
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.antecedentes.map((antecedente, i) => (
                  <tr
                    key={i}
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      height: "2.5rem",
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <td></td>
                    <td>{antecedente.country}</td>
                    <td>{antecedente.placeOfBirth}</td>
                    <td>{antecedente.idType}</td>
                    <td>{antecedente.name}</td>
                    <td>{antecedente.nationalityCountry}</td>
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
