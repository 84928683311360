import axiosApiInstance from "../../../helpers/interceptor";

const getTagsMethod = "tags/allTags";
const getTagsByServiceMethod = "tags/tagsByService";
const getTagsByEntityMethod = "tags/tagsByEntity";
const associateTagMethod = "tags/associateTag";
const disassociateTagMethod = "tags/clearTag";

export const getTagsAPI = async (): Promise<{
  error: string;
  tags: SimpleTag[];
}> => {
  try {
    let tags = await axiosApiInstance.get(getTagsMethod);
    return { error: "", tags: tags.data };
  } catch (error) {
    return { error: "error", tags: [] };
  }
};

export const getTagsByServiceAPI = async (
  service: string
): Promise<{
  error: string;
  tags: TagsByService[];
}> => {
  try {
    let tags = await axiosApiInstance.get(
      `${getTagsByServiceMethod}/${service}`
    );
    return { error: "", tags: tags.data };
  } catch (error) {
    return { error: "error", tags: [] };
  }
};

export const getTagsByEntityAPI = async (
  req: TagsByEntityRequest
): Promise<{
  error: string;
  tags: TagsByEntityResponseItem[];
}> => {
  try {
    let tags = await axiosApiInstance.post(`${getTagsByEntityMethod}`, req);
    return { error: "", tags: tags.data };
  } catch (error) {
    return { error: "error", tags: [] };
  }
};

export const associateTagAPI = async (
  req: AssociateTagRequest
): Promise<{
  error: string;
}> => {
  try {
    let tags = await axiosApiInstance.post(`${associateTagMethod}`, req);
    return { error: "" };
  } catch (error) {
    return { error: "error" };
  }
};

export const disassociateTagAPI = async (
  req: DisassociateTagRequest
): Promise<{
  error: string;
}> => {
  try {
    let tags = await axiosApiInstance.post(`${disassociateTagMethod}`, req);
    return { error: "" };
  } catch (error) {
    return { error: "error" };
  }
};

export interface SimpleTag {
  id: string;
  tag: string | null;
}

export interface TagsByService {
  id: string;
  tag: string | null;
  associatedEntities: string[];
}

export interface TagsByEntityRequest {
  service: string;
  entityId: string;
}

export interface TagsByEntityResponseItem {
  id: string;
  tag: string | null;
}

export interface AssociateTagRequest {
  service: string;
  entityId: string;
  tag: string | null;
  tagId: string;
}

export interface DisassociateTagRequest {
  service: string;
  entityId: string;
  tagId: string;
}
