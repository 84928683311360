import axiosApiInstance from "../../../helpers/interceptor";

let initializePath = "backoffice/authentikator/initialize";
let uploadDocumentPath = "backoffice/authentikator/uploadDocument";
let getConfigsPath = "backoffice/authentikator/getConfigs";
let getConfigByIdPath = (id: string) =>
  `backoffice/authentikator/getConfigById/${id}`;
let getDocumentConfigsPath = "backoffice/authentikator/documentConfigs";
let getDocumentConfigByIdPath = (id: string) =>
  `backoffice/authentikator/documentConfig/${id}`;
let getFilteredPath = "backoffice/authentikator/getFiltered";
let getProcessByIdPath = (id: string) =>
  `backoffice/authentikator/getProcessById/${id}`;
let downloadDocumentPath = (processId: string, documentId: string) =>
  `backoffice/authentikator/downloadDocument/${processId}/${documentId}`;
export interface ResponseWrapper<T> {
  result: boolean;
  data?: T;
  error: string;
}

export const initializeProcessAPI = async (
  req: InitializeRequest
): Promise<ResponseWrapper<InitializeResponse>> => {
  try {
    let response = await axiosApiInstance.post<InitializeResponse>(
      `/${initializePath}`,
      req
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: response.data,
      };
    } else {
      return { result: true, error: "", data: response.data };
    }
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const uploadDocumentAPI = async (
  req: UploadDocumentRequest
): Promise<ResponseWrapper<UploadDocumentResponse>> => {
  try {
    let response = await axiosApiInstance.post(`/${uploadDocumentPath}`, req);
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getConfigsAPI = async (): Promise<
  ResponseWrapper<ATKProcessConfig[]>
> => {
  try {
    let response = await axiosApiInstance.get<ATKProcessConfig[]>(
      `/${getConfigsPath}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getConfigAPI = async (
  id: string
): Promise<ResponseWrapper<ATKProcessConfig>> => {
  try {
    let response = await axiosApiInstance.get<ATKProcessConfig>(
      `/${getConfigByIdPath(id)}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getDocumentConfigsAPI = async (): Promise<
  ResponseWrapper<ATKDocumentConfig[]>
> => {
  try {
    let response = await axiosApiInstance.get<ATKDocumentConfig[]>(
      `/${getDocumentConfigsPath}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getDocumentConfigAPI = async (
  id: string
): Promise<ResponseWrapper<ATKDocumentConfig>> => {
  try {
    let response = await axiosApiInstance.get<ATKDocumentConfig>(
      `/${getDocumentConfigByIdPath(id)}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getFilteredProcessesAPI = async (
  req: ATKGetFilteredRequest
): Promise<ResponseWrapper<ATKGetFilteredResponse>> => {
  try {
    let response = await axiosApiInstance.post<ATKGetFilteredResponse>(
      `/${getFilteredPath}`,
      req
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const getProcessByIdAPI = async (
  id: string
): Promise<ResponseWrapper<ATKProcess>> => {
  try {
    let response = await axiosApiInstance.get<ATKProcess>(
      `/${getProcessByIdPath(id)}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export const downloadDocumentAPI = async (
  processId: string,
  documentId: string
): Promise<ResponseWrapper<ATKDownloadDocumentResponse>> => {
  try {
    let response = await axiosApiInstance.get<ATKDownloadDocumentResponse>(
      `/${downloadDocumentPath(processId, documentId)}`
    );
    if (response.status !== 200) {
      return {
        result: false,
        error: "Error inesperado: " + response.status,
        data: undefined,
      };
    }
    return {
      result: true,
      error: "",
      data: response.data,
    };
  } catch (err: any) {
    return {
      result: false,
      error: "Error inesperado",
    };
  }
};

export interface InitializeRequest {
  configId: string;
  userId?: string;
  manualInfo: ManualInfo;
  manualVerifiableData: { [key: string]: string };
}

export interface InitializeResponse {
  processId: string;
}

export interface ManualInfo {
  fullName?: string;
  email?: string;
  document?: string;
  customId?: string;
}

export interface UploadDocumentRequest {
  fileName: string;
  fileBase64: string;
  processId: string;
  documentId: string;
}

export interface UploadDocumentResponse {
  result: boolean;
  errorMessage?: string;
  blurryImage: boolean;
  incorrectFormat: boolean;
  process: ATKProcess;
  documentId: string;
}

export interface ATKProcess {
  id: string;
  clientId: string;
  configId: string;
  data: ATKProcessDataPoint[];
  userId?: string;
  documents: ATKProcessDocument[];
  createdBackOfficeUserId?: string;
  createdDate?: string;
  lastUpdateDate?: Date;
  done: boolean;
  deleted: boolean;
  deletedDate?: Date;
  deletedByUserId?: string;
  manualInfo: ManualInfo;
  manualVerifiableData: { [key: string]: string };
}

export interface ATKProcessDataPoint {
  dataId: string;
  extractedData: { [key: string]: string };
  allValid: boolean;
  mayorityAgreement?: string;
  mayorityPercentage: number;
  differentDocumentIds: string[];
  manualDataVerified: boolean;
}

export interface ATKProcessDocument {
  documentId: string;
  documentConfigId: string;
  providedFileName: string;
  data: { [key: string]: string };
  uploaded: boolean;
  counter: number;
  type: "IMG" | "PDF";
  uploadedBackofficeUserId: string;
  validityScore: number;
  validityReason: string;
  dateUploaded: Date;
}

export interface ATKDocumentConfig {
  id: string;
  clientId: string;
  name: string;
  description: string;
  documentType: string;
  prompt: string;
  extraInstructions: string;
  dataPoints: ATKDocumentConfigDataPoint[];
  validations: ATKDocumentConfigValidation[];
  supportedTypes: string;
  customModelName: string;
}

export interface ATKDocumentConfigDataPoint {
  labels: string[];
  name: string;
  description: string;
  extraInstructions: string;
  responseName: string;
  dataId: string;
}

export interface ATKDocumentConfigValidation {
  inputId: string;
  type: string;
  value1: number;
  value2: number;
  stringValue: string;
  regex: string;
  date1: Date;
  date2: Date;
}

export interface ATKProcessConfig {
  id: string;
  clientId: string;
  name: string;
  description: string;
  documents: { [key: string]: string };
  dataNameMapper: { [key: string]: string };
}

export interface ATKGetFilteredRequest {
  startDate?: string;
  endDate?: string;
  processConfigId?: string;
  searchQuery?: string;
  page: number;
  pageSize: number;
}

export interface ATKGetFilteredResponse {
  processes: ATKProcess[];
  numPages: number;
}

export interface ATKDownloadDocumentResponse {
  docBase64: string;
  fileName: string;
}
