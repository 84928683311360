import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  checkResetPasswordTokenAPI,
  getClientTokenAPI,
  login,
  recoverEmail,
  resetPasswordAPI,
  ResetPasswordRequest,
  Token,
  TokenRecovery,
} from "./loginAPI";
import localforage from "localforage";
import jwt_decode from "jwt-decode";
import { LoadingStatus } from "../common/commonSlice";
import {
  ClientBrandResponse,
  getClientBrandByCodeAPI,
} from "../common/commonApi";

export interface TokenJson {
  clientId: string;
  metaclientId: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  upn: string;
  userId: string;
  username: string;
}

// Tipos del estado del login
export interface LoginState {
  username: string;
  password: string;
  email: string;
  loading: boolean;
  loadingRecovery: LoadingStatus;
  loadingResetPassword: LoadingStatus;
  errorMessage: string;
  tokenString: string;
  emailRecovery: string;
  sentEmailRecovery: boolean;
  resetPasswordError: string;
  tokenJson: TokenJson;
  clientBrand: ClientBrandResponse | null;
  loadingClientBrand: LoadingStatus;
}

// Estado inicial del login
const initialState: LoginState = {
  username: "",
  password: "",
  email: "",
  loading: false,
  errorMessage: "",
  tokenString: "",
  emailRecovery: "",
  sentEmailRecovery: false,
  loadingRecovery: "idle",
  loadingResetPassword: "idle",
  resetPasswordError: "",
  tokenJson: {
    clientId: "",
    metaclientId: "",
    exp: 0,
    iat: 0,
    iss: "",
    jti: "",
    upn: "",
    userId: "",
    username: "",
  },
  clientBrand: null,
  loadingClientBrand: "idle",
};

// Thunks que permite realizar funciones asincronicas y poder definir que
// pasa segun el resultado (rechazado, aceptado, pendiente).
export const tryLogin = createAsyncThunk(
  "login/tryLogin",
  async (_, { getState, rejectWithValue }) => {
    const currentState = getState() as RootState;
    const username = currentState.login.username;
    const password = currentState.login.password;

    const token = await login(username, password);
    if (!token.loggedIn) {
      return rejectWithValue(token);
    } else {
      await localforage.setItem("token", token.token);
      return token;
    }
  }
);

export const getClientToken = createAsyncThunk(
  "login/getClientToken",
  async (clientId: string, { rejectWithValue }) => {
    const token = await getClientTokenAPI(clientId);
    if (!token) {
      return rejectWithValue(token);
    } else {
      await localforage.setItem("token", token.token);
      return token;
    }
  }
);

export const recovery = createAsyncThunk(
  "login/recovery",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const email = currentState.login.emailRecovery;

    const token = await recoverEmail(email);
    return token;
  }
);

export const checkResetPasswordToken = createAsyncThunk(
  "login/checkResetPasswordToken",
  async (token: string, { rejectWithValue }) => {
    const checkResponse = await checkResetPasswordTokenAPI(token);
    if (!checkResponse.valid) {
      return rejectWithValue(checkResponse);
    } else {
      return checkResponse;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "login/resetPassword",
  async (req: ResetPasswordRequest, { rejectWithValue }) => {
    const response = await resetPasswordAPI(req.token, req.newPassword);
    if (!response.valid) {
      return rejectWithValue(response);
    } else {
      return response;
    }
  }
);

export const getClientBrandByCode = createAsyncThunk(
  "common/getClientBrandByCode",
  async (code: string, { rejectWithValue }) => {
    const clientBrand = await getClientBrandByCodeAPI(code);
    if (clientBrand.error) {
      return rejectWithValue(clientBrand.error);
    }
    if (!clientBrand.data) {
      return rejectWithValue(clientBrand.error);
    } else {
      return clientBrand.data;
    }
  }
);

// Slice, contiene los reducers que se ejecutan sincronicamente
// tambien extraReducers que contienen casos segun la respuesta de los thunk que se
// ejecutan asincronicamente
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    changeUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    changePassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    emptyTokenString: (state) => {
      state.tokenString = "";
    },
    emptyErrorMessage: (state) => {
      state.errorMessage = "";
    },
    changeEmailRecovery: (state, action: PayloadAction<string>) => {
      state.emailRecovery = action.payload;
    },
    changeSentEmailRecovery: (state, action: PayloadAction<boolean>) => {
      state.sentEmailRecovery = action.payload;
    },
    setLoadingClientBrand: (state, action: PayloadAction<LoadingStatus>) => {
      state.loadingClientBrand = action.payload;
      if (action.payload === "rejected") {
        state.clientBrand = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryLogin.fulfilled, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          if (token.loggedIn) {
            let decoded = jwt_decode(token.token) as TokenJson;
            state.username = "";
            state.password = "";
            state.tokenString = token.token;
            state.errorMessage = "";
            state.tokenJson = decoded;
          }
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          state.errorMessage = token.errorMessage;
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(tryLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(recovery.fulfilled, (state, action) => {
        let token = action.payload as TokenRecovery;
        state.emailRecovery = "";
        if (token) {
          state.sentEmailRecovery = true;
          state.errorMessage = "";
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.loading = false;
      })
      .addCase(recovery.rejected, (state, action) => {
        let token = action.payload as TokenRecovery;
        if (token) {
          state.errorMessage = token.errorMessage;
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.loading = false;
      })
      .addCase(recovery.pending, (state) => {
        state.loading = true;
      });

    builder
      .addCase(checkResetPasswordToken.fulfilled, (state, action) => {
        if (action.payload.valid) {
          state.loadingRecovery = "resolved";
        } else {
          state.loadingRecovery = "rejected";
        }
      })
      .addCase(checkResetPasswordToken.rejected, (state, action) => {
        let check = action.payload as { errorMessage: string; valid: boolean };
        if (check.valid) {
          state.loadingRecovery = "resolved";
        } else {
          state.loadingRecovery = "rejected";
        }
      })
      .addCase(checkResetPasswordToken.pending, (state) => {
        state.loadingRecovery = "pending";
      });

    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.errorMessage = "";
        state.loadingResetPassword = "resolved";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        let check = action.payload as { errorMessage: string; valid: boolean };
        if (check.valid) {
          state.errorMessage = "";
          state.loadingResetPassword = "resolved";
        } else {
          state.errorMessage = check.errorMessage;
          state.loadingResetPassword = "rejected";
        }
      })
      .addCase(resetPassword.pending, (state) => {
        state.loadingResetPassword = "pending";
      });

    // SubClient Token
    builder
      .addCase(getClientToken.fulfilled, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          if (token.loggedIn) {
            let decoded = jwt_decode(token.token) as TokenJson;
            state.username = "";
            state.password = "";
            state.tokenString = token.token;
            state.errorMessage = "";
            state.tokenJson = decoded;
          }
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(getClientToken.rejected, (state, action) => {
        let token = action.payload as Token;
        if (token) {
          state.errorMessage = token.errorMessage;
        } else {
          state.errorMessage = "Hubo un error conectandote";
        }
        state.password = "";
        state.loading = false;
      })
      .addCase(getClientToken.pending, (state) => {
        state.loading = true;
      });

    //

    builder
      .addCase(getClientBrandByCode.pending, (state) => {
        state.clientBrand = null;
        state.loadingClientBrand = "pending";
      })
      .addCase(getClientBrandByCode.fulfilled, (state, action) => {
        state.clientBrand = action.payload;
        state.loadingClientBrand = "resolved";
      })
      .addCase(getClientBrandByCode.rejected, (state) => {
        state.clientBrand = null;
        state.loadingClientBrand = "rejected";
      });
  },
});

// Se exponen las acciones que se pueden realizar en este slice.
export const {
  changePassword,
  changeUsername,
  changeEmail,
  changeEmailRecovery,
  emptyTokenString,
  emptyErrorMessage,
  changeSentEmailRecovery,
  setLoadingClientBrand,
} = loginSlice.actions;

// Selectors: Se exportan los valores que contiene el slice.
export const selectUsername = (state: RootState) => state.login.username;
export const selectPassword = (state: RootState) => state.login.password;
export const selectEmail = (state: RootState) => state.login.email;
export const selectLoginLoading = (state: RootState) => state.login.loading;
export const selectLoginErrorMessage = (state: RootState) =>
  state.login.errorMessage;

export const selectTokenString = (state: RootState) => state.login.tokenString;
export const selectEmailRecovery = (state: RootState) =>
  state.login.emailRecovery;
export const selectSentEmailRecovery = (state: RootState) =>
  state.login.sentEmailRecovery;
export const selectLoadingRecovery = (state: RootState) =>
  state.login.loadingRecovery;
export const selectLoadingResetPassword = (state: RootState) =>
  state.login.loadingResetPassword;
export const selectResetPasswordError = (state: RootState) =>
  state.login.resetPasswordError;
export const selectTokenJson = (state: RootState) => state.login.tokenJson;
export const selectClientBrand = (state: RootState) => state.login.clientBrand;
export const selectLoadingClientBrand = (state: RootState) =>
  state.login.loadingClientBrand;
// Se exporta el reducer para poder ser utilizado en el store global
export default loginSlice.reducer;
