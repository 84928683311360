import axios from "axios";
import { RNDCItem } from "./vigia/RNDCResult";
import { FosygaData } from "./vigia/FosygaResult";
import { LicenciaSupendida } from "./vigia/LicenciaSuspendida";
import { Lista } from "./vigia/ListaRestrictiva";
import { AntecedentesPolicia } from "./vigia/PoliciaResult";
import { RamaJudicial } from "./vigia/RamaJudicial";
import { MultasInfracciones } from "./vigia/MultaResult";
import { Procuraduria } from "./vigia/Procuraduria";
import { ProcesosUnificados } from "./vigia/ProcesoUnificado";
import { RuntPersonaResult } from "./vigia/RuntPersona";
import { RuntPlacaResult } from "./vigia/RuntPlaca";
import { SofiaPlus } from "./vigia/SofiaPlus";

let baseUrl = "https://api.inteligenciadatos.anteia.co";

const getResultsIdPath = (id: string) => `${baseUrl}/api/getResults/${id}`;

export const getResultsAPI = async (id: string) => {
  try {
    const token = await getTokenExternal();
    const response = await axios.get<ResultResponse[]>(getResultsIdPath(id), {
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    });
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getTokenExternal = async () => {
  const response = await axios.get<TokenExternalRes>(
    `${baseUrl}/api/authenticate`,
    { auth: { username: "vigiaFase2", password: "7zV#9WGWT!XKg2U!" } }
  );
  return response.data;
};

export interface ResultResponse {
  resultId: string;
  externalId: string;
  identification: string;
  category: string;
  result: ResultData;
}
export interface ResultData {
  rndcCedula: Array<RNDCItem> | null;
  rndcPlaca: Array<RNDCItem> | null;
  fosyga: FosygaData | null;
  licenciasSuspendidas: LicenciaSupendida | null;
  ofac: Lista | null;
  onu: Lista | null;
  ue: Lista | null;
  policia: AntecedentesPolicia | null;
  ramaJudicial: RamaJudicial | null;
  procesosUnificados: ProcesosUnificados | null;
  multas: MultasInfracciones | null;
  procuraduria: Procuraduria | null;
  runtPersona: RuntPersonaResult | null;
  runtPlaca: RuntPlacaResult | null;
  sofiaPlus: SofiaPlus | null;
}

export interface TokenExternalRes {
  token: string;
  expiresIn: number;
}
