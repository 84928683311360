import { useEffect, useState } from "react";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as HighAlert } from "../../../../Assets/alerts/highLevelAlerts.svg";
import { UnifiedAlerts as Alert } from "../../alerts/alertsAPI";
import {
  changeActiveComponent,
  changeAlertPageState,
  getCoreIdAlerts,
  selectActiveCoreIdComponent,
  selectAlertsLoadingStatus,
  selectCoreIdAlertsSeverity,
  selectCurrentCoreIdAlertsRequest,
  selectCurrentPageCoreId,
  selectFilteredCoreIdAlerts,
  selectNumPagesCoreId,
} from "../coreIdSlice";
import { AlertDashboardElement } from "../../alerts/components/AlertDashboardElement";
import { AlertSeverity } from "../../alerts/alertsSlice";
import { formatDateStandar } from "../../../../helpers/utils";
import { AlertDot } from "../../alerts/components/AlertDot";

export const CoreIdAlerts = () => {
  let loading = useAppSelector(selectAlertsLoadingStatus);
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let alerts = useAppSelector(selectFilteredCoreIdAlerts);
  let currentPage = useAppSelector(selectCurrentPageCoreId);
  let numPages = useAppSelector(selectNumPagesCoreId);
  let currentAlertsRequest = useAppSelector(selectCurrentCoreIdAlertsRequest);
  let alertsSeverity = useAppSelector(selectCoreIdAlertsSeverity);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changeAlertPageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changeAlertPageState(currentPage - 1));
    }
  };

  let alertNavigate = (alert: Alert) => {
    switch (alert.service) {
      case "COREID":
        return `/services/coreId/registrations/${currentAlert.entityId}`;
      case "MAGICFORMS":
        return `/services/magicForms/filledForms/${currentAlert.entityId}`;
      case "FIRMA":
        return `/services/electronicSignatureNew/signatures/${currentAlert.entityId}`;
      case "HYPERFLOW":
        return `/services/hyperFlow/flows/${currentAlert.entityId}`;
      default:
        return "/services/alerts";
    }
  };

  const getColorDot = (levelAlert: AlertSeverity) => {
    switch (levelAlert) {
      case "NONE":
        return <div></div>;
      default:
        return <AlertDot levelAlert={levelAlert} />;
    }
  };

  const [currentAlert, setCurrentAlert] = useState<Alert>({
    date: "",
    description: "",
    id: "",
    service: "",
    category: "",
    userId: "",
    criticality: "",
    entityId: "",
    status: "",
    name: "",
    userName: "",
  });

  useEffect(() => {
    if (activeComponent !== "Alerts") {
      dispatch(changeActiveComponent("Alerts"));
    }
    dispatch(getCoreIdAlerts({ ...currentAlertsRequest }));
  }, [activeComponent, currentPage, currentAlertsRequest]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {alerts && alerts.alerts.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "15%",
              justifyContent: "center",
              alignItems: "start",
              marginBottom: "1%",
            }}
          >
            <AlertDashboardElement
              dot={getColorDot(alertsSeverity.compliance)}
              title="Compliance"
              subtitle="Revisa el estado del cumplimiento normativo"
            />
            <AlertDashboardElement
              dot={getColorDot(alertsSeverity.dates)}
              title="Fechas"
              subtitle="Revisa si se vence una fecha"
            />
            <AlertDashboardElement
              dot={getColorDot(alertsSeverity.incongruences)}
              title="Incongruencias"
              subtitle="Revisa si hay incongruencias"
            />
            <AlertDashboardElement
              dot={getColorDot(alertsSeverity.attachments)}
              title="adjuntos"
              subtitle="Revisa si hay problemas con adjuntos"
            />
            <AlertDashboardElement
              dot={getColorDot(alertsSeverity.authenticity)}
              title="autenticidad"
              subtitle="Revisa si falló una prueba de autenticidads"
            />
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "start",
              height: "40%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "60%", overflow: "auto", height: "90%" }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Servicio</th>
                    <th>Fecha y Hora</th>
                    <th>Categoría</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {alerts?.alerts.map((alert, i) => {
                    return (
                      <tr
                        key={i}
                        className="list-hover"
                        style={{ height: "30px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentAlert(alert);
                        }}
                      >
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {alert.service}
                        </td>
                        <td>{formatDateStandar(alert.date)}</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {alert.category}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "2%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {numPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              marginTop: "2%",
              height: "20%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "60%", alignItems: "center" }}
            >
              <div
                className="flex-row"
                style={{
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "80%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    marginRight: "3%",
                  }}
                >
                  <div className="text-normal text-small primary-color">
                    Descripción de la alerta
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "rgba(0,0,0,0.2)",
                      justifyContent: "center",
                      alignItems: "center",
                      boxSizing: "border-box",
                      paddingBottom: "6px",
                      borderRadius: "8px",
                      paddingLeft: "5%",
                      paddingTop: "2%",
                      paddingRight: "2%",
                    }}
                  >
                    <div
                      className="text-normal primary-color "
                      style={{ fontSize: "13px", height: "100%" }}
                    >
                      {currentAlert.description}
                    </div>
                  </div>
                </div>
              </div>
              {currentAlert.id !== "" ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(alertNavigate(currentAlert));
                  }}
                  className="flex-col text-bold text-medium primary-color-35"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  Ir al servicio de origen
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" ? "" : "No existen alertas actualmente"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
