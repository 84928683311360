export interface MediumSquareProps {
  title: string;
  content: JSX.Element;
  parentWidth: string;
}

export const MediumSquare = (props: MediumSquareProps) => {
  return (
    <div
      className="flex-col"
      style={{
        width: props.parentWidth,
        height: "100%",
        marginRight: "1%",
      }}
    >
      <div
        className="flex-col text-medium text-normal primary-color-35"
        style={{
          background: "rgba(0,0,0,0.4)",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          paddingTop: "2%",
          paddingBottom: "2%",
          height: "20%",
        }}
      >
        {props.title}
      </div>
      <div
        className="flex-col text-medium text-normal primary-color"
        style={{
          background: "rgba(0,0,0,0.2)",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          height: "80%",
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

export const SmallSquare = (props: MediumSquareProps) => {
  return (
    <div
      className="flex-col"
      style={{
        width: props.parentWidth,
        height: "100%",
        marginRight: "1%",
      }}
    >
      <div
        className="flex-col text-medium text-normal primary-color-35"
        style={{
          background: "rgba(0,0,0,0.4)",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          paddingTop: "2%",
          paddingBottom: "2%",
          height: "40%",
        }}
      >
        {props.title}
      </div>
      <div
        className="flex-col text-medium text-normal primary-color"
        style={{
          background: "rgba(0,0,0,0.2)",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingLeft: "2%",
          paddingRight: "2%",
          height: "60%",
        }}
      >
        {props.content}
      </div>
    </div>
  );
};
