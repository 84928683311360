import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Modal, { Styles } from "react-modal";
import {
  changeActiveComponent,
  changeEventPageSize,
  changeEventPageState,
  changePageState,
  getAllUsers,
  getFilteredEvents,
  selectActiveConfigurationComponent,
  selectAllUsers,
  selectCurrentEventFilters,
  selectCurrentEvents,
  selectEventsCurrentNumPages,
  selectEventsCurrentPage,
  selectEventsPageSize,
  selectLoadingEvents,
} from "../configurationSlice";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../helpers/utils";
import { EventData } from "../configurationAPI";
import { usePagination } from "pagination-react-js";
import PaginationItem from "../../../helpers/PaginationItem";

Modal.setAppElement("#root");

const customStylesListDetail: Styles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "40%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
export const ActionHistory = () => {
  let events = useAppSelector(selectCurrentEvents);
  let currentPage = useAppSelector(selectEventsCurrentPage);
  let currentNumPages = useAppSelector(selectEventsCurrentNumPages);
  const pageSize = useAppSelector(selectEventsPageSize);
  let loadingStatus = useAppSelector(selectLoadingEvents);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentEventFilters);

  const [currentEvent, setCurrentEvent] = useState<EventData | null>(null);
  const [currentEventDetail, setCurrentEventDetail] = useState<{
    [key: string]: string;
  } | null>(null);
  const [showDetail, setShowDetail] = useState(false);

  const users = useAppSelector(selectAllUsers);

  let dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateToEntityId = () => {
    if (!currentEvent) return;
    switch (currentEvent.service) {
      case "COREID":
        return `/services/coreId/registrations/${currentEvent.entityId}`;
      case "MAGICFORMS":
        return `/services/magicForms/filledForms/${currentEvent.entityId}`;
      case "FIRMA":
        return `/services/electronicSignatureNew/signatures/${currentEvent.entityId}`;
      case "HYPERFLOW":
        return `/services/hyperFlow/flows/${currentEvent.entityId}`;
      default:
        return "/services/alerts";
    }
  };

  const getProductName = (name: string | null) => {
    switch (name) {
      case "COREID":
        return "CoreId";
      case "FIRMA":
        return "Firma Electrónica";
      case "HYPERFLOW":
        return "HyperFlow";
      case "SMARTFLOW":
        return "SmartFlow";
      case "MAGICFORMS":
        return "MagicForms";
      case "PAGARES":
        return "Pagarés";
      case "CLIENTS":
        return "Subclients";
      case "USERS":
        return "Usuarios";
      case "ACCOUNT":
        return "Cuenta";
      case "ALERTS":
        return "Alertas";
      default:
        return name;
    }
  };

  const getEventType = (name: string | null) => {
    switch (name) {
      case "STATUSCHANGE":
        return "Cambio de estado";
      default:
        return name;
    }
  };

  const getStatusName = (name: string | null) => {
    switch (name) {
      case "ACCEPTED":
        return "Aprobado";
      case "REJECTED":
        return "Rechazado";
      case "REVISION":
        return "En revisión";
      case "IN_PROGRESS":
        return "En progreso";
      default:
        return name;
    }
  };

  const getUserName = (id: string | null) => {
    let user = users.find((user) => user.id === id);
    if (user) {
      return user.name;
    } else {
      return "No encontrado";
    }
  };

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: pageSize || 10,
        totalRecordsLength: currentNumPages * 10,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changeEventPageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changeEventPageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  useEffect(() => {
    if (activeComponent !== "ActionHistory") {
      dispatch(changeActiveComponent("ActionHistory"));
    }
    dispatch(getAllUsers());
    dispatch(getFilteredEvents({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={showDetail}
        style={customStylesListDetail}
        onRequestClose={() => {
          setShowDetail(false);
          setCurrentEventDetail(null);
        }}
      >
        <div className="flex-col">
          <div
            className="text-large text-bold"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            Detalle de la acción
          </div>
          {currentEventDetail &&
            Object.keys(currentEventDetail).map((key, i) => {
              return (
                <div
                  key={i}
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0 10px 0",
                  }}
                >
                  <p style={{ fontWeight: 700, margin: 0 }}>{key}</p>
                  <p style={{ fontWeight: 500, margin: 0 }}>
                    {getStatusName(currentEventDetail[key])}
                  </p>
                </div>
              );
            })}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setShowDetail(false);
                setCurrentEventDetail(null);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {events && events.events.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "space-between",
              alignItems: "start",
              margin: "2% 4% 1% 1%",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ margin: "0 0 0 2%" }}
            >
              Historial de acciones
            </div>
          </div>

          {events.events ? (
            <div
              className="flex-col"
              style={{ height: "50%", margin: "1% 2% 0 0 " }}
            >
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre de acción</th>
                      <th>Fecha y hora</th>
                      <th>Tipo de evento</th>
                      <th>Producto</th>
                      <th>Nombre del usuario</th>
                      <th>Exitoso</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {events.events.map((event, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentEvent(event);
                          }}
                        >
                          <td></td>
                          <td>{event.name}</td>
                          <td>{formatDateStandar(event.startedDate)}</td>
                          <td>{getEventType(event.type)}</td>
                          <td>{getProductName(event.service)}</td>
                          <td>{getUserName(event.userId)}</td>
                          <td>{event.completedAction ? "Si" : "No"}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td> <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más acciones en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: "8%",
              margin: "1% 0 0 5%",
            }}
          >
            <Pagination />
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <div
              className="flex-col"
              style={{
                width: "80%",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    width:
                      currentEvent &&
                      (currentEvent.currentState || currentEvent.previousState)
                        ? "45%"
                        : "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="text-normal text-small primary-color-40"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    Descripción de la acción
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "rgba(0,0,0,0.2)",
                      justifyContent: "center",
                      alignItems: "center",
                      boxSizing: "border-box",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    {currentEvent ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-evenly",
                          alignItems: "start",
                        }}
                      >
                        <h4
                          style={{
                            margin: "10px 0 10px 0px",
                            color: "#FFC9C9",
                            fontSize: "11px",
                            fontWeight: 700,
                          }}
                        >
                          Descripción de la acción
                        </h4>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#fff",
                          }}
                        >
                          {currentEvent.description}
                        </p>
                        <button
                          onClick={() => {
                            setShowDetail(true);
                            setCurrentEventDetail(currentEvent.metadata);
                          }}
                          style={{
                            color: "#fff",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            border: "none",
                            borderRadius: "8px",
                            alignItems: "center",
                            alignSelf: "center",
                            justifyContent: "center",
                            padding: "4px 10px",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          Ver más
                        </button>
                      </div>
                    ) : (
                      <div
                        className="text-normal primary-color-40"
                        style={{
                          fontSize: "13px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        SELECCIONE UNA ACCIÓN PARA VER SU DESCRIPCIÓN
                      </div>
                    )}
                  </div>
                </div>
                {currentEvent &&
                  (currentEvent.currentState || currentEvent.previousState) && (
                    <div
                      className="flex-col"
                      style={{
                        justifyContent: "center",
                        width: "45%",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="text-normal text-small primary-color-40"
                        style={{ margin: "0 0 10px 0" }}
                      >
                        Cambios
                      </div>
                      <div
                        className="flex-col"
                        style={{
                          height: "100%",
                          width: "100%",
                          background: "rgba(0,0,0,0.2)",
                          justifyContent: "center",
                          alignItems: "center",
                          boxSizing: "border-box",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        {currentEvent ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              width: "100%",
                              justifyContent: "space-evenly",
                              alignItems: "start",
                            }}
                          >
                            {currentEvent.previousState && (
                              <>
                                <h4
                                  style={{
                                    margin: "10px 0 10px 0px",
                                    color: "#FFC9C9",
                                    fontSize: "11px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Estado anterior
                                </h4>
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    color: "#fff",
                                  }}
                                >
                                  {getStatusName(currentEvent.previousState)}
                                </p>
                              </>
                            )}
                            {currentEvent.currentState && (
                              <>
                                <h4
                                  style={{
                                    margin: "10px 0 10px 0px",
                                    color: "#D4FFC9",
                                    fontSize: "11px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Estado actual
                                </h4>
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    color: "#fff",
                                  }}
                                >
                                  {getStatusName(currentEvent.currentState)}
                                </p>
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className="text-normal primary-color-40"
                            style={{
                              fontSize: "13px",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            SELECCIONE UNA ACCIÓN PARA VER SUS CAMBIOS
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "2% 0 0 0",
              width: "100%",
            }}
          >
            {currentEvent && currentEvent.entityId && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const url = navigateToEntityId();
                  if (url) {
                    navigate(url);
                  }
                }}
                style={{
                  color: "#fff",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  borderRadius: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px 10px",
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                Ver registro origen
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más acciones en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
