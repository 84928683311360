import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface LicenciaSuspendidaProps {
  loading: LoadingStatus;
  item: LicenciaSupendida | null;
}
export interface LicenciaSupendida {
  mensajeSin: string;
  tieneAntecedentes: boolean;
  sanciones: Array<SancionLicencia>;
  presentaRiesgo: boolean;
}

export interface SancionLicencia {
  estado: string;
  licencia: string;
  categoria: string;
}

export const LicenciaSuspendida = (props: LicenciaSuspendidaProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          <div
            style={{
              color: props.item.presentaRiesgo
                ? "rgba(248, 113, 113, 1)"
                : "rgba(74, 222, 128, 1)",
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Licencias Suspendidas
          </div>
          <div
            style={{
              color: "white",
              fontSize: "1.125rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Tiene antecedentes: {props.item.tieneAntecedentes ? "Sí" : "No"}
          </div>
          {props.item.sanciones ? (
            <div
              style={{
                color: "white",
                fontSize: "1.125rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Sanciones
            </div>
          ) : (
            <div
              style={{
                color: "white",
                fontSize: "1.125rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {props.item.mensajeSin}
            </div>
          )}
          {props.item.sanciones && props.item.sanciones.length > 0 ? (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Estado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Licencia
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Categoria
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.sanciones.map((sancion, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        height: "2.5rem",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "0.375rem",
                      }}
                    >
                      <td></td>
                      <td>{sancion.estado}</td>
                      <td>{sancion.licencia}</td>
                      <td>{sancion.categoria}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
