import axiosApiInstance from "../../../../helpers/interceptor";
import { FormTemplate } from "../dtos/FormTemplate";
import { DrawableFormTemplate } from "../dtos/DrawableFormTemplate";
import { PdfTemplate } from "../dtos/PdfTemplate";
import { MfEditHistory } from "../dtos/MfEditHistory";

let getTemplatesPath = "api/Backoffice/editor/mf/getTemplates";
let getTemplateByIdPath = (id: string) =>
  `api/Backoffice/editor/mf/getTemplateById/${id}`;
let getHistoryByIdPath = (id: string) =>
  `api/Backoffice/editor/mf/getHistoryById/${id}`;
let updateFormByIdPath = (id: string) =>
  `api/Backoffice/editor/mf/updateFormById/${id}`;

let getModalsPath = "api/Backoffice/editor/mf/modalConfig/getAll";
let getModalByIdPath = (id: string) =>
  `api/Backoffice/editor/mf/modalConfig/getById/${id}`;
let updateModalByIdPath = (id: string) =>
  `api/Backoffice/editor/mf/modalConfig/updateById/${id}`;
let createModalPath = "api/Backoffice/editor/mf/modalConfig/create";

const getTemplatesAPI = async (): Promise<
  EditorResponseWrapper<{ [key: string]: string }>
> => {
  try {
    let templates = await axiosApiInstance.get(getTemplatesPath);
    if (templates.status !== 200) {
      return { success: false, data: {}, error: `error: ${templates.status}` };
    }
    return { success: true, data: templates.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const getTemplateByIdAPI = async (
  id: string
): Promise<EditorResponseWrapper<GetTemplateResponse>> => {
  try {
    let template = await axiosApiInstance.get(getTemplateByIdPath(id));
    if (template.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${template.status}`,
      };
    }
    return { success: true, data: template.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const getHistoryByIdAPI = async (
  id: string
): Promise<EditorResponseWrapper<MfEditHistory>> => {
  try {
    let history = await axiosApiInstance.get(getHistoryByIdPath(id));
    if (history.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${history.status}`,
      };
    }
    return { success: true, data: history.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const updateFormTemplateByIdAPI = async (
  id: string,
  formTemplate?: FormTemplate,
  drawableTemplate?: DrawableFormTemplate,
  pdfTemplate?: PdfTemplate
): Promise<EditorResponseWrapper<void>> => {
  try {
    let response = await axiosApiInstance.post(updateFormByIdPath(id), {
      formTemplate: formTemplate,
      drawableTemplate: drawableTemplate,
      pdfTemplate: pdfTemplate,
    });
    if (response.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${response.status}`,
      };
    }
    return { success: true, data: undefined, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const getModalsAPI = async (): Promise<
  EditorResponseWrapper<ModalConfig[]>
> => {
  try {
    let modals = await axiosApiInstance.get(getModalsPath);
    if (modals.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${modals.status}`,
      };
    }
    return { success: true, data: modals.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const getModalByIdAPI = async (
  id: string
): Promise<EditorResponseWrapper<ModalConfig>> => {
  try {
    let modal = await axiosApiInstance.get(getModalByIdPath(id));
    if (modal.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${modal.status}`,
      };
    }
    return { success: true, data: modal.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};  

const updateModalByIdAPI = async (
  id: string,
  modal: ModalConfig
): Promise<EditorResponseWrapper<void>> => {
  try {
    let response = await axiosApiInstance.post(updateModalByIdPath(id), modal);
    if (response.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${response.status}`,
      };
    }
    return { success: true, data: undefined, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const createModalAPI = async (
  modal: CreateModalConfigRequest
): Promise<EditorResponseWrapper<void>> => {
  try {
    let response = await axiosApiInstance.post(createModalPath, modal);
    if (response.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${response.status}`,
      };
    }
    return { success: true, data: undefined, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
}

export interface EditorResponseWrapper<T> {
  success: boolean;
  data?: T;
  error: string;
}

export interface GetTemplateResponse {
  formTemplate: FormTemplate;
  drawableTemplate: DrawableFormTemplate;
  pdfTemplate: PdfTemplate;
}

export interface UpdateFormTemplateRequest {
  formTemplate?: FormTemplate;
  drawableTemplate?: DrawableFormTemplate;
  pdfTemplate?: PdfTemplate;
}


export interface CreateModalConfigRequest {
  name: string;
  description: string;
  items: ModalConfigItem[];
}
export interface ModalConfig {
  id: string;
  name: string;
  description: string;
  items: ModalConfigItem[];
}

export interface ModalConfigItem {
  text: string;
  style: { [key: string]: string };
}

export {
  getTemplatesAPI,
  getTemplateByIdAPI,
  getHistoryByIdAPI,
  updateFormTemplateByIdAPI,
  getModalsAPI,
  getModalByIdAPI,
  updateModalByIdAPI,
  createModalAPI
};
