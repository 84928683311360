import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  getRegistrationByIdAPI,
  getImagesRegistrationAPI,
  Pictures,
  Attatchment,
} from "../coreid/coreIdAPI";
import {
  getGeneratedDocumentsNewAPI,
  getSignatureByIdAPI,
  GetSignatureResponse,
} from "../firmanew/firmaNewAPI";
import { File, GeneratedDocuments } from "../firma/firmaAPI";
import {
  FilledForm,
  getFilledFormByIdAPI,
  getPdfFilledFormsAPI,
  getUserAttatchmentsAPI,
} from "../magicforms/magicFormsAPI";
import {
  changeFlowStatusAPI,
  Flow,
  FlowFilteredOptions,
  getFilteredFlowAPI,
  GetFilteredFlowRequest,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  WebhookData,
} from "./smartFlowAPI";

export type SmartflowActiveComponent = "flows" | "Analytics" | "Detail";

export interface SmartFlowState {
  smartFlowActiveComponent: SmartflowActiveComponent;
  loadingStatus: LoadingStatus;
  loadingFlow: LoadingStatus;
  documentLoading: LoadingStatus;
  documentSeguroLoading: LoadingStatus;
  currentFilteredRequest: GetFilteredFlowRequest;
  currentPage: number;
  numPages: number;
  flowFiltered: Flow[];
  filteredOptions: FlowFilteredOptions;
  currentFlow: Flow | null;
  isFlowDetail: boolean;
  currentFlowStatus: string;
  formPDFBase64: string | null;
  formSeguroPDFBase64: string | null;
  loadingForm: LoadingStatus;
  loadingFormSeguro: LoadingStatus;
  filledForm: FilledForm | null;
  filledFormSeguro: FilledForm | null;
  loadingSignature: LoadingStatus;
  signature: GetSignatureResponse | null;
  loadingSignatureDocs: LoadingStatus;
  signatureDocs: GeneratedDocuments | null;
  loadingPictures: LoadingStatus;
  pictures: Pictures | null;
  webhookData: WebhookData | null;
  getFlowByIdRequestId: string;
  changeStatusByIdRequestId: string;
  getFlowFilledFormRequestId: string;
  getFlowFilledFormSeguroRequestId: string;
  getSmartFlowFormByIdRequestId: string;
  getSmartFlowFormSeguroByIdRequestId: string;
  getSmartFlowRegistrationImagesRequestId: string;
  getSmartFlowSignatureByIdRequestId: string;
  //Seguro Signature
  getSmartFlowSeguroSignatureByRequestId: string;
  getSmartFlowSignatureDocumentsSeguroByIdRequestId: string;
  signatureSeguro: GetSignatureResponse | null;
  signatureSeguroDocs: GeneratedDocuments | null;
  loadingSeguroSignature: LoadingStatus;
  loadingSeguroSignatureDocs: LoadingStatus;

  getSmartFlowSignatureDocumentsByIdRequestId: string;
  //Atachments
  userAttachments: Array<Attatchment>;
  loadingAttachments: LoadingStatus;

}

const initialState: SmartFlowState = {
  smartFlowActiveComponent: "flows",
  loadingStatus: "idle",
  documentLoading: "idle",
  documentSeguroLoading: "idle",
  currentFilteredRequest: {
    page: 0,
    searchQuery: null,
    endDate: null,
    startDate: null,
    flowId: null,
    status: null,
  },
  loadingFlow: "idle",
  flowFiltered: [],
  currentPage: 0,
  numPages: 0,
  filteredOptions: {},
  currentFlow: null,
  isFlowDetail: false,
  currentFlowStatus: "IN_PROGRESS",
  formPDFBase64: null,
  formSeguroPDFBase64: null,
  loadingForm: "idle",
  loadingFormSeguro: "idle",
  filledForm: null,
  filledFormSeguro: null,
  loadingSignature: "idle",
  pictures: null,
  loadingPictures: "idle",
  signatureDocs: null,
  loadingSignatureDocs: "idle",
  signature: null,
  webhookData: null,
  changeStatusByIdRequestId: "",
  getFlowByIdRequestId: "",
  getFlowFilledFormRequestId: "",
  getFlowFilledFormSeguroRequestId: "",
  getSmartFlowFormByIdRequestId: "",
  getSmartFlowFormSeguroByIdRequestId: "",
  getSmartFlowRegistrationImagesRequestId: "",
  getSmartFlowSignatureByIdRequestId: "",
  //Seguro Signature
  getSmartFlowSignatureDocumentsByIdRequestId: "",
  getSmartFlowSignatureDocumentsSeguroByIdRequestId: "",
  signatureSeguro: null,
  signatureSeguroDocs: null,
  loadingSeguroSignature: "idle",
  loadingSeguroSignatureDocs: "idle",

  getSmartFlowSeguroSignatureByRequestId: "",
  userAttachments: [],
  loadingAttachments: "idle",
};

export const getFilteredFlow = createAsyncThunk(
  "smartFlow/getFilteredFlow",
  async (params: GetFilteredFlowRequest, { rejectWithValue }) => {
    const filteredFlow = await getFilteredFlowAPI(params);
    if (
      !filteredFlow.flows ||
      (filteredFlow.error && filteredFlow.error !== "")
    ) {
      return rejectWithValue(filteredFlow.error);
    } else {
      for (let flow of filteredFlow.flows.flows) {
        try {
          let reg = await getRegistrationByIdAPI(flow.userId);
          if (reg.registration) {
            flow.registration = reg.registration;
          }
        } catch (err) {}
      }
      return filteredFlow;
    }
  }
);

export const getFilterOptions = createAsyncThunk(
  "smartFlow/getFilterOptions",
  async (_, { rejectWithValue }) => {
    const filterOptions = await getFilterOptionsFlowAPI();
    if (filterOptions.error && filterOptions.error !== "") {
      return rejectWithValue(filterOptions.error);
    } else {
      return filterOptions;
    }
  }
);

export const getFlowById = createAsyncThunk(
  "smartFlow/getFlowById",
  async (flowId: string, { rejectWithValue }) => {
    const flow = await getFlowByIdAPI(flowId);
    if (!flow.flow || (flow.error && flow.error !== "")) {
      return rejectWithValue(flow.error);
    } else {
      let reg = await getRegistrationByIdAPI(flow.flow.userId!);
      flow.flow.registration = reg.registration!;
      return flow.flow;
    }
  }
);

export const getSmartFlowRegistrationImages = createAsyncThunk(
  "smartFlow/getSmartFlowRegistrationImages",
  async (userId: string, { rejectWithValue }) => {
    try {
      let images = await getImagesRegistrationAPI(userId);
      if (images.error) {
        return rejectWithValue(images.error);
      } else {
        return images.pictures;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowFormById = createAsyncThunk(
  "smartFlow/getSmartFlowFormById",
  async (formId: string, { rejectWithValue }) => {
    try {
      console.log("getForm: ", formId);
      let form = await getFilledFormByIdAPI(formId);
      if (form.error) {
        return rejectWithValue(form.error);
      } else {
        return form.filledForm;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowFormSeguroById = createAsyncThunk(
  "smartFlow/getSmartFlowFormSeguroById",
  async (formId: string, { rejectWithValue }) => {
    try {
      console.log("getFormSeguro: ", formId);
      let form = await getFilledFormByIdAPI(formId);
      if (form.error) {
        return rejectWithValue(form.error);
      } else {
        return form.filledForm;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowSignatureById = createAsyncThunk(
  "smartFlow/getSmartFlowSignatureById",
  async (signatureId: string, { rejectWithValue }) => {
    try {
      
      let firma = await getSignatureByIdAPI(signatureId);
      if (firma.error) {
        return rejectWithValue(firma.error);
      } else {
        return firma.signature;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowSignatureSeguroById = createAsyncThunk(
  "smartFlow/getSmartFlowSignatureSeguroById",
  async (signatureId: string, { rejectWithValue }) => {
    try {
      let firma = await getSignatureByIdAPI(signatureId);
      if (firma.error) {
        return rejectWithValue(firma.error);
      } else {
        return firma.signature;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowSignatureDocumentsById = createAsyncThunk(
  "smartFlow/getSmartFlowSignatureDocumentsById",
  async (signatureId: string, { rejectWithValue }) => {
    try {
      let firmaPDFS = await getGeneratedDocumentsNewAPI(signatureId);
      if (firmaPDFS.error) {
        return rejectWithValue(firmaPDFS.error);
      } else {
        return firmaPDFS.documents;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getSmartFlowSignatureDocumentsSeguroById = createAsyncThunk(
  "smartFlow/getSmartFlowSignatureDocumentsSeguroById",
  async (signatureId: string, { rejectWithValue }) => {
    try {
      let firmaPDFS = await getGeneratedDocumentsNewAPI(signatureId);
      if (firmaPDFS.error) {
        return rejectWithValue(firmaPDFS.error);
      } else {
        return firmaPDFS.documents;
      }
    } catch {
      rejectWithValue("error");
    }
  }
);

export const changeStatusById = createAsyncThunk(
  "smartFlow/changeFlowStatusById",
  async (
    params: { comment: string; status: string },
    { getState, rejectWithValue, dispatch }
  ) => {
    const currentState = getState() as RootState;
    const flow = currentState.smartFlow.currentFlow;
    if (flow?.status) {
      const updatedFlow = await changeFlowStatusAPI(
        params.status,
        params.comment,
        flow.id
      );
      if (updatedFlow.error && updatedFlow.error !== "") {
        return rejectWithValue(updatedFlow.error);
      } else {
        return updatedFlow.flow;
      }
    } else {
      return;
    }
  }
);

export const getFlowFilledFormPDF = createAsyncThunk(
  "smartFlow/getFlowFormPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      console.log("getPDF: ", id);
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getFlowFilledFormSeguroPDF = createAsyncThunk(
  "smartFlow/getFlowFilledFormSeguroPDF",
  async (id: string, { rejectWithValue }) => {
    try {
      console.log("getPDFSeguro: ", id);
      const filledFormPDF = await getPdfFilledFormsAPI(id);
      return filledFormPDF.pdf;
    } catch {
      rejectWithValue("error");
    }
  }
);

export const getAttatchmentByRegistrationId = createAsyncThunk(
  "hyperflow/getAttachments",
  async (regId: string) => {
    let attatchments = await getUserAttatchmentsAPI(regId);

    return attatchments;
  }
);

export const smartFlowSlice = createSlice({
  name: "smartFlow",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<GetFilteredFlowRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      state.currentFilteredRequest = {
        ...state.currentFilteredRequest,
        page: action.payload,
      };
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      state.currentFilteredRequest = {
        ...state.currentFilteredRequest,
        pageSize: action.payload,
      };
    },
    resetFlow: (state) => {
      state.currentFlow = null;
      state.loadingFlow = "idle";
      state.documentLoading = "idle";
      state.signatureDocs = null;
      state.signature = null;
      state.loadingForm = "idle";
      state.formPDFBase64 = null;
      state.filledForm = null;
      state.loadingSignature = "idle";
      state.pictures = null;
      state.loadingPictures = "idle";
      state.signatureDocs = null;
      state.loadingSignatureDocs = "idle";
      state.loadingFormSeguro = "idle";
      state.formSeguroPDFBase64 = null;
      state.documentSeguroLoading = "idle";
      state.filledFormSeguro = null;
      state.getSmartFlowSignatureDocumentsByIdRequestId = "";
      state.getSmartFlowFormSeguroByIdRequestId = "";
      state.getSmartFlowFormByIdRequestId = "";
      state.getSmartFlowRegistrationImagesRequestId = "";
      state.getFlowFilledFormSeguroRequestId = "";
      state.getFlowFilledFormRequestId = "";
      state.getFlowByIdRequestId = "";
      state.signatureSeguro = null;
      state.signatureSeguroDocs = null;
      state.loadingSeguroSignature = "idle";
      state.loadingSeguroSignatureDocs = "idle";
      state.getSmartFlowSeguroSignatureByRequestId = "";
      state.getSmartFlowSignatureDocumentsSeguroByIdRequestId = "";
      state.userAttachments = [];
      state.loadingAttachments = "idle";
    },
    setIsFlowDetail: (state, action: PayloadAction<boolean>) => {
      state.isFlowDetail = action.payload;
    },
    changeCurrentFlowStatus: (state, action: PayloadAction<string>) => {
      if (state.currentFlow) {
        state.currentFlowStatus = action.payload;
      }
    },
    changeCurrentActiveComponent: (
      state,
      action: PayloadAction<SmartflowActiveComponent>
    ) => {
      state.smartFlowActiveComponent = action.payload;
    },
    clearWebhookData: (state) => {
      state.webhookData = null;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAttatchmentByRegistrationId.fulfilled, (state, action) => {
      let attatchments = action.payload;
      if (state.currentFlow && attatchments) {
        state.userAttachments = attatchments;
        state.loadingAttachments = "resolved";
      } else {
        state.userAttachments = [];
        state.loadingAttachments = "rejected";
      }
    })
    .addCase(getAttatchmentByRegistrationId.pending, (state) => {
      state.loadingAttachments = "pending";
    })
    .addCase(getAttatchmentByRegistrationId.rejected, (state) => {
      state.loadingAttachments = "rejected";
    })
    builder
      .addCase(getFilteredFlow.fulfilled, (state, action) => {
        let flow = action.payload.flows;
        if (flow) {
          state.flowFiltered = flow.flows;
          state.currentPage = flow.currentPage;
          state.numPages = flow.numPages;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilteredFlow.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilteredFlow.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let filterOptions = action.payload;
        if (filterOptions) {
          state.filteredOptions = filterOptions;
          state.loadingStatus = "resolved";
        } else {
          state.loadingStatus = "rejected";
        }
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingStatus = "rejected";
        state.filteredOptions = {};
      })
      .addCase(getFlowById.fulfilled, (state, action) => {
        let flow = action.payload;
        const { requestId } = action.meta;
        if (
          state.loadingFlow === "pending" &&
          requestId === state.getFlowByIdRequestId &&
          flow
        ) {
          state.currentFlow = flow;
          state.currentFlowStatus = flow.status;
          state.loadingFlow = "resolved";
        }
      })
      .addCase(getFlowById.pending, (state, action) => {
        if (state.loadingFlow === "idle") {
          state.loadingFlow = "pending";
          state.getFlowByIdRequestId = action.meta.requestId;
        }
      })
      .addCase(getFlowById.rejected, (state, action) => {
        if (
          state.loadingFlow === "pending" &&
          state.getFlowByIdRequestId === action.meta.requestId
        ) {
          state.loadingFlow = "rejected";
          state.currentFlow = null;
        }
      })
      .addCase(changeStatusById.fulfilled, (state, action) => {
        let webhookData = action.payload;
        if (webhookData) {
          state.webhookData = webhookData;
        }
        state.loadingStatus = "resolved";
        state.loadingFlow = "idle";
        // if (flow && state.currentFlow) {
        //   state.currentFlow.status = flow.status;
        //   state.currentFlowStatus = flow.status;
        //   state.loadingStatus = "resolved";
        // } else if (flow) {
        //   state.currentFlow = flow;
        //   state.currentFlowStatus = flow.status;
        //   state.loadingStatus = "resolved";
        // }
      })
      .addCase(changeStatusById.pending, (state, action) => {
        if (state.loadingFlow === "idle" || state.loadingFlow === "resolved") {
          state.loadingFlow = "pending";
          state.changeStatusByIdRequestId = action.meta.requestId;
        }
      })
      .addCase(changeStatusById.rejected, (state, action) => {
        if (
          state.loadingFlow === "pending" &&
          state.changeStatusByIdRequestId === action.meta.requestId
        ) {
          state.loadingFlow = "rejected";
          state.currentFlow = null;
        }
      })
      .addCase(getFlowFilledFormPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        if (
          state.documentLoading === "pending" &&
          state.getFlowFilledFormRequestId === action.meta.requestId
        ) {
          if (pdf) {
            state.formPDFBase64 = pdf.pdfBase64;
            state.documentLoading = "resolved";
          } else {
            state.documentLoading = "rejected";
          }
        }
      })
      .addCase(getFlowFilledFormPDF.pending, (state, action) => {
        if (state.documentLoading === "idle") {
          state.documentLoading = "pending";
          state.getFlowFilledFormRequestId = action.meta.requestId;
        }
      })
      .addCase(getFlowFilledFormPDF.rejected, (state, action) => {
        if (
          state.documentLoading === "pending" &&
          state.getFlowFilledFormRequestId === action.meta.requestId
        ) {
          state.documentLoading = "rejected";
        }
      });
    //PDF Seguro
    builder
      .addCase(getFlowFilledFormSeguroPDF.fulfilled, (state, action) => {
        let pdf = action.payload;
        if (
          state.documentSeguroLoading === "pending" &&
          state.getFlowFilledFormSeguroRequestId === action.meta.requestId
        ) {
          if (pdf) {
            state.formSeguroPDFBase64 = pdf.pdfBase64;
            state.documentSeguroLoading = "resolved";
          } else {
            state.documentSeguroLoading = "rejected";
          }
        }
      })
      .addCase(getFlowFilledFormSeguroPDF.pending, (state, action) => {
        if (state.documentSeguroLoading === "idle") {
          state.documentSeguroLoading = "pending";
          state.getFlowFilledFormSeguroRequestId = action.meta.requestId;
        }
      })
      .addCase(getFlowFilledFormSeguroPDF.rejected, (state, action) => {
        if (
          state.documentSeguroLoading === "pending" &&
          state.getFlowFilledFormSeguroRequestId === action.meta.requestId
        ) {
          state.documentSeguroLoading = "rejected";
        }
      });

    builder
      .addCase(getSmartFlowFormById.fulfilled, (state, action) => {
        let form = action.payload;
        if (
          state.loadingForm === "pending" &&
          state.getSmartFlowFormByIdRequestId === action.meta.requestId
        ) {
          if (form) {
            state.filledForm = form;
            state.loadingForm = "resolved";
          }
        }
      })
      .addCase(getSmartFlowFormById.pending, (state, action) => {
        if (state.loadingForm === "idle") {
          state.loadingForm = "pending";
          state.getSmartFlowFormByIdRequestId = action.meta.requestId;
        }
      })
      .addCase(getSmartFlowFormById.rejected, (state, action) => {
        if (
          state.loadingForm === "pending" &&
          state.getSmartFlowFormByIdRequestId === action.meta.requestId
        ) {
          state.loadingForm = "rejected";
        }
      });

    // SeguroForm
    builder
      .addCase(getSmartFlowFormSeguroById.fulfilled, (state, action) => {
        let form2 = action.payload;
        if (
          state.loadingFormSeguro === "pending" &&
          state.getSmartFlowFormSeguroByIdRequestId === action.meta.requestId
        ) {
          if (form2) {
            state.filledFormSeguro = form2;
            state.loadingFormSeguro = "resolved";
          }
        }
      })
      .addCase(getSmartFlowFormSeguroById.pending, (state, action) => {
        if (state.loadingFormSeguro === "idle") {
          state.loadingFormSeguro = "pending";
          state.getSmartFlowFormSeguroByIdRequestId = action.meta.requestId;
        }
      })
      .addCase(getSmartFlowFormSeguroById.rejected, (state, action) => {
        if (
          state.loadingFormSeguro === "pending" &&
          state.getSmartFlowFormSeguroByIdRequestId === action.meta.requestId
        ) {
          state.loadingFormSeguro = "rejected";
        }
      });

    builder
      .addCase(getSmartFlowRegistrationImages.fulfilled, (state, action) => {
        let images = action.payload;
        if (
          state.loadingPictures === "pending" &&
          state.getSmartFlowRegistrationImagesRequestId ===
            action.meta.requestId
        ) {
          if (images) {
            state.pictures = images;
            state.loadingPictures = "resolved";
          }
        }
      })
      .addCase(getSmartFlowRegistrationImages.pending, (state, action) => {
        if (state.loadingPictures === "idle") {
          state.getSmartFlowRegistrationImagesRequestId = action.meta.requestId;
          state.loadingPictures = "pending";
        }
      })
      .addCase(getSmartFlowRegistrationImages.rejected, (state, action) => {
        if (
          state.loadingPictures === "pending" &&
          state.getSmartFlowRegistrationImagesRequestId ===
            action.meta.requestId
        ) {
          state.loadingPictures = "rejected";
        }
      });
    //Signature
    builder
      .addCase(getSmartFlowSignatureById.fulfilled, (state, action) => {
        let signature = action.payload;
        if (
          state.loadingSignature === "pending" &&
          state.getSmartFlowSignatureByIdRequestId === action.meta.requestId
        ) {
          if (signature) {
            state.signature = signature;
            state.loadingSignature = "resolved";
          }
        }
      })
      .addCase(getSmartFlowSignatureById.pending, (state, action) => {
        if (state.loadingSignature === "idle") {
          state.getSmartFlowSignatureByIdRequestId = action.meta.requestId;
          state.loadingSignature = "pending";
        }
      })
      .addCase(getSmartFlowSignatureById.rejected, (state, action) => {
        if (
          state.loadingSignature === "pending" &&
          state.getSmartFlowSignatureByIdRequestId === action.meta.requestId
        ) {
          state.loadingSignature = "rejected";
        }
      });
    //Seguro signature
    builder
      .addCase(getSmartFlowSignatureSeguroById.fulfilled, (state, action) => {
        let signature = action.payload;
        if (
          state.loadingSeguroSignature === "pending" &&
          state.getSmartFlowSeguroSignatureByRequestId === action.meta.requestId
        ) {
          if (signature) {
            state.signatureSeguro = signature;
            state.loadingSeguroSignature = "resolved";
          }
        }
      })
      .addCase(getSmartFlowSignatureSeguroById.pending, (state, action) => {
        if (state.loadingSeguroSignature === "idle") {
          state.getSmartFlowSeguroSignatureByRequestId = action.meta.requestId;
          state.loadingSeguroSignature = "pending";
        }
      })
      .addCase(getSmartFlowSignatureSeguroById.rejected, (state, action) => {
        if (
          state.loadingSeguroSignature === "pending" &&
          state.getSmartFlowSeguroSignatureByRequestId === action.meta.requestId
        ) {
          state.loadingSeguroSignature = "rejected";
        }
      });

    //Signature documents
    builder
      .addCase(
        getSmartFlowSignatureDocumentsById.fulfilled,
        (state, action) => {
          let documents = action.payload;
          if (
            state.loadingSignatureDocs === "pending" &&
            state.getSmartFlowSignatureDocumentsByIdRequestId ===
              action.meta.requestId
          ) {
            if (documents) {
              state.signatureDocs = documents;
              state.loadingSignatureDocs = "resolved";
            }
          }
        }
      )
      .addCase(getSmartFlowSignatureDocumentsById.pending, (state, action) => {
        if (state.loadingSignatureDocs === "idle") {
          state.getSmartFlowSignatureDocumentsByIdRequestId =
            action.meta.requestId;
          state.loadingSignatureDocs = "pending";
        }
      })
      .addCase(getSmartFlowSignatureDocumentsById.rejected, (state, action) => {
        if (
          state.loadingSignatureDocs === "pending" &&
          state.getSmartFlowSignatureDocumentsByIdRequestId ===
            action.meta.requestId
        ) {
          state.loadingSignatureDocs = "rejected";
        }
      });
    //Seguro Signature documents
    builder
      .addCase(getSmartFlowSignatureDocumentsSeguroById.fulfilled, (state, action) => {
        let signature = action.payload;
        if (
          state.loadingSeguroSignatureDocs === "pending" &&
          state.getSmartFlowSignatureDocumentsSeguroByIdRequestId ===
            action.meta.requestId
        ) {
          if (signature) {
            state.signatureSeguroDocs = signature;
            state.loadingSeguroSignatureDocs = "resolved";
          }
        }
      })
      .addCase(getSmartFlowSignatureDocumentsSeguroById.pending, (state, action) => {
        if (state.loadingSeguroSignatureDocs === "idle") {
          state.getSmartFlowSignatureDocumentsSeguroByIdRequestId =
            action.meta.requestId;
          state.loadingSeguroSignatureDocs = "pending";
        }
      })
      .addCase(getSmartFlowSignatureDocumentsSeguroById.rejected, (state, action) => {
        if (
          state.loadingSeguroSignatureDocs === "pending" &&
          state.getSmartFlowSignatureDocumentsSeguroByIdRequestId ===
            action.meta.requestId
        ) {
          state.loadingSeguroSignatureDocs = "rejected";
        }
      });
  },
});

export const {
  changeCurrentFilters,
  changePageState,
  resetFlow,
  setIsFlowDetail,
  changeCurrentActiveComponent,
  changeCurrentFlowStatus,
  clearWebhookData,
  changePageSize,
} = smartFlowSlice.actions;

export const selectCurrentPageSmartFlow = (state: RootState) =>
  state.smartFlow.currentFilteredRequest.page;
export const selectNumPagesSmartFlow = (state: RootState) =>
  state.smartFlow.numPages;
export const selectPageSizeSmartFlow = (state: RootState) =>
  state.smartFlow.currentFilteredRequest.pageSize;
export const selectFlowFiltered = (state: RootState) =>
  state.smartFlow.flowFiltered;
export const selectFilteredOptions = (state: RootState) =>
  state.smartFlow.filteredOptions;
export const selectCurrentFlow = (state: RootState) =>
  state.smartFlow.currentFlow;
export const selectCurrentFilterRequest = (state: RootState) =>
  state.smartFlow.currentFilteredRequest;
export const selectLoadingFlowStatus = (state: RootState) =>
  state.smartFlow.loadingStatus;
export const selectDocumentLoadingFlow = (state: RootState) =>
  state.smartFlow.documentLoading;
export const selectIsFlowDetail = (state: RootState) =>
  state.smartFlow.isFlowDetail;
export const selectCurrentFlowStatus = (state: RootState) =>
  state.smartFlow.currentFlowStatus;
export const selectFormPDFBase64 = (state: RootState) =>
  state.smartFlow.formPDFBase64;

export const selectFilledForm = (state: RootState) =>
  state.smartFlow.filledForm;

export const selectSignature = (state: RootState) => state.smartFlow.signature;
export const selectSignatureDocs = (state: RootState) =>
  state.smartFlow.signatureDocs;
export const selectImagesRegistration = (state: RootState) =>
  state.smartFlow.pictures;
export const selectLoadingForm = (state: RootState) =>
  state.smartFlow.loadingForm;
export const selectLoadingSignature = (state: RootState) =>
  state.smartFlow.loadingSignature;
export const selectLoadingSignatureDocs = (state: RootState) =>
  state.smartFlow.loadingSignatureDocs;
export const selectLoadingImagesRegistration = (state: RootState) =>
  state.smartFlow.loadingPictures;
export const selectLoadingFlow = (state: RootState) =>
  state.smartFlow.loadingFlow;

//Seguro selectors
export const selectFormSeguroPDFBase64 = (state: RootState) =>
  state.smartFlow.formSeguroPDFBase64;
export const selectFilledFormSeguro = (state: RootState) =>
  state.smartFlow.filledFormSeguro;
export const selectLoadingFormSeguro = (state: RootState) =>
  state.smartFlow.loadingFormSeguro;
export const selectLoadingSeguroPdfLoading = (state: RootState) =>
  state.smartFlow.documentSeguroLoading;
export const selectSignatureSeguro = (state: RootState) =>
  state.smartFlow.signatureSeguro;
export const selectSignatureDocsSeguro = (state: RootState) =>
  state.smartFlow.signatureSeguroDocs;
export const selectLoadingSeguroSignature = (state: RootState) =>
  state.smartFlow.loadingSeguroSignature;
export const selectLoadingSeguroSignatureDocs = (state: RootState) =>
  state.smartFlow.loadingSeguroSignatureDocs;

export const selectWebhookData = (state: RootState) =>
  state.smartFlow.webhookData;
export const selectSmartFlowActiveComponent = (state: RootState) =>
  state.smartFlow.smartFlowActiveComponent;
//Atachments
export const selectSmartFlowUserAttachments = (state: RootState) =>
  state.smartFlow.userAttachments;
export const selectSmartFlowLoadingAttachments = (state: RootState) =>
  state.smartFlow.loadingAttachments;


export default smartFlowSlice.reducer;

