import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../common/commonSlice";
import {
  getMessageAPI,
  getMessagesAPI,
  HfMessageModel,
  updateMessageAPI,
} from "./hfEditorApi";
import { RootState } from "../../../../app/store";

//State
export interface HfEditorState {
  loadingStatus: LoadingStatus;
  messageList: HfMessageModel[];
  messageId?: string;
  message?: HfMessageModel;
  edited: boolean;
  error?: string;
}

const initialState: HfEditorState = {
  loadingStatus: "idle",
  messageId: undefined,
  messageList: [],
  message: undefined,
  edited: false,
  error: undefined,
};

//Thunks
export const hfEditorGetMessages = createAsyncThunk(
  "hfEditor/getMessages",
  async (_, { rejectWithValue }) => {
    const resp = await getMessagesAPI();
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const hfEditorGetMessageById = createAsyncThunk(
  "hfEditor/getMessageById",
  async (id: string, { rejectWithValue }) => {
    const resp = await getMessageAPI(id);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const hfEditorUpdateMessageServer = createAsyncThunk(
  "hfEditor/updateMessage",
  async (message: HfMessageModel, { rejectWithValue }) => {
    const resp = await updateMessageAPI(message.id, message);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

//Slice

export const hfEditorSlice = createSlice({
  name: "hfEditorSlice",
  initialState,
  reducers: {
    selectActiveMessage: (state, action: PayloadAction<string>) => {
      state.messageId = action.payload;
      state.edited = false;
    },
    updateMesage: (state, action: PayloadAction<HfMessageModel>) => {
      state.message = action.payload;
      state.edited = true;
    },
  },
  extraReducers: (builder) => {
    //Get Messages
    builder
      .addCase(hfEditorGetMessages.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(hfEditorGetMessages.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.messageList = action.payload!!;
      })
      .addCase(hfEditorGetMessages.rejected, (state, action) => {
        state.loadingStatus = "rejected";
        state.error = action.payload as string;
      });
    //Get Message By Id
    builder
      .addCase(hfEditorGetMessageById.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(hfEditorGetMessageById.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.message = action.payload!!;
      })
      .addCase(hfEditorGetMessageById.rejected, (state, action) => {
        state.loadingStatus = "rejected";
        state.error = action.payload as string;
      });
    //Update Message
    builder
      .addCase(hfEditorUpdateMessageServer.pending, (state) => {
        state.loadingStatus = "pending";
      })
      .addCase(hfEditorUpdateMessageServer.fulfilled, (state, action) => {
        state.loadingStatus = "resolved";
        state.message = action.payload!!;
        state.edited = false;
      })
      .addCase(hfEditorUpdateMessageServer.rejected, (state, action) => {
        state.loadingStatus = "rejected";
        state.error = action.payload as string;
      });
  },
});
//Exports
export const { selectActiveMessage, updateMesage } = hfEditorSlice.actions;

export default hfEditorSlice.reducer;

export const selectHfEditorLoadingStatus = (state: RootState) =>
  state.hfEditor.loadingStatus;
export const selectHfEditorMessageList = (state: RootState) =>
    state.hfEditor.messageList;
export const selectHfEditorMessage = (state: RootState) =>
    state.hfEditor.message;
export const selectHfEditorMessageId = (state: RootState) =>
    state.hfEditor.messageId;
export const selectHfEditorEdited = (state: RootState) =>
    state.hfEditor.edited;