import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Client,
  ClientCreateRequest,
  ClientCreateResponse,
  ClientDetail,
  ClientProject,
  EditClientRequest,
  FilteredClientsRequest,
  SubClient,
  createClientAPI,
  editClientAPI,
  getClientAPI,
  getClientProjectsAPI,
  getFilteredSubclientsAPI,
  getSubClientsAPI,
} from "./clientAPI";
import { LoadingStatus } from "../../common/commonSlice";
import { RootState } from "../../../app/store";

export interface ClientState {
  clientsLogin: Array<Client>;
  loadingClientsLogin: LoadingStatus;
  loadingClients: LoadingStatus;
  loadingClientDetail: LoadingStatus;
  loadingCreateClient: LoadingStatus;
  loadingEditClient: LoadingStatus;
  loadingPossibleProjects: LoadingStatus;
  currentClients: SubClient[];
  clientsPage: number;
  numPages: number;
  currentClientRequest: FilteredClientsRequest;
  currentClientDetail: ClientDetail | null;
  clientCreateRequest: ClientCreateRequest;
  possibleProjects: Array<ClientProject>;
  clientCreateResponse: ClientCreateResponse | null;
  clientCreateError: string;
  editClientRequest: EditClientRequest;
  loadingLoginSubclients: LoadingStatus;
}

const initialState: ClientState = {
  clientsLogin: [],
  loadingClientsLogin: "idle",
  loadingClients: "idle",
  loadingEditClient: "idle",
  loadingPossibleProjects: "idle",
  currentClients: [],
  clientsPage: 0,
  numPages: 0,
  currentClientRequest: {
    startDate: null,
    endDate: null,
    page: 0,
    tag: null,
  },
  currentClientDetail: null,
  loadingClientDetail: "idle",
  clientCreateRequest: {
    brandConfig: {
      color1: "#0081E3",
      color2: "#002039",
      logoBase64: "",
    },
    canSeeData: false,
    name: "",
    nit: "",
    projectId: "",
  },
  possibleProjects: [],
  clientCreateError: "",
  clientCreateResponse: null,
  loadingCreateClient: "idle",
  editClientRequest: {
    brandConfig: {
      color1: "#0081E3",
      color2: "#002039",
      logoBase64: "",
    },
    id: "",
    name: "",
    nit: "",
    projectId: "",
  },
  loadingLoginSubclients: "idle",
};

export const getClientDetail = createAsyncThunk(
  "clients/getClientDetail",
  async (clientId: string, { rejectWithValue }) => {
    const clientDetail = await getClientAPI(clientId);
    if (clientDetail.error !== "") {
      return rejectWithValue(clientDetail.error);
    }
    return clientDetail.client;
  }
);

export const getSubClients = createAsyncThunk(
  "clients/getSubClients",
  async () => {
    const subClients = await getSubClientsAPI();
    return subClients;
  }
);

export const getFilteredClients = createAsyncThunk(
  "clients/getFilteredClients",
  async (params: FilteredClientsRequest, { rejectWithValue }) => {
    try {
      const filteredResponse = await getFilteredSubclientsAPI(params);
      if (filteredResponse.subclients) {
        return filteredResponse;
      } else {
        return rejectWithValue("");
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPossibleProjects = createAsyncThunk(
  "clients/getPossibleProjects",
  async (_, { rejectWithValue }) => {
    let possibleProjects = await getClientProjectsAPI();
    if (possibleProjects.error !== "" && possibleProjects.projects === null) {
      return rejectWithValue(possibleProjects.error);
    }

    return possibleProjects.projects;
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const clientCreateRequest = state.clients.clientCreateRequest;
    const clientCreateResponse = await createClientAPI(clientCreateRequest);
    if (clientCreateResponse.error !== "") {
      return rejectWithValue(clientCreateResponse.error);
    }
    return clientCreateResponse.client;
  }
);

export const editClient = createAsyncThunk(
  "clients/editClient",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const editClientRequest = state.clients.editClientRequest;
    const editClientResponse = await editClientAPI(editClientRequest);
    if (editClientResponse.error !== "") {
      return rejectWithValue(editClientResponse.error);
    }
    return true;
  }
);

const clientsSlice = createSlice({
  name: "clientSlice",
  initialState,
  reducers: {
    changeClientFilters: (
      state,
      action: PayloadAction<FilteredClientsRequest>
    ) => {
      state.currentClientRequest = {
        ...action.payload,
        page: 0,
      };
    },
    changePageStateClients: (state, action: PayloadAction<number>) => {
      let current = state.currentClientRequest;
      state.currentClientRequest = { ...current, page: action.payload };
    },
    changeCreateClientRequest: (
      state,
      action: PayloadAction<ClientCreateRequest>
    ) => {
      state.clientCreateRequest = action.payload;
    },
    changeEditClientRequest: (
      state,
      action: PayloadAction<EditClientRequest>
    ) => {
      state.editClientRequest = action.payload;
    },

    resetCreateClient: (state) => {
      state.clientCreateRequest = {
        brandConfig: {
          color1: "",
          color2: "",
          logoBase64: "",
        },
        canSeeData: false,
        name: "",
        nit: "",
        projectId: "",
      };
      state.clientCreateResponse = null;
      state.loadingCreateClient = "idle";
      state.loadingPossibleProjects = "idle";
      state.possibleProjects = [];
    },
    resetClient: (state) => {
      state.editClientRequest = {
        brandConfig: {
          color1: "#0081E3",
          color2: "#002039",
          logoBase64: "",
        },
        name: "",
        nit: "",
        projectId: "",
        id: "",
      };
      state.currentClientDetail = null;
      state.loadingEditClient = "idle";
    },
    setEditRequestFromCurrentClient: (state) => {
      state.editClientRequest = {
        brandConfig: {
          color1: state.currentClientDetail?.brandConfig.color1 || "#0081E3",
          color2: state.currentClientDetail?.brandConfig.color2 || "#002039",
          logoBase64: "",
        },
        name: state.currentClientDetail?.name || "",
        nit: state.currentClientDetail?.nit || "",
        projectId: "",
        id: state.currentClientDetail?.id || "",
      };
    },
  },
  extraReducers: (builder) => {
    // Get clients
    builder
      .addCase(getSubClients.fulfilled, (state, action) => {
        state.clientsLogin = action.payload;
        state.loadingClientsLogin = "resolved";
      })
      .addCase(getSubClients.rejected, (state) => {
        state.clientsLogin = [];
        state.loadingClientsLogin = "rejected";
      })
      .addCase(getSubClients.pending, (state) => {
        state.loadingClientsLogin = "pending";
      });
    // Get filtered clients
    builder
      .addCase(getFilteredClients.pending, (state) => {
        state.loadingClients = "pending";
      })
      .addCase(getFilteredClients.fulfilled, (state, action) => {
        state.loadingClients = "resolved";
        state.currentClients = action.payload.subclients?.subClients || [];
        state.numPages = action.payload.subclients?.numPages || 0;
        state.clientsPage = action.payload.subclients?.page || 0;
      })
      .addCase(getFilteredClients.rejected, (state) => {
        state.loadingClients = "rejected";
        state.currentClients = [];
        state.numPages = 0;
        state.clientsPage = 0;
      });

    // Get client detail
    builder
      .addCase(getClientDetail.pending, (state) => {
        state.loadingClientDetail = "pending";
      })
      .addCase(getClientDetail.fulfilled, (state, action) => {
        state.loadingClientDetail = "resolved";
        state.currentClientDetail = action.payload;
      })
      .addCase(getClientDetail.rejected, (state) => {
        state.loadingClientDetail = "rejected";
        state.currentClientDetail = null;
      });

    // Get possible projects
    builder
      .addCase(getPossibleProjects.fulfilled, (state, action) => {
        state.possibleProjects = action.payload!;
        state.loadingPossibleProjects = "resolved";
      })
      .addCase(getPossibleProjects.rejected, (state) => {
        state.loadingPossibleProjects = "rejected";
        state.possibleProjects = [];
      })
      .addCase(getPossibleProjects.pending, (state) => {
        state.loadingPossibleProjects = "pending";
      });

    // Create client
    builder
      .addCase(createClient.pending, (state) => {
        state.clientCreateError = "";
        state.clientCreateResponse = null;
        state.loadingCreateClient = "pending";
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.loadingCreateClient = "resolved";
        state.clientCreateResponse = action.payload;
        state.clientCreateError = "";
      })
      .addCase(createClient.rejected, (state, action) => {
        state.loadingCreateClient = "rejected";
        state.clientCreateError = action.payload as string;
        state.clientCreateResponse = null;
      });

    // Edit client
    builder
      .addCase(editClient.pending, (state) => {
        state.loadingEditClient = "pending";
      })
      .addCase(editClient.fulfilled, (state) => {
        state.loadingEditClient = "resolved";
      });
  },
});

export const {
  changeClientFilters,
  changePageStateClients,
  changeCreateClientRequest,
  resetCreateClient,
  changeEditClientRequest,
  resetClient,
  setEditRequestFromCurrentClient,
} = clientsSlice.actions;

export const selectClientsLogin = (state: RootState) =>
  state.clients.clientsLogin;
export const selectLoadingClientsLogin = (state: RootState) =>
  state.clients.loadingClientsLogin;
export const selectLoadingClients = (state: RootState) =>
  state.clients.loadingClients;
export const selectClientPage = (state: RootState) => state.clients.clientsPage;
export const selectNumPagesClients = (state: RootState) =>
  state.clients.numPages;
export const selectClients = (state: RootState) => state.clients.currentClients;
export const selectFilteredClientsRequest = (state: RootState) =>
  state.clients.currentClientRequest;
export const selectLoadingSubClient = (state: RootState) =>
  state.clients.loadingClientDetail;
export const selectSubclientDetail = (state: RootState) =>
  state.clients.currentClientDetail;
export const selectClientCreateRequest = (state: RootState) =>
  state.clients.clientCreateRequest;
export const selectPossibleProjects = (state: RootState) =>
  state.clients.possibleProjects;
export const selectClientCreateResponse = (state: RootState) =>
  state.clients.clientCreateResponse;
export const selectClientCreateError = (state: RootState) =>
  state.clients.clientCreateError;
export const selectLoadingCreateClient = (state: RootState) =>
  state.clients.loadingCreateClient;
export const selectLoadingEditClient = (state: RootState) =>
  state.clients.loadingEditClient;
export const selectEditClientRequest = (state: RootState) =>
  state.clients.editClientRequest;
export const selectLoadingPossibleProjects = (state: RootState) =>
  state.clients.loadingPossibleProjects;

export default clientsSlice.reducer;
