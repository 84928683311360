import { useEffect, useState } from "react";
import { ReactComponent as DownArrow } from "../../../../Assets/common/images/downArrow.svg";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as FirmaNetLogo } from "../../../../Assets/common/images/firmaNetLogo.svg";
import { ReactComponent as CoreIdNetLogo } from "../../../../Assets/common/images/huellaNetLogo.svg";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as MagicNetLogo } from "../../../../Assets/common/images/magicNetLogo.svg";
import { LoadingStatus, selectRoles } from "../../../common/commonSlice";
import fileDownload from "js-file-download";
import { FilledForm } from "../magicFormsAPI";
import {
  changeActiveComponent,
  getFilledFormPDF,
  getFormById,
  selectCurrentFilledForm,
  selectFilledFormsDetailLoading,
  selectFormPDFLoading,
  selectNotFoundMessageMagicForms,
} from "../magicFormsSlice";
import "../magicFormsStyles.scss";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByEntity,
  selectAllTags,
  selectCurrentEntity,
  selectCurrentTagCreation,
  selectCurrentTagsByEntity,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByEntity,
  setTagCreation,
} from "../../tags/tagSlice";
import { base64PDFToBlobUrl } from "../../../../helpers/utils";

export const FilledFormsDetail = () => {
  let dispatch = useAppDispatch();
  let currentFilledForm = useAppSelector(selectCurrentFilledForm);
  let loadingStatus = useAppSelector(selectFilledFormsDetailLoading);
  let pdfLoadingStatus = useAppSelector(selectFormPDFLoading);
  let notFoundMessage = useAppSelector(selectNotFoundMessageMagicForms);

  // Tag stuff
  let currentEntity = useAppSelector(selectCurrentEntity);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let allTags = useAppSelector(selectAllTags);
  let currentEntityTags = useAppSelector(selectCurrentTagsByEntity);
  let loadingEntityTags = useAppSelector(selectLoadingCurrentTagsByEntity);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState(false);
  let roles = useAppSelector(selectRoles);

  const [dataTest, setDataTest] = useState<any>(null);

  let getDocument = (
    loadingPDFStatus: LoadingStatus,
    filledForm: FilledForm
  ) => {
    switch (loadingPDFStatus) {
      case "idle":
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
      case "pending":
        return (
          <LoadingOverlay
            active={loadingPDFStatus === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          ></LoadingOverlay>
        );
      case "rejected":
        return (
          <div className="primary-color text-xx-large text-bold">
            Hubo un problema cargando del documento
          </div>
        );
      case "resolved":
        return (
          <object
            data={base64PDFToBlobUrl(filledForm.document!)}
            type="application/pdf"
            width="100%"
            height="100%"
            onError={() => {}}
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <p style={{ color: "white", width: "80%", textAlign: "center" }}>
                Tu navegador no permite visualizar PDFs, puedes descargar el
                documento aquí{" "}
                <a
                  style={{ color: "white", textDecoration: "underline" }}
                  onClick={() => {
                    const url = `data:application/pdf;base64,${filledForm.document}`;
                    fetch(url)
                      .then((res) => res.blob())
                      .then((blob) => {
                        fileDownload(blob, "Documento.pdf", "application/pdf");
                      });
                  }}
                >
                  Descargar
                </a>
              </p>
            </div>
          </object>
        );
      default:
        return (
          <div className="primary-color text-xx-large text-bold">
            Formulario lleno
          </div>
        );
    }
  };
  const getTagsRender = () => {
    if (loadingEntityTags == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
            margin: "5px 0px",
          }}
        >
          {currentEntityTags.map((tag) => {
            return (
              <div
                className="flex-row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)",
                  borderRadius: "8px",
                  padding: "3px 8px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  {tag.tag}
                </div>
                {roles.includes("tags.edit") && (
                  <DeleteTag
                    onClick={() => {
                      dispatch(
                        disassociateTag({
                          tagId: tag.id,
                          entityId: id!,
                          service: "MAGICFORMS",
                        })
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            );
          })}
          <div>
            <div
              className="flex-row"
              style={{
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: "8px",
                padding: "1px 8px",
                marginRight: "5px",
                marginLeft: "0px",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (roles.includes("tags.edit")) {
                  setAddTagWindow(!addTagWindow);
                }
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 600,
                  color: "#fff",
                  marginRight: "5px",
                }}
              >
                Etiquetas
              </div>

              {roles.includes("tags.edit") && (
                <AddTag
                  style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
                />
              )}
            </div>
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: id!,
                              service: "MAGICFORMS",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag && tagCreation.tag.length > 0
                              ? "visible"
                              : "hidden",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: id!,
                                service: "MAGICFORMS",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: id!,
                                  service: "MAGICFORMS",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if ((!currentFilledForm || currentFilledForm.id !== id) && id !== "") {
      dispatch(getFormById(id || ""));
    }
    if (currentFilledForm) {
      dispatch(getFilledFormPDF(id!));
    }
    dispatch(changeActiveComponent("none"));

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentEntity !== id && id) {
      dispatch(getCurrentTagsByEntity({ entityId: id, service: "MAGICFORMS" }));
    }
  }, [currentFilledForm]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {notFoundMessage && notFoundMessage !== "" ? (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "idle"
              ? ""
              : "No hay más documentos en esta página"}
          </h1>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            paddingLeft: "0.5%",
            paddingTop: "0.5%",
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <div className="flex-row" style={{ height: "100%" }}>
            <div className="flex-col" style={{ width: "100%" }}>
              <div
                className="flex-row"
                style={{
                  height: "5%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "5%",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "start",
                      width: "50%",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        alignContent: "center",
                        height: "28px",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="flex-row"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <LeftArrow style={{ marginRight: "15px" }} />
                  <div className="text-bold text-xx-large primary-color">
                    Detalle formulario
                  </div>
                </div>
              </div>
              <div
                className="flex-row"
                style={{
                  height: "24%",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <div className="flex-col" style={{ marginRight: "5%" }}>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "450px",
                      height: "29px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Datos del Usuario
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "450px",
                      height: "159px",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <div
                      className="flex-col text-normal text-medium primary-color-35"
                      style={{
                        marginLeft: "25px",
                        marginTop: "15px",
                        marginBottom: "15px",
                        justifyContent: "space-evenly",
                        height: "100%",
                      }}
                    >
                      <div>
                        Nombre:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          `${currentFilledForm?.userData.name} ${currentFilledForm.userData.lastName}`}
                      </div>
                      <div>Id de Registro: {currentFilledForm?.userId}</div>
                      <div>
                        E-mail:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          currentFilledForm?.userData.email}
                      </div>
                      <div>
                        No. de celular:{" "}
                        {currentFilledForm &&
                          currentFilledForm.userData &&
                          currentFilledForm?.userData.phone}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col" style={{ justifyContent: "start" }}>
                  <div className="flex-col">
                    <div
                      className="flex-col"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        width: "450px",
                        height: "29px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px 8px 0px 0px",
                      }}
                    >
                      <div className="text-normal text-medium primary-color-35">
                        Red de documentos
                      </div>
                    </div>
                    <div
                      className="flex-col"
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "450px",
                        height: "117px",
                        borderRadius: "0px 0px 8px 8px",
                      }}
                    >
                      <div
                        className="flex-row text-normal text-medium primary-color-35"
                        style={{
                          justifyContent: "space-evenly",
                          marginTop: "15px",
                          marginBottom: "15px",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="flex-col"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/coreId/registrations/${currentFilledForm?.userId}`
                            );
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            border: "none",
                            background: "none",
                            width: "25%",
                          }}
                        >
                          <CoreIdNetLogo />
                          <div className="text-normal text-small primary-color">
                            Core ID
                          </div>
                        </button>
                        <div className="text-normal text-small primary-color">
                          +
                        </div>
                        <button
                          className="flex-col"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            border: "none",
                            background: "none",
                            width: "25%",
                          }}
                        >
                          <MagicNetLogo />
                          <div className="text-normal text-small primary-color">
                            Magic Forms
                          </div>
                        </button>

                        {currentFilledForm?.electronicSignatureId &&
                        currentFilledForm.electronicSignatureId !== "" ? (
                          <>
                            <div className="text-normal text-small primary-color">
                              +
                            </div>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  `/services/electronicSignatureNew/signatures/${currentFilledForm?.electronicSignatureId}`
                                );
                              }}
                              className="flex-col"
                              style={{
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                border: "none",
                                background: "none",
                                width: "25%",
                              }}
                            >
                              <FirmaNetLogo />
                              <div className="text-normal text-small primary-color">
                                Firma electrónica
                              </div>
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  {roles.includes("tags.view") && getTagsRender()}
                </div>
              </div>
              <div
                className="flex-col"
                style={{
                  marginTop: "24px",
                  alignItems: "center",
                }}
              >
                <div className="flex-col" style={{ width: "67%" }}>
                  {currentFilledForm && currentFilledForm.document && (
                    <div
                      style={{
                        color: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "10px ",
                      }}
                    >
                      <p>
                        Si no puedes visualizar el documento a continuación
                        puedes descargarlo
                      </p>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          let url = `data:application/pdf;base64,${
                            currentFilledForm!.document
                          }`;

                          fetch(url)
                            .then((res) => res.blob())
                            .then((blob) => {
                              fileDownload(blob, "Certificado.pdf");
                            });
                        }}
                        className="flex-row"
                        style={{
                          background: "rgba(0,0,0,0.3)",
                          width: "30%",
                          height: "40px",
                          padding: "5px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ marginRight: "5%" }}>Descargar</div>
                        <DownArrow />
                      </div>
                    </div>
                  )}
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      width: "100%",
                      height: "29px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Documento
                    </div>
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "100%",
                      height: "370px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    {getDocument(pdfLoadingStatus, currentFilledForm!)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LoadingOverlay>
  );
};
