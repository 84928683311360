import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface ProcuraduriaProps {
  loading: LoadingStatus;
  item: Procuraduria | null;
}

export interface Procuraduria {
  lista: string;
  antecedentesPenales: AntecedentesPenale[];
  tipo: string;
  coincidencia: string;
  isFallecido: boolean;
  presentaRiesgo: boolean;
  nombre: string;
  presentaAdvertencia: boolean;
  textoIdentificado: string;
  tieneAntecedentes: boolean;
  textoSaludo: string;
  tipoDocumentoConsulta: TipoDocumentoConsulta[];
  duracion: number;
  vinoTextoTradicional: boolean;
}

export interface AntecedentesPenale {
  titulo: string;
  antecedentes: Antecedente[];
  tituloSiri: string;
}

export interface TipoDocumentoConsulta {
  tipoDocumento: string;
  nombreDocumento: string;
}

export interface Antecedente {
  subTitulo: string;
  tituloAntecedente: string[];
  textoAntecedente: string[][];
}

export const ProcuraduriaResult = (props: ProcuraduriaProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "rgb(248 113 113)";
    } else if (presentaAdvertencia) {
      return "rgb(250 204 21)";
    } else {
      return "rgb(74 222 128)";
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              color: getColor(
                props.item.presentaRiesgo,
                props.item.presentaAdvertencia
              ),
              fontSize: "1.875rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Procuraduría
          </div>
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Datos Básicos
          </div>
          <table style={{ width: "100%", position: "relative" }}>
            <thead>
              <tr>
                <th></th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Nombre
                </th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Tiene antecedentes
                </th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Es Fallecido
                </th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Texto identificado
                </th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Coincidencia
                </th>
                <th
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                    padding: "8px 0",
                    textAlign: "center",
                  }}
                >
                  Tipo
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr
                style={{
                  color: "#FFFFFF",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  height: "40px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                <td></td>
                <td>{props.item.nombre}</td>
                <td>{props.item.tieneAntecedentes ? "Sí" : "No"}</td>
                <td>{props.item.isFallecido ? "Sí" : "No"}</td>
                <td>{props.item.textoIdentificado}</td>
                <td>{props.item.coincidencia}</td>
                <td>{props.item.tipo}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {props.item.textoSaludo && (
            <>
              <h4
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Mensaje de respuesta
              </h4>
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                {props.item.textoSaludo}
              </p>
            </>
          )}
          {props.item.antecedentesPenales &&
            props.item.antecedentesPenales.length > 0 && (
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Antecedentes Penales
              </div>
            )}
          {props.item.antecedentesPenales.map((antPenal, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              key={i}
            >
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {antPenal.titulo}
              </div>
              {antPenal.antecedentes.map((antecedente, j) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  key={j}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: "1.125rem",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {antecedente.subTitulo}
                  </div>
                  <table style={{ width: "100%", position: "relative" }}>
                    <thead>
                      <tr>
                        <th></th>
                        {antecedente.tituloAntecedente.map((titulo, k) => (
                          <th
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                            key={k}
                          >
                            {titulo}
                          </th>
                        ))}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {antecedente.textoAntecedente.map((texto, l) => (
                        <tr
                          key={l}
                          style={{
                            color: "#FFFFFF",
                            fontSize: "0.75rem",
                            fontWeight: "400",
                            height: "40px",
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                            borderRadius: "4px",
                            textAlign: "center",
                          }}
                        >
                          <td></td>
                          {texto.map((data, m) => (
                            <td
                              style={{
                                color: "#FFFFFF",
                                fontWeight: "600",
                                textAlign: "center",
                              }}
                              key={m}
                            >
                              {data}
                            </td>
                          ))}
                          <td></td>
                        </tr>
                      ))}
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "1.25rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
