import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import "../magicFormsStyles.scss";
import {
  changeActiveComponent,
  changePageSize,
  changePageState,
  getFilteredForms,
  selectActiveMagicComponent,
  selectCurrentNumPages,
  selectCurrentPageMagic,
  selectFilledForms,
  selectFilledFormsLoading,
  selectFilteredFormRequest,
  selectPageSizeMagic,
} from "../magicFormsSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByService,
  selectAllTags,
  selectCurrentService,
  selectCurrentTagCreation,
  selectCurrentTagsByService,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByService,
  setTagCreation,
} from "../../tags/tagSlice";
import { selectRoles } from "../../../common/commonSlice";
import { usePagination } from "pagination-react-js";
import PaginationItem from "../../../../helpers/PaginationItem";

export const FilledFormsComponent = () => {
  let filledForms = useAppSelector(selectFilledForms);
  let currentPage = useAppSelector(selectCurrentPageMagic);
  let currentNumPages = useAppSelector(selectCurrentNumPages);
  const pageSize = useAppSelector(selectPageSizeMagic);
  let loadingStatus = useAppSelector(selectFilledFormsLoading);
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let filterRequest = useAppSelector(selectFilteredFormRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: pageSize || 10,
        totalRecordsLength: currentNumPages * 10,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changePageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };
  // Tag Stuff
  let currentService = useAppSelector(selectCurrentService);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let loadingTagsByService = useAppSelector(selectLoadingCurrentTagsByService);
  let allTags = useAppSelector(selectAllTags);
  let currentServiceTags = useAppSelector(selectCurrentTagsByService);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState({ entityId: "" });
  let roles = useAppSelector(selectRoles);

  const getTagsRender = (entityId: string) => {
    if (loadingTagsByService == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
          }}
        >
          {currentServiceTags.map((tag) => {
            if (tag.associatedEntities.includes(entityId)) {
              return (
                <div
                  className="flex-row"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.25)",
                    borderRadius: "8px",
                    padding: "3px 8px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  >
                    {tag.tag}
                  </div>
                  {roles.includes("tags.edit") && (
                    <DeleteTag
                      onClick={() => {
                        dispatch(
                          disassociateTag({
                            tagId: tag.id,
                            entityId: entityId,
                            service: "MAGICFORMS",
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              );
            } else return "";
          })}
          <div>
            {roles.includes("tags.edit") && (
              <AddTag
                onClick={() => {
                  if (addTagWindow.entityId === entityId) {
                    setAddTagWindow({ entityId: "" });
                  } else {
                    setAddTagWindow({ entityId: entityId });
                  }
                }}
                style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
              />
            )}
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow.entityId === entityId && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: entityId,
                              service: "MAGICFORMS",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          height: "28px",
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag === "" ? "hidden" : "visible",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: entityId,
                                service: "MAGICFORMS",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: entityId,
                                  service: "MAGICFORMS",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (activeComponent !== "Filledforms") {
      dispatch(changeActiveComponent("Filledforms"));
    }

    dispatch(getFilteredForms({ ...filterRequest }));

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentService !== "MAGICFORMS") {
      dispatch(getCurrentTagsByService("MAGICFORMS"));
    }

    return () => {};
  }, [filterRequest, activeComponent]);
  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {filledForms && filledForms.filledForms.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {filledForms?.filledForms ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Form ID</th>
                      <th>E-mail</th>
                      <th>Esquema</th>
                      <th>
                        {roles.includes("tags.view")
                          ? "Etiquetas"
                          : "ID CoreID"}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {filledForms.filledForms.map((form, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px" }}
                        >
                          <td></td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/magicForms/filledForms/${form.id}`
                              );
                            }}
                            style={{
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                          >
                            {form &&
                              form.userData &&
                              `${form.userData.name || ""} ${
                                form.userData.lastName || ""
                              }`}
                          </td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/magicForms/filledForms/${form.id}`
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {form.id}
                          </td>
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/magicForms/filledForms/${form.id}`
                              );
                            }}
                            style={{
                              textTransform: "uppercase",
                              cursor: "pointer",
                            }}
                          >
                            {form && form.userData && form.userData.email}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/services/magicForms/filledForms/${form.id}`
                              );
                            }}
                          >
                            {form.userId}
                          </td>
                          <td>
                            {roles.includes("tags.view")
                              ? getTagsRender(form.id)
                              : form.userId}
                          </td>

                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "resolved"
                  ? ""
                  : "No hay más formularios en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "10%",
            }}
          >
            <Pagination />
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending" || loadingStatus === "idle"
              ? ""
              : "No hay más formularios en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
