import ReactECharts from "echarts-for-react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeCurrentProduct,
  changeHelpText,
} from "../../../common/commonSlice";
import {
  getAttachmentInfoMagicForms,
  getStatusMagicForms,
  getStatusMagicformsByDate,
  selectAnalyticsCoreIdRequest,
  selectAnalyticsMagicFormsRequest,
  selectAttachmentInfoMF,
  selectLoadingMagicFormsAttachments,
  selectLoadingStatusByDate,
  selectLoadingStatusResult,
  selectStatusByDate,
  selectStatusResult,
} from "../../analytics/analyticsSlice";
import {
  convertAlertsToPieChart,
  convertStatusByDateToStackedBar,
  convertStatusToPieChart,
} from "../../analytics/analyticsUtils";

import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as Info } from "../../../../Assets/common/images/infoYellow.svg";
import { ReactComponent as Attachment } from "../../../../Assets/common/images/attachment.svg";
import {
  changeActiveComponent,
  selectActiveMagicComponent,
} from "../magicFormsSlice";

export const AnalyticsMagicForms = () => {
  let activeComponent = useAppSelector(selectActiveMagicComponent);
  let loadingAttachmentInfo = useAppSelector(
    selectLoadingMagicFormsAttachments
  );
  let attachmentInfo = useAppSelector(selectAttachmentInfoMF);
  let loadingStatusResult = useAppSelector(selectLoadingStatusResult);
  let statusResult = useAppSelector(selectStatusResult);
  let loadingStatusByDate = useAppSelector(selectLoadingStatusByDate);
  let statusByDate = useAppSelector(selectStatusByDate);
  let magicFormsAnalyticsRequest = useAppSelector(
    selectAnalyticsMagicFormsRequest
  );

  let dispatch = useAppDispatch();

  const [statusPercentage, setStatusPercentage] = useState(false);

  const [selectedSection, setSelectedSection] = useState<"general">("general");

  const showContent = () => {
    switch (selectedSection) {
      case "general":
        return getGeneralContent();

      default:
        return getGeneralContent();
    }
  };

  const getGeneralContent = () => {
    return (
      <div
        className="flex-col"
        style={{
          width: "94%",
          height: "90%",
          alignItems: "center",
          borderRadius: "8px",
          margin: "0 0 0 1%",
          padding: "1% 1% 1% 1%",
          justifyContent: "space-between",
          background: "rgba(0,0,0,0.2)",
        }}
      >
        <div
          className="flex-row"
          style={{
            width: "100%",
            height: "48%",
            justifyContent: "space-between",
          }}
        >
          <div
            className="flex-row"
            style={{
              width: "48%",
              height: "100%",
              alignItems: "center",
              justifyContent: "start",
              borderRadius: "8px",
              background: "rgba(0,0,0,0.2)",
              alignSelf: "start",
              padding: "10px 0 0 10px",
            }}
          >
            {!statusResult || loadingStatusResult === "pending" ? (
              <LoadingOverlay
                active={true}
                spinner={<ClipLoader color="white" size="100px" />}
              />
            ) : (
              <>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "start",
                    margin: "1%",
                    alignSelf: "start",
                  }}
                >
                  <button
                    onClick={(e) => {
                      setStatusPercentage(true);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px 0 0 8px ",
                      background: statusPercentage
                        ? "rgba(0,0,0,1)"
                        : "rgba(13,60,80,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    %
                  </button>
                  <button
                    onClick={(e) => {
                      setStatusPercentage(false);
                    }}
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "0 8px 8px 0 ",
                      background: statusPercentage
                        ? "rgba(13,60,80,1)"
                        : "rgba(0,0,0,1)",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10px",
                      paddingLeft: "10px",
                      color: "#fff",
                    }}
                  >
                    #
                  </button>
                </div>

                <ReactECharts
                  style={{ height: "90%", width: "50%" }}
                  option={convertStatusToPieChart(
                    statusResult,
                    statusPercentage,
                    "MagicForms",
                    "75%"
                  )}
                  showLoading={false}
                  loadingOption={{
                    text: "Cargando",
                    color: "#FFF",
                    textColor: "#FFF",
                    maskColor: "rgba(0, 0, 0, 0.6)",
                    zlevel: 0,
                  }}
                />
              </>
            )}
          </div>
          <div
            className="flex-col"
            style={{
              width: "48%",
              height: "100%",
              alignItems: "center",
              justifyContent: "space-evenly",
              borderRadius: "8px",
              background: "rgba(0,0,0,0.2)",
              alignSelf: "start",
              padding: "10px 10px 10px 10px",
            }}
          >
            {loadingAttachmentInfo === "pending" ? (
              <LoadingOverlay
                active={true}
                spinner={<ClipLoader color="white" size="100px" />}
              />
            ) : (
              <>
                <div
                  className="flex-col"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Attachment style={{ width: "30px", height: "50px" }} />{" "}
                  <h6
                    style={{
                      color: "white",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    Adjuntos formulario
                  </h6>
                </div>
                <div
                  className="flex-row"
                  style={{ width: "100%", justifyContent: "space-evenly" }}
                >
                  <div
                    className="flex-col"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <h3
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "60px",
                      }}
                    >
                      {attachmentInfo.numAttachments}
                    </h3>
                    <h6
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Numero de adjuntos
                    </h6>
                  </div>
                  <div
                    className="flex-col"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <h3
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "60px",
                      }}
                    >
                      {attachmentInfo.attachmentsAverage.toFixed(2)}
                    </h3>
                    <h6
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Promedio de adjuntos
                    </h6>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex-row" style={{ width: "100%", height: "48%" }}>
          {!statusByDate || loadingStatusByDate === "pending" ? (
            <LoadingOverlay
              active={true}
              spinner={<ClipLoader color="white" size="100px" />}
            />
          ) : (
            <ReactECharts
              style={{ height: "90%", width: "100%" }}
              option={convertStatusByDateToStackedBar(
                statusByDate,
                "MagicForms",
                "Estado del formulario por fecha"
              )}
              showLoading={false}
              loadingOption={{
                text: "Cargando",
                color: "#FFF",
                textColor: "#FFF",
                maskColor: "rgba(0, 0, 0, 0.6)",
                zlevel: 0,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(changeCurrentProduct("Magic Forms"));
    if (activeComponent !== "Analytics") {
      dispatch(changeActiveComponent("Analytics"));
    }
    if (loadingStatusResult === "idle") {
      dispatch(getStatusMagicForms());
    }

    if (loadingStatusByDate === "idle") {
      dispatch(getStatusMagicformsByDate());
    }

    if (loadingAttachmentInfo === "idle") {
      dispatch(getAttachmentInfoMagicForms());
    }
    return () => {};
  }, [activeComponent, magicFormsAnalyticsRequest]);

  return (
    <div
      className="flex-col"
      style={{
        width: "100%",
        height: "90%",
        justifyContent: "space-evenly",
        alignItems: "start",
      }}
    >
      <div className="flex-row" style={{ width: "95%", alignSelf: "center" }}>
        <div
          className="flex-row"
          style={{ marginRight: "1%", alignItems: "center" }}
        >
          <Info
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                changeHelpText(
                  "En esta sección podrás explorar la información general de los formularios"
                )
              );
            }}
          />
          <button
            onClick={(e) => {
              setSelectedSection("general");
            }}
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "8px 8px 8px 8px ",
              background:
                selectedSection === "general"
                  ? "rgba(0,0,0,1)"
                  : "rgba(13,60,80,1)",
              paddingTop: "6px",
              paddingBottom: "6px",
              paddingRight: "12px",
              paddingLeft: "12px",
              marginLeft: "5px",
              color: "#fff",
            }}
          >
            General
          </button>
        </div>
      </div>
      {showContent()}
    </div>
  );
};
