import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface RamaJudicialProps {
  loading: LoadingStatus;
  item: RamaJudicial | null;
}

export interface RamaJudicial {
  lista: string;
  tipo: string;
  bd: boolean;
  Resultado: Array<any>;
  coincidencia: string;
  tipoDocumentoConsulta: [
    {
      tipoDocumento: string;
      nombreDocumento: string;
    }
  ];
  duracion: string;
  presentaRiesgo: true;
  presentaAdvertencia: true;
  result: Array<RamaJudicialItem>;
  tipoDocumentoNoValido?: string;
}

export interface RamaJudicialItem {
  ciudad: string;
  condenas: Array<RamaJudicialCondena>;
}

export interface RamaJudicialCondena {
  numeroRadicacion: string;
  representante: string;
  presentaRiesgoCiudad: string;
  nombreSujeto: string;
  juzgado: string;
  identificacion: string;
  url: string;
  clase: string;
  delito: string;
}

export const RamaJudicialResult = (props: RamaJudicialProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "rgb(248 113 113)";
    } else if (presentaAdvertencia) {
      return "rgb(250 204 21)";
    } else {
      return "rgb(74 222 128)";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {props.item?.tipoDocumentoNoValido ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              width: "100%",
              padding: "0 8px",
            }}
          >
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "1.875rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Rama Judicial
            </div>
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "1.125rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {props.item.tipoDocumentoNoValido}
            </div>
          </div>
        </>
      ) : (
        <>
          {props.item ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
                padding: "0 8px",
              }}
            >
              <div
                style={{
                  color: getColor(
                    props.item.presentaRiesgo,
                    props.item.presentaAdvertencia
                  ),
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Rama Judicial
              </div>
              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {props.item.presentaRiesgo
                  ? "Presenta riesgo"
                  : "No presenta riesgo"}
              </div>

              <div
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Condenas Por ciudad
              </div>
              {props.item && props.item.result ? (
                props.item.result.map((condenaCiudad, i) => {
                  if (
                    condenaCiudad.condenas &&
                    condenaCiudad.condenas.length > 0
                  ) {
                    return (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        key={i}
                      >
                        <div
                          style={{
                            color: "#FFFFFF",
                            fontSize: "1.125rem",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                        >
                          Ciudad: {condenaCiudad.ciudad}
                        </div>
                        <table style={{ width: "100%", position: "relative" }}>
                          <thead>
                            <tr>
                              <th></th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                No. Radicación
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Representante
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Presenta Riesgo
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Nombre
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Juzgado
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Identificacion
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Clase
                              </th>
                              <th
                                style={{
                                  color: "rgba(255, 255, 255, 0.35)",
                                  fontWeight: "600",
                                  fontSize: "0.875rem",
                                  padding: "8px 0",
                                  textAlign: "center",
                                }}
                              >
                                Delito
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {condenaCiudad.condenas.map((condena, j) => (
                              <tr
                                key={j}
                                style={{
                                  color: "#FFFFFF",
                                  fontSize: "0.75rem",
                                  fontWeight: "400",
                                  height: "40px",
                                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                                  borderRadius: "4px",
                                  textAlign: "center",
                                }}
                              >
                                <td></td>
                                <td>{condena.numeroRadicacion}</td>
                                <td>{condena.representante}</td>
                                <td>
                                  {condena.presentaRiesgoCiudad ? "Sí" : "No"}
                                </td>
                                <td>{condena.nombreSujeto}</td>
                                <td>{condena.juzgado}</td>
                                <td>{condena.identificacion}</td>
                                <td>{condena.clase}</td>
                                <td>{condena.delito}</td>
                                <td></td>
                              </tr>
                            ))}
                            <tr>
                              <td>&nbsp;</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  } else {
                    return <div key={i}></div>;
                  }
                })
              ) : (
                <h6 style={{ color: "#FFFFFF", textAlign: "center" }}>
                  "No se encontraron registros en condenas por ciudad"
                </h6>
              )}
            </div>
          ) : (
            <div>
              {props.loading === "rejected" || props.loading === "resolved" ? (
                <div
                  style={{
                    color: "rgba(255, 255, 255, 0.35)",
                    fontSize: "1.25rem",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  No se encontraron registros, intente de nuevo más tarde
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
