import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { formatDateStandar } from "../../../../helpers/utils";
import {
  selectLoginStatusReportsComponent,
  getFilteredReport,
  selectActiveDashboardComponent,
  selectCurrentFilteredDashboard,
  selectFilteredReport,
  selectCurrentPageReports,
  selectNumPagesReports,
  selectColumnsReport,
  changePageState,
} from "../DashboardSlice";
import { changeCurrentProduct } from "../../../common/commonSlice";
import "./../DashboardStyles.scss";
import PaginationItem from "../../../../helpers/PaginationItem";
import { usePagination } from "pagination-react-js";
import { useNavigate } from "react-router-dom";

export const Reports = () => {
  const loading = useAppSelector(selectLoginStatusReportsComponent);
  const dispatch = useAppDispatch();
  const activeComponent = useAppSelector(selectActiveDashboardComponent);
  const currentFilteredRequest = useAppSelector(selectCurrentFilteredDashboard);
  const dataReports = useAppSelector(selectFilteredReport);
  const columnsReport = useAppSelector(selectColumnsReport);
  const currentPage = useAppSelector(selectCurrentPageReports);
  const numPages = useAppSelector(selectNumPagesReports);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(changeCurrentProduct("Dashboard"));

    dispatch(
      getFilteredReport({
        filter: currentFilteredRequest,
      })
    );
  }, [activeComponent, currentFilteredRequest]);

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: 15,
        totalRecordsLength: numPages * 15,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {dataReports && dataReports.reportData.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "95%" }}>
          <div
            className="flex-row"
            style={{
              height: "20%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Registros realizados
            </div>
          </div>
          {dataReports.reportData ? (
            <div className="flex-col" style={{ height: "65%" }}>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table-report"
                  style={{
                    width: "max-content",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      {columnsReport?.columns.map(
                        (column) =>
                          column.active && (
                            <th style={{ padding: "5px" }} key={column.field}>
                              {column.name}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    {dataReports.reportData.map((reg) => {
                      return (
                        <tr
                          key={reg._id}
                          className="list-hover"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                        >
                          {columnsReport?.columns.map(
                            (column) =>
                              column.active && (
                                <td
                                  key={column.field}
                                  onClick={() => {
                                    if (column.field === "_id") {
                                      navigate(
                                        `/services/coreId/registrations/${reg._id}`
                                      );
                                    }
                                  }}
                                >
                                  {column.field === "startDatetime"
                                    ? formatDateStandar(reg.startDatetime)
                                    : column.field === "currentAssignees"
                                    ? Array.isArray(reg.currentAssignees) &&
                                      reg.currentAssignees.length > 0
                                      ? reg.currentAssignees.map(
                                          (item, index) => (
                                            <span key={index}>{item}</span>
                                          )
                                        )
                                      : ""
                                    : reg[column.field as keyof typeof reg]}
                                </td>
                              )
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              marginRight: "100px",
            }}
          >
            <div
              className="flex-row primary-color"
              style={{
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Pagination />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-row text-bold primary-color"
          style={{
            width: "100%",
            height: "100%",
            fontSize: "13px",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle" ? (
              ""
            ) : (
              <p>No hay más registros en esta página</p>
            )}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
