import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import "../../servicesCommonStyles.scss";
import { ReactComponent as ExpandMore } from "../../../../Assets/common/images/iconExpand.svg";
import { ReactComponent as ExpandLess } from "../../../../Assets/common/images/iconExpand2.svg";
import { ReactComponent as HyperFlowAlert } from "../../../../Assets/alerts/hyperFlowAlerts.svg";

export interface ExpandibleComponentProps {
  title: string;
  isActive: boolean;
  content: JSX.Element;
  alert: boolean;
  description: string;
  detail: string;

  onClickFunction?: Function;
  contentHeight?: string;
}

export const ExpandibleComponent = (props: ExpandibleComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });
  useEffect(() => {
    setIsExpanded(props.isActive);
  }, [props.isActive, setIsExpanded]);

  return (
    <div className="mt-2 mb-2">
      <div
        className="flex-row"
        {...getToggleProps({
          onClick: (e) => {
            e.preventDefault();
            setIsExpanded(!isExpanded);
            if (props.onClickFunction) {
              props.onClickFunction();
            }
          },
        })}
        style={{
          background: "rgba(0,0,0,0.4)",
          border: "none",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          height: "28px",
          boxSizing: "border-box",
          paddingLeft: "30px",
          paddingRight: "15px",
          marginBottom: "1px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        {props.alert && <HyperFlowAlert />}
        <div
          className="col-10 text-normal text-medium primary-color"
          style={{ textAlign: "start", width: "90%" }}
        >
          {props.title}
        </div>
      </div>
      <section
        className="flex-row"
        style={{
          background: "rgba(0,0,0,0.2)",
          width: "100%",
          paddingTop: "0",
          borderBottomLeftRadius: isExpanded ? "0px" : "8px",
          borderBottomRightRadius: isExpanded ? "0px" : "8px",
        }}
      >
        <div className="flex-col" style={{ width: "90%", padding: "1%" }}>
          {props.content}
        </div>
        <div className="flex-col" style={{ width: "10%", paddingTop: "5%" }}>
          <div
            className="flex-col text-normal text-xx-large primary-color"
            style={{
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
              if (props.onClickFunction) {
                props.onClickFunction();
              }
            }}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
      </section>
      <section
        className="flex-col primary-color text-small"
        {...getCollapseProps({
          style: {
            background: "rgba(0,0,0,0.3)",
            width: "100%",
            overflow: "auto",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            padding: "4%",
          },
        })}
      >
        <div className="flex-col">{props.description}</div>
        <div className="flex-col" style={{ paddingTop: "2%" }}>
          {props.detail}
        </div>
      </section>
    </div>
  );
};
