import axiosApiInstance from "../../../helpers/interceptor";
import fileDownload from "js-file-download";
import { UnifiedAlertsFiltered } from "../alerts/alertsAPI";
import { ExternalQueryResult, RegistrationDetail } from "../coreid/coreIdAPI";
import { GetSignatureResponse } from "../firmanew/firmaNewAPI";
import { AttatchmentData, FilledForm } from "../magicforms/magicFormsAPI";
import { GetPagareResponse } from "../pagares/pagareAPI";

let getFiltered = "hyperflow/getFiltered";
let getRevisionMode = "hyperflow/revisionModeFiltered";
let getFilterOptions = "hyperflow/filterOptions";
let getDashboardDataMethod = "hyperflow/stats";
let getFlowProcess = (flowId: string) => `hyperflow/execFlow/${flowId}`;
let changeStatus = (flowId: string) =>
  `hyperflow/execFlow/${flowId}/updateStep`;
let retryStep = (flowId: string) => `hyperflow/execFlow/${flowId}/manualRetry`;
let getDataShowcase = (flowId: string) =>
  `hyperflow/execFlow/${flowId}/dataShowcase`;
let getCustomStatePath = (execFlowId: string) =>
  `hyperflow/execFlow/${execFlowId}/getCustomStates`;
let getCustomPathResolveCustomState = (execFlowId: string) =>
  `hyperflow/execFlow/${execFlowId}/resolveCustomState`;
const executeFlowPath = (flowId: string) => `hyperflow/executeFlow/${flowId}`;

const sendContinueEmailPath = (execFlowId: string) =>
  `hyperflow/execFlow/${execFlowId}/sendContinueEmail`;

const reExecuteStepPath = (execFlowId: string, stepId: string) =>
  `execFlow/${execFlowId}/reExecuteStep/${stepId}`;

const getManualInputDataPath = (execFlowId: string, stepId: string) =>
  `hyperflow/execFlow/${execFlowId}/getManualInputDataConfig/${stepId}`;

const getMiniDashboardDataAPI = async (): Promise<{
  miniDasboardData: GetStatsHyperFlowResponse | null;
  error: string;
}> => {
  let dashboardData = await axiosApiInstance.get<GetStatsHyperFlowResponse>(
    `/${getDashboardDataMethod}`
  );
  if (dashboardData.status !== 200) {
    return {
      miniDasboardData: null,
      error: `Se generó un código ${dashboardData.status}`,
    };
  } else {
    return { miniDasboardData: dashboardData.data, error: "" };
  }
};

const getFilteredFlowAPI = async (
  params: GetFilteredHyperFlowRequest
): Promise<{ flows: FlowFiltered | null; error: string }> => {
  try {
    let registrationsResponse = await axiosApiInstance.post(
      `/${getFiltered}`,
      params
    );
    return {
      flows: registrationsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      flows: null,
    };
  }
};

const getRevisionFlowAPI = async (): Promise<{
  flows: FlowFiltered | null;
  error: string;
}> => {
  let params: GetFilteredHyperFlowRequest = {
    startDate: null,
    endDate: null,
    hyperFlowId: null,
    searchQuery: null,
    status: null,
    page: 0,
    tagId: null,
    stepFilter: null,
  };

  try {
    let registrationsResponse = await axiosApiInstance.post(
      `/${getRevisionMode}`,
      params
    );
    return {
      flows: registrationsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      flows: null,
    };
  }
};

const getFilterOptionsFlowAPI = async (): Promise<{
  filterOptions: FlowFilteredOptions | null;
  error: string;
}> => {
  try {
    let filterOptionsResponse = await axiosApiInstance.get(
      `/${getFilterOptions}`
    );

    return {
      filterOptions: filterOptionsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      filterOptions: null,
    };
  }
};

const getFlowByIdAPI = async (
  flowId: string
): Promise<{ flow: HyperFlow | null; error: string }> => {
  try {
    let flowResponse = await axiosApiInstance.get(`/${getFlowProcess(flowId)}`);
    return {
      flow: flowResponse.data,
      error: "",
    };
  } catch (err: any) {
    if (err.response.status === 404) {
      return {
        error: "404",
        flow: null,
      };
    } else if (err.response.status === 403) {
      return {
        error: "403",
        flow: null,
      };
    }
    return {
      error: "500",
      flow: null,
    };
  }
};

const getCheckListsAPI = async (
  execFlowId: string,
  stepId: string
): Promise<{ listResult: CheckListsResult | null; error: "" }> => {
  try {
    const listsResponse = await axiosApiInstance.get<CheckListsResult>(
      `hyperflow/execFlow/${execFlowId}/getListsResults/${stepId}`
    );
    return {
      listResult: listsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      listResult: null,
    };
  }
};

const getDataShowcaseByExecFlowIdAPI = async (
  flowId: string
): Promise<{ dataShowcase: DataShowcase | null; error: string }> => {
  try {
    let showcaseResponse = await axiosApiInstance.get(
      `/${getDataShowcase(flowId)}`
    );
    return {
      dataShowcase: showcaseResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      dataShowcase: null,
    };
  }
};

const getRetryStep = async (flowId: string): Promise<{ error: string }> => {
  try {
    await axiosApiInstance.get(`/${retryStep(flowId)}`);
    return {
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
    };
  }
};

const changeFlowStatusAPI = async (
  newStatus: string,
  flowId: string,
  stepId: string,
  comment: string
): Promise<{ flow: string | null; error: string }> => {
  try {
    let updatedFlow = await axiosApiInstance.post(`/${changeStatus(flowId)}`, {
      stepId: stepId,
      status: newStatus,
      comment: comment,
    });
    return {
      flow: updatedFlow.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err?.message,
      flow: null,
    };
  }
};

const getCustomStatesAPI = async (
  execFlowId: string
): Promise<{ customState: CustomStateResponse | null; error: string }> => {
  try {
    let cState = await axiosApiInstance.get(
      `/${getCustomStatePath(execFlowId)}`
    );
    return { customState: cState.data, error: "" };
  } catch (err: any) {
    return {
      error: err?.message,
      customState: null,
    };
  }
};

const resolveCustomStateAPI = async (
  execFlowId: string,
  data: ResolveCustomStateRequest
): Promise<{ result: boolean }> => {
  try {
    await axiosApiInstance.post(
      `/${getCustomPathResolveCustomState(execFlowId)}`,
      data
    );
    return { result: true };
  } catch (err: any) {
    return { result: false };
  }
};

export const executeFlowAPI = async (
  flowId: string
): Promise<{ result: ExecuteFlowResponse }> => {
  try {
    let response = await axiosApiInstance.get(`/${executeFlowPath(flowId)}`);
    return { result: response.data };
  } catch (err: any) {
    return { result: err };
  }
};

export const sendContinueEmailAPI = async (
  execFlowId: string
): Promise<{ result: boolean }> => {
  try {
    let response = await axiosApiInstance.get(
      `/${sendContinueEmailPath(execFlowId)}`
    );
    if (response.status !== 200) {
      return { result: false };
    }
    return { result: true };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const reExecuteStepAPI = async (
  execFlowId: string,
  stepId: string
): Promise<{ result: boolean }> => {
  try {
    let response = await axiosApiInstance.get(
      `/${reExecuteStepPath(execFlowId, stepId)}`
    );
    if (response.status !== 200) {
      return { result: false };
    }
    return { result: true };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const sendEmailLinkAPI = async (req: SendFlowLinkRequest) => {
  try {
    await axiosApiInstance.post("/lead/sendFlowLinkByEmail", req);
    return { result: true };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const sendEmailLinkToUnregisteredAPI = async (
  req: SendFlowLinkRequest
) => {
  try {
    const response = await axiosApiInstance.post<boolean>(
      "/lead/sentFlowLinkToUnregistered",
      req
    );
    return { result: response.data };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const getFilteredFlowSentAPI = async (
  req: GetFilteredFlowSentRequest
) => {
  try {
    let response = await axiosApiInstance.post<GetFilteredFlowSentResponse>(
      "/lead/getFilteredSentFlows",
      req
    );
    return { result: response.data };
  } catch (err: any) {
    console.error(err);
    return { result: { sentLinkFlows: [], numPages: 0 } };
  }
};

export const generateSentLinkReportAPI = async () => {
  try {
    let report = await axiosApiInstance.get<SentLinkReport>(
      "lead/generateSentFlowReport"
    );
    let url = `data:application/pdf;base64,${report.data.base64Data}`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(blob, `ReporteUsuarios.pdf`);
      });
    return report.data;
  } catch {
    return null;
  }
};
export const getManualInputDataAPI = async (
  execFlowId: string
): Promise<{ result: GetManualInputDataResponse; error: string }> => {
  try {
    let response = await axiosApiInstance.get<GetManualInputDataResponse>(
      `/hyperflow/execFlow/${execFlowId}/manualInputData`
    );
    return { result: response.data, error: "" };
  } catch (err: any) {
    console.error(err);
    return {
      result: { showChangelableDataWindow: false, items: [] },
      error: err?.message,
    };
  }
};

export const getManualInputDataConfigAPI = async (
  execFlowId: string,
  stepId: string
): Promise<{
  result: boolean;
  error: string;
  data: ManualInputDataConfig | undefined;
}> => {
  try {
    let response = await axiosApiInstance.get<ManualInputDataConfig>(
      `/${getManualInputDataPath(execFlowId, stepId)}`
    );
    return { data: response.data, error: "", result: true };
  } catch (err: any) {
    console.error(err);
    return {
      data: undefined,
      error: err?.message,
      result: false,
    };
  }
};

export const resolveManualInputAPI = async (
  execFlowId: string,
  data: ResolveManualInputRequest
): Promise<{ result: boolean }> => {
  try {
    await axiosApiInstance.post(
      `/hyperflow/execFlow/${execFlowId}/resolveManualInput`,
      data
    );
    return { result: true };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const getRpaStatusAPI = async (
  execFlowId: string
): Promise<{ result: GetRpaStatusResponse; error: string }> => {
  try {
    let response = await axiosApiInstance.get<GetRpaStatusResponse>(
      `/hyperflow/execFlow/${execFlowId}/getRpaStatus`
    );
    return { result: response.data, error: "" };
  } catch (err: any) {
    console.error(err);
    return {
      result: { showRpaStatus: false, rpaStatus: "PENDING" },
      error: err?.message,
    };
  }
};

export const switchRpaStatusAPI = async (
  execFlowId: string
): Promise<{ result: boolean }> => {
  try {
    await axiosApiInstance.get(
      `/hyperflow/execFlow/${execFlowId}/switchRpaStatus`
    );
    return { result: true };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const uploadSupportFileAPI = async (
  req: UploadSupportFileRequest,
  id: string
): Promise<{ result: boolean }> => {
  try {
    let resp = await axiosApiInstance.post(
      `/hyperflow/execFlow/${id}/uploadSupportFile`,
      req
    );

    return { result: resp.status === 200 };
  } catch (err: any) {
    console.error(err);
    return { result: false };
  }
};

export const getSupportFilesAPI = async (
  id: string
): Promise<{ result: SupportFile[] }> => {
  try {
    let response = await axiosApiInstance.get<SupportFile[]>(
      `/hyperflow/execFlow/${id}/getSupportFiles`
    );
    if (response.status !== 200) {
      return { result: [] };
    }
    return { result: response.data };
  } catch (err: any) {
    console.error(err);
    return { result: [] };
  }
};

export const getSupportFileAPI = async (
  id: string,
  fileName: string
): Promise<{ result: AttatchmentData | null }> => {
  try {
    let response = await axiosApiInstance.get<AttatchmentData>(
      `/hyperflow/execFlow/${id}/getSupportFile/${fileName}`
    );
    if (response.status !== 200) {
      return { result: null };
    }
    let url = `data:application/pdf;base64,${response.data.fileBase64}`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(blob, `${fileName}`);
      });
    return { result: response.data };
  } catch (err: any) {
    console.error(err);
    return { result: null };
  }
};

export interface ExecuteFlowResponse {
  link: string;
}
export interface GetFilteredHyperFlowRequest {
  searchQuery: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  hyperFlowId: string | null;
  page: number;
  pageSize?: number;
  tagId: string | null;
  stepFilter: string | null;
}

export interface GetStatsHyperFlowResponse {
  total: number;
  rejected: number;
  accepted: number;
  pending: number;
  new: number;
}

export interface FlowFiltered {
  flows: HyperFlow[];
  numPages: number;
  page?: number;
}

export interface ExecutedStep {
  id: string;
  status:
    | "DONE"
    | "FAILED_AUTO_RETRY"
    | "FAILED_MANUAL_RETRY"
    | "IN_PROGRESS"
    | "PENDING"
    | "PENDING_MANUAL_REVISION";
  errorData: string;
  customName: string;
  entityId: string;
  stepDescriptionId: string;
  resultData: string[];
  nextStepId: string;
  detailCore?: RegistrationDetail | null;
  detailMagic?: FilledForm | null;
  detailSig?: GetSignatureResponse | null;
  detailPagare?: GetPagareResponse | null;
  detailLists?: CheckListsResult | null;
  reExecutable: boolean;
}

export interface CheckListsResult {
  execFlowId: string;
  stepId: string;
  results: { [key: string]: ExternalQueryResult };
}

export interface HyperFlow {
  clientId: string;
  hyperFlowId: string;
  id: string;
  executedSteps?: { [key: string]: ExecutedStep };
  stateId: string;
  userId: string;
  flowStatus:
    | "AWAITING_MANUAL_INTERACTION"
    | "DONE"
    | "IN_PROGRESS"
    | "AWAITING_USER_INTERACTION"
    | "ABANDONED"
    | "CANCELLED";
  metadata: { [key: string]: string };
  currentAssignees: string[];
  currentStepId: string;
  registrationAlerts?: UnifiedAlertsFiltered | null;
  registration?: RegistrationDetail | null;
  acceptanceStatus: "ACCEPTED" | "REJECTED" | "NONE";
  asesorId?: string;
  startDatetime?: string;
  provisionalData?: ProvisionalData;
  supportFiles?: SupportFile[];
  cancelationReason?: string;
  cancelatinDetail?: string;
}

export interface SupportFile {
  fileName: string;
  name: string;
  dateCreated: string;
}

export interface ProvisionalData {
  name: string;
  lastName: string;
}

export interface CustomStateResponse {
  customStates: { [key: string]: CustomStateItem };
}

export interface CustomStateItem {
  stepId: string;
  tabName: string;
  buttons: Object;
  comment: string;
  selectedState: string;
  selectedStateName: string;
}

export interface ResolveCustomStateRequest {
  comment: string;
  selectedState: string;
  stepId: string;
}

export interface FlowFilteredOptions {
  hyperFlows: { [key: string]: string };
  stepFilters: { [key: string]: string };
}

export interface DataShowcase {
  showShowcase: boolean;
  items: Array<DataShowcaseItem>;
}

export interface DataShowcaseItem {
  name: string;
  value: string;
}

export interface SendFlowLinkRequest {
  link?: string;
  subject: string;
  message: string;
  emails?: string[];
}

export interface GetManualInputDataResponse {
  showChangelableDataWindow: boolean;
  items: Array<GetManualInputDataItem>;
}

export interface ManualInputDataConfig {
  name: string;
  description: string;
  clientId: string;
  fields: ManualInputDataConfigField[];
  conditionals: ManualInputDataConfigConditional[];
}

export interface ManualInputDataConfigField {
  fieldId: string;
  dataId: string;
  title: string;
  type: "TEXT" | "NUMBER" | "CURRENCY_CO";
  conditionalSourceId?: string;
  conditionalDestinationIds: string[];
  validator: ManualInputDataConfigValidator;
  value: string;
}

export interface ManualInputDataConfigValidator {
  validatorType:
    | "REQUIRED"
    | "EMAIL"
    | "PHONE"
    | "NUMBER"
    | "DATE"
    | "CURRENCY"
    | "VALUE_ABOVE"
    | "VALUE_BELOW"
    | "VALUE_BETWEEN";

  firstValue: number;
  secondValue: number;
  message: string;
}

export interface ManualInputDataConfigConditional {
  conditionalId: string;
  conditionalType: "EQUALS" | "BETWEEN";
  firstValue: number;
  secondValue: number;
}

export interface GetManualInputDataItem {
  id: string;
  name: string;
  type: string;
  value: string;
}

export interface ResolveManualInputRequest {
  data: { [key: string]: string };
  step: string;
}

export interface GetRpaStatusResponse {
  showRpaStatus: boolean;
  rpaStatus: "PENDING" | "ERROR" | "COMPLETE";
}

export interface GetFilteredFlowSentRequest {
  status: string | null;
  page: number;
}

export interface GetFilteredFlowSentResponse {
  sentLinkFlows: Array<SentFlow>;
  numPages: number;
}
export interface SentLinkReport {
  base64Data: string;
}
export interface SentFlow {
  email: string;
  linkSent: string;
  clientId: string;
  dateLastSent: string;
  status: string;
  timesSent: number;
  registrationId: string;
  hyperFlowId: string;
}

export interface UploadSupportFileRequest {
  fileBase64: string;
  fileName: string;
  name: string;
}

export {
  getFilteredFlowAPI,
  getFilterOptionsFlowAPI,
  getFlowByIdAPI,
  changeFlowStatusAPI,
  getMiniDashboardDataAPI,
  getRevisionFlowAPI,
  getRetryStep,
  getDataShowcaseByExecFlowIdAPI,
  getCustomStatesAPI,
  resolveCustomStateAPI,
  getCheckListsAPI,
};
