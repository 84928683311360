import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  mfEditorCreateModal,
  mfEditorGetModalById,
  mfEditorGetModals,
  mfEditorGetTemplateById,
  mfEditorGetTemplates,
  mfEditorUpdateData,
  mfEditorUpdateModal,
  selectActiveModal,
  selectActiveTemplate,
  selectMfCurrentModal,
  selectMfCurrentModalId,
  selectMfDrawableTemplate,
  selectMfEdited,
  selectMfError,
  selectMfFormTemplate,
  selectMfLoadingStatus,
  selectMfLoadingStatusModal,
  selectMfModals,
  selectMfPdfTemplate,
  selectMfTemplateId,
  selectMfTemplates,
  updateDrawableTemplate,
  updateFormTemplate,
  updateModal,
} from "./mfEditorSlice";
import { DropDown } from "../../../common/components/Btns/DropDown";
import "./MfEditorPage.scss";
import FormTemplateEditor from "./screens/FormTemplateEditor";
import DrawableTemplateEditor from "./screens/DrawableTemplateEditor";
import Loader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import ModalEditorDetail from "./modalEditor/ModalEditorDetail";

export const ModalEditorPage = () => {
  let loading = useAppSelector(selectMfLoadingStatusModal);
  let edited = useAppSelector(selectMfEdited);
  let error = useAppSelector(selectMfError);
  let modals = useAppSelector(selectMfModals);
  let modalId = useAppSelector(selectMfCurrentModalId);
  let currentModal = useAppSelector(selectMfCurrentModal);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  //Local State
  //Load All modals
  useEffect(() => {
    if (loading === "idle") dispatch(mfEditorGetModals());
  }, [loading]);

  useEffect(() => {
    if (modalId) {
      dispatch(mfEditorGetModalById(modalId));
    }
  }, [modalId]);

  //Helper Functions
  function changeModalId(id: string) {
    console.log("Changing modal id");
    console.log(id);
    if (edited) {
      if (window.confirm("¿Desea editar otro modal sin guardar los cambios?")) {
        dispatch(selectActiveModal(id));
      }
    } else {
      dispatch(selectActiveModal(id));
    }
  }

  function saveChanges() {
    if (currentModal && modalId) {
      if (window.confirm("¿Desea guardar los cambios?")) {
        dispatch(mfEditorUpdateModal({ id: modalId, modal: currentModal }));
      }
    }
  }

  //Component Funcionts
  const getModalsDropdown = (): JSX.Element => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          borderRadius: "10px",
          padding: "10px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <div
          className="text-large primary-color text-bold"
          style={{ marginBottom: "18px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {" < "}Volver al menu principal
        </div>
        <div
          className="text-medium primary-color"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Selecciona el modal a editar
        </div>
        <div
          style={{
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          {modals.map((modal) => {
            return (
              <div
                className="text-medium primary-color"
                key={modal.id}
                onClick={() => {
                  changeModalId(modal.id);
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "10px",
                  fontSize: "14px",
                  color: modal.id === modalId ? "green" : "white",
                  backgroundColor:
                    modal.id === modalId ? "rgba(0,0,0,0.2)" : "",
                }}
              >
                {modal.name === "" ? "Sin nombre" : modal.name}
              </div>
            );
          })}
          <div
            className="text-medium primary-color"
            onClick={() => {
              dispatch(
                mfEditorCreateModal({
                  description: "",
                  items: [],
                  name: "",
                })
              );
            }}
            style={{
              cursor: "pointer",
              padding: "10px",
              borderRadius: "10px",
              fontSize: "14px",
              color: "white",
              backgroundColor: "",
              marginTop: "30px",
            }}
          >
            Agregar modal +
          </div>
        </div>
      </div>
    );
  };

  const getContent = () => {
    if (currentModal)
      return (
        <div>
          <ModalEditorDetail
            modal={currentModal}
            onChange={(modal) => {
              dispatch(updateModal(modal));
            }}
          />
        </div>
      );
    else return <div></div>;
  };
  //Render

  if (loading === "pending") {
    return (
      <div
        className="text-normal text-medium primary-color"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          padding: "50px",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader color="white" loading={true} size={150} />
        Cargando...
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "60%",
        padding: "30px",
      }}
    >
      <div style={{ width: "18vw", height: "50vh" }}>
        <div>{getModalsDropdown()}</div>
        {edited && (
          <button
            className="boton1"
            style={{ marginTop: "20px" }}
            onClick={() => saveChanges()}
          >
            Guardar cambios en el servidor
          </button>
        )}
      </div>
      {modalId && currentModal && (
        <div style={{ width: "80%" }}>
          <div>{getContent()}</div>
        </div>
      )}
    </div>
  );
};
