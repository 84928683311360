import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  ClientBrandResponse,
  getClientBrandByCodeAPI,
  getClientDataAPI,
  getClientUserDataAPI,
} from "./commonApi";
import { InactiveService } from "../intro/components/InactiveService";

// State Type
export type Service =
  | ""
  | "coreId"
  | "magicForms"
  | "firmaNew"
  | "Biometrix Pay"
  | "Smart Contracts"
  | "hyperFlow"
  | "authentikator"
  | "smartFlow"
  | "electronicSignature"
  | "pagares"
  | "dashboard";

export type LoadingStatus = "idle" | "resolved" | "rejected" | "pending";

export type ProductTitle =
  | ""
  | "Core ID"
  | "Magic Forms"
  | "Firma Electrónica"
  | "HyperFlow"
  | "Alertas"
  | "Smart Contracts"
  | "Authentikator"
  | "Biometrix Pay"
  | "Smart Flow"
  | "Pagarés"
  | "Configuración"
  | "Ayuda y Documentacion"
  | "Dashboard";

export interface CommonState {
  clientName: string;
  currentProduct: ProductTitle;
  userName: string;
  roles: Array<string>;
  activeServices: Array<Service>;
  inactiveServices: Array<Service>;
  loading: boolean;
  requestToken: string;
  tokenExpiryTimestamp: number;
  errorMessage: string;
  userEmail: string;
  menuEmergenteOpen: boolean;
  userId: string;
  helpText: string;
  clientId: string;
  metaClientId: string;
  isMetaClient: boolean;
}
// State
const initialState: CommonState = {
  clientName: "",
  roles: [],
  userName: "",
  activeServices: [],
  inactiveServices: [],
  loading: false,
  requestToken: "",
  tokenExpiryTimestamp: -1,
  currentProduct: "",
  errorMessage: "",
  userEmail: "",
  menuEmergenteOpen: false,
  userId: "",
  helpText: "",
  clientId: "",
  metaClientId: "",
  isMetaClient: false,
};

// Thunks
export const getClientAndUserData = createAsyncThunk(
  "common/getClientUserData",
  async (_, { rejectWithValue }) => {
    try {
      const clientData = await getClientDataAPI();
      if (clientData.error) {
        return rejectWithValue(clientData.error);
      }
      const clientUserData = await getClientUserDataAPI();
      if (clientUserData.error) {
        return rejectWithValue(clientData.error);
      }
      if (!clientData.data || !clientUserData.data) {
        return rejectWithValue(clientData.error);
      } else {
        return { clientUserData, clientData };
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Reducers
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setTokenInfo: (
      state,
      action: PayloadAction<{ token: string; expiry: number }>
    ) => {
      state.requestToken = action.payload.token;
      state.tokenExpiryTimestamp = action.payload.expiry;
    },
    changeCurrentProduct: (state, action: PayloadAction<ProductTitle>) => {
      state.currentProduct = action.payload;
    },
    resetState: () => {},
    changeMenuEmergente: (state, action: PayloadAction<boolean>) => {
      state.menuEmergenteOpen = action.payload;
    },
    setRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    changeHelpText: (state, action: PayloadAction<string>) => {
      state.helpText = action.payload;
    },
    setMetaClientId: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.metaClientId = action.payload;
        state.isMetaClient = true;
      } else {
        state.metaClientId = "";
        state.isMetaClient = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientAndUserData.fulfilled, (state, action) => {
        let clientData = action.payload.clientData.data;
        let userData = action.payload.clientUserData.data;
        let active: Array<Service> = [];
        let inactive: Array<Service> = [];
        if (clientData?.activeServices && userData) {
          let service: keyof typeof clientData.activeServices;

          for (service in clientData?.activeServices) {
            let isActive = clientData?.activeServices[service];

            if (isActive) {
              active.push(service);
            } else {
              if (service !== "electronicSignature") {
                inactive.push(service);
              }
            }
          }


          inactive.push("Biometrix Pay");
          inactive.push("Smart Contracts");
          state.clientName = clientData.name;
          // Temporal
          userData.roles.push("clients.admin");
          
          state.roles = userData.roles;
          state.userName = userData.name;
          state.activeServices = active;
          state.inactiveServices = inactive;
          state.userEmail = userData.email;
          state.userId = userData.id;
          state.clientId = clientData.clientId;
          state.isMetaClient = clientData.metaClient;
        } else {
          state.errorMessage = "No hay información sobre el cliente";
        }
        state.loading = false;
      })
      .addCase(getClientAndUserData.rejected, (state) => {
        state.errorMessage =
          "No pudimos obtener información, intentemos de nuevo";
      });
  },
});

export const {
  setTokenInfo,
  changeCurrentProduct,
  resetState,
  changeMenuEmergente,
  setRoles,
  changeHelpText,
  setMetaClientId,
} = commonSlice.actions;

export const selectClientName = (state: RootState) => state.common.clientName;
export const selectActiveServices = (state: RootState) =>
  state.common.activeServices;
export const selectInactiveServices = (state: RootState) =>
  state.common.inactiveServices;
export const selectRequestToken = (state: RootState) =>
  state.common.requestToken;
export const selectTokenExpiryTimestamp = (state: RootState) =>
  state.common.tokenExpiryTimestamp;
export const selectLoading = (state: RootState) => state.common.loading;
export const selectProductTitle = (state: RootState) =>
  state.common.currentProduct;
export const selectUsername = (state: RootState) => state.common.userName;
export const selectRoles = (state: RootState) => state.common.roles;
export const selectUserEmail = (state: RootState) => state.common.userEmail;
export const selectMenuEmergente = (state: RootState) =>
  state.common.menuEmergenteOpen;
export const selectUserId = (state: RootState) => state.common.userId;
export const selectHelpText = (state: RootState) => state.common.helpText;
export const selectClientId = (state: RootState) => state.common.clientId;
export const selectMetaClientId = (state: RootState) =>
  state.common.metaClientId;
export const selectIsMetaClient = (state: RootState) =>
  state.common.isMetaClient;

export default commonSlice.reducer;
