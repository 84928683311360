import { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  selectActiveConfigurationComponent,
} from "../configurationSlice";
import {
  getClientDetail,
  selectLoadingSubClient,
  selectSubclientDetail,
  setEditRequestFromCurrentClient,
} from "./clientsSlice";
import {
  getClientAndUserData,
  selectClientId,
  selectIsMetaClient,
  selectRoles,
  setMetaClientId,
} from "../../common/commonSlice";
import {
  emptyTokenString,
  getClientToken,
  selectLoginLoading,
  selectTokenString,
} from "../../login/loginSlice";
import addHours from "date-fns/addHours";
import { useCookies } from "react-cookie";
import { ReactComponent as DeleteTag } from "../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../Assets/common/images/addTag.svg";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByEntity,
  selectAllTags,
  selectCurrentEntity,
  selectCurrentTagCreation,
  selectCurrentTagsByEntity,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByEntity,
  setTagCreation,
} from "../../services/tags/tagSlice";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "25%",
    width: "35%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const SubClientDetail = () => {
  let { id } = useParams();

  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  const [, setCookie] = useCookies(["token"]);

  let loadingSubclient = useAppSelector(selectLoadingSubClient);
  let subClientDetail = useAppSelector(selectSubclientDetail);
  let currentClientId = useAppSelector(selectClientId);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let isMetaclient = useAppSelector(selectIsMetaClient);

  //Roles stuff
  let roles = useAppSelector(selectRoles);

  // Tag stuff
  let currentEntity = useAppSelector(selectCurrentEntity);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let allTags = useAppSelector(selectAllTags);
  let currentEntityTags = useAppSelector(selectCurrentTagsByEntity);
  let loadingEntityTags = useAppSelector(selectLoadingCurrentTagsByEntity);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState(false);

  //Login stuff
  let loadingSeeAsClient = useAppSelector(selectLoginLoading);
  let tokenString = useAppSelector(selectTokenString);

  const [modalSeeAsSubclient, setModalSeeAsSubclient] = useState(false);

  const closeModalSeeAsSubclient = () => {
    setModalSeeAsSubclient(false);
  };

  const canEdit = () => {
    if (
      isMetaclient &&
      (roles.includes("clients.edit") || roles.includes("clients.admin"))
    ) {
      return true;
    }
    return false;
  };

  const getTagsRender = () => {
    if (loadingEntityTags == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
            marginTop: "20px",
          }}
        >
          {currentEntityTags.map((tag) => {
            return (
              <div
                key={tag.id}
                className="flex-row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)",
                  borderRadius: "8px",
                  padding: "3px 8px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  {tag.tag}
                </div>
                <DeleteTag
                  onClick={() => {
                    dispatch(
                      disassociateTag({
                        tagId: tag.id,
                        entityId: id!,
                        service: "COREID",
                      })
                    );
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            );
          })}
          <div>
            <div
              className="flex-row"
              style={{
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: "8px",
                padding: "1px 8px",
                marginRight: "5px",
                marginLeft: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddTagWindow(!addTagWindow);
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 600,
                  color: "#fff",
                  marginRight: "5px",
                }}
              >
                Etiquetas
              </div>

              <AddTag style={{ cursor: "pointer", margin: "5px 0 5px 5px" }} />
            </div>
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: id!,
                              service: "COREID",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          height: "28px",
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag === "" ? "hidden" : "visible",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: id!,
                                service: "COREID",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: id!,
                                  service: "COREID",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (tokenString && tokenString !== "") {
      //Save cookie
      let now = new Date();
      let inAnHour = addHours(now, 1);
      setCookie(
        "token",
        { token: tokenString, expiry: inAnHour.getTime() },
        {
          path: "/",
          expires: inAnHour,
          sameSite: "lax",
          secure: true,
        }
      );
      dispatch(emptyTokenString());
      dispatch(getClientAndUserData());

      setModalSeeAsSubclient(true);
    }
    if (activeComponent !== "Client") {
      dispatch(changeActiveComponent("Client"));
    }

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentEntity !== id && id) {
      dispatch(getCurrentTagsByEntity({ entityId: id, service: "CLIENTS" }));
    }

    if (id) {
      dispatch(getClientDetail(id!));
    }
  }, [tokenString]);
  if (loadingSubclient === "pending" || loadingSeeAsClient) {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "1%",
        }}
      >
        <ClipLoader color="#FFF" size={"200px"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "96%",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1%",
        }}
      >
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px 8px 0 0px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px 8px 0 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "35px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "8px 8px 0 0px",
                color: "rgba(255,255,255,0.5)",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Configuración del cliente
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  height: "100%",
                  padding: "6% 0 0 6%",
                }}
              >
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                  }}
                >
                  Nombre
                </h6>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {subClientDetail?.name}
                </div>
                <h6
                  style={{
                    margin: "0 0 0 10px",
                    color: "white",
                    fontSize: "11px",
                    fontWeight: 400,
                    marginTop: "20px",
                  }}
                >
                  NIT
                </h6>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.05)",
                    borderRadius: "6px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    maxWidth: "290px",
                    padding: "6px 10px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {subClientDetail?.nit || "No registrado"}
                </div>
                {subClientDetail?.projects && (
                  <>
                    {" "}
                    <h6
                      style={{
                        margin: "0 0 0 10px",
                        color: "white",
                        fontSize: "11px",
                        fontWeight: 400,
                        marginTop: "20px",
                      }}
                    >
                      Proyectos asociados
                    </h6>
                    {subClientDetail?.projects.map((project, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(255,255,255,0.05)",
                            borderRadius: "6px",
                            border: "1px solid rgba(255,255,255,0.2)",
                            maxWidth: "290px",
                            padding: "6px 10px",
                            color: "white",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          {project.projectName}
                        </div>
                      );
                    })}
                  </>
                )}
                {getTagsRender()}
              </div>
              <div style={{ height: "100%", width: "50%" }}></div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "15%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {
              <div
                className="text-medium text-bold primary-color"
                style={{
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "30px",
                  borderRadius: "8px",
                  textAlign: "center",
                  cursor: "pointer",
                  width: "180px",
                  visibility: canEdit() ? "visible" : "hidden",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setEditRequestFromCurrentClient());
                  navigate(
                    `/configuration/subclients/edit/${subClientDetail?.id}`
                  );
                }}
              >
                Editar cliente
              </div>
            }
            {subClientDetail &&
              subClientDetail.parentCanSeeData &&
              subClientDetail.id &&
              currentClientId !== subClientDetail?.id && (
                <div
                  className="text-medium text-bold primary-color"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "30px",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                    width: "250px",
                  }}
                  onClick={(e) => {
                    dispatch(getClientToken(subClientDetail?.id!));
                    dispatch(setMetaClientId(""));
                    e.preventDefault();
                  }}
                >
                  Ver backoffice como este cliente
                </div>
              )}
          </div>
        </div>
        <Modal
          isOpen={modalSeeAsSubclient}
          onRequestClose={closeModalSeeAsSubclient}
          style={customStylesModal}
          contentLabel="Cambio visualización de subcliente"
          shouldCloseOnOverlayClick={true}
        >
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="text-medium text-normal"
              style={{
                color: "black",
                marginBottom: "10px",
                paddingLeft: "5%",
                paddingRight: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
                Modo de visualización modificado
              </h5>
              <p
                style={{
                  margin: "17px 4% 0 4% ",
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Ahora puede visualizar la información del Backoffice como la
                vería este cliente.
              </p>
              <p
                style={{
                  margin: "5px 4% 0 4% ",
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Navegue el Backoffice para revisar esta información.
              </p>
            </div>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();

                closeModalSeeAsSubclient();
              }}
            >
              Continuar
            </button>
          </div>
        </Modal>
      </div>
    );
  }
};
