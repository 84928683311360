import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../common/commonSlice";
import {
  ATKDocumentConfig,
  ATKGetFilteredRequest,
  ATKProcess,
  ATKProcessConfig,
  getConfigsAPI,
  getDocumentConfigsAPI,
  getFilteredProcessesAPI,
  getProcessByIdAPI,
  initializeProcessAPI,
  InitializeRequest,
  uploadDocumentAPI,
  UploadDocumentRequest,
} from "./AuthentikatorAPI";
import { RootState } from "../../../app/store";

//State
export interface AuthentikatorState {
  loaders: AuthentikatorLoaders;
  pageSize: number;
  processList: ATKProcess[];
  currentProcess: ATKProcess | null;
  currentProcessId: string | null;
  currentPage: number;
  totalPages: number;
  currentFilter: ATKGetFilteredRequest;
  documentConfigsMap: { [key: string]: ATKDocumentConfig }; //Where K is the documentConfigId and V is the document config
  documentsBase64Map: { [key: string]: string }; //Where K is the documentId and V is the base64 of the document
  queuedErrors: string[];
  currentError?: string;
  processConfigMap: { [key: string]: ATKProcessConfig }; //Where K is the processConfigId and V is the process config
  isProcessDetail: boolean;
  viewingDetail: boolean;
  newProcessId?: string;
}

export interface AuthentikatorLoaders {
  listLoading: LoadingStatus;
  processLoading: LoadingStatus;
  documentsLoading: LoadingStatus;
  documentUploading: LoadingStatus;
  initializeProcessLoading: LoadingStatus;
  configsLoading: LoadingStatus;
  documentConfigsLoading: LoadingStatus;
}

const initialState: AuthentikatorState = {
  loaders: {
    listLoading: "idle",
    processLoading: "idle",
    documentsLoading: "idle",
    documentUploading: "idle",
    initializeProcessLoading: "idle",
    configsLoading: "idle",
    documentConfigsLoading: "idle",
  },
  pageSize: 10,
  processList: [],
  currentProcess: null,
  currentProcessId: null,
  currentPage: 0,
  totalPages: 1,
  documentConfigsMap: {},
  documentsBase64Map: {},
  queuedErrors: [],
  currentError: undefined,
  processConfigMap: {},
  viewingDetail: false,
  currentFilter: {
    page: 0,
    pageSize: 10,
    endDate: undefined,
    startDate: undefined,
    processConfigId: undefined,
    searchQuery: undefined,
  },
  isProcessDetail: false,
  newProcessId: undefined,
};

// ---------- THUNKS ----------

/**
 * Initializes a new process
 * @param req The request object
 * @returns
 */
export const initializeProcess = createAsyncThunk(
  "authentikator/initializeProcess",
  async (req: InitializeRequest, { rejectWithValue }) => {
    let res = await initializeProcessAPI(req);
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

/**
 * Uploads a document
 * @param req The request object
 * @returns The documentId
 */
export const uploadDocument = createAsyncThunk(
  "authentikator/uploadDocument",
  async (req: UploadDocumentRequest, { rejectWithValue }) => {
    let res = await uploadDocumentAPI(req);
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

export const getConfigs = createAsyncThunk(
  "authentikator/getConfigs",
  async (_, { rejectWithValue }) => {
    let res = await getConfigsAPI();
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

export const getDocumentConfigs = createAsyncThunk(
  "authentikator/getDocumentConfigs",
  async (_, { rejectWithValue }) => {
    let res = await getDocumentConfigsAPI();
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

export const getFilteredProcesses = createAsyncThunk(
  "authentikator/getFilteredProcesses",
  async (filter: ATKGetFilteredRequest, { rejectWithValue }) => {
    let res = await getFilteredProcessesAPI(filter);
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

export const getProcessById = createAsyncThunk(
  "authentikator/getProcessById",
  async (id: string, { rejectWithValue }) => {
    let res = await getProcessByIdAPI(id);
    if (res.result && res.data) {
      return res.data;
    } else {
      return rejectWithValue(res.error);
    }
  }
);

// ---------- SLICE ----------

export const authentikatorSlice = createSlice({
  name: "authentikator",
  initialState,
  reducers: {
    resetProcess: (state) => {
      state.currentProcess = null;
      state.documentsBase64Map = {};
      state.loaders.documentUploading = "idle";
      state.loaders.documentsLoading = "idle";
      state.loaders.processLoading = "idle";
      state.loaders.initializeProcessLoading = "idle";
      state.currentProcessId = null;
      state.viewingDetail = false;
      state.newProcessId = undefined;
    },
    dequeueError: (state) => {
      state.currentError = state.queuedErrors.shift();
    },
    deleteCurrentProcessId: (state) => {
      console.log("Deleting current process id");
      state.newProcessId = undefined;
    },
    changePageState: (state, action: PayloadAction<number>) => {
      state.currentFilter = {
        ...state.currentFilter,
        page: action.payload,
      };
      state.currentPage = action.payload;
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      state.currentFilter = {
        ...state.currentFilter,
        pageSize: action.payload,
      };
      state.pageSize = action.payload;
    },
    setIsProcessDetail: (state, action: PayloadAction<boolean>) => {
      state.isProcessDetail = action.payload;
    },
    changeCurrentFiltersATK: (
      state,
      action: PayloadAction<ATKGetFilteredRequest>
    ) => {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // initializeProcess
    builder
      .addCase(initializeProcess.pending, (state, action) => {
        state.loaders.initializeProcessLoading = "pending";
      })
      .addCase(initializeProcess.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loaders.initializeProcessLoading = "resolved";
        state.currentProcessId = action.payload.processId;
        state.newProcessId = action.payload.processId;
      })
      .addCase(initializeProcess.rejected, (state, action) => {
        state.loaders.initializeProcessLoading = "rejected";
      });
    // uploadDocument
    builder
      .addCase(uploadDocument.pending, (state, action) => {
        state.loaders.documentUploading = "pending";
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.loaders.documentUploading = "resolved";
        state.currentProcessId = action.payload.process.id;
        state.currentProcess = action.payload.process;
        if (action.payload.result === false) {
          state.queuedErrors.push(
            action.payload.errorMessage ??
              "Error inesperado al subir el documento"
          );
        }
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.loaders.documentUploading = "rejected";
      });
    // getConfigs
    builder
      .addCase(getConfigs.pending, (state, action) => {
        state.loaders.listLoading = "pending";
      })
      .addCase(getConfigs.fulfilled, (state, action) => {
        state.loaders.listLoading = "resolved";
        let map: { [key: string]: ATKProcessConfig } = {};
        action.payload.forEach((config) => {
          map[config.id] = config;
        });
        state.processConfigMap = map;
      })
      .addCase(getConfigs.rejected, (state, action) => {
        state.loaders.listLoading = "rejected";
      });
    // getDocumentConfigs
    builder
      .addCase(getDocumentConfigs.pending, (state, action) => {
        state.loaders.documentConfigsLoading = "pending";
      })
      .addCase(getDocumentConfigs.fulfilled, (state, action) => {
        state.loaders.documentConfigsLoading = "resolved";
        let map: { [key: string]: ATKDocumentConfig } = {};
        action.payload.forEach((config) => {
          map[config.id] = config;
        });
        state.documentConfigsMap = map;
      })
      .addCase(getDocumentConfigs.rejected, (state, action) => {
        state.loaders.documentConfigsLoading = "rejected";
      });
    // getFilteredProcesses
    builder
      .addCase(getFilteredProcesses.pending, (state, action) => {
        state.loaders.listLoading = "pending";
      })
      .addCase(getFilteredProcesses.fulfilled, (state, action) => {
        state.loaders.listLoading = "resolved";
        state.processList = action.payload.processes;
        state.totalPages = action.payload.numPages;
      })
      .addCase(getFilteredProcesses.rejected, (state, action) => {
        state.loaders.listLoading = "rejected";
      });
    // getProcessById
    builder
      .addCase(getProcessById.pending, (state, action) => {
        state.loaders.processLoading = "pending";
      })
      .addCase(getProcessById.fulfilled, (state, action) => {
        state.loaders.processLoading = "resolved";
        state.currentProcess = action.payload;
        state.currentProcessId = action.payload.id;
      })
      .addCase(getProcessById.rejected, (state, action) => {
        state.loaders.processLoading = "rejected";
      });
  },
});

export const {
  resetProcess,
  deleteCurrentProcessId,
  dequeueError,
  changePageSize,
  changePageState,
  setIsProcessDetail,
  changeCurrentFiltersATK,
} = authentikatorSlice.actions;

export default authentikatorSlice.reducer;

// ---------- SELECTORS ----------

export const selectInitializeProcessLoadingATK = (state: RootState) =>
  state.authentikator.loaders.initializeProcessLoading;
export const selectListLoadingATK = (state: RootState) =>
  state.authentikator.loaders.listLoading;
export const selectProcessLoadingATK = (state: RootState) =>
  state.authentikator.loaders.processLoading;
export const selectDocumentsLoadingATK = (state: RootState) =>
  state.authentikator.loaders.documentsLoading;
export const selectDocumentUploadingATK = (state: RootState) =>
  state.authentikator.loaders.documentUploading;
export const selectProcessList = (state: RootState) =>
  state.authentikator.processList;
export const selectCurrentProcessATK = (state: RootState) =>
  state.authentikator.currentProcess;
export const selectCurrentPageATK = (state: RootState) =>
  state.authentikator.currentPage;
export const selectTotalPagesATK = (state: RootState) =>
  state.authentikator.totalPages;
export const selectDocumentConfigsMapATK = (state: RootState) =>
  state.authentikator.documentConfigsMap;
export const selectDocumentsBase64MapATK = (state: RootState) =>
  state.authentikator.documentsBase64Map;
export const selectPageSizeATK = (state: RootState) =>
  state.authentikator.pageSize;
export const selectCurrentFilterRequestATK = (state: RootState) =>
  state.authentikator.currentFilter;
export const selectConfigs = (state: RootState) =>
  state.authentikator.processConfigMap;
export const selectNewProcessId = (state: RootState) =>
  state.authentikator.newProcessId;

export const selectCurrentErrorATK = (state: RootState) =>
  state.authentikator.currentError;

export const selectErrorQueueATK = (state: RootState) =>
  state.authentikator.queuedErrors;
