import React, { useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeCurrentProduct, selectRoles } from "../../common/commonSlice";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import {
  changeActiveComponent,
  changeCurrentFilters,
  selectCurrentFilterRequest,
  getFilterOptions,
  selectActiveCoreIdComponent,
  selectFilterOptions,
  changeCurrentProject,
  selectCurrentCoreIdAlertsRequest,
  changeCurrentAlertFilters,
  selectCurrentRegistration,
  changeStatus,
  selectCurrentFilterListsRequest,
  changeCurrentListsFilters,
  resetTries,
} from "./coreIdSlice";
import "./coreIdStyles.scss";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { Category } from "../components/sidePanel/categories/Category";
import { DropDown } from "../../common/components/Btns/DropDown";
import { SearchInputFilter } from "../../common/components/inputs/SearchInputFilter";
import { RadioButton } from "../../common/components/Btns/RadioButton";
import { ChooseState } from "./detail/components/buttons/ChooseState";
import {
  changeCurrentCoreIdFilters,
  resetStateAnalytics,
  selectAnalyticsCoreIdRequest,
} from "../analytics/analyticsSlice";
import {
  getCurrentTagsByService,
  selectCurrentService,
  selectCurrentTagsByService,
} from "../tags/tagSlice";
import {
  getAllUsers,
  selectAllUsers,
} from "../../configuration/configurationSlice";
import { ClipLoader } from "react-spinners";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "10%",
    width: "35%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const CoreId = () => {
  let activeComponent = useAppSelector(selectActiveCoreIdComponent);
  let filterOptions = useAppSelector(selectFilterOptions);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let alertsFilteredRequest = useAppSelector(selectCurrentCoreIdAlertsRequest);
  let currentAlertsRequest = useAppSelector(selectCurrentCoreIdAlertsRequest);
  const currentListsRequest = useAppSelector(selectCurrentFilterListsRequest);
  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let coreIdAnalyticsRequest = useAppSelector(selectAnalyticsCoreIdRequest);
  let roles = useAppSelector(selectRoles);
  let currentService = useAppSelector(selectCurrentService);
  let currentServiceTags = useAppSelector(selectCurrentTagsByService);
  const users = useAppSelector(selectAllUsers);

  let [resetTriesModalOpen, setResetTriesModalOpen] = useState(false);
  let [resetTriesLoading, setResetTriesLoading] = useState(false);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Registration") {
      let projectOptions: { value: string; label: string }[] = [];
      let projects = filterOptions?.projects;
      if (projects) {
        for (const project in projects) {
          let label = projects[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }
      let projectDropdown = (
        <DropDown
          key={"Projects"}
          title="Proyecto"
          options={projectOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                projectId: e?.value!,
              })
            );
          }}
        />
      );
      let tagsDropdown: JSX.Element;
      if (roles.includes("tags.view")) {
        let tagsOptions: { value: string; label: string }[] = [];
        let tags = currentServiceTags;
        if (tags) {
          for (const tag of tags) {
            let label = tag.tag;
            let value = tag.id;
            if (tag.associatedEntities.length > 0) {
              tagsOptions.push({ label: label!, value });
            }
          }
        }
        tagsDropdown = (
          <DropDown
            key={"Tags"}
            title="Etiqueta"
            options={tagsOptions}
            onChange={(e) => {
              if (e) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tagId: e?.value!,
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilteredRequest,
                    tagId: null,
                  })
                );
              }
            }}
          />
        );
      } else {
        tagsDropdown = <div></div>;
      }
      let estadoDropdown = (
        <DropDown
          key={"Status"}
          title="Estado"
          options={[
            { label: "Aprobado", value: "ACCEPTED" },
            { label: "Rechazado", value: "REJECTED" },
            { label: "Por revisar", value: "REVISION" },
            { label: "En proceso", value: "IN_PROGRESS" },
          ]}
          onChange={(e) => {
            dispatch(
              changeCurrentFilters({
                ...currentFilteredRequest,
                result: e?.value!,
              })
            );
          }}
        />
      );
      let searchInput = (
        <SearchInputFilter
          value={currentFilteredRequest.searchQuery}
          key={"SearchRegistrations"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  searchQuery: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="No. de documento"
        />
      );
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={true}
            endDate={
              currentFilteredRequest.endDate
                ? new Date(currentFilteredRequest.endDate)
                : null
            }
            startDate={
              currentFilteredRequest.startDate
                ? new Date(currentFilteredRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      endDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                if (date) {
                  dispatch(
                    changeCurrentFilters({
                      ...currentFilteredRequest,
                      startDate: date.toISOString(),
                    })
                  );
                }
              }
            }}
            resetFunction={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilteredRequest,
                  endDate: null,
                  startDate: null,
                })
              );
            }}
            items={[projectDropdown, estadoDropdown, tagsDropdown, searchInput]}
          />
        </>
      );
    } else if (activeComponent === "Alerts") {
      return <FilterComponent key={0} hasCalendar={false} items={[]} />;
    } else if (activeComponent === "Flow") {
      let projectOptions: { value: string; label: string }[] = [];
      let projects = filterOptions?.projects;
      if (projects) {
        for (const project in projects) {
          let label = projects[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }
      let projectDropdown = (
        <DropDown
          key={"Projects"}
          title="Proyecto"
          options={projectOptions}
          onChange={(e) => {
            if (e?.value) {
              dispatch(changeCurrentProject(e?.value));
            }
          }}
        />
      );
      return (
        <FilterComponent
          key={2}
          hasCalendar={false}
          items={[projectDropdown]}
        />
      );
    } else if (activeComponent === "Analytics") {
      let projectOptions: { value: string; label: string }[] = [];
      let projects = filterOptions?.projects;
      if (projects) {
        for (const project in projects) {
          let label = projects[project];
          let value = project;
          projectOptions.push({ label, value });
        }
      }
      let projectDropdown = (
        <DropDown
          key={"Projects"}
          title="Proyecto"
          options={projectOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentCoreIdFilters({
                ...coreIdAnalyticsRequest,
                projectId: e?.value!,
              })
            );
          }}
        />
      );
      let estadoDropdown = (
        <DropDown
          key={"Status"}
          title="Estado"
          options={[
            { label: "Aprobado", value: "ACCEPTED" },
            { label: "Rechazado", value: "REJECTED" },
            { label: "Por revisar", value: "REVISION" },
            { label: "En proceso", value: "IN_PROGRESS" },
          ]}
          onChange={(e) => {
            dispatch(
              changeCurrentCoreIdFilters({
                ...coreIdAnalyticsRequest,
                result: e?.value!,
              })
            );
          }}
        />
      );

      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            calendarType="double"
            endDate={
              coreIdAnalyticsRequest.endDate
                ? new Date(coreIdAnalyticsRequest.endDate)
                : null
            }
            startDate={
              coreIdAnalyticsRequest.startDate
                ? new Date(coreIdAnalyticsRequest.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentCoreIdFilters({
                    ...coreIdAnalyticsRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentCoreIdFilters({
                    ...coreIdAnalyticsRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }}
            items={[projectDropdown, estadoDropdown]}
          />
        </>
      );
    } else if (activeComponent === "Manual") {
      let userOptions: { value: string; label: string }[] = [];
      for (const user of users) {
        let label = user.name;
        let value = user.id;
        userOptions.push({ label, value });
      }
      const userOptionsDropdown = (
        <DropDown
          key={"userOptionsDropdown"}
          title="Por usuario consultor"
          options={userOptions}
          maxContent={true}
          onChange={(e) => {
            dispatch(
              changeCurrentListsFilters({
                ...currentListsRequest,
                userId: e?.value!,
              })
            );
          }}
        />
      );
      const tieneCoincidencias = (
        <div style={{ width: "100%" }} key={"coincidenciasDropdown"}>
          <DropDown
            title="Tiene Coincidencias"
            options={[
              { label: "Si", value: "true" },
              { label: "No", value: "false" },
            ]}
            maxContent={true}
            onChange={(e) => {
              if (e?.value === "true") {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    matched: true,
                  })
                );
              } else if (e?.value === "false") {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    matched: false,
                  })
                );
              } else {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    matched: null,
                  })
                );
              }
            }}
          />
        </div>
      );
      const searchInputName = (
        <div
          key={"SearchListsName"}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <SearchInputFilter
            value={currentListsRequest.nameQuery}
            onChange={(e) => {
              if (!e.target.value || e.target.value === "") {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    nameQuery: null,
                  })
                );
              } else {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    nameQuery: e.target.value,
                  })
                );
              }
            }}
            style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
            placeHolder="Por Nombre"
          />
        </div>
      );
      const searchInputDocument = (
        <div
          key={"SearchListsDocument"}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <SearchInputFilter
            value={currentListsRequest.documentQuery}
            onChange={(e) => {
              if (!e.target.value || e.target.value === "") {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    documentQuery: null,
                  })
                );
              } else {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    documentQuery: e.target.value,
                  })
                );
              }
            }}
            style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
            placeHolder="Por Documento"
          />
        </div>
      );

      return (
        <>
          <FilterComponent
            key={5}
            hasCalendar={false}
            calendarType="double"
            startDate={
              currentListsRequest.startedDate
                ? new Date(currentListsRequest.startedDate)
                : null
            }
            endDate={
              currentListsRequest.finishedDate
                ? new Date(currentListsRequest.finishedDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    finishedDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    finishedDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    startedDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentListsFilters({
                    ...currentListsRequest,
                    startedDate: null,
                  })
                );
              }
            }}
            items={[
              userOptionsDropdown,
              tieneCoincidencias,
              searchInputName,
              searchInputDocument,
            ]}
          />
        </>
      );
    } else if (activeComponent === "none") {
      return;
    } else if (activeComponent === "RegistrationDetail") {
      if (roles.includes("coreid.admin") || roles.includes("coreid.edit")) {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ChooseState
              status={currentRegistration?.status!}
              onConfirm={(e: "ACCEPTED" | "REJECTED" | "REVISION" | "") => {
                if (e !== "") {
                  dispatch(
                    changeStatus({
                      registrationId: currentRegistration?.id!,
                      newStatus: e,
                    })
                  );
                }
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Modal
                isOpen={resetTriesModalOpen}
                style={customStylesModal}
                contentLabel="Intentos restaurados"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                  setResetTriesModalOpen(false);
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    height: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div
                    className="text-medium text-normal"
                    style={{
                      color: "black",
                      marginBottom: "10px",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "5%",
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#000",
                      }}
                    >
                      Los intentos se han restaurado
                    </h5>
                  </div>
                  <button
                    className="text-medium text-bold primary-color"
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px",
                      background: "#1A617D",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingRight: "10%",
                      paddingLeft: "10%",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      setResetTriesModalOpen(false);
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              </Modal>
              {resetTriesLoading && <ClipLoader color="#FFF" size={"100px"} />}
              {!resetTriesLoading && (
                <button
                  onClick={() => {
                    setResetTriesLoading(true);
                    dispatch(resetTries(currentRegistration?.id!)).then(() => {
                      setResetTriesLoading(false);
                      setResetTriesModalOpen(true);
                    });
                  }}
                  style={{
                    color: "#fff",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    border: "none",
                    borderRadius: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px 10px",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  Restaurar intentos
                </button>
              )}
            </div>
          </div>
        );
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const getCategoryItems = () => {
    let categories: JSX.Element[] = [];
    categories.push(
      <Category
        key={0}
        name="Registros"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Core ID"));
          navigate("/services/coreId/registrations");
        }}
        selected={activeComponent === "Registration"}
      />
    );
    if (
      roles.includes("analytics.admin") ||
      roles.includes("analytics.coreid.view")
    ) {
      categories.push(
        <Category
          key={1}
          name="Analitica"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Core ID"));
            dispatch(changeActiveComponent("Analytics"));
            dispatch(resetStateAnalytics());
            navigate("/services/coreId/analytics");
          }}
          selected={activeComponent === "Analytics"}
        />
      );
    }
    if (roles.includes("lists.view") || roles.includes("lists.consult")) {
      categories.push(
        <Category
          key={2}
          name="Consulta Manual en listas"
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeCurrentProduct("Core ID"));
            dispatch(changeActiveComponent("Manual"));
            dispatch(resetStateAnalytics());
            navigate("/services/coreId/manualLists");
          }}
          selected={activeComponent === "Manual"}
        />
      );
    }

    return categories;
  };
  useEffect(() => {
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("Registration"));
    }
    dispatch(getAllUsers());
    if (!filterOptions) {
      dispatch(getFilterOptions());
    }

    if (currentService !== "COREID") {
      dispatch(getCurrentTagsByService("COREID"));
    }
  }, [activeComponent, filterOptions]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
