import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct } from "../../../common/commonSlice";
import {
  changeCurrentActiveComponent,
  changePageSize,
  changePageState,
  getFilteredFlow,
  resetFlow,
  selectCurrentFilterRequest,
  selectCurrentPageSmartFlow,
  selectFlowFiltered,
  selectLoadingFlowStatus,
  selectNumPagesSmartFlow,
  selectPageSizeSmartFlow,
  setIsFlowDetail,
} from "../smartFlowSlice";
import { formatDateStandar } from "../../../../helpers/utils";
import { Styles } from "react-modal";
import Modal from "react-modal";
import {
  getFilteredLeads,
  selectCurrentLeads,
  selectLeadsLoading,
  setLeads,
  toggleCheckedLead,
} from "../../coreid/coreIdSlice";
import { usePagination } from "pagination-react-js";
import PaginationItem from "../../../../helpers/PaginationItem";

const customStylesModal3: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",

    width: "80%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const Flows = () => {
  let currentPage = useAppSelector(selectCurrentPageSmartFlow);
  let numPages = useAppSelector(selectNumPagesSmartFlow);
  const pageSize = useAppSelector(selectPageSizeSmartFlow);
  let loading = useAppSelector(selectLoadingFlowStatus);
  let flows = useAppSelector(selectFlowFiltered);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  //Leads

  const leads = useAppSelector(selectCurrentLeads);
  const loadingLeads = useAppSelector(selectLeadsLoading);
  const [leadPage, setLeadPage] = useState(0);
  const [modalLead, setModalLead] = useState(false);

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: pageSize || 10,
        totalRecordsLength: numPages * 10,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changePageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  const getFlowStep = (
    step: "CORE_ID" | "MAGIC_FORMS" | "ELECTRONIC_SIGNATURE" | "DONE" | string
  ) => {
    switch (step) {
      case "CORE_ID":
        return "Core ID";
      case "MAGIC_FORMS":
        return "Magic Forms";
      case "ELECTRONIC_SIGNATURE":
        return "Firma Electrónica";
      case "DONE":
        return "Finalizado";
      default:
        return "No Empezado";
    }
  };

  const getEstado = (
    status: "IN_PROGRESS" | "PENDING" | "APPROVED" | "REJECTED" | string
  ) => {
    switch (status) {
      case "IN_PROGRESS":
        return "En Proceso";
      case "PENDING":
        return "Pendiente";
      case "APPROVED":
        return "Aprobado";
      case "REJECTED":
        return "Rechazado";
      default:
        return "No Empezado";
    }
  };

  useEffect(() => {
    if (leads?.currentPage !== leadPage) {
      dispatch(
        getFilteredLeads({
          page: leadPage,
          hyperflow: false,
        })
      );
    }
  }, [leadPage]);

  useEffect(() => {
    dispatch(changeCurrentProduct("Smart Flow"));
    dispatch(changeCurrentActiveComponent("flows"));
    dispatch(
      getFilteredFlow({
        ...currentFilteredRequest,
      })
    );
    dispatch(resetFlow());
    dispatch(setIsFlowDetail(false));
    return () => {};
  }, [currentFilteredRequest]);
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalLead}
        onRequestClose={() => {
          setModalLead(false);
          dispatch(setLeads(null));
          setLeadPage(0);
        }}
        style={customStylesModal3}
        contentLabel="Leads"
        shouldCloseOnOverlayClick={true}
      >
        {loadingLeads === "pending" ? (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <ClipLoader color="#49829C" size={200} />
            <p style={{ color: "black" }}>Cargando leads...</p>
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {" "}
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "end",
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  setModalLead(false);
                  dispatch(setLeads(null));
                  setLeadPage(0);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "24px",
                  background: "#1A617D",
                  width: "40px",
                  height: "35px",
                  color: "white",
                }}
              >
                X
              </button>
            </div>
            <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
              Lista de posibles leads
            </h4>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000",
                width: "80%",
              }}
            >
              Esta lista muestra los usuarios que intentaron registrarse pero
              que no completaron el flujo, aquí puede hacerle seguimiento y
              obtener información de contacto.
            </p>{" "}
            {leads && leads.registrations.length > 0 ? (
              <table
                style={{
                  width: "98%",
                  borderCollapse: "collapse",
                  marginLeft: "1%",
                  background: "#49829C",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre y Apellido</th>
                    <th>Correo Electrónico</th>
                    <th>No. Celular</th>
                    <th>Identificación</th>
                    <th>Intentos</th>
                    <th>Fecha último intento</th>
                    <th>¿Revisado?</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {leads.registrations.map((lead, i) => {
                    return (
                      <tr
                        key={i}
                        className={`list-hover`}
                        style={{
                          height: "30px",
                          color: "white",
                        }}
                      >
                        <td></td>
                        <td>{lead.fullName}</td>
                        <td>{lead.email}</td>
                        <td>{lead.phoneNumber}</td>
                        <td>{lead.userIdentification}</td>
                        <td>{lead.tries}</td>
                        <td>{formatDateStandar(lead.dateTime)}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={lead.checked}
                            onChange={(e) => {
                              dispatch(
                                toggleCheckedLead({
                                  userIdentification: lead.userIdentification!,
                                })
                              );
                              dispatch(
                                setLeads({
                                  ...leads,
                                  registrations: leads.registrations.map(
                                    (item) => {
                                      if (item.email === lead.email) {
                                        return {
                                          ...item,
                                          checked: e.target.checked,
                                        };
                                      } else {
                                        return item;
                                      }
                                    }
                                  ),
                                })
                              );
                            }}
                          />
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div
                className="flex-col"
                style={{
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  color: "black",
                }}
              >
                No hay leads en esta página <br />
                Puedes revisar las páginas anteriores o siguientes para
                encontrar más leads
              </div>
            )}
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "center",
                height: "100px",
              }}
            >
              <div
                className="flex-row text-bold primary-color"
                style={{
                  fontSize: "13px",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (leadPage > 0) {
                      setLeadPage((x) => x - 1);
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                    boxSizing: "border-box",
                  }}
                >
                  {"<"}
                </button>
                Página {leadPage + 1} de {leads?.numPages || 0}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (leadPage < leads?.numPages! - 1) {
                      setLeadPage((x) => x + 1);
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      {flows && flows.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "13%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Flujos Realizados
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                marginRight: "2%",
                marginLeft: "1%",
                marginBottom: "1%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                width: "150px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setModalLead(true);
                dispatch(
                  getFilteredLeads({
                    page: leadPage,
                    hyperflow: false,
                  })
                );
              }}
            >
              Destacar No Completados
            </button>
          </div>
          {flows ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>Fecha y Hora</th>
                      <th>E-mail</th>
                      <th>Estado</th>
                      <th>Paso</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td> <td></td>
                    </tr>
                    {flows.map((flow, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/services/smartFlow/flows/${flow.id}`);
                          }}
                        >
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {flow.registration ? (
                              <div>{`${flow.registration.name || ""} ${
                                flow.registration.lastName || ""
                              }`}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{formatDateStandar(flow.startDateTime)}</td>
                          <td>
                            {flow.registration ? (
                              <div>{flow.registration.email || ""}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getEstado(flow.status)}
                          </td>
                          <td>{getFlowStep(flow.step)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                No hay más registros en esta página
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <Pagination />
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "pending" || loading === "idle"
              ? ""
              : "No hay más flujos en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
