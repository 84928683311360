export interface DrawableFormTemplate {
  name: string;
  clientId: string;
  canCancel: boolean;
  cancelRedirectionUrl: string;
  formTemplateId: string;
  formPages: FormPage[];
  conditionals: { [key: string]: FormConditional };
  liveCalculationConfigs: LiveCalculationConfigs[];
  finalButtonText: string;
  extraValidators: { [key: string]: FieldValidator[] };
  customFinalButtons?: CustomFinalButtons;
  defaultStyle: { [key: string]: string };
}

export interface FormPage {
  hasLogo: boolean;
  logoStyle: { [key: string]: string };
  logoAlign: "LEFT" | "CENTER" | "RIGHT";
  title: string;
  continueButtonText: string;
  backButtonText: string;
  hasSlideshow: boolean;
  cancelOptionList: string[];
  fields: { [key: string]: FormField };
  name?: string;
}


export const FormFieldTypeMapper: { [key: string]: string } = {
  money: "Moneda COP",
  moneyCr: "Moneda CR",
  moneyNi: "Moneda NI",
  multipleChoice: "Selección múltiple",
  singleChoice: "Selección única",
  dropdown: "Dropdown",
  dropdown_dynamic: "Dropdown dinámico",
  dropdown_from_state: "Dropdown desde estado",
  file: "Archivo",
  number: "Número",
  date: "Fecha",
  label: "Etiqueta",
  labelSummary: "Resumen de etiqueta",
  dynamic_label: "Etiqueta dinámica",
  read_more: "Leer más",
  freeFormText: "Label Texto libre",
  multiText: "Labels Texto múltiple",
  image: "Imagen",
  sliderButton: "Botón deslizante",
  sliderButtonCurrencyCO: "Botón deslizante moneda CO",
  sliderButtonCurrencyCR: "Botón deslizante moneda CR",
  sliderButtonCurrencyNI: "Botón deslizante moneda NI",
  daneAddress: "Dirección DANE",
  text: "Texto",
};

export interface FormField {
  id: string;
  stitchTypeId: string;
  type:
    | "money"
    | "moneyCr"
    | "moneyNi"
    | "multipleChoice"
    | "singleChoice"
    | "dropdown"
    | "dropdown_dynamic"
    | "dropdown_from_state"
    | "file"
    | "number"
    | "date"
    | "label"
    | "labelSummary"
    | "dynamic_label"
    | "read_more"
    | "freeFormText"
    | "multiText"
    | "image"
    | "sliderButton"
    | "sliderButtonCurrencyCO"
    | "sliderButtonCurrencyCR"
    | "sliderButtonCurrencyNI"
    | "daneAddress"
    | "text";
  title: string;
  description?: string;
  userEditable: boolean;
  style: { [key: string]: string };
  align?: "LEFT" | "CENTER" | "RIGHT";
  choices: string[];
  supportedExtensions: string[];
  supportsOther: boolean;
  otherType: string;
  conditionalSourceId: string;
  conditionalDestinationId: string[];
  order: string;
  subFields: { [key: string]: FormField };
  defaultValue: string;
  optionsListId: string;
  optional: boolean;
  validator?: FieldValidator;
  mappedListSubId1: string;
  mappedListSubId2: string;
  mappedListSubTitle1: string;
  mappedListSubTitle2: string;
  checkIfFieldInDictionary: boolean;
  hasDynamicChoices: boolean;
  validatorHint?: string;
}

export interface FormConditional {
  id: string;
  status: boolean;
  valueMapper: { [key: string]: boolean };
  type:
    | "MAPPED"
    | "OVER"
    | "UNDER"
    | "EXISTS"
    | "NOT_EXISTS"
    | "BETWEEN"
    | "NOT_NULL"
    | "NULL";
  threshold: number;
  threshold2: number;
  existanceCondConfigId: string;
}

export interface LiveCalculationConfigs {
  sourceIds: string[];
  destId: string;
  type:
    | "CONCAT"
    | "ADD"
    | "SUBSTRACT"
    | "MAP"
    | "MULTIPLY"
    | "LAST_UPDATED"
    | "CREDIT_CONSTANT_CUOTA"
    | "VALUE_IF_CONDITIONAL";
  dataMap: { [key: string]: string };
  conditionalId: string;
  constantValue: string;
}

export interface FieldValidator {
  stitchTypeId: string;
  threshold: number;
  threshold2: number;
  regex: string;
  message: string;
  type:
    | "THRESHOLD_ABOVE"
    | "THRESHOLD_BELOW,"
    | "REGEX"
    | "EMAIL"
    | "NUMBER"
    | "TEXT"
    | "BETWEEN"
    | "MULTI_SIZE"
    | "MULTI_SUM"
    | "VALUE_NOT_EQUALS"
    | "VALUE_EQUALS"
    | "TEXT_NUMBERS"
    | "DATE_BEFORE"
    | "DATE_AFTER"
    | "DATE_BETWEEN"
    | "DATE_BEFORE_PRESENT"
    | "DATE_AFTER_PRESENT"
    | "DATE_IN_RANGE_BEFORE"
    | "MULTI_CHOICE_COUNT_ABOVE";
  inputId: string;
  inputId2: string;
  getInputFromState: boolean;
  loadThresholdFromStateId: boolean;
  loadThreshold2FromStateId: boolean;
  step: number;
}

export interface CustomFinalButtons {
  stitchTypeId: string;
  valueMap: { [key: string]: string };
}
