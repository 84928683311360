import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface RuntPersonaProps {
  loading: LoadingStatus;
  item: RuntPersonaResult | null;
}

export interface RuntPersonaResult {
  lista: string;
  infracciones: RuntInfraccion;
  solicitudesSicov: Array<SolicitudSicov>;
  solicitudesAptitud: Array<SolicitudAptitud>;
  datosBasicos: DatosBasicosRunt;
  presentaRiesto: boolean;
  licencias: Array<LicenciaRunt>;
}

export interface RuntInfraccion {
  serialVersionUID: string;
  tieneMultas: string;
  nroPazYSalvo: string;
}

export interface SolicitudSicov {
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  descripcionTipoValidacion: string;
  tramitesRealizados: string;
}

export interface SolicitudAptitud {
  numeroSolicitud: string;
  serialVersionUID: string;
  estado: string;
  fechaSolicitud: string;
  entidad: string;
  tramitesRealizados: string;
  identificador: string;
}

export interface DatosBasicosRunt {
  apellidos: string;
  tipoDocumento: string;
  noInscrito: boolean;
  numeroDocumento: string;
  estadoConductor: string;
  tieneLicencias: string;
  nroInscripcion: string;
  fechaInscripcion: string;
  nombres: string;
  estadoPersona: string;
}

export interface LicenciaRunt {
  otExpide: string;
  fechaExpedicion: string;
  fechaVencimientoExamen: string;
  fechaVencimiento: string;
  categoria: string;
  estadoLicencia: string;
  numeroLicencia: string;
}

export const RuntPersona = (props: RuntPersonaProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.875rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Runt
          </div>
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {props.item.presentaRiesto
              ? "Presenta riesgo"
              : "No presenta riesgo"}
          </div>
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.5rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Datos Básicos
          </div>
          {props.item.datosBasicos ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Nombres
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Apellidos
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Tipo Identificación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Identificación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado de conductor
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Tiene licencias
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha inscripción
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado de persona
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    height: "40px",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "4px",
                  }}
                >
                  <td></td>
                  <td>{props.item.datosBasicos.nombres}</td>
                  <td>{props.item.datosBasicos.apellidos}</td>
                  <td>{props.item.datosBasicos.tipoDocumento}</td>
                  <td>{props.item.datosBasicos.numeroDocumento}</td>
                  <td>{props.item.datosBasicos.estadoConductor}</td>
                  <td>
                    {props.item.datosBasicos.tieneLicencias ? "Sí" : "No"}
                  </td>
                  <td>{props.item.datosBasicos.fechaInscripcion}</td>
                  <td>{props.item.datosBasicos.estadoPersona}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.25rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Licencias
          </div>
          {props.item.licencias && props.item.licencias.length > 0 ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    No. Licencia
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Categoria
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha de vencimiento
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha de Expedición
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    OT que expide
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.licencias.map((licencia, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        height: "40px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "4px",
                      }}
                    >
                      <td></td>
                      <td>{licencia.numeroLicencia}</td>
                      <td>{licencia.estadoLicencia}</td>
                      <td>{licencia.categoria}</td>
                      <td>{licencia.fechaVencimiento}</td>
                      <td>{licencia.fechaExpedicion}</td>
                      <td>{licencia.otExpide}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          {props.item.solicitudesSicov &&
          props.item.solicitudesSicov.length > 0 ? (
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "1.125rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Solicitudes Sicov
            </div>
          ) : (
            ""
          )}
          {props.item.solicitudesSicov &&
          props.item.solicitudesSicov.length > 0 ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Entidad
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha de solicitud
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Descripción
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Tramites realizados
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.solicitudesSicov.map((sicov, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        height: "40px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "4px",
                      }}
                    >
                      <td></td>
                      <td>{sicov.estado}</td>
                      <td>{sicov.entidad}</td>
                      <td>{sicov.fechaSolicitud}</td>
                      <td>{sicov.descripcionTipoValidacion}</td>
                      <td>{sicov.tramitesRealizados}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div
            style={{
              color: "#FFFFFF",
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Solicitudes Aptitud
          </div>
          {props.item.solicitudesAptitud &&
          props.item.solicitudesAptitud.length > 0 ? (
            <table style={{ width: "100%", position: "relative" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    No. Solicitud
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Estado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Fecha Solicitud
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Entidad
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Trámites realizados
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "8px 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: "600",
                    }}
                  >
                    Identificador
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.solicitudesAptitud.map((aptitud, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        color: "#FFFFFF",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        height: "40px",
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "4px",
                      }}
                    >
                      <td></td>
                      <td>{aptitud.numeroSolicitud}</td>
                      <td>{aptitud.estado}</td>
                      <td>{aptitud.fechaSolicitud}</td>
                      <td>{aptitud.entidad}</td>
                      <td>{aptitud.tramitesRealizados}</td>
                      <td>{aptitud.identificador}</td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "#FFFFFF",
                fontSize: "1.25rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
