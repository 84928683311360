import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface RuntPlacaProps {
  loading: LoadingStatus;
  item: RuntPlacaResult | null;
}

export interface RuntPlacaResult {
  cap_carga: number;
  motor: string;
  estado: string;
  color: string;
  pbv: number;
  fechaRegistro: string;
  placaRna: string;
  modelo: string;
  linea: string;
  noLicenciaTransito: string;
  organismoTransito: string;
  clase: string;
  marca: string;
  cilindraje: string;
  regrabacionSerie: string;
  chasis: string;
  serie: string;
  regrabacionMotor: string;
  vin: string;
  id: number;
  clasificacion: string;
  prenda: string;
  limitaciones: string;
  placa: string;
  data: string;
}

export const RuntPlaca = (props: RuntPlacaProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item && !props.item.data ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 8px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.875rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Runt
          </div>
          <div
            style={{
              color: "white",
              fontSize: "1.125rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Datos
          </div>
          <table style={{ width: "100%", position: "relative" }}>
            <thead>
              <tr>
                <th></th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Placa
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Placa RNA
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Modelo
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Linea
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Marca
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Clase
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Serie
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Chasis
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Prenda
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Cilindraje
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  height: "40px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "4px",
                }}
              >
                <td></td>
                <td>{props.item.placa}</td>
                <td>{props.item.placaRna}</td>
                <td>{props.item.modelo}</td>
                <td>{props.item.linea}</td>
                <td>{props.item.marca}</td>
                <td>{props.item.clase}</td>
                <td>{props.item.serie}</td>
                <td>{props.item.chasis}</td>
                <td>{props.item.prenda}</td>
                <td>{props.item.cilindraje}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", position: "relative" }}>
            <thead>
              <tr>
                <th></th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Color
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Capacida de carga
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Estado
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  PBV
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Fecha de registro
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Regrabación Serie
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Regrabación Motor
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Limitaciones
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Motor
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "8px 0",
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.35)",
                    fontWeight: "600",
                  }}
                >
                  Organismo de transito
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  height: "40px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "4px",
                }}
              >
                <td></td>
                <td>{props.item.color}</td>
                <td>{props.item.cap_carga}</td>
                <td>{props.item.estado}</td>
                <td>{props.item.pbv}</td>
                <td>{props.item.fechaRegistro}</td>
                <td>{props.item.regrabacionSerie}</td>
                <td>{props.item.regrabacionMotor}</td>
                <td>{props.item.limitaciones}</td>
                <td>{props.item.motor}</td>
                <td>{props.item.organismoTransito}</td>
                <td></td>
              </tr>

              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <>
              <div
                style={{
                  color: "white",
                  fontSize: "1.875rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Runt
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {props.item?.data
                  ? props.item?.data
                  : "No se encontraron registros"}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
