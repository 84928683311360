import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  mfEditorGetTemplateById,
  mfEditorGetTemplates,
  mfEditorUpdateData,
  selectActiveTemplate,
  selectMfDrawableTemplate,
  selectMfEdited,
  selectMfError,
  selectMfFormTemplate,
  selectMfLoadingStatus,
  selectMfPdfTemplate,
  selectMfTemplateId,
  selectMfTemplates,
  updateDrawableTemplate,
  updateFormTemplate,
} from "./mfEditorSlice";
import { DropDown } from "../../../common/components/Btns/DropDown";
import "./MfEditorPage.scss";
import FormTemplateEditor from "./screens/FormTemplateEditor";
import DrawableTemplateEditor from "./screens/DrawableTemplateEditor";
import Loader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

export const MfEditorPage = () => {
  let loading = useAppSelector(selectMfLoadingStatus);
  let templates = useAppSelector(selectMfTemplates);
  let templateId = useAppSelector(selectMfTemplateId);
  let drawable = useAppSelector(selectMfDrawableTemplate);
  let formTemplate = useAppSelector(selectMfFormTemplate);
  let pdfTemplate = useAppSelector(selectMfPdfTemplate);
  let edited = useAppSelector(selectMfEdited);
  let error = useAppSelector(selectMfError);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  //Local State
  const [selectedTab, setSelectedTab] = useState<"FORM" | "DRAWABLE" | "PDF">(
    "FORM"
  );
  //Load All templates
  useEffect(() => {
    dispatch(mfEditorGetTemplates());
  }, []);

  useEffect(() => {
    if (templateId) {
      dispatch(mfEditorGetTemplateById(templateId));
    }
  }, [templateId]);

  //Helper Functions
  function changeFlowId(id: string) {
    console.log("Changing flow id");
    console.log(id);
    if (edited) {
      if (
        window.confirm("¿Desea editar otro formulario sin guardar los cambios?")
      ) {
        dispatch(selectActiveTemplate(id));
      }
    } else {
      dispatch(selectActiveTemplate(id));
    }
  }

  function saveChanges() {
    if (window.confirm("¿Desea guardar los cambios?")) {
      dispatch(
        mfEditorUpdateData({
          id: templateId!!,
          formTemplate: formTemplate,
          drawableTemplate: drawable,
          pdfTemplate: pdfTemplate,
        })
      );
    }
  }

  //Component Funcionts
  const getFlowsDropdown = (): JSX.Element => {
    let elements: { value: string; label: string }[] = [];
    templates.forEach((template) => {
      elements.push({ value: template.id, label: template.name });
    });
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          borderRadius: "10px",
          padding: "10px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <div
          className='text-large primary-color text-bold'
          style={{ marginBottom: "18px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {" < "}Volver al menu principal
        </div>
        <div
          className='text-medium primary-color'
          style={{
            fontSize: "16px",
            fontWeight: "600",
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Selecciona el formulario a editar
        </div>
        <div style={{
          maxHeight: "50vh",
          overflow:"scroll"
        }}>
          {templates.map((template) => {
            return (
              <div
                className='text-medium primary-color'
                key={template.id}
                onClick={() => {
                  changeFlowId(template.id);
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "10px",
                  fontSize: "14px",
                  color: template.id === templateId ? "green" : "white",
                  backgroundColor:
                    template.id === templateId ? "rgba(0,0,0,0.2)" : "",
                }}
              >
                {template.name}
              </div>
            );
          })}
        </div>
        {/* <DropDown
          key={"templates"}
          maxContent={true}
          title='Selecciona el formulario a editar'
          options={elements}
          onChange={(value) => {
            if (value) changeFlowId(value.value);
          }}
          valueKey={templateId}
        /> */}
      </div>
    );
  };

  const getTabs = () => {
    return (
      <div className='flex-row items-center'>
        <div className='flex-row'>
          <button
            className={selectedTab === "FORM" ? "boton1Selected" : "boton1"}
            onClick={() => setSelectedTab("FORM")}
          >
            Configuración del formulario
          </button>
          <button
            className={selectedTab === "DRAWABLE" ? "boton1Selected" : "boton1"}
            onClick={() => setSelectedTab("DRAWABLE")}
          >
            Plantilla del formulario
          </button>
          {/* <button className='boton1' onClick={() => setSelectedTab("PDF")}>
            PDF
          </button> */}
        </div>
        <div
          className='text-medium primary-color align-self-center'
          style={{ marginLeft: "100px", fontWeight: "600" }}
        >
          Editando formulario: {formTemplate?.name}
        </div>
      </div>
    );
  };

  const getContent = () => {
    if (selectedTab === "FORM") {
      if (formTemplate)
        return (
          <FormTemplateEditor
            template={formTemplate}
            onChange={(template) => {
              dispatch(updateFormTemplate(template));
            }}
          />
        );
      else return <div></div>;
    } else if (selectedTab === "DRAWABLE") {
      if (drawable)
        return (
          <DrawableTemplateEditor
            template={drawable}
            onChange={(template) => {
              dispatch(updateDrawableTemplate(template));
            }}
          />
        );
      else return <div></div>;
    } else if (selectedTab === "PDF") {
      return (
        <div>
          <div className='text-normal text-medium primary-color'>
            {pdfTemplate?.formName}
          </div>
          <div className='text-normal text-medium primary-color'>
            {pdfTemplate?.title}
          </div>
        </div>
      );
    }
  };
  //Render

  if (loading === "pending") {
    return (
      <div
        className='text-normal text-medium primary-color'
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          padding: "50px",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader color='white' loading={true} size={150} />
        Cargando...
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "60%",
        padding: "30px",
      }}
    >
      <div style={{ width: "18vw", height: "50vh" }}>
        <div>{getFlowsDropdown()}</div>
        {edited && (
          <button
            className='boton1'
            style={{ marginTop: "20px" }}
            onClick={() => saveChanges()}
          >
            Guardar cambios en el servidor
          </button>
        )}
      </div>
      {templateId && (
        <div style={{ width: "80%" }}>
          <div style={{ marginLeft: "20px" }}>{getTabs()}</div>
          <div>{getContent()}</div>
        </div>
      )}
    </div>
  );
};
