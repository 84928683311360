import axiosApiInstance from "../../../helpers/interceptor";

export const getAnalyticsCoreId = async (
  req: CoreIdAnalyticsRequest
): Promise<CoreIdAnalyticsResult> => {
  let data = await axiosApiInstance.post<CoreIdAnalyticsResult>(
    "/analytics/coreId/analytics",
    req
  );
  return data.data;
};

export const getStatusCoreIdByDateAPI = async (req: CoreIdAnalyticsRequest) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/coreId/resultByDate",
    req
  );
  return data.data;
};

export const getAnalyticsHyperflow = async (
  req: HyperflowAnalyticsRequest
): Promise<HyperflowAnalyticsResponse> => {
  let data = await axiosApiInstance.post<HyperflowAnalyticsResponse>(
    "/analytics/hyperFlow/analytics",
    req
  );
  return data.data;
};

export const getStatusHyperflowDateAPI = async (
  req: HyperflowAnalyticsRequest
) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/hyperFlow/resultByDate",
    req
  );
  return data.data;
};

export const getAnalyticsSmartflow = async (
  req: SmartFlowAnalyticsRequest
): Promise<SmartFlowAnalyticsResponse> => {
  let data = await axiosApiInstance.post<SmartFlowAnalyticsResponse>(
    "/analytics/smartFlow/analytics",
    req
  );
  return data.data;
};

export const getStatusSmartflowDateAPI = async (
  req: SmartFlowAnalyticsRequest
) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/smartFlow/resultByDate",
    req
  );
  return data.data;
};

export const getAnalyticsMagicFormsAPI = async (
  req: MagicFormsAnalyticsRequest
): Promise<MagicFormsAnalyticsResponse> => {
  let data = await axiosApiInstance.post<MagicFormsAnalyticsResponse>(
    "/analytics/magicForms/analytics",
    req
  );
  return data.data;
};

export const getStatusMagicFormsDateAPI = async (
  req: MagicFormsAnalyticsRequest
) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/magicForms/resultByDate",
    req
  );
  return data.data;
};

export const getAnalyticsFirmaAPI = async (
  req: FirmaAnalyticsRequest
): Promise<FirmaAnalyticsResponse> => {
  let data = await axiosApiInstance.post<FirmaAnalyticsResponse>(
    "/analytics/firma/analytics",
    req
  );
  return data.data;
};

export const getStatusFirmaByDateAPI = async (req: FirmaAnalyticsRequest) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/firma/resultByDate",
    req
  );
  return data.data;
};

export const getAnalyticsPagareAPI = async (
  req: PagareAnalyticsRequest
): Promise<PagareAnalyticsResponse> => {
  let data = await axiosApiInstance.post<PagareAnalyticsResponse>(
    "/analytics/pagare/analytics",
    req
  );
  return data.data;
};

export const getStatusPagareByDateAPI = async (req: PagareAnalyticsRequest) => {
  let data = await axiosApiInstance.post<StatusResultByDate>(
    "/analytics/pagare/resultByDate",
    req
  );
  return data.data;
};

export const getAsesoresAPI = async () => {
  let data = await axiosApiInstance.get<BackofficeAsesor[]>(
    "/users/getAsesores"
  );
  return data.data;
};

export type AnalyticsType =
  | "mfma"
  | "status"
  | "alerts"
  | "finished"
  | "attachments"
  | "signers"
  | "general"
  | "type"
  | "asesor";

export interface CoreIdAnalyticsRequest {
  projectId: string | null;
  result: string | null | undefined;
  startDate: string | null;
  endDate: string | null;
  analyticsType: AnalyticsType;
}

export interface HyperflowAnalyticsRequest {
  startDate: string | null;
  endDate: string | null;
  status: string | null;
  hyperFlowId: string | null;
  analyticsType: AnalyticsType;
  stepFilter: string | null;
}

export interface SmartFlowAnalyticsRequest {
  startDate: string | null;
  endDate: string | null;
  status: string | null;
  analyticsType: AnalyticsType;
}

export interface MagicFormsAnalyticsRequest {
  templateId: string | null;
  startDate: string | null;
  endDate: string | null;
  analyticsType: AnalyticsType;
}

export interface FirmaAnalyticsRequest {
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  analyticsType: AnalyticsType;
}

export interface PagareAnalyticsRequest {
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  analyticsType: AnalyticsType;
  type: string | null;
}

export interface CoreIdAnalyticsResult {
  mfmaresult: MFMAResult;
  alertsResult: AlertsResult;
  statusResult: StatusResult[];
}

export interface HyperflowAnalyticsResponse {
  statusResult: StatusResult[];
  mfmaresult: MFMAResult;
  alertsResult: AlertsResult;
}

export interface SmartFlowAnalyticsResponse {
  statusResult: StatusResult[];
  mfmaresult: MFMAResult;
  alertsResult: AlertsResult;
}

export interface MagicFormsAnalyticsResponse {
  finished: StatusResult[];
  numAttachments: number;
  attachmentsAverage: number;
}

export interface FirmaAnalyticsResponse {
  statusResult: StatusResult[];
  signers: number;
  averageSigners: number;
  totalSignatures: number;
}

export interface PagareAnalyticsResponse {
  statusResult: StatusResult[];
  diligenciados: number;
  endosados: number;
  firmados: number;
  bloqueadosPorEndoso: number;
  totalPagares: number;
}
export interface StatusResult {
  name: string;
  value: number;
  category: string;
}

export interface AlertsResult {
  has: number;
  hasNot: number;
}

export interface MFMAResult {
  faceMatch: MFMAResultElement;
  validity: MFMAResultElement;
  veracity: MFMAResultElement;
  lists: MFMAResultElement;
  liveness: MFMAResultElement;
  geolocation: MFMAResultElement;
  deviceId: MFMAResultElement;
  initialDataMatch: MFMAResultElement;
  govEntity: MFMAResultElement;
  impresion: MFMAResultElement;
  screen: MFMAResultElement;
}

export interface MFMAResultElement {
  passed: number;
  failed: number;
  active: boolean;
  umbral: number;
}

export interface StatusResultByDate {
  categories: string[];
  stackId: string;
  statusResultByDate: StatusResult[];
}

export interface BackofficeAsesor {
  id: string;
  name: string;
  email: string;
  userId: string;
  identification: string;
  active: string;
  esAsesor: string;
  asesorData: AsesorData;
}
export interface AsesorData {
  cargo: string;
  direccion: string;
  oficina: string;
  codAsesor: string;
  photoUrl: string;
  hyperFlowId: string;
}
