import axios from "axios";
import axiosApiInstance from "../../helpers/interceptor";

let baseUrl = process.env.REACT_APP_BASE_URL || "";
let loginMethod = "auth";
let clientTokenMethod = "auth/loginAsSubClient";

export interface Token {
  token: string;
  loggedIn: boolean;
  errorMessage: string;
}
export interface TokenRecovery {
  mensage: string;
  errorMessage: string;
}

let login = async (username: string, password: string): Promise<Token> => {
  try {
    let loginRequest = { username, password };
    let loginData = await axios.post<Token>(
      `${baseUrl}/${loginMethod}`,
      loginRequest
    );

    if (loginData.status === 200) {
      return { loggedIn: true, token: loginData.data.token, errorMessage: "" };
    } else {
      return {
        loggedIn: false,
        token: "",
        errorMessage: "Hubo un error conectandote",
      };
    }
  } catch (err: any) {
    return {
      loggedIn: false,
      token: "",
      errorMessage: "El usuario o la contraseña NO son correctas",
    };
  }
};

let recoverEmail = async (email: string): Promise<TokenRecovery> => {
  try {
    let loginRequest = { email };
    let loginData = await axios.post<TokenRecovery>(
      `${baseUrl}/users/recoverPassword`,
      loginRequest
    );

    if (loginData.status === 200) {
      return {
        mensage: "",
        errorMessage:
          "Se envió a tu correo electrónico un enlace para la recuperación de la contraseña.",
      };
    } else {
      return {
        mensage: "",
        errorMessage: "Hubo un error conectandote",
      };
    }
  } catch (err: any) {
    return {
      mensage: "",
      errorMessage: "El usuario NO existe",
    };
  }
};

const checkResetPasswordTokenAPI = async (
  token: string
): Promise<{ errorMessage: string; valid: boolean }> => {
  try {
    let req: CheckResetTokenRequest = { token };
    let checkResetPasswordToken = await axios.post<CheckResetTokenResponse>(
      `${baseUrl}/users/checkResetPasswordToken`,
      req
    );

    if (checkResetPasswordToken.data.correct) {
      return { errorMessage: "", valid: true };
    } else {
      return { errorMessage: "El token no es válido", valid: false };
    }
  } catch (err: any) {
    if (err.response === undefined)
      return { errorMessage: "Hubo un error inesperado", valid: false };
    if (err.response.status === 401) {
      return { errorMessage: "El token no es válido", valid: false };
    } else if (err.response.status === 401) {
      return { errorMessage: "El token no es válido", valid: false };
    } else if (err.response.status === 500) {
      return { errorMessage: "Hubo un error conectandote", valid: false };
    } else {
      return { errorMessage: "Hubo un error inesperado", valid: false };
    }
  }
};

const resetPasswordAPI = async (
  token: string,
  newPassword: string
): Promise<{ errorMessage: string; valid: boolean }> => {
  try {
    let req: ResetPasswordRequest = { token, newPassword };
    let resetPassword = await axios.post<boolean>(
      `${baseUrl}/users/resetPasswordWithToken`,
      req
    );

    if (resetPassword.data) {
      return { errorMessage: "", valid: true };
    } else {
      return { errorMessage: "Hubo un error conectandote", valid: false };
    }
  } catch (err: any) {
    if (err.response === undefined)
      return { errorMessage: "Hubo un error inesperado", valid: false };
    if (err.response.status === 401) {
      return { errorMessage: "El token no es válido", valid: false };
    } else if (err.response.status === 401) {
      return { errorMessage: "El token no es válido", valid: false };
    } else if (err.response.status === 500) {
      return { errorMessage: "Hubo un error conectandote", valid: false };
    } else {
      return { errorMessage: "Hubo un error inesperado", valid: false };
    }
  }
};

export const getClientTokenAPI = async (clientId: string): Promise<Token> => {
  try {
    let loginData = await axiosApiInstance.get<Token>(
      `${baseUrl}/${clientTokenMethod}/${clientId}`
    );

    return { loggedIn: true, token: loginData.data.token, errorMessage: "" };
  } catch (err: any) {
    return {
      loggedIn: false,
      token: "",
      errorMessage: "El usuario o la contraseña NO son correctas",
    };
  }
};

export interface CheckResetTokenRequest {
  token: string;
}

export interface CheckResetTokenResponse {
  correct: boolean;
}

export interface ResetPasswordRequest {
  token: string;
  newPassword: string;
}

export { login, recoverEmail, checkResetPasswordTokenAPI, resetPasswordAPI };
