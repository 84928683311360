import { useEffect, useState } from "react";
import {
  FormField,
  FormFieldTypeMapper,
} from "../../dtos/DrawableFormTemplate";
import { DropDown } from "../../../../common/components/Btns/DropDown";
import Modal, { Styles } from "react-modal";
import MapEditorComponent from "./MapEditorComponent";
import ListEditorComponent from "./ListEditorComponent";
import ValidatorEditorComponent from "./ValidatorEditorComponent";
const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#154e66",
    maxHeight: "700px",
    // width: "1200px",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");
const getFieldTypeName = (type: string): string => {
  return FormFieldTypeMapper[type] ?? type;
};

//Const FieldsDetailComponent

type FieldsDetailComponentProps = {
  field: FormField;
  onChange: (field: FormField, parentFieldId: string) => void;
  id: string;
  parentFieldId: string;
  subFieldId: string;
};

const FieldsDetailComponent: React.FC<FieldsDetailComponentProps> = ({
  field,
  onChange,
  id,
  parentFieldId,
}) => {
  const [visibility, setVisibility] = useState<VisibleFields>(defaulVisibility);
  const [modalStylesOpen, setModalStylesOpen] = useState(false);
  const [modalChoicesOpen, setModalChoicesOpen] = useState(false);
  const [modalExtensionsOpen, setModalExtensionsOpen] = useState(false);
  const [modalValidadorOpen, setModalValidadorOpen] = useState(false);
  useEffect(() => {
    if (field.type === "dropdown") {
      setVisibility(dropdown);
    } else if (field.type === "dropdown_dynamic") {
      setVisibility(dropdownDynamic);
    } else if (field.type === "file") {
      setVisibility(file);
    } else {
      setVisibility(defaulVisibility);
    }
  }, [field.type]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.2)",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      {/* Modal Validador */}
      {field.validator && (
        <Modal
          isOpen={modalValidadorOpen}
          style={customStylesModal}
          contentLabel='Modificar estilos'
          onRequestClose={() => {
            setModalValidadorOpen(false);
          }}
        >
          <ValidatorEditorComponent
            validator={field.validator}
            onChange={(validator) => {
              onChange({ ...field, validator }, parentFieldId);
              setModalValidadorOpen(false);
            }}
          />
        </Modal>
      )}

      {/* Modal Styles */}
      <Modal
        isOpen={modalStylesOpen}
        style={customStylesModal}
        contentLabel='Modificar estilos'
        onRequestClose={() => {
          setModalStylesOpen(false);
        }}
      >
        <MapEditorComponent
          map={field.style}
          onChange={(map) => {
            onChange({ ...field, style: map }, parentFieldId);
            setModalStylesOpen(false);
          }}
        />
      </Modal>
      {/* Modal Choices */}
      <Modal
        isOpen={modalChoicesOpen}
        style={customStylesModal}
        contentLabel='Modificar opciones'
        onRequestClose={() => {
          setModalChoicesOpen(false);
        }}
      >
        <ListEditorComponent
          list={field.choices}
          onChange={(choices) => {
            onChange({ ...field, choices }, parentFieldId);
            setModalChoicesOpen(false);
          }}
        />
      </Modal>
      {/* Modal Extensions */}
      <Modal
        isOpen={modalExtensionsOpen}
        style={customStylesModal}
        contentLabel='Modificar estilos'
        onRequestClose={() => {
          setModalExtensionsOpen(false);
        }}
      >
        <ListEditorComponent
          list={field.supportedExtensions}
          onChange={(ext) => {
            onChange({ ...field, supportedExtensions: ext }, parentFieldId);
            setModalExtensionsOpen(false);
          }}
        />
      </Modal>
      <h1 className='text-normal text-medium primary-color'>
        Editando campo: {id}
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* title */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Título</h1>
            <input
              type='text'
              value={field.title}
              onChange={(e) => {
                onChange({ ...field, title: e.target.value }, parentFieldId);
              }}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
          {/* description */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>
              {field.type === "image" ? "URL" : "Descripción"}
            </h1>
            <input
              type='text'
              value={field.description}
              onChange={(e) => {
                onChange(
                  { ...field, description: e.target.value },
                  parentFieldId
                );
              }}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
          {/* type */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Tipo</h1>
            <input
              type='text'
              value={getFieldTypeName(field.type)}
              disabled={true}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
          {/* order */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Orden</h1>
            <input
              type='text'
              value={field.order}
              onChange={(e) => {
                onChange({ ...field, order: e.target.value }, parentFieldId);
              }}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
          {/* obligatorio */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Opcional</h1>
            <input
              style={{ width: "20px" }}
              type='checkbox'
              checked={field.optional}
              onChange={(e) => {
                onChange(
                  { ...field, optional: e.target.checked },
                  parentFieldId
                );
              }}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
          {/* Editar estilo */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Estilo</h1>
            <button
              onClick={() => {
                setModalStylesOpen(true);
              }}
              className='boton1'
            >
              Editar
            </button>
          </div>
          {/* Align */}
          <div className='editor-containerFieldDetail'>
            <DropDown
              title='Alineación'
              options={[
                { value: "LEFT", label: "Izquierda" },
                { value: "CENTER", label: "Centro" },
                { value: "RIGHT", label: "Derecha" },
              ]}
              onChange={(value) => {
                onChange(
                  { ...field, align: getAlign(value?.value ?? "") },
                  parentFieldId
                );
              }}
              defaultValue={{
                value: field.align ?? "LEFT",
                label: getInverseAlign(field.align ?? "LEFT"),
              }}
            />
          </div>
          {/* choices */}
          {visibility.choices && (
            <div className='editor-containerFieldDetail'>
              <h1 className='text-normal text-medium primary-color'>
                Opciones
              </h1>
              <button
                onClick={() => {
                  setModalChoicesOpen(true);
                }}
                className='boton1'
              >
                Editar
              </button>
            </div>
          )}

          {/* supported extensions */}
          {visibility.supportedExtensions && (
            <div className='editor-containerFieldDetail'>
              <h1 className='text-normal text-medium primary-color'>
                Extensiones admitidas
              </h1>
              <button
                onClick={() => {
                  setModalExtensionsOpen(true);
                }}
                className='boton1'
              >
                Editar
              </button>
            </div>
          )}

          {/* Agregar "Otro" */}
          {visibility.supportsOther && (
            <div className='editor-containerFieldDetail'>
              <h1 className='text-normal text-medium primary-color'>
                Puede seleccionar "Otro"
              </h1>
              <input
                style={{ width: "20px" }}
                type='checkbox'
                checked={field.supportsOther}
                onChange={(e) => {
                  onChange(
                    { ...field, supportsOther: e.target.checked },
                    parentFieldId
                  );
                }}
                className='text-normal text-small primary-color-80 input1'
              />
            </div>
          )}

          {/* Validador */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Validador</h1>
            <button
              onClick={() => {
                setModalValidadorOpen(true);
                if (!field.validator) {
                  onChange(
                    {
                      ...field,
                      validator: {
                        message: "",
                        type: "TEXT",
                        stitchTypeId: field.id,
                        threshold: 0.0,
                        threshold2: 0.0,
                        getInputFromState: false,
                        inputId: "",
                        inputId2: "",
                        loadThreshold2FromStateId: false,
                        loadThresholdFromStateId: false,
                        regex: "",
                        step: 0.0,
                      },
                    },
                    parentFieldId
                  );
                }
              }}
              className='boton1'
            >
              Editar
            </button>
          </div>

          {/* Validator Hint */}
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>
              Texto de ayuda
            </h1>
            <input
              type='text'
              value={field.validatorHint}
              onChange={(e) => {
                onChange(
                  { ...field, validatorHint: e.target.value },
                  parentFieldId
                );
              }}
              className='text-normal text-small primary-color-80 input1'
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "start",
            alignItems: "start",
            height: "100%",
          }}
        >
          <div className='editor-containerFieldDetail'>
            <h1 className='text-normal text-medium primary-color'>Opciones</h1>
            <button
              onClick={() => {
                setModalChoicesOpen(true);
              }}
              className='boton1'
            >
              Editar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getAlign = (value: string): "LEFT" | "CENTER" | "RIGHT" => {
  switch (value) {
    case "LEFT":
      return "LEFT";
    case "CENTER":
      return "CENTER";
    case "RIGHT":
      return "RIGHT";
    default:
      return "LEFT";
  }
};

const getInverseAlign = (value: "LEFT" | "CENTER" | "RIGHT"): string => {
  switch (value) {
    case "LEFT":
      return "Izquierda";
    case "CENTER":
      return "Centro";
    case "RIGHT":
      return "Derecha";
    default:
      return "Izquierda";
  }
};

export default FieldsDetailComponent;

interface VisibleFields {
  choices: boolean;
  supportedExtensions: boolean;
  supportsOther: boolean;
  subFields: boolean;
  optionsListId: boolean;
  hasDynamicChoices: boolean;
}

const defaulVisibility: VisibleFields = {
  choices: false,
  supportedExtensions: false,
  supportsOther: false,
  subFields: false,
  optionsListId: false,
  hasDynamicChoices: false,
};

const dropdown: VisibleFields = {
  ...defaulVisibility,
  choices: true,
  supportsOther: true,
  hasDynamicChoices: true,
};

const dropdownDynamic: VisibleFields = {
  ...defaulVisibility,
  optionsListId: true,
};

const file: VisibleFields = {
  ...defaulVisibility,
  supportedExtensions: true,
};
