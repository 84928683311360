import ReactECharts from "echarts-for-react";
import "./../DashboardStyles.scss";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { getGaugeOption, getPieOption } from "../utils/EchartsUtils";
import { Statistics } from "./components/Statistics";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getPieData,
  getGaugeData,
  getStatisticsData,
  selectActiveDashboardComponent,
  selectCurrentFilteredDashboard,
  selectDataStatistics,
  selectLoadingStatisticsData,
  selectLoadingGaugeData,
  selectLoadingPieData,
  selectDataPie,
  selectDataGauge,
  selectDataFunnel,
  changeActiveComponent,
} from "../DashboardSlice";
import { changeCurrentProduct } from "../../../common/commonSlice";
import "./../DashboardStyles.scss";

export const Indicators = () => {
  const dispatch = useAppDispatch();
  const loadingStatistics = useAppSelector(selectLoadingStatisticsData);
  const loadingGauge = useAppSelector(selectLoadingGaugeData);
  const loadingPie = useAppSelector(selectLoadingPieData);
  const activeComponent = useAppSelector(selectActiveDashboardComponent);
  const currentFilteredRequest = useAppSelector(selectCurrentFilteredDashboard);
  const dataStatistics = useAppSelector(selectDataStatistics);
  const dataPie = useAppSelector(selectDataPie);
  const gaugeData = useAppSelector(selectDataGauge);
  const [isActive, setIsActive] = useState(false);
  const { data } = dataPie || {};

  useEffect(() => {
    dispatch(changeCurrentProduct("Dashboard"));

    if (activeComponent != "Indicators") {
      dispatch(changeActiveComponent("Indicators"));
    }
  }, []);

  useEffect(() => {
    if (currentFilteredRequest) {
      dispatch(
        getStatisticsData({
          filter: currentFilteredRequest,
        })
      );

      dispatch(
        getPieData({
          filter: currentFilteredRequest,
        })
      );

      dispatch(
        getGaugeData({
          filter: currentFilteredRequest,
        })
      );
    }
  },[currentFilteredRequest]);

  return (
    <div className="container-indicators">
      <div className="container-header">
        <LoadingOverlay
          active={loadingStatistics === "pending"}
          spinner={<ClipLoader color="white" size="60px" />}
        >
          {dataStatistics ? (
            <div className="header-registrations">
              {dataStatistics?.statisticsData.map((item, index) => {
                return (
                  <Statistics
                    key={index}
                    title={item.title}
                    score={item.score}
                    difference={item.difference}
                  />
                );
              })}
            </div>
          ) : (
            <div
              className="text-xx-medium text-bold primary-color"
              style={{ textAlign: "center" }}
            >
              <span></span>
            </div>
          )}
        </LoadingOverlay>

        <div className="header-pie">
          <div className="btns-options">
            <div className="btn-container">
              <button
                type="button"
                className={`btn-indicators ${!isActive ? "active" : ""}`}
                onClick={() => {
                  setIsActive(false);
                }}
              >
                #
              </button>
            </div>
            <div className="btn-container">
              <button
                type="button"
                className={`btn-indicators ${
                  isActive ? "active" : ""
                } btn-indicators-second`}
                onClick={() => {
                  setIsActive(true);
                }}
              >
                %
              </button>
            </div>
          </div>

          <div className="echartsPie">
            <LoadingOverlay
              active={loadingPie === "pending"}
              spinner={<ClipLoader color="white" size="50px" />}
            >
              {data && data.length > 0 ? (
                <ReactECharts
                  option={getPieOption(isActive, data)}
                  style={{ height: "230px", width: "100%" }}
                />
              ) : (
                <div
                  className="text-xx-medium text-bold primary-color"
                  style={{ textAlign: "center", padding: "83px" }}
                >
                  <span>No hay datos disponibles</span>
                </div>
              )}
            </LoadingOverlay>
          </div>
        </div>
      </div>
      <div className="gauge-title">
        <span>Indicadores factores de verificación</span>
      </div>
      <LoadingOverlay
        active={loadingGauge === "pending"}
        spinner={<ClipLoader color="white" size="50px" />}
        className="overlay"
      >
        {gaugeData ? (
          <div className="container-gauge">
            {gaugeData?.gaugeData.map((item, index) => (
              <div className="gauge" key={index}>
                <div className="gauge-title-indicators">{item.title}</div>
                <ReactECharts
                  option={getGaugeOption(
                    {
                      title: item.title,
                      scoreIndicator: item.score,
                      deferenceIndicator: item.difference,
                      scoreUmbral: item.success_users,
                      deferenceUmbral: item.difference_success_users,
                    }
                  )}
                  style={{ height: "370px", width: "100%", top: "37px" }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            className="text-xx-medium text-bold primary-color"
            style={{ textAlign: "center", padding: "200px" }}
          >
            <span>No hay datos disponibles</span>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};
