import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import {
  changeCurrentFilters,
  changePageState,
  selectCurrentAlertsRequest,
  selectUnifiedAlerts,
  selectAlertsLoading,
  selectCurrentPageAlerts,
  selectNumPagesAlerts,
  getUnifiedAlerts,
  AlertSeverity,
  selectAlertsSeverity,
  getAlertDashBoard,
  changeAlertStatus,
  selectLoadingStatusChange,
} from "./alertsSlice";
import { formatDateStandar } from "../../../helpers/utils";
import { AlertDashboardElement } from "./components/AlertDashboardElement";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { TopPanel } from "../components/TopPanel";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { UnifiedAlerts as Alert } from "./alertsAPI";
import {
  changeCurrentProduct,
  selectClientId,
  selectMetaClientId,
} from "../../common/commonSlice";
import { AlertDot } from "./components/AlertDot";
import { DropDown } from "../../common/components/Btns/DropDown";
import { getNumAlerts } from "../components/GeneralSlice";

export const UnifiedAlerts = () => {
  let loading = useAppSelector(selectAlertsLoading);
  let loadingStatusChange = useAppSelector(selectLoadingStatusChange);

  let unifiedAlerts = useAppSelector(selectUnifiedAlerts);
  let currentPage = useAppSelector(selectCurrentPageAlerts);
  let numPages = useAppSelector(selectNumPagesAlerts);
  let currentAlertsRequest = useAppSelector(selectCurrentAlertsRequest);
  let alertsSeverity = useAppSelector(selectAlertsSeverity);
  let metaClientId = useAppSelector(selectMetaClientId);
  let clientId = useAppSelector(selectClientId);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < numPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  const getCallToAction = (alert: Alert) => {
    if (
      clientId !== "62393f1cac63113d0bebbb9e" &&
      metaClientId !== "62393f1cac63113d0bebbb9e"
    )
      return <></>;
    if (
      alert.category === "COMPLIANCE" ||
      alert.category === "INCONGRUENCE" ||
      alert.category === "DATE" ||
      alert.category === "ATTACHMENT"
    ) {
      return (
        <>
          <p
            style={{
              fontSize: "10px",
              fontWeight: 600,
              color: "#F7CD00",
              width: "60%",
              margin: "0 auto 6px auto",
            }}
          >
            Debido al tipo de alerta, recomendamos que te comuniques con PPU a
            través de correo electrónico.
          </p>
          <a href="mailto:" style={{ textDecoration: "none" }}>
            <button
              style={{
                color: "#fff",
                backgroundColor: "rgba(0,0,0,0.2)",
                border: "none",
                borderRadius: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px 10px",
                fontSize: "12px",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Enviar correo
            </button>
          </a>
        </>
      );
    } else {
      return <></>;
    }
  };

  let alertNavigate = (alert: Alert) => {
    switch (alert.service) {
      case "COREID":
        return `/services/coreId/registrations/${currentAlert.entityId}`;
      case "MAGICFORMS":
        return `/services/magicForms/filledForms/${currentAlert.entityId}`;
      case "FIRMA":
        return `/services/electronicSignatureNew/signatures/${currentAlert.entityId}`;
      case "HYPERFLOW":
        return `/services/hyperFlow/flows/${currentAlert.entityId}`;
      default:
        return "/services/alerts";
    }
  };

  const getColorDot = (levelAlert: AlertSeverity | string) => {
    switch (levelAlert) {
      case "NONE":
        return <div></div>;
      default:
        return <AlertDot levelAlert={levelAlert} />;
    }
  };

  let getCategoryName = (category: string) => {
    switch (category) {
      case "COMPLIANCE":
        return "COMPLIANCE";
      case "INCONGRUENCE":
        return "INCONGRUENCIAS";
      case "DATE":
        return "FECHAS";
      case "ATTACHMENT":
        return "ADJUNTOS";
      case "AUTHENTICITY":
        return "AUTENTICIDAD";
      case "BIOMETRY":
        return "BIOMETRÍA";
      default:
        return "";
    }
  };

  const getCriticidadRender = (criticidad: string) => {
    switch (criticidad) {
      case "HIGH":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Alta
            </h5>
            <AlertDot levelAlert="HIGH" />
          </div>
        );
      case "MEDIUM":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Media
            </h5>
            <AlertDot levelAlert="MEDIUM" />
          </div>
        );
      case "LOW":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );
      case "OTHER":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );

      default:
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Otra
            </h5>
            <AlertDot levelAlert="OTHER" />
          </div>
        );
    }
  };

  const getServiceName = (service: string) => {
    switch (service) {
      case "COREID":
        return "COREID";
      case "MAGICFORMS":
        return "MAGIC FORMS";
      case "FIRMA":
        return "FIRMA";
      case "HYPERFLOW":
        return "HYPERFLOW";
      default:
        return "";
    }
  };

  const getStatusName = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "Activa";
      case "CLOSED":
        return "Cerrada";
      case "ACK":
        return "Revisada";
      default:
        return "Abierto";
    }
  };

  const [currentAlert, setCurrentAlert] = useState<Alert>({
    entityId: "",
    date: "",
    description: "",
    id: "",
    service: "",
    category: "",
    userId: "",
    criticality: "",
    status: "",
    name: "",
    userName: "",
  });

  let getFilters = () => {
    let categoryOptions: { value: string; label: string }[] = [
      { value: "COMPLIANCE", label: "Compliance" },
      { value: "INCONGRUENCE", label: "Incongruencias" },
      { value: "DATE", label: "Fechas" },
      { value: "ATTACHMENT", label: "Adjuntos" },
      { value: "AUTHENTICITY", label: "Autenticidad" },
      { value: "OTHER", label: "Otro" },
      { value: "BIOMETRY", label: "Biometría" },
    ];
    let serviceOptions: { value: string; label: string }[] = [
      { value: "COREID", label: "CoreID" },
      { value: "MAGICFORMS", label: "Magic Forms" },
      { value: "FIRMA", label: "Firma" },
      { value: "HYPERFLOW", label: "HyperFlow" },
    ];
    let criticalityOptions: { value: string; label: string }[] = [
      { value: "HIGH", label: "Alta" },
      { value: "MEDIUM", label: "Media" },
      { value: "LOW", label: "Baja" },
      { value: "OTHER", label: "Otra" },
    ];
    let statusOptions: { value: string; label: string }[] = [
      { value: "ACTIVE", label: "Activa" },
      { value: "CLOSED", label: "Cerrada" },
      { value: "ACK", label: "Revisada" },
    ];

    let dropDownCategory = (
      <DropDown
        key={"category"}
        title="Categoría"
        options={categoryOptions}
        onChange={(e: any) => {
          if (e) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                category: e.value,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                category: null,
              })
            );
          }
        }}
      />
    );

    let dropDownService = (
      <DropDown
        key={"service"}
        options={serviceOptions}
        onChange={(e: any) => {
          if (e) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                service: e.value,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                service: null,
              })
            );
          }
        }}
        title="Servicio"
      />
    );

    let dropDownCriticality = (
      <DropDown
        key={"criticality"}
        options={criticalityOptions}
        onChange={(e: any) => {
          if (e) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                criticality: e.value,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                criticality: null,
              })
            );
          }
        }}
        title="Criticidad"
      />
    );

    let dropDownStatus = (
      <DropDown
        key={"status"}
        options={statusOptions}
        onChange={(e: any) => {
          if (e) {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                status: e.value,
              })
            );
          } else {
            dispatch(
              changeCurrentFilters({
                ...currentAlertsRequest,
                status: null,
              })
            );
          }
        }}
        title="Estado"
      />
    );
    return (
      <FilterComponent
        key={0}
        hasCalendar={true}
        endDate={
          currentAlertsRequest.endDate
            ? new Date(currentAlertsRequest.endDate)
            : null
        }
        startDate={
          currentAlertsRequest.startDate
            ? new Date(currentAlertsRequest.startDate)
            : null
        }
        setEndDate={(date: Date) => {
          if (date) {
            if (date) {
              dispatch(
                changeCurrentFilters({
                  ...currentAlertsRequest,
                  endDate: date.toISOString(),
                })
              );
            }
          }
        }}
        setStartDate={(date: Date) => {
          if (date) {
            if (date) {
              dispatch(
                changeCurrentFilters({
                  ...currentAlertsRequest,
                  startDate: date.toISOString(),
                })
              );
            }
          }
        }}
        resetFunction={() => {
          dispatch(
            changeCurrentFilters({
              ...currentAlertsRequest,
              endDate: null,
              startDate: null,
            })
          );
        }}
        items={[
          dropDownCategory,
          dropDownService,
          dropDownCriticality,
          dropDownStatus,
        ]}
      />
    );
  };

  useEffect(() => {
    if (!loading) {
      dispatch(getUnifiedAlerts({ ...currentAlertsRequest }));
    }
    dispatch(getAlertDashBoard());
    dispatch(changeCurrentProduct("Alertas"));
    if (loadingStatusChange === "resolved") {
      dispatch(getNumAlerts());
    }
  }, [currentPage, currentAlertsRequest, loadingStatusChange]);
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "90%" }}
      >
        <SidePanel filter={getFilters()} />

        <div
          className="flex-col"
          style={{ width: "90%", justifyContent: "end" }}
        >
          <LoadingOverlay
            active={loading || loadingStatusChange === "pending"}
            spinner={<ClipLoader color="white" size="150px" />}
          >
            <div
              className="flex-row"
              style={{
                height: "10%",
                justifyContent: "space-between",
                alignItems: "start",
                margin: "1% 1%",
              }}
            >
              <AlertDashboardElement
                dot={getColorDot(alertsSeverity.compliance)}
                title="Compliance"
                subtitle="Revisa el estado del cumplimiento normativo"
              />
              <AlertDashboardElement
                dot={getColorDot(alertsSeverity.biometry)}
                title="Biometría"
                subtitle="Revisa si hay problemas con la verificación biométrica"
              />
              <AlertDashboardElement
                dot={getColorDot(alertsSeverity.incongruences)}
                title="Incongruencias"
                subtitle="Revisa si hay incongruencias"
              />
              <AlertDashboardElement
                dot={getColorDot(alertsSeverity.authenticity)}
                title="autenticidad"
                subtitle="Revisa si falló una prueba de autenticidad"
              />
              <AlertDashboardElement
                dot={getColorDot(alertsSeverity.dates)}
                title="Fechas"
                subtitle="Revisa si se vence una fecha"
              />
              <AlertDashboardElement
                show={alertsSeverity.other !== "NONE"}
                dot={getColorDot(alertsSeverity.other)}
                title="Otros"
                subtitle="Revisa si hay una alerta personalizada"
              />
            </div>
            {unifiedAlerts && unifiedAlerts.alerts.length > 0 ? (
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "80%",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "start",
                    height: "45%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{ width: "95%", overflow: "auto", height: "100%" }}
                  >
                    <table style={{ borderCollapse: "collapse" }}>
                      <thead
                        className="text-normal text-medium primary-color-35"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "30px",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <th></th>
                          <th>Servicio</th>
                          <th>Fecha y Hora</th>
                          <th>Categoría</th>
                          <th>Nombre</th>
                          <th>Criticidad</th>
                          <th>Estado</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-normal text-x-small primary-color"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {unifiedAlerts?.alerts.map((alert, i) => {
                          return (
                            <tr
                              key={i}
                              className="list-hover"
                              style={{ height: "30px", cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentAlert(alert);
                              }}
                            >
                              <td></td>
                              <td style={{ textTransform: "uppercase" }}>
                                {getServiceName(alert.service)}
                              </td>
                              <td>{formatDateStandar(alert.date)}</td>
                              <td style={{ textTransform: "uppercase" }}>
                                {getCategoryName(alert.category)}
                              </td>
                              <td>{alert.userName}</td>
                              <td>{getCriticidadRender(alert.criticality)}</td>
                              <td>{getStatusName(alert.status)}</td>
                              <td></td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "2%",
                  }}
                >
                  <div
                    className="flex-row text-bold primary-color-40"
                    style={{ fontSize: "13px" }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changePage("before");
                      }}
                      style={{
                        width: "30px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "15px",
                        height: "30px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {"<"}
                    </div>
                    Página {currentPage + 1} de {numPages}
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        changePage("after");
                      }}
                      style={{
                        width: "30px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        fontSize: "15px",
                        height: "30px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {">"}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    marginTop: "2%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      width: "70%",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        alignItems: "start",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="flex-col"
                        style={{
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="text-normal text-small primary-color-40"
                          style={{ margin: "0 0 10px 0" }}
                        >
                          Detalle de la alerta
                        </div>
                        <div
                          className="flex-col"
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "rgba(0,0,0,0.2)",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            padding: "10px",
                            borderRadius: "8px",
                          }}
                        >
                          {currentAlert.id !== "" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                width: "100%",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <AlertDot
                                  levelAlert={currentAlert.criticality}
                                />
                                <h4
                                  style={{
                                    margin: "0 0 0 10px",
                                    color: "#fff",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {currentAlert.name}
                                </h4>
                              </div>
                              <h4
                                style={{
                                  margin: "10px 0 10px 0px",
                                  color: "rgba(255,255,255,0.5)",
                                  fontSize: "11px",
                                  fontWeight: 700,
                                }}
                              >
                                Descripción de la alerta
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  color: "#fff",
                                }}
                              >
                                {currentAlert.description}
                              </p>
                              {getCallToAction(currentAlert)}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  width: "100%",
                                }}
                              >
                                {currentAlert.entityId && (
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (currentAlert.status !== "CLOSED") {
                                        dispatch(
                                          changeAlertStatus({
                                            id: currentAlert.id,
                                            status: "ACK",
                                          })
                                        );
                                      }
                                      navigate(alertNavigate(currentAlert));
                                    }}
                                    style={{
                                      color: "#fff",
                                      backgroundColor: "rgba(0,0,0,0.2)",
                                      border: "none",
                                      borderRadius: "8px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "4px 10px",
                                      fontSize: "12px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Ver registro origen
                                  </button>
                                )}
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                      changeAlertStatus({
                                        id: currentAlert.id,
                                        status:
                                          currentAlert.status === "CLOSED"
                                            ? "ACTIVE"
                                            : "CLOSED",
                                      })
                                    );
                                    setCurrentAlert({
                                      ...currentAlert,
                                      status:
                                        currentAlert.status === "CLOSED"
                                          ? "OPEN"
                                          : "CLOSED",
                                    });
                                  }}
                                  style={{
                                    color: "#fff",
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    border: "none",
                                    borderRadius: "8px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "4px 10px",
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                >
                                  {currentAlert.status === "CLOSED"
                                    ? "Activar alerta"
                                    : "Cerrar alerta"}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="text-normal primary-color-40"
                              style={{
                                fontSize: "13px",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              SELECCIONE UNA ALERTA PARA VER SU DESCRIPCIÓN
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex-col"
                style={{
                  width: "100%",
                  height: "80%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1 className="text-huge text-bold primary-color">
                  {loading ? "" : "No existen alertas actualmente"}
                </h1>
              </div>
            )}
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};
