import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import {
  hfEditorGetMessageById,
  hfEditorGetMessages,
  hfEditorUpdateMessageServer,
  selectActiveMessage,
  selectHfEditorEdited,
  selectHfEditorLoadingStatus,
  selectHfEditorMessage,
  selectHfEditorMessageId,
  selectHfEditorMessageList,
  updateMesage,
} from "./hfEditorSlice";
import Loader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { HfEditorMessage, HfMessageModel } from "./hfEditorApi";
import "./HfEditorPage.scss";
import Modal, { Styles } from "react-modal";
import MapEditorComponent from "../../magicforms/editor/screens/MapEditorComponent";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#154e66",
    maxHeight: "700px",
    // width: "1200px",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const HfEditorPage = () => {
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  //Selectors
  let loading = useAppSelector(selectHfEditorLoadingStatus);
  let messages = useAppSelector(selectHfEditorMessageList);
  let currentMessage = useAppSelector(selectHfEditorMessage);
  let messageId = useAppSelector(selectHfEditorMessageId);
  let edited = useAppSelector(selectHfEditorEdited);
  //Helper Functions
  function changeMessageId(id: string) {
    if (edited) {
      if (
        window.confirm("¿Desea editar otro formulario sin guardar los cambios?")
      ) {
        dispatch(selectActiveMessage(id));
        dispatch(hfEditorGetMessageById(id));
      }
    } else {
      dispatch(selectActiveMessage(id));
      dispatch(hfEditorGetMessageById(id));
    }
  }

  function saveChanges() {
    if (window.confirm("¿Desea guardar los cambios?")) {
      dispatch(hfEditorUpdateMessageServer(currentMessage!!));
    }
  }

  function auto_grow(element: HTMLTextAreaElement) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + 10 + "px";
  }

  //Effects
  useEffect(() => {
    dispatch(hfEditorGetMessages());
  }, []);

  //Local State
  let [modalOpen, setModalOpen] = useState(false);
  let [selectedMessageIndex, setSelectedMessageIndex] = useState(0);

  //Component functions
  const getMessagesDropdown = (): JSX.Element => {
    let elements: { value: string; label: string }[] = [];
    messages.forEach((message) => {
      elements.push({ value: message.id, label: message.id });
    });
    return (
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.4)",
          borderRadius: "10px",
          padding: "10px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <div
          className="text-large primary-color text-bold"
          style={{ marginBottom: "18px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {" < "}Volver al menu principal
        </div>
        <div
          className="text-medium primary-color"
          style={{
            fontSize: "16px",
            fontWeight: "600",
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Selecciona el mensaje a editar
        </div>
        <div
          style={{
            maxHeight: "50vh",
            overflow: "scroll",
          }}
        >
          {messages.map((message) => {
            return (
              <div
                className="text-medium primary-color"
                key={message.id}
                onClick={() => {
                  changeMessageId(message.id);
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "10px",
                  fontSize: "14px",
                  color: message.id === messageId ? "green" : "white",
                  backgroundColor:
                    message.id === messageId ? "rgba(0,0,0,0.2)" : "",
                }}
              >
                {message.id}
              </div>
            );
          })}
        </div>
        {/* <DropDown
          key={"templates"}
          maxContent={true}
          title='Selecciona el formulario a editar'
          options={elements}
          onChange={(value) => {
            if (value) changeFlowId(value.value);
          }}
          valueKey={templateId}
        /> */}
      </div>
    );
  };

  const getContent = (
    message: HfMessageModel,
    changeMessage: (ms: HfMessageModel) => void,
    modalOpen: boolean,
    setModalOpen: (open: boolean) => void,
    selectedMessageIndex: number,
    setSelectedMessageIndex: (index: number) => void
  ): JSX.Element => {
    //State

    function addMessage() {
      let newMessages = [...message.messages];
      newMessages.push({
        message: "",
        align: "LEFT",
        color: "WHITE",
        size: "20px",
        fontWeight: "400",
        linkUnderlined: false,
        linkColor: "WHITE",
        stateIds: [],
      });
      changeMessage({ ...message, messages: newMessages });
    }

    function updateMessageByIndex(index: number, msg: HfEditorMessage) {
      let newMessages = [...message.messages];
      newMessages[index] = msg;
      changeMessage({ ...message, messages: newMessages });
    }

    function deleteByIndex(index: number) {
      let newMessages = [...message.messages];
      newMessages.splice(index, 1);
      changeMessage({ ...message, messages: newMessages });
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          maxHeight: "100%",
          backgroundColor: "rgba(0,0,0,0.2)",
          padding: "20px",
          margin: "20px",
          borderRadius: "10px",
        }}
      >
        <Modal
          isOpen={modalOpen}
          style={customStylesModal}
          contentLabel="Modificar estilos"
          onRequestClose={() => {
            setModalOpen(false);
          }}
        >
          {message.messages[selectedMessageIndex].message && (
            <div
              style={{
                padding: "30px",
                background: "rgba(0,0,0,0.2)",
                borderRadius: "10px",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                width: "600px",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  placeContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <h1 className="text-normal text-medium primary-color">Color</h1>
                <input
                  type="text"
                  value={message.messages[selectedMessageIndex].color}
                  onChange={(e) => {
                    console.log(message.messages[selectedMessageIndex]);
                    updateMessageByIndex(selectedMessageIndex, {
                      ...message.messages[selectedMessageIndex],
                      color: e.target.value,
                    });
                  }}
                  className="text-normal text-small primary-color-80 input1"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  placeContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <h1 className="text-normal text-medium primary-color">
                  Tamaño
                </h1>
                <input
                  type="text"
                  value={message.messages[selectedMessageIndex].size}
                  onChange={(e) => {
                    console.log(message.messages[selectedMessageIndex]);
                    updateMessageByIndex(selectedMessageIndex, {
                      ...message.messages[selectedMessageIndex],
                      size: e.target.value,
                    });
                  }}
                  className="text-normal text-small primary-color-80 input1"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  placeContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <h1 className="text-normal text-medium primary-color">Peso</h1>
                <input
                  type="text"
                  value={message.messages[selectedMessageIndex].fontWeight}
                  onChange={(e) => {
                    console.log(message.messages[selectedMessageIndex]);
                    updateMessageByIndex(selectedMessageIndex, {
                      ...message.messages[selectedMessageIndex],
                      fontWeight: e.target.value,
                    });
                  }}
                  className="text-normal text-small primary-color-80 input1"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  placeContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <h1 className="text-normal text-medium primary-color">
                  Subrayar link
                </h1>
                <input
                  style={{ width: "20px" }}
                  type="checkbox"
                  checked={message.hasConfetti}
                  onChange={(e) => {
                    updateMessageByIndex(selectedMessageIndex, {
                      ...message.messages[selectedMessageIndex],
                      linkUnderlined: e.target.checked,
                    });
                  }}
                  className="text-normal text-small primary-color-80 input1"
                />
              </div>
            </div>
          )}
        </Modal>
        <div>
          <h1 className="text-normal text-medium primary-color">
            Editando mensaje: {message.id}
          </h1>
        </div>
        <div className="editor-containerFieldDetail">
          <h1 className="text-normal text-medium primary-color">
            Mensaje del botón para continuar
          </h1>
          <input
            type="text"
            value={message.continueMessage}
            disabled={false}
            className="text-normal text-small primary-color-80 input1"
            onChange={(e) => {
              changeMessage({ ...message, continueMessage: e.target.value });
            }}
          />
        </div>
        <div className="editor-containerFieldDetail">
          <h1 className="text-normal text-medium primary-color">
            Mostrar confeti
          </h1>
          <input
            style={{ width: "20px" }}
            type="checkbox"
            checked={message.hasConfetti}
            onChange={(e) => {
              changeMessage({ ...message, hasConfetti: e.target.checked });
            }}
            className="text-normal text-small primary-color-80 input1"
          />
        </div>
        <div className="editor-containerFieldDetail">
          <h1 className="text-normal text-medium primary-color">
            Mostrar Cara Triste
          </h1>
          <input
            style={{ width: "20px" }}
            type="checkbox"
            checked={message.hasSadFace}
            onChange={(e) => {
              changeMessage({ ...message, hasSadFace: e.target.checked });
            }}
            className="text-normal text-small primary-color-80 input1"
          />
        </div>
        <div className="editor-containerFieldDetail">
          <h1 className="text-normal text-medium primary-color">Es PopUp</h1>
          <input
            style={{ width: "20px" }}
            type="checkbox"
            checked={message.isPopUp}
            onChange={(e) => {
              changeMessage({ ...message, isPopUp: e.target.checked });
            }}
            className="text-normal text-small primary-color-80 input1"
          />
        </div>
        <div
          className="editor-containerFieldDetail"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignContent: "start",
            alignItems: "start",
            padding: "10px",
          }}
        >
          <h1
            className="text-normal text-medium primary-color"
            style={{ marginBottom: "10px" }}
          >
            Mensajes
          </h1>
          {message.messages.map((msg, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  marginTop: "10px",
                }}
              >
                <textarea
                  style={{
                    marginBottom: "10px",
                    width: "80%",
                    padding: "10px",
                  }}
                  value={msg.message}
                  className="text-normal text-small primary-color-80 input1"
                  onChange={(e) => {
                    let newMessages = [...message.messages];
                    newMessages[index] = {
                      ...newMessages[index],
                      message: e.target.value,
                    };
                    changeMessage({
                      ...message,
                      messages: newMessages,
                    });
                    auto_grow(e.target as HTMLTextAreaElement);
                  }}
                  onClick={(e) => {
                    auto_grow(e.target as HTMLTextAreaElement);
                  }}
                  onLoad={(e) => {
                    auto_grow(e.target as HTMLTextAreaElement);
                  }}
                />
                <button
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    setSelectedMessageIndex(index);
                    setModalOpen(true);
                  }}
                  className={"boton1"}
                >
                  Modificar estilo
                </button>

                <button
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    marginLeft: "20px",
                  }}
                  onClick={() => {
                    deleteByIndex(index);
                  }}
                  className={"boton1"}
                >
                  Eliminar
                </button>
              </div>
            );
          })}
          <button
            style={{
              marginTop: "10px",
              flexDirection: "column",
              display: "flex",
            }}
            onClick={() => addMessage()}
            className={"boton1"}
          >
            Agregar mensaje +
          </button>
        </div>
      </div>
    );
  };

  if (loading === "pending") {
    return (
      <div
        className="text-normal text-medium primary-color"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          padding: "50px",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader color="white" loading={true} size={150} />
        Cargando...
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "60%",
        padding: "30px",
      }}
    >
      <div style={{ width: "20vw", height: "50vh" }}>
        <div>{getMessagesDropdown()}</div>
        {edited && (
          <button
            className="boton1"
            style={{ marginTop: "20px" }}
            onClick={() => saveChanges()}
          >
            Guardar cambios en el servidor
          </button>
        )}
      </div>
      {currentMessage && (
        <div style={{ width: "80%" }}>
          <div>
            {getContent(
              currentMessage!!,
              (ms: HfMessageModel) => {
                dispatch(updateMesage(ms));
              },
              modalOpen,
              setModalOpen,
              selectedMessageIndex,
              setSelectedMessageIndex
            )}
          </div>
        </div>
      )}
    </div>
  );
};
