import axiosApiInstance from "../../helpers/interceptor";

let clientDataMethod = "clientData";
let clientUserDataMethod = "clientData/clientUserData";

let getClientDataAPI = async (): Promise<{
  data: ClientDataResponse | null;
  error: string;
  status: number;
}> => {
  try {
    let clientData = await axiosApiInstance.get<ClientDataResponse>(
      `/${clientDataMethod}`
    );
    if (clientData.status === 200) {
      return { data: clientData.data, error: "", status: 200 };
    } else if (clientData.status === 401) {
      return {
        data: null,
        error: "Unauthorized",
        status: clientData.status,
      };
    } else {
      return { data: null, error: "Unknown", status: clientData.status };
    }
  } catch (err) {
    throw err;
  }
};

let getClientUserDataAPI = async (): Promise<{
  data: ClientUserDataResponse | null;
  error: string;
  status: number;
}> => {
  try {
    let clientUserData = await axiosApiInstance.get<ClientUserDataResponse>(
      `/${clientUserDataMethod}`
    );
    if (clientUserData.status === 200) {
      return { data: clientUserData.data, error: "", status: 200 };
    } else if (clientUserData.status === 401) {
      return {
        data: null,
        error: "Unauthorized",
        status: clientUserData.status,
      };
    } else {
      return {
        data: null,
        error: "Unknown",
        status: clientUserData.status,
      };
    }
  } catch (err) {
    throw err;
  }
};

export const getClientBrandByCodeAPI = async (
  code: string
): Promise<{ data: ClientBrandResponse | null; error: string }> => {
  try {
    let clientBrand = await axiosApiInstance.get<ClientBrandResponse>(
      `/${clientDataMethod}/brandConfigByCode/${code}`
    );

    return { data: clientBrand.data, error: "" };
  } catch (err: any) {
    return { data: null, error: err.message };
  }
};

export interface ClientBrandResponse {
  logoUrl: string;
}

export interface ClientDataResponse {
  name: string;
  clientId: string;
  email: string;
  alertsEmail: string;
  activeServices: ActiveServices;
  metaClient: boolean;
}

export interface ClientUserDataResponse {
  id: string;
  name: string;
  userName: string;
  email: string;
  roles: Array<string>;
}

export interface ActiveServices {
  coreId: boolean;
  magicForms: boolean;
  firmaNew: boolean;
  hyperFlow: boolean;
  pagares: boolean;
  electronicSignature: boolean;
  authentikator: boolean;
  dashboard: boolean;
}

export { getClientDataAPI, getClientUserDataAPI };
