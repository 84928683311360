import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

import {
  AlertStatusChangeRequest,
  changeAlertStatusAPI,
  DashboardAlerts,
  getDashboardAlertsAPI,
  getUnifiedAlertsFilteredAPI,
  UnifiedAlertsFiltered,
  UnifiedAlertsFilteredRequest,
} from "./alertsAPI";
import { getRegistrationByIdAPI } from "../coreid/coreIdAPI";
import { LoadingStatus } from "../../common/commonSlice";

export type AlertSeverity = "NONE" | "MEDIUM" | "HIGH" | "LOW" | "OTHER";

export interface AlertsState {
  loading: boolean;
  loadingDashboard: LoadingStatus;
  loadingStatusChange: LoadingStatus;
  unifiedAlertsFiltered: UnifiedAlertsFiltered | null;
  currentAlertsRequest: UnifiedAlertsFilteredRequest;
  currentPage: number;
  numPages: number;
  notFoundMessage: string;
  categoryAlerts: DashboardAlerts;
}

const initialState: AlertsState = {
  loading: false,
  currentAlertsRequest: {
    page: 0,
    service: null,
    category: null,
    criticality: null,
    status: null,
    endDate: null,
    startDate: null,
  },
  currentPage: 0,
  notFoundMessage: "",
  numPages: 0,
  unifiedAlertsFiltered: null,
  categoryAlerts: {
    attachments: "HIGH",
    authenticity: "MEDIUM",
    compliance: "LOW",
    dates: "NONE",
    incongruences: "OTHER",
    biometry: "NONE",
    other: "NONE",
  },
  loadingDashboard: "idle",
  loadingStatusChange: "idle",
};

export const getAlertDashBoard = createAsyncThunk(
  "alerts/getAlertDashBoard",
  async (_, { rejectWithValue }) => {
    const alerts = await getDashboardAlertsAPI();
    if (alerts.dashboard) {
      return alerts.dashboard;
    } else {
      rejectWithValue(alerts.error);
    }
  }
);

export const changeAlertStatus = createAsyncThunk(
  "alerts/changeAlertStatus",
  async (params: AlertStatusChangeRequest, { rejectWithValue }) => {
    const alerts = await changeAlertStatusAPI(params);
    if (alerts.alert) {
      return alerts.alert;
    } else {
      rejectWithValue(alerts.error);
    }
  }
);

export const getUnifiedAlerts = createAsyncThunk(
  "alerts/getUnifiedAlerts",
  async (params: UnifiedAlertsFilteredRequest, { rejectWithValue }) => {
    try {
      const alerts = await getUnifiedAlertsFilteredAPI(params);
      if (alerts.alerts) {
        for (let i = 0; i < alerts.alerts.alerts.length; i++) {
          let alert = alerts.alerts.alerts[i];
          if (alert.userId) {
            try {
              let user = await getRegistrationByIdAPI(alert.userId);
              if (user.registration) {
                alert.userName = `${user.registration.name} ${user.registration.lastName}`;
              } else {
                alert.userName = "N/A";
              }
            } catch {
              alert.userName = "N/A";
            }
          }
        }
        return alerts;
      } else {
        rejectWithValue(alerts.error);
      }
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const alertsSlice = createSlice({
  name: "alertsSlice",
  initialState,
  reducers: {
    changeCurrentFilters: (
      state,
      action: PayloadAction<UnifiedAlertsFilteredRequest>
    ) => {
      state.currentAlertsRequest = { ...action.payload, page: 0 };
    },
    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentAlertsRequest;
      state.currentPage = action.payload;
      state.currentAlertsRequest = { ...current, page: action.payload };
    },
    resetCurrentAlerts: (state) => {
      state.currentAlertsRequest = initialState.currentAlertsRequest;
      state.loading = initialState.loading;
      state.categoryAlerts = initialState.categoryAlerts;
      state.currentPage = initialState.currentPage;
      state.loadingDashboard = initialState.loadingDashboard;
      state.loadingStatusChange = initialState.loadingStatusChange;
      state.numPages = initialState.numPages;
      state.unifiedAlertsFiltered = initialState.unifiedAlertsFiltered;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnifiedAlerts.fulfilled, (state, action) => {
        let alertsResponse = action.payload;
        if (alertsResponse && alertsResponse.alerts) {
          state.unifiedAlertsFiltered = alertsResponse.alerts;

          state.numPages = alertsResponse.alerts.numPages;
        }
        state.loading = false;
      })
      .addCase(getUnifiedAlerts.rejected, (state) => {
        state.loading = false;
        state.currentPage = 0;
        state.numPages = 0;
      })
      .addCase(getUnifiedAlerts.pending, (state) => {
        state.loading = true;
      });

    // Dashboard
    builder
      .addCase(getAlertDashBoard.fulfilled, (state, action) => {
        let alertsResponse = action.payload;
        if (alertsResponse) {
          state.categoryAlerts = alertsResponse;
        }
        state.loadingDashboard = "resolved";
      })
      .addCase(getAlertDashBoard.rejected, (state) => {
        state.loadingDashboard = "rejected";
      })
      .addCase(getAlertDashBoard.pending, (state) => {
        state.loadingDashboard = "pending";
      });

    // Change status
    builder
      .addCase(changeAlertStatus.fulfilled, (state, action) => {
        let alertResponse = action.payload;
        if (alertResponse) {
          state.loadingStatusChange = "resolved";
          let alerts = state.unifiedAlertsFiltered?.alerts;
          if (alerts) {
            let index = alerts.findIndex(
              (alert) => alert.id === alertResponse?.id
            );
            if (index > -1) {
              alerts[index].status = alertResponse.status;
            }
            state.unifiedAlertsFiltered = {
              alerts: alerts,
              numPages: state.unifiedAlertsFiltered?.numPages!,
              currentPage: state.unifiedAlertsFiltered?.currentPage!,
            };
          }
        }
      })
      .addCase(changeAlertStatus.rejected, (state) => {
        state.loadingStatusChange = "rejected";
      })
      .addCase(changeAlertStatus.pending, (state) => {
        state.loadingStatusChange = "pending";
      });
  },
});

export const { changeCurrentFilters, changePageState, resetCurrentAlerts } =
  alertsSlice.actions;

export const selectCurrentAlertsRequest = (state: RootState) =>
  state.alerts.currentAlertsRequest;
export const selectUnifiedAlerts = (state: RootState) =>
  state.alerts.unifiedAlertsFiltered;
export const selectAlertsLoading = (state: RootState) => state.alerts.loading;
export const selectNotFoundMessageAlerts = (state: RootState) =>
  state.alerts.notFoundMessage;
export const selectCurrentPageAlerts = (state: RootState) =>
  state.alerts.currentPage;
export const selectNumPagesAlerts = (state: RootState) => state.alerts.numPages;
export const selectAlertsSeverity = (state: RootState) =>
  state.alerts.categoryAlerts;
export const selectLoadingDashboard = (state: RootState) =>
  state.alerts.loadingDashboard;
export const selectLoadingStatusChange = (state: RootState) =>
  state.alerts.loadingStatusChange;
export default alertsSlice.reducer;
