import React, { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../../common/commonSlice";
import {
  getManualInputDataConfigAPI,
  ManualInputDataConfig,
  ManualInputDataConfigField,
} from "../../hyperFlowAPI";
import Loader from "react-spinners/ClipLoader";
import { ClipLoader } from "react-spinners";
import { useAppDispatch } from "../../../../../app/hooks";
import { resolveManualInputDataV2 } from "../../hyperFlowSlice";
import { delay } from "../../../../../helpers/utils";

interface InputManualDataTabProps {
  stepId: string;
  execFlowId: string;
  title: string;
  onSubmit: (data: string) => void;
  isOpen: boolean;
}

const InputManualDataTab: FC<InputManualDataTabProps> = ({
  stepId,
  execFlowId,
  title,
  onSubmit,
  isOpen,
}) => {
  //Dispatches
  const dispatch = useAppDispatch();

  //State
  const [loading, setLoading] = useState<LoadingStatus>("idle");
  const [config, setConfig] = useState<ManualInputDataConfig | undefined>(
    undefined
  );
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [stateData, setStateData] = useState<{ [key: string]: string }>({});
  const [validations, setValidations] = useState<{ [key: string]: string }>({});
  const [conditionals, setConditionals] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  //Functions
  async function getConfig() {
    try {
      setLoading("pending");
      let resp = await getManualInputDataConfigAPI(execFlowId, stepId);
      if (resp.result && resp.data) {
        setConfig(resp.data);
        intializeValidations(resp.data);
        initializeConditionals(resp.data);
        initializeStateData(resp.data);
        setLoading("resolved");
      } else {
        setErrorMsg(resp.error);
        setLoading("rejected");
      }
    } catch (error) {
      setErrorMsg(String(error));
      setLoading("rejected");
    }
  }

  function initializeStateData(conf: ManualInputDataConfig) {
    let data: { [key: string]: string } = {};
    conf.fields.forEach((field) => {
      data[field.dataId] = field.value;
    });
    setStateData(data);
  }

  function intializeValidations(conf: ManualInputDataConfig) {
    let validations: { [key: string]: string } = {};
    conf.fields.forEach((field) => {
      validations[field.dataId] = "";
    });
    setValidations(validations);
  }

  function initializeConditionals(conf: ManualInputDataConfig) {
    let conditionals: { [key: string]: boolean } = {};
    conf.fields.forEach((field) => {
      conditionals[field.dataId] = false;
    });
    setConditionals(conditionals);
  }

  function updateValue(fieldId: string, dataId: string, value: string) {
    setStateData({ ...stateData, [dataId]: value });
  }

  function executeAllConditionals() {
    let newConds = { ...conditionals };
    config?.fields.forEach((field) => {
      executeConditional(field.fieldId, stateData[field.dataId], newConds);
    });
    console.log(newConds);
    setConditionals(newConds);
  }
  /**
   * Execute the conditional logic for a field
   * @param fieldId
   * @param dataId
   * @param value
   * @returns
   */
  function executeConditional(
    fieldId: string,
    value: string,
    newConds: { [key: string]: boolean }
  ) {
    //find the field
    let field = config?.fields.find((field) => field.fieldId === fieldId);
    if (!field) {
      return;
    }
    //if no conditionals, return
    if (field?.conditionalDestinationIds.length === 0) {
      return;
    }
    //for each conditional, find the conditional config and execute the condition
    field.conditionalDestinationIds.forEach((destId) => {
      let condConfig = config?.conditionals?.find(
        (cond) => cond.conditionalId === destId
      );
      let num = Number.parseFloat(value);
      if (value === "") {
        num = 0;
      }
      console.log("Executing conditional for field", fieldId);
      console.log("conditional config", condConfig);
      console.log("value", value);
      if (condConfig) {
        let condition = false;
        if (condConfig.conditionalType === "EQUALS") {
          condition = value === String(condConfig.firstValue);
        } else if (condConfig.conditionalType === "BETWEEN") {
          condition =
            num >= condConfig.firstValue && num <= condConfig.secondValue;
        }
        console.log("result: ", condition);
        // setConditionals({ ...conditionals, [destId]: condition });
        newConds[destId] = condition;
      }
    });
  }

  function executeAllValidators() {
    let newValidations = { ...validations };
    config?.fields.forEach((field) => {
      executeValidator(field.fieldId, stateData[field.dataId], newValidations);
    });
    console.log(newValidations);
    setValidations(newValidations);
  }

  /**
   * Execute the validator for a field
   * @param fieldId
   * @param value
   * @returns
   */
  function executeValidator(
    fieldId: string,
    value: string,
    newValidations: { [key: string]: string }
  ) {
    let field = config?.fields.find((field) => field.fieldId === fieldId);
    if (!field) {
      return;
    }
    let validator = field.validator;
    let message = "";

    let num = Number.parseFloat(value);
    if (value === "") {
      num = 0;
    }

    if (validator.validatorType === "REQUIRED") {
      if (value === "") {
        message = validator.message;
      }
    } else if (validator.validatorType === "NUMBER") {
      if (isNaN(Number(value))) {
        message = validator.message;
      }
    } else if (validator.validatorType === "VALUE_ABOVE") {
      if (Number(value) <= validator.firstValue) {
        message = validator.message;
      }
    } else if (validator.validatorType === "VALUE_BELOW") {
      if (Number(value) >= validator.firstValue) {
        message = validator.message;
      }
    } else if (validator.validatorType === "VALUE_BETWEEN") {
      if (num < validator.firstValue || num > validator.secondValue) {
        message = validator.message;
      }
    }
    // setValidations({ ...validations, [fieldId]: message });
    newValidations[fieldId] = message;
  }

  function checkIfFieldIsShown(fieldId: string) {
    let field = config?.fields.find((field) => field.fieldId === fieldId);
    if (!field) {
      return false;
    }
    if (field.conditionalSourceId && field.conditionalSourceId !== "") {
      return conditionals[field.conditionalSourceId];
    }
    return true;
  }

  async function updateData() {
    setLoading("pending");
    dispatch(resolveManualInputDataV2({ step: stepId, data: stateData }));
    // await delay(8000)
    // reload page
    // window.location.reload();
  }

  //UseEffects
  useEffect(() => {
    executeAllConditionals();
    executeAllValidators();
  }, [stateData]);

  useEffect(() => {
    if (isOpen) {
      getConfig();
    }
  }, [isOpen]);

  useEffect(() => {
    let showButton = true;
    config?.fields.forEach((field) => {
      if (checkIfFieldIsShown(field.fieldId)) {
        if (validations[field.fieldId] !== "") {
          showButton = false;
        }
      }
    });
    setShowSaveButton(showButton);
  }, [validations, conditionals]);

  //Helper Render Functions
  function renderList() {
    return config?.fields.map((item) => {
      if (!checkIfFieldIsShown(item.fieldId)) {
        return <></>;
      }
      return (
        <div key={item.fieldId} className="row">
          <div className="col-12">
            <label
              htmlFor={item.fieldId}
              className="form-label"
              style={{ color: "white" }}
            >
              {item.title}
            </label>
            {getItemInputField(item)}
            <h1
              style={{
                color: "red",
                fontSize: "12px",
                fontWeight: "600",
                textAlign: "start",
                width: "100%",
                marginTop: "5px",
              }}
            >
              {validations[item.fieldId]}
            </h1>
          </div>
        </div>
      );
    });
  }

  function getItemInputField(field: ManualInputDataConfigField) {
    const handleInputChange = (event: any): string => {
      const numericValue = event.target.value.replace(/[^0-9]/g, "");

      return numericValue;
    };

    const formatCurrency = (value: string) => {
      if (!value) return "";
      // get numeric value deleting symbols, commas, dots, etc
      const numericValue = parseFloat(value.replace(/\D/g, "")) || 0;

      if (numericValue === 0) return "";

      const formatted = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: 0,
      }).format(numericValue);

      return formatted;
    };
    switch (field.type) {
      //Textos
      case "TEXT":
        return (
          <input
            id={field.fieldId}
            name={field.fieldId}
            type="text"
            onChange={(e) => {
              // onChange(item.id, e.target.value);
              updateValue(field.fieldId, field.dataId, e.target.value);
            }}
            value={stateData[field.dataId]}
            style={{
              border: "0.5px solid rgba(255,255,255,0.2)",
              borderRadius: "6px",
              background: "rgba(255,255,255,0.05)",
              minHeight: "30px",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "start",
              width: "100%",
              color: "#FFF",
            }}
          />
        );
      //Numeros
      case "NUMBER":
        return (
          <input
            id={field.fieldId}
            name={field.fieldId}
            type="number"
            onChange={(e) => {
              // onChange(item.id, e.target.value);
              updateValue(field.fieldId, field.dataId, e.target.value);
            }}
            value={stateData[field.dataId]}
            style={{
              border: "0.5px solid rgba(255,255,255,0.2)",
              borderRadius: "6px",
              background: "rgba(255,255,255,0.05)",
              minHeight: "30px",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "start",
              width: "100%",
              color: "#FFF",
            }}
          />
        );
      //Monedas
      case "CURRENCY_CO":
        return (
          <input
            id={field.fieldId}
            name={field.fieldId}
            type="text"
            onChange={(e) => {
              let a = handleInputChange(e);
              updateValue(field.fieldId, field.dataId, a);
            }}
            value={formatCurrency(stateData[field.dataId])}
            style={{
              border: "0.5px solid rgba(255,255,255,0.2)",
              borderRadius: "6px",
              background: "rgba(255,255,255,0.05)",
              minHeight: "30px",
              fontSize: "12px",
              fontWeight: "600",
              textAlign: "start",
              width: "100%",
              color: "#FFF",
            }}
          />
        );
    }
  }

  //Render

  if (loading === "idle") {
    return <div></div>;
  }
  if (loading === "rejected") {
    return <div>{errorMsg}</div>;
  }
  if (loading === "pending") {
    return (
      <div>
        <ClipLoader color="white" size="150px" />
      </div>
    );
  }
  return (
    <div
      style={{
        width: "90%",
        height: "100%",
        justifyContent: "center",
        alignContent: "center",
        paddingLeft: "20px",
      }}
    >
      {renderList()}
      <button
        type="submit"
        onClick={() => {
          // onSubmit("");
          updateData();
        }}
        style={{
          width: "100%",
          height: "40px",
          borderRadius: "6px",
          background: showSaveButton ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.8)",
          color: "white",
          fontWeight: "600",
          fontSize: "12px",
          border: "0px",
          marginTop: "20px",
          cursor: showSaveButton === true ? "pointer" : "not-allowed",
        }}
      >
        {showSaveButton ? "Confirmar Cambios" : "Corriges los errores"}
      </button>
    </div>
  );
};

export default InputManualDataTab;
