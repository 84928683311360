import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface FosygaProps {
  loading: LoadingStatus;
  item: FosygaData | null;
}

export interface FosygaData {
  afiliaciones: Array<AfiliacionFosyga>;
  presentaRiesgo: boolean;
  datosBasicos: {
    apellidos: string;
    tipoIdentificacion: string;
    numIdentificacion: string;
    fechaNacimiento: string;
    municipio: string;
    departamento: string;
    nombres: string;
  };
}

export interface AfiliacionFosyga {
  estado: string;
  fechaAfiliacion: string;
  entidad: string;
  regimen: string;
  tipoAfiliado: string;
}

export const FosygaResult = (props: FosygaProps) => {
  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Fosyga
          </div>
          {props.item && props.item.datosBasicos && (
            <div
              style={{
                color: props.item.presentaRiesgo
                  ? "rgba(248, 113, 113, 1)"
                  : "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Datos Básicos
            </div>
          )}
          {props.item && props.item.datosBasicos ? (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Nombre
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Apellidos
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Tipo Identificación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Identificación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Fecha de nacimiento
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Municipio
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Departamento
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    height: "2.5rem",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "0.375rem",
                  }}
                >
                  <td></td>
                  <td>{props.item.datosBasicos.nombres}</td>
                  <td>{props.item.datosBasicos.apellidos}</td>
                  <td>{props.item.datosBasicos.tipoIdentificacion}</td>
                  <td>{props.item.datosBasicos.numIdentificacion}</td>
                  <td>{props.item.datosBasicos.fechaNacimiento}</td>
                  <td>{props.item.datosBasicos.municipio}</td>
                  <td>{props.item.datosBasicos.departamento}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          )}
          <div
            style={{
              color: "white",
              fontSize: "1.25rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Afiliaciones
          </div>
          {props.item && props.item.afiliaciones ? (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Estado
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Fecha de afiliación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Entidad
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Regimen
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem 0",
                      fontSize: "0.875rem",
                      color: "rgba(255, 255, 255, 0.35)",
                      fontWeight: 600,
                    }}
                  >
                    Tipo de Afiliado
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item &&
                  props.item.afiliaciones &&
                  props.item.afiliaciones.map((afiliacion, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "0.75rem",
                          fontWeight: 400,
                          height: "2.5rem",
                          backgroundColor: "rgba(255, 255, 255, 0.05)",
                          borderRadius: "0.375rem",
                        }}
                      >
                        <td></td>
                        <td>{afiliacion.estado}</td>
                        <td>{afiliacion.fechaAfiliacion}</td>
                        <td>{afiliacion.entidad}</td>
                        <td>{afiliacion.regimen}</td>
                        <td>{afiliacion.tipoAfiliado}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  color: "rgba(252, 165, 165, 1)",
                  fontSize: "2rem",
                  fontWeight: 600,
                }}
              >
                La persona no está registrado en BDUA
              </h5>
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}{" "}
    </div>
  );
};
