import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RegistrationDetail } from "../coreid/coreIdAPI";
import {
  RegPicResponse,
  getClientNameApi,
  getCurrentIdsApi,
  getPicsApi,
  getRegApi,
} from "./liveMonitoringApi";

export interface LiveMonitoringState {
  masterLoading: boolean;
  regs: { [key: string]: MonitoringReg };
}

const initialState: LiveMonitoringState = {
  regs: {},
  masterLoading: true,
};

export interface MonitoringReg {
  id: string;
  status: "idle" | "loading" | "loaded" | "error";
  picsLoading: boolean;
  reg: RegistrationDetail | null;
  pics: RegPicResponse | null;
  clientName: string | null;
}

export const getCurrentIds = createAsyncThunk(
  "liveMonitoring/getCurrentIds",
  async (_, { rejectWithValue }) => {
    const ids = await getCurrentIdsApi();
    console.log("ids");
    console.log(ids);
    if (ids.error && ids.error !== "") {
      return rejectWithValue(ids.error);
    } else {
      return ids.ids;
    }
  }
);

export const getNewIds = createAsyncThunk(
  "liveMonitoring/getNewIds",
  async (_, { getState, dispatch }) => {
    const state = getState() as { liveMonitoring: LiveMonitoringState };
    const currentIds = Object.keys(state.liveMonitoring.regs);
    const newIds = await getCurrentIdsApi();
    if (newIds.error && newIds.error !== "") {
      return;
    } else {
      return newIds?.ids?.filter((id) => !currentIds.includes(id));
    }
  }
);

export const getReg = createAsyncThunk(
  "liveMonitoring/getReg",
  async (regId: string, { rejectWithValue }) => {
    const reg = await getRegApi(regId);
    if (reg.error && reg.error !== "") {
      return rejectWithValue(reg.error);
    } else {
      return reg.reg;
    }
  }
);

function dateFromIsoDate(date: string): Date {
  return new Date(date);
}

export const getPics = createAsyncThunk(
  "liveMonitoring/getPics",
  async (regId: string, { rejectWithValue }) => {
    const pics = await getPicsApi(regId);
    if (pics.error && pics.error !== "") {
      return rejectWithValue(pics.error);
    } else {
      return pics.pics;
    }
  }
);

export const getClientName = createAsyncThunk(
  "liveMonitoring/getClientName",
  async (regId: string, { rejectWithValue, getState }) => {
    const state = getState() as { liveMonitoring: LiveMonitoringState };
    const clientId = state.liveMonitoring.regs[regId].reg?.clientId as string;
    const client = await getClientNameApi(clientId);
    if (client.error && client.error !== "") {
      return rejectWithValue(client.error);
    } else {
      return client.clientName;
    }
  }
);

export const loadAllCurrentIds = createAsyncThunk(
  "liveMonitoring/loadAllCurrentIds",
  async (ids: string[], { dispatch }) => {
    ids.forEach((id) => {
      dispatch(getReg(id));
    });
    ids.forEach((id) => {
      dispatch(getPics(id));
    });
  }
);

export const loadAllClientNames = createAsyncThunk(
  "liveMonitoring/loadAllClientNames",
  async (ids: string[], { dispatch }) => {
    ids.forEach((id) => {
      dispatch(getClientName(id));
    });
  }
);

/**
 * Reloads all regs that are not done yet
 */
export const reloadNecesaryIds = createAsyncThunk(
  "liveMonitoring/reloadNecesaryIds",
  async (_, { getState, dispatch }) => {
    const state = getState() as { liveMonitoring: LiveMonitoringState };
    const currentIds = Object.keys(state.liveMonitoring.regs);
    //Date 20 minutes ago
    let compDate = new Date();
    compDate.setMinutes(compDate.getMinutes() - 20);

    currentIds.forEach((id) => {
      let date = dateFromIsoDate(
        state.liveMonitoring.regs[id].reg?.startDatetime as string
      );

      if (
        state.liveMonitoring.regs[id].reg?.result !== true &&
        date > compDate
      ) {
        console.log("getting reg for id: " + id);
        console.log(state.liveMonitoring.regs[id].reg?.result);
        dispatch(getReg(id));
      }
    });
    currentIds.forEach((id) => {
      let date = dateFromIsoDate(
        state.liveMonitoring.regs[id].reg?.startDatetime as string
      );

      if (
        state.liveMonitoring.regs[id].reg?.result !== true &&
        date > compDate
      ) {
        console.log("getting pics for id: " + id);

        console.log(state.liveMonitoring.regs[id].reg?.result);
        dispatch(getPics(id));
      }
    });
    currentIds.forEach((id) => {
      let date = dateFromIsoDate(
        state.liveMonitoring.regs[id].reg?.startDatetime as string
      );

      if (
        state.liveMonitoring.regs[id].reg?.result !== true &&
        date > compDate
      ) {
        console.log("getting clientName for id: " + id);

        console.log(state.liveMonitoring.regs[id].reg?.result);
        dispatch(getClientName(id));
      }
    });
  }
);

export const liveMonitoringSlice = createSlice({
  name: "liveMonitoring",
  initialState,
  reducers: {
    loadCurrentIds: (state) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentIds.pending, (state) => {
      state.regs = {};
      state.masterLoading = true;
    });
    builder.addCase(getCurrentIds.fulfilled, (state, action) => {
      state.masterLoading = false;
      if (action.payload) {
        action.payload.forEach((id) => {
          state.regs[id] = {
            clientName: null,
            id,
            status: "idle",
            reg: null,
            pics: null,
            picsLoading: false,
          };
        });
      }
    });
    builder.addCase(getCurrentIds.rejected, (state, action) => {
      state.masterLoading = false;
    });
    builder.addCase(getReg.pending, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].status = "loading";
      }
    });
    builder.addCase(getReg.fulfilled, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].status = "loaded";
        state.regs[action.meta.arg].reg = action.payload;
      }
    });
    builder.addCase(getReg.rejected, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].status = "error";
      }
    });
    builder.addCase(getPics.pending, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].picsLoading = true;
      }
    });
    builder.addCase(getPics.fulfilled, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].picsLoading = false;
        state.regs[action.meta.arg].pics = action.payload;
      }
    });
    builder.addCase(getPics.rejected, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].picsLoading = false;
      }
    });
    builder.addCase(getNewIds.fulfilled, (state, action) => {
      if (action.payload) {
        action.payload.forEach((id) => {
          console.log("updated id: " + id);
          if (!state.regs[id]) {
            state.regs[id] = {
              id,
              clientName: null,
              status: "idle",
              reg: null,
              pics: null,
              picsLoading: false,
            };
          }
        });
      }
    });
    //GetClientName
    builder.addCase(getClientName.fulfilled, (state, action) => {
      if (state.regs[action.meta.arg]) {
        state.regs[action.meta.arg].status = "loaded";
        state.regs[action.meta.arg].clientName = action.payload;
      }
    });
  },
});

export const selectRegs = (state: { liveMonitoring: LiveMonitoringState }) =>
  state.liveMonitoring.regs;
export const selectMasterLoading = (state: {
  liveMonitoring: LiveMonitoringState;
}) => state.liveMonitoring.masterLoading;

export const { loadCurrentIds } = liveMonitoringSlice.actions;

export default liveMonitoringSlice.reducer;
