import { useEffect, useState } from "react";

// const getConstantsView
type MapEditorComponentProps = {
  map: { [key: string]: string };
  onChange: (map: { [key: string]: string }) => void;
};
const MapEditorComponent: React.FC<MapEditorComponentProps> = ({
  map,
  onChange,
}) => {
  type LocalConstant = {
    id: string;
    key: string;
    value: string;
  };
  const [localEdited, setLocalEdited] = useState(false);
  const [localConstants, setLocalConstants] = useState<LocalConstant[]>([]);
  //Load Constants to local state
  useEffect(() => {
    let newLC = Object.keys(map).map((key) => {
      return { id: Math.random().toString(), key, value: map[key] };
    });
    setLocalConstants(newLC);
  }, [map]);
  function deleteConstant(key: string) {
    setLocalEdited(true);
    let newLC = localConstants.filter((lc) => lc.key !== key);
    setLocalConstants(newLC);
  }

  function changeKey(oldId: string, newKey: string) {
    setLocalEdited(true);
    let newLC = localConstants.map((lc) => {
      if (lc.id === oldId) {
        return { ...lc, key: newKey };
      }
      return lc;
    });
    setLocalConstants(newLC);
  }

  function changeValue(key: string, value: string) {
    setLocalEdited(true);
    let newLC = localConstants.map((lc) => {
      if (lc.key === key) {
        return { ...lc, value };
      }
      return lc;
    });
    setLocalConstants(newLC);
  }
  return (
    <div
      style={{
        padding: "10px",
        background: "rgba(0,0,0,0.2)",
        borderRadius: "10px",
        overflow: "auto",
      }}
    >
      <table>
        <thead
          className='text-normal text-small primary-color'
          style={{
            height: "30px",
            textAlign: "center",
          }}
        >
          <tr style={{ height: "15px" }}>
            <th style={{ width: "300px" }}>Llave</th>
            <th style={{ width: "600px" }}>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody
          className={`text-normal text-small primary-color-50`}
          style={{
            textAlign: "center",
            margin: "5px 0 5px 0",
          }}
        >
          {localConstants.map((lc) => {
            return (
              <tr
                key={lc.id}
                className=''
                style={{
                  padding: "2px",
                  //   flexDirection: "row",
                  //   display: "flex",
                  //   alignItems: "center",
                }}
              >
                <td>
                  <input
                    type='text'
                    value={lc.key}
                    onChange={(e) => {
                      changeKey(lc.id, e.target.value);
                    }}
                    className='text-normal text-small primary-color-80 input1'
                  />
                </td>
                <td>
                  <textarea
                    style={{ width: "600px" }}
                    value={lc.value}
                    onChange={(e) => {
                      changeValue(lc.key, e.target.value);
                    }}
                    className='text-normal text-small primary-color-80 input1'
                  />
                </td>
                <td>
                  <button
                    className='boton1'
                    onClick={() => {
                      deleteConstant(lc.key);
                    }}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        className='boton1'
        style={{ marginTop: "20px" }}
        onClick={() => {
          setLocalEdited(true);
          setLocalConstants([
            ...localConstants,
            {
              id: Math.random().toString(),
              key: "",
              value: "",
            },
          ]);
        }}
      >
        Agregar
      </button>
      {localEdited && (
        <button
          className='boton1'
          style={{ marginTop: "20px" }}
          onClick={() => {
            setLocalEdited(false);
            let newConstants: { [key: string]: string } = {};
            localConstants.forEach((lc) => {
              newConstants[lc.key] = lc.value;
            });
            onChange(newConstants);
          }}
        >
          Guardar Cambios
        </button>
      )}
    </div>
  );
};

export default MapEditorComponent;