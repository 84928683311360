import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Modal, { Styles } from "react-modal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeActiveComponent,
  changePageState,
  cleanCurrentRoles,
  getFilteredUsers,
  selectAccountData,
  selectActiveConfigurationComponent,
  selectCurrentFilterRequestUsers,
  selectCurrentNumPagesUsers,
  selectCurrentPageUsers,
  selectUsers,
  selectUsersLoading,
} from "../configurationSlice";
import { useEffect, useState } from "react";
import { selectRoles, selectUserId } from "../../common/commonSlice";

Modal.setAppElement("#root");

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "20%",
    width: "20%",
    borderRadius: "20px",
    boxSizing: "border-box",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

export const Users = () => {
  let usuarios = useAppSelector(selectUsers);
  let userId = useAppSelector(selectUserId);
  let currentPage = useAppSelector(selectCurrentPageUsers);
  let currentNumPages = useAppSelector(selectCurrentNumPagesUsers);
  let loadingStatus = useAppSelector(selectUsersLoading);
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequestUsers);
  let roles = useAppSelector(selectRoles);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  let changePage = (type: "before" | "after") => {
    if (type === "after" && currentPage < currentNumPages - 1) {
      dispatch(changePageState(currentPage + 1));
    } else if (type === "before" && currentPage > 0) {
      dispatch(changePageState(currentPage - 1));
    }
  };

  useEffect(() => {
    if (activeComponent !== "Users") {
      dispatch(changeActiveComponent("Users"));
    }

    dispatch(getFilteredUsers({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {usuarios && usuarios.users.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStylesModal}
            contentLabel="Error de login"
            shouldCloseOnOverlayClick={true}
          >
            <div
              className="flex-col"
              style={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            ></div>
          </Modal>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "space-between",
              alignItems: "start",
              margin: "2% 4% 1% 1%",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ margin: "0 0 0 2%" }}
            >
              Usuarios
            </div>
            <div
              className="text-medium text-bold primary-color"
              style={{
                marginTop: "4%",
                marginRight: "2%",
                marginLeft: "1%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                width: "180px",
                visibility: roles.includes("users.create")
                  ? "visible"
                  : "collapse",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(cleanCurrentRoles());
                navigate("/configuration/users/create");
              }}
            >
              Crear usuario
            </div>
          </div>

          {usuarios.users ? (
            <div
              className="flex-col"
              style={{ height: "80%", margin: "1% 4% 0 0 " }}
            >
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombres y Apellidos</th>
                      <th>Email</th>
                      <th>Identificación</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {usuarios.users.map((usuario, i) => {
                      if (usuario.id !== userId) {
                        return (
                          <tr
                            key={i}
                            className="list-hover"
                            style={{ height: "30px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`${usuario.id}`);
                            }}
                          >
                            <td></td>
                            <td>{usuario.name}</td>
                            <td>{usuario.email}</td>
                            <td>{usuario.identification}</td>
                            <td>
                              {usuario.active === true
                                ? "Activo"
                                : "Suspendido"}
                            </td>
                            <td></td>
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más usuarios en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="flex-row text-bold primary-color"
              style={{ marginRight: "100px", fontSize: "13px" }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("before");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {"<"}
              </div>
              Página {currentPage + 1} de {currentNumPages}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  changePage("after");
                }}
                style={{
                  width: "30px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  fontSize: "15px",
                  height: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más usuarios en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
