import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changePageState,
  getFilteredPagares,
  selectActivePagareComponent,
  selectCurrentFilterPagareRequest,
  selectCurrentNumPagesPagare,
  selectCurrentPagePagare,
  selectPagares,
  selectLoadingPagares,
  selectPageSizePagare,
  changePageSize,
} from "../pagareSlice";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import { getStatusPagareName } from "../../firma/helpers/utils";
import Modal, { Styles } from "react-modal";
import { selectRoles } from "../../../common/commonSlice";
import { usePagination } from "pagination-react-js";
import PaginationItem from "../../../../helpers/PaginationItem";

Modal.setAppElement("#root");

export const PagaresList = () => {
  let pagares = useAppSelector(selectPagares);
  let currentPage = useAppSelector(selectCurrentPagePagare);
  let currentNumPages = useAppSelector(selectCurrentNumPagesPagare);
  const pageSize = useAppSelector(selectPageSizePagare);
  let loadingStatus = useAppSelector(selectLoadingPagares);
  let activeComponent = useAppSelector(selectActivePagareComponent);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterPagareRequest);
  let roles = useAppSelector(selectRoles);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: pageSize || 10,
        totalRecordsLength: currentNumPages * 10,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changePageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  useEffect(() => {
    if (activeComponent !== "Pagares") {
      dispatch(changeActiveComponent("Pagares"));
    }

    dispatch(getFilteredPagares({ ...currentFilteredRequest }));
    return () => {};
  }, [currentPage, activeComponent, currentFilteredRequest]);

  return (
    <LoadingOverlay
      active={loadingStatus === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      {pagares && pagares.filteredPagares.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          <div
            className="flex-row"
            style={{
              height: "10%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="text-xx-large text-bold primary-color"
              style={{ marginRight: "2%" }}
            >
              Pagares
            </div>
          </div>
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "5px",
              marginRight: "2%",
              marginLeft: "1%",
              marginBottom: "2%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "180px",
              visibility: roles.includes("pagare.create")
                ? "visible"
                : "collapse",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/pagares/create");
            }}
          >
            CREAR NUEVO PAGARÉ
          </div>
          {pagares.filteredPagares ? (
            <div className="flex-col" style={{ height: "70%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>No. Pagaré</th>
                      <th>Deudor</th>
                      <th>Tipo de pagaré</th>
                      <th>Valor</th>
                      <th>Fecha Última versión</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-normal text-x-small primary-color"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {pagares.filteredPagares.map((pagare, i) => {
                      return (
                        <tr
                          key={i}
                          className="list-hover"
                          style={{ height: "30px", cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/pagares/list/${pagare.pagareRootId}`
                            );
                          }}
                        >
                          <td></td>
                          <td>{pagare.noPagare}</td>
                          <td>{pagare.deudoresName[0]}</td>
                          <td>
                            {pagare.pagareType == "Blank"
                              ? "En blanco con carta de instrucciones"
                              : "Diligenciado"}
                          </td>
                          <td>{`${
                            pagare.value === "0" ? "N/A" : pagare.value
                          } ${
                            pagare.currency && pagare.currency !== ""
                              ? pagare.currency
                              : ""
                          }`}</td>

                          <td>{formatDateStandar(pagare.versionDate)}</td>
                          <td>{getStatusPagareName(pagare.status)}</td>
                          <td></td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "70%" }}>
              <h1 className="text-huge text-bold primary-color">
                {loadingStatus === "pending"
                  ? ""
                  : "No hay más pagarés en esta página"}
              </h1>
            </div>
          )}
          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <Pagination />
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="text-medium text-bold primary-color"
            style={{
              marginTop: "5px",
              marginRight: "2%",
              marginLeft: "1%",
              marginBottom: "2%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "180px",
              visibility: roles.includes("pagare.create")
                ? "visible"
                : "collapse",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/services/pagares/create");
            }}
          >
            CREAR NUEVO PAGARÉ
          </div>
          <h1 className="text-huge text-bold primary-color">
            {loadingStatus === "pending"
              ? ""
              : "No hay más pagarés en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
