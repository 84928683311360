import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface PoliciaProps {
  loading: LoadingStatus;
  item: AntecedentesPolicia | null;
}

export interface AntecedentesPolicia {
  lista: string;
  presentaRiesgo: string;
  mensajeRespuesta: string;
  tipoDocumentoNoValido?: string;
}

export const PoliciaResult = (props: PoliciaProps) => {
  const getColor = (text: string) => {
    if (!text) return { color: "rgba(0, 0, 0, 0.4)" };
    if (
      text.includes(
        "NO TIENE ASUNTOS PENDIENTES CON LAS AUTORIDADES JUDICIALES"
      )
    ) {
      return { color: "rgba(34, 197, 94, 1)" }; // equivalent to Tailwind's text-green-400
    } else if (
      text.includes("ACTUALMENTE NO ES REQUERIDO POR AUTORIDAD JUDICIAL ALGUNA")
    ) {
      return { color: "rgba(251, 191, 36, 1)" }; // equivalent to Tailwind's text-yellow-400
    } else if (text.includes("Por favor acérquese a las instalaciones")) {
      return { color: "rgba(239, 68, 68, 1)" }; // equivalent to Tailwind's text-red-400
    } else {
      return { color: "rgba(239, 68, 68, 1)" }; // default to red
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {props.item && props.item.tipoDocumentoNoValido ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0.5rem",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Antecedente Policia
          </div>
          <div
            style={{
              color: "white",
              fontSize: "0.875rem",
              fontWeight: 600,
              textAlign: "center",
              marginTop: "0.5rem",
            }}
          >
            {props.item.tipoDocumentoNoValido}
          </div>
        </div>
      ) : (
        <>
          {props.item ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
                padding: "0.5rem",
              }}
            >
              <div
                style={{
                  ...getColor(props.item.mensajeRespuesta),
                  fontSize: "1.875rem",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Antecedente Policia
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  textAlign: "center",
                  marginTop: "0.5rem",
                }}
              >
                Mensaje del resultado: {props.item.mensajeRespuesta}
              </div>
            </div>
          ) : (
            <div>
              {(props.loading === "rejected" ||
                props.loading === "resolved") && (
                <div
                  style={{
                    color: "white",
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  No se encontraron registros, intente de nuevo más tarde
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
