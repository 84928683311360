import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  changeCurrentProduct,
  selectMetaClientId,
  selectProductTitle,
  selectRoles,
} from "../common/commonSlice";
import { SearchInputFilter } from "../common/components/inputs/SearchInputFilter";
import { CategoriesComponent } from "../services/components/sidePanel/categories/CategoriesComponent";
import { Category } from "../services/components/sidePanel/categories/Category";
import { FilterComponent } from "../services/components/sidePanel/FilterComponent";
import { SidePanel } from "../services/components/sidePanel/SidePanel";
import { TopPanel } from "../services/components/TopPanel";
import {
  changeAccountLoading,
  changeCurrentData,
  changeCurrentEventFilters,
  changeCurrentFilters,
  cleanCurrentRoles,
  selectActiveConfigurationComponent,
  selectAllUsers,
  selectCurrentEventFilters,
  selectCurrentFilterRequestUsers,
  selectHelpActiveComponent,
} from "./configurationSlice";
import {
  changeClientFilters,
  selectFilteredClientsRequest,
} from "./clients/clientsSlice";
import { DropDown } from "../common/components/Btns/DropDown";
import { selectCurrentTagsByService } from "../services/tags/tagSlice";

export const Configuration = () => {
  let activeComponent = useAppSelector(selectActiveConfigurationComponent);
  let helpActiveComponent = useAppSelector(selectHelpActiveComponent);
  let currentProduct = useAppSelector(selectProductTitle);
  let roles = useAppSelector(selectRoles);
  let metaClientId = useAppSelector(selectMetaClientId);

  let usersFilteredRequest = useAppSelector(selectCurrentFilterRequestUsers);
  const users = useAppSelector(selectAllUsers);
  const eventFilteredRequest = useAppSelector(selectCurrentEventFilters);
  let clientsFilteredRequest = useAppSelector(selectFilteredClientsRequest);

  let currentServiceTags = useAppSelector(selectCurrentTagsByService);

  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  const getUsersOptions = () => {
    let usersOptions: { value: string; label: string }[] = [];
    for (const user of users) {
      const label = `${user.email}`;
      const value = user.id;
      usersOptions.push({ label: label, value });
    }
    return usersOptions;
  };

  useEffect(() => {
    if (window.location.pathname.includes("help")) {
      dispatch(changeCurrentProduct("Ayuda y Documentacion"));
    } else {
      dispatch(changeCurrentProduct("Configuración"));
    }
  }, []);

  let getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "Account") {
      return;
    } else if (activeComponent === "ActionHistory") {
      // let searchInput = (
      //   <SearchInputFilter
      //     key={"SearchEvents"}
      //     value={eventFilteredRequest.query}
      //     onChange={(e) => {
      //       if (!e.target.value || e.target.value === "") {
      //         dispatch(
      //           changeCurrentEventFilters({
      //             ...eventFilteredRequest,
      //             query: null,
      //           })
      //         );
      //       } else {
      //         dispatch(
      //           changeCurrentEventFilters({
      //             ...eventFilteredRequest,
      //             query: e.target.value,
      //           })
      //         );
      //       }
      //     }}
      //     style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
      //     placeHolder="Nombre de acción"
      //   />
      // );

      let productOptions: { value: string; label: string }[] = [
        {
          label: "CoreId",
          value: "COREID",
        },
        {
          label: "Firma electrónica",
          value: "FIRMA",
        },
        {
          label: "HyperFlow",
          value: "HYPERFLOW",
        },
        {
          label: "SmartFlow",
          value: "SMARTFLOW",
        },
        {
          label: "MagicForms",
          value: "MAGICFORMS",
        },
        {
          label: "Pagarés",
          value: "PAGARES",
        },
        {
          label: "SubClientes",
          value: "CLIENTS",
        },
        {
          label: "Usuarios",
          value: "USERS",
        },
        {
          label: "Cuenta",
          value: "ACCOUNT",
        },
        {
          label: "Alertas",
          value: "ALERTS",
        },
      ];
      const serviceDropdown = (
        <DropDown
          key={"service"}
          title="Producto"
          options={productOptions}
          onChange={(e) => {
            dispatch(
              changeCurrentEventFilters({
                ...eventFilteredRequest,
                service: e?.value!,
              })
            );
          }}
        />
      );

      let EventTypeOptions: { value: string; label: string }[] = [
        {
          label: "Cambio de estado",
          value: "STATUSCHANGE",
        },
      ];
      // const eventTypeDropdown = (
      //   <DropDown
      //     key={"eventType"}
      //     maxContent
      //     title="Tipo de Evento"
      //     options={EventTypeOptions}
      //     onChange={(e) => {
      //       dispatch(
      //         changeCurrentEventFilters({
      //           ...eventFilteredRequest,
      //           type: e?.value!,
      //         })
      //       );
      //     }}
      //   />
      // );

      const userDropdown = (
        <DropDown
          key={"userId"}
          title="Por Usuario de backoffice"
          maxContent
          options={getUsersOptions()}
          onChange={(e) => {
            dispatch(
              changeCurrentEventFilters({
                ...eventFilteredRequest,
                userId: e?.value!,
              })
            );
          }}
        />
      );

      let completedOptions: { value: string; label: string }[] = [
        {
          label: "Exitoso",
          value: "COMPLETED",
        },
        {
          label: "No exitoso",
          value: "NOTCOMPLETED",
        },
      ];
      const completedDropdown = (
        <DropDown
          key={"eventType"}
          title="Estado del evento"
          maxContent
          options={completedOptions}
          onChange={(e) => {
            if (e?.value === "COMPLETED") {
              dispatch(
                changeCurrentEventFilters({
                  ...eventFilteredRequest,
                  completed: true,
                })
              );
            } else if (e?.value === "NOTCOMPLETED") {
              dispatch(
                changeCurrentEventFilters({
                  ...eventFilteredRequest,
                  completed: false,
                })
              );
            } else {
              dispatch(
                changeCurrentEventFilters({
                  ...eventFilteredRequest,
                  completed: null,
                })
              );
            }
          }}
        />
      );
      const searchInput = (
        <SearchInputFilter
          value={eventFilteredRequest.entityId}
          key={"Service Id"}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentEventFilters({
                  ...eventFilteredRequest,
                  entityId: null,
                })
              );
            } else {
              dispatch(
                changeCurrentEventFilters({
                  ...eventFilteredRequest,
                  entityId: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "130%", alignSelf: "center" }}
          placeHolder="Id de servicio asociado"
        />
      );

      return (
        <>
          <FilterComponent
            key={5}
            hasCalendar={false}
            calendarType="double"
            startDate={
              eventFilteredRequest.startedDate
                ? new Date(eventFilteredRequest.startedDate)
                : null
            }
            endDate={
              eventFilteredRequest.finishedDate
                ? new Date(eventFilteredRequest.finishedDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentEventFilters({
                    ...eventFilteredRequest,
                    finishedDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentEventFilters({
                    ...eventFilteredRequest,
                    finishedDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentEventFilters({
                    ...eventFilteredRequest,
                    startedDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentEventFilters({
                    ...eventFilteredRequest,
                    startedDate: null,
                  })
                );
              }
            }}
            items={[
              serviceDropdown,
              userDropdown,
              completedDropdown,
              searchInput,
            ]}
          />
        </>
      );
    } else if (activeComponent === "Users") {
      let searchInput = (
        <SearchInputFilter
          key={"SearchUsers"}
          value={usersFilteredRequest.searchQuery}
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") {
              dispatch(
                changeCurrentFilters({
                  ...usersFilteredRequest,
                  searchQuery: null,
                })
              );
            } else {
              dispatch(
                changeCurrentFilters({
                  ...usersFilteredRequest,
                  searchQuery: e.target.value,
                })
              );
            }
          }}
          style={{ marginTop: "5px", width: "90%", alignSelf: "center" }}
          placeHolder="No. de documento, email ó identificación"
        />
      );

      return (
        <>
          <FilterComponent key={0} hasCalendar={false} items={[searchInput]} />
        </>
      );
    } else if (activeComponent === "Clients") {
      let tagsDropdown = <></>;
      if (roles.includes("tags.view")) {
        let tagsOptions: { value: string; label: string }[] = [];
        let tags = currentServiceTags;
        if (tags) {
          for (const tag of tags) {
            let label = tag.tag;
            let value = tag.id;
            if (tag.associatedEntities.length > 0) {
              tagsOptions.push({ label: label!, value });
            }
          }
        }
        tagsDropdown = (
          <DropDown
            key={"Tags"}
            title="Etiqueta"
            options={tagsOptions}
            onChange={(e) => {
              dispatch(
                changeClientFilters({
                  ...clientsFilteredRequest,
                  tag: e?.value!,
                })
              );
            }}
          />
        );
      }
      return (
        <>
          <FilterComponent
            key={2}
            hasCalendar={true}
            startDate={
              clientsFilteredRequest.startDate
                ? new Date(clientsFilteredRequest.startDate)
                : null
            }
            endDate={
              clientsFilteredRequest.endDate
                ? new Date(clientsFilteredRequest.endDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeClientFilters({
                    ...clientsFilteredRequest,
                    endDate: date.toISOString(),
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeClientFilters({
                    ...clientsFilteredRequest,
                    startDate: date.toISOString(),
                  })
                );
              }
            }}
            resetFunction={() => {
              dispatch(
                changeClientFilters({
                  ...clientsFilteredRequest,
                  startDate: null,
                  endDate: null,
                })
              );
            }}
            items={[tagsDropdown]}
          />
        </>
      );
    }
  };

  let showClients = () => {
    if (
      (metaClientId && metaClientId !== "" && roles.includes("clients.view")) ||
      roles.includes("clients.admin")
    ) {
      return (
        <Category
          key={2}
          name="Clientes"
          selected={activeComponent === "Clients"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            navigate("/configuration/subclients");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  let showMfEditor = () => {
    if (
      roles.includes("magicforms.admin") ||
      roles.includes("magicforms.form.edit")
    ) {
      return (
        <Category
          key={2}
          name="Editor de formularios"
          selected={activeComponent === "MfEditor"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            navigate("/mfeditor");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  let showModalEditor = () => {
    if (
      roles.includes("magicforms.admin") ||
      roles.includes("magicforms.form.edit")
    ) {
      return (
        <Category
          key={2}
          name="Editor de modales"
          selected={activeComponent === "ModalEditor"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            navigate("/mfModalEditor");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  let showHfEditor = () => {
    if (roles.includes("editor.hyperflow") || roles.includes("hfEditor")) {
      return (
        <Category
          key={2}
          name="Editor de Mensajes"
          selected={activeComponent === "HfEditor"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            navigate("/hfEditor");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const showActionHistory = () => {
    if (roles.includes("users.admin") || roles.includes("users.audit")) {
      return (
        <Category
          key={5}
          name="Historial de acciones"
          selected={activeComponent === "ActionHistory"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            navigate("/configuration/actionhistory");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  let showUsers = () => {
    if (
      roles.includes("users.view") ||
      roles.includes("users.edit") ||
      roles.includes("users.admin")
    ) {
      return (
        <Category
          key={1}
          name="Usuarios"
          selected={activeComponent === "Users"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(cleanCurrentRoles());
            dispatch(changeAccountLoading("idle"));
            dispatch(changeCurrentData(null));
            navigate("/configuration/users");
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const getCategoryItems = () => {
    if (currentProduct !== "Ayuda y Documentacion") {
      return (
        <CategoriesComponent
          categories={[
            <Category
              key={0}
              name="Cuenta"
              selected={activeComponent === "Account"}
              onClick={(e) => {
                e.preventDefault();
                dispatch(cleanCurrentRoles());
                dispatch(changeAccountLoading("idle"));
                dispatch(changeCurrentData(null));
                navigate("/configuration/account");
              }}
            />,
            showUsers(),
            showActionHistory(),
            showClients(),
            showMfEditor(),
            showModalEditor(),  
            showHfEditor(),
            <Category
              key={4}
              name="Ayuda"
              last
              selected={activeComponent === "Help"}
              onClick={(e) => {
                e.preventDefault();
                dispatch(changeCurrentProduct("Ayuda y Documentacion"));
                navigate("/configuration/help/general");
              }}
            />,
          ]}
        />
      );
    } else {
      return (
        <CategoriesComponent
          categories={[
            <Category
              key={0}
              name="Introducción"
              selected={helpActiveComponent === "Documentacion General"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/general");
              }}
            />,
            <Category
              key={0}
              name="Documentacion Hyperflow"
              selected={helpActiveComponent === "Documentacion Hyperflow"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/hyperflow");
              }}
            />,
            <Category
              key={1}
              name="Documentacion CoreId"
              selected={helpActiveComponent === "Documentacion CoreId"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/coreid");
              }}
            />,
            <Category
              key={2}
              name="Documentacion MagicForms"
              selected={helpActiveComponent === "Documentacion MagicForms"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/magicforms");
              }}
            />,
            <Category
              key={4}
              name="Documentacion Firma Electrónica"
              selected={helpActiveComponent === "Documentacion Firma"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/firma");
              }}
            />,
            <Category
              key={3}
              name="Documentacion Pagarés"
              selected={helpActiveComponent === "Documentacion Pagares"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/pagares");
              }}
            />,
            <Category
              key={4}
              name="Documentación Configuración Backoffice"
              selected={helpActiveComponent === "Documentacion Configuracion"}
              onClick={(e) => {
                e.preventDefault();
                navigate("/configuration/help/configuracion");
              }}
            />,
          ]}
        />
      );
    }
  };
  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100",
        overflow: "auto",
      }}
    >
      <TopPanel />
      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={getCategoryItems()}
        />

        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "space-between" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
