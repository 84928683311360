import React, { useEffect, useState } from "react";
import { ModalConfig, ModalConfigItem } from "../mfEditorAPI";
import "./ModalEditorDetail.scss";
import Modal, { Styles } from "react-modal";
import MapEditorComponent from "../screens/MapEditorComponent";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#154e66",
    maxHeight: "700px",
    // width: "1200px",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

interface ModalEditorDetailProps {
  modal: ModalConfig;
  onChange: (modal: ModalConfig) => void;
}

const ModalEditorDetail: React.FC<ModalEditorDetailProps> = ({
  modal,
  onChange,
}) => {
  //state
  const [selIndex, setSelIndex] = useState<number>(-1);
  const [confItem, setConfItem] = useState<ModalConfigItem | undefined>(
    undefined
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  //Helper Functions
  function auto_grow(element: HTMLTextAreaElement) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + 10 + "px";
  }

  function deleteByIndex(index: number) {
    const newItems = modal.items.filter((item, i) => i !== index);
    onChange({ ...modal, items: newItems });
  }

  function addItem() {
    const newItems = [...modal.items, { text: "", style: {} }];
    onChange({ ...modal, items: newItems });
  }

  //Effects
  useEffect(() => {
    if (selIndex >= 0 && selIndex < modal.items.length) {
      setConfItem(modal.items[selIndex]);
    }
  }, [selIndex, modal]);

  return (
    <div
      className="modal-editor-container col"
      style={{ height: "80vh", alignContent: "start", justifyContent: "start" }}
    >
      <Modal
        isOpen={modalOpen}
        style={customStylesModal}
        contentLabel="Modificar estilos"
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        <MapEditorComponent
          map={confItem?.style ?? {}}
          onChange={(ext) => {
            onChange({
              ...modal,
              items: modal.items.map((item, index) => {
                if (index === selIndex) {
                  return { ...item, style: ext };
                }
                return item;
              }),
            });
            setModalOpen(false);
          }}
        />
      </Modal>
      <div className="row" style={{marginTop: "10px"}}>
        <div className="col">
          <h1 className="text-normal text-medium primary-color">Título</h1>
          <input
            type="text"
            value={modal.name}
            onChange={(e) => {
              onChange({ ...modal, name: e.target.value });
            }}
            className="text-normal text-small primary-color-80 input1"
          />
        </div>
        <div className="col">
          <h1 className="text-normal text-medium primary-color">Descripción</h1>
          <input
            type="text"
            value={modal.description}
            onChange={(e) => {
              onChange({ ...modal, description: e.target.value });
            }}
            className="text-normal text-small primary-color-80 input1"
          />
        </div>
        <div className="col">
          <h1 className="text-normal text-medium primary-color">Identificador</h1>
          <p className="primary-color" style={{margin: "5px 0 0 10px"}}>{modal.id}</p>
        </div>
      </div>
      <div
        className="row modal-editor-container"
        id="row with selector and detail"
        style={{
          height: "100%",
          overflow: "auto",
          width: "90%",
          margin: "20px",
        }}
      >
        <div
          className="col"
          style={{
            width: "50%",
          }}
        >
          <h1 className="text-normal text-medium primary-color">
            Selecciona un texto para editar
          </h1>
          {modal.items.map((item, index) => {
            return (
              <div className="row" style={{ width: "100%" }}>
                <button
                  style={{
                    marginTop: "10px",
                    flexDirection: "column",
                    display: "flex",
                  }}
                  key={index}
                  onClick={() => setSelIndex(index)}
                  className={selIndex === index ? "boton1Selected" : "boton1"}
                >
                  {index}:{item.text.substring(0, 40)}
                </button>
              </div>
            );
          })}

          <button
            style={{
              marginTop: "40px",
              flexDirection: "column",
              display: "flex",
            }}
            onClick={() => addItem()}
            className={"boton1"}
          >
            Agregar texto
          </button>
        </div>
        <div
          className="col"
          style={{
            width: "70%",
          }}
        >
          {confItem ? (
            <div>
              <button
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  flexDirection: "column",
                  display: "flex",
                }}
                onClick={() => deleteByIndex(selIndex)}
                className={"boton1"}
              >
                Eliminar
              </button>
              <h1 className="text-normal text-medium primary-color">Texto</h1>
              <textarea
                value={confItem.text}
                onChange={(e) => {
                  const newItems = modal.items.map((item, index) => {
                    if (index === selIndex) {
                      return { ...item, text: e.target.value };
                    }
                    return item;
                  });
                  onChange({ ...modal, items: newItems });
                  auto_grow(e.target as HTMLTextAreaElement);
                }}
                onClick={(e) => {
                  auto_grow(e.target as HTMLTextAreaElement);
                }}
                onLoad={(e) => {
                  auto_grow(e.target as HTMLTextAreaElement);
                }}
                className="text-normal text-small primary-color-80 input1"
              />
              <button
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  flexDirection: "column",
                  display: "flex",
                }}
                onClick={() => setModalOpen(true)}
                className={"boton1"}
              >
                Modificar estilo
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalEditorDetail;
