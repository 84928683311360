import { useAppSelector } from "../../../../../app/hooks";
import {
  selectLoadingDataConvertibility,
  selectDataConvertibility,
} from "../../DashboardSlice";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";

export const ConvertibilityRate = () => {
  const formattedData = (numberFormatted: number | undefined) => {
    if (typeof numberFormatted === "number") {
      let numberTwoDigits = numberFormatted.toFixed(2);
      return parseFloat(numberTwoDigits);
    } else {
      return 0;
    }
  };

  let data = useAppSelector(selectDataConvertibility);
  let loadingStatus = useAppSelector(selectLoadingDataConvertibility);

  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "13px",
        backgroundColor: "rgb(0, 0, 0, 0.2)",
        width: "250px",
        height: "60px",
        borderRadius: "0px 0px 0px 0px",
        color: "white",
        marginBottom: "1px",
      }}
    >
      <LoadingOverlay
        active={loadingStatus === "pending"}
        spinner={<ClipLoader color="white" size="40px" />}
      >
        <p
          className="text-medium"
          style={{
            backgroundColor: "rgb(0, 0, 0, 0.3)",
            fontWeight: "700",
            margin: "0px",
            padding: "7px",
          }}
        >
          Tasa Convertibilidad
        </p>
        {data ? (
          <>
            <span
              className="text-large"
              style={{ margin: "8px" }}
            >{`actual: ${formattedData(
              data?.convertibilityData.current_rate
            )}%`}</span>
            <span className="text-large">{`anterior: ${formattedData(
              data?.convertibilityData.previous_rate
            )}%`}</span>
          </>
        ) : (
          <p className="text-large text-bold primary-color">
            No hay datos disponibles
          </p>
        )}
      </LoadingOverlay>
    </div>
  );
};
