import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as EyeIcon } from "../../../../Assets/common/images/eye.svg";
import {
  changeActiveComponent,
  emptyCurrentAttatchment,
  getAttatchmentData,
  getRegistrationById,
  selectRegistrationImagesLoading,
  selectCoreIdLoading,
  selectCurrentAttatchment,
  selectCurrentRegistration,
  getImagesRegistration,
  selectAttatchmentsLoadingStatus,
  selectAttatchmentDownloadingStatus,
  getAttatchments,
  selectRegistrationAlerts,
  selectRegistrationAlertsLoading,
  getAlertsByRegistrationId,
  selectDownloadZipLoading,
  downloadImagesZip,
  selectLoadingAttachmentZip,
  selectAttachmentToVisualize,
  setAttachmentToVisualize,
  getAttachmentsZip,
  selectDownloadingCertificate,
  generateCertificate,
} from "../coreIdSlice";
import { ReactComponent as LeftArrow } from "../../../../Assets/common/images/leftArrow.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/common/images/iconDownload.svg";
import { ReactComponent as DownloadIcon2 } from "../../../../Assets/common/images/downloadIcon2.svg";
import { ReactComponent as Warning } from "../../../../Assets/common/images/warningGreen.svg";

import Modal, { Styles } from "react-modal";
import { TitleSubtitleBox } from "../../components/Squares";
import { ExpandibleContent } from "../../components/ExpandibleContent";
import { useEffect, useState } from "react";
import { formatDateStandar } from "../../../../helpers/utils";
import {
  ExternalQueryResult,
  ListsRenderResult,
  MultiFactorMatrixData,
  RegistrationDetail,
} from "../coreIdAPI";
import { Attatchment } from "../../magicforms/magicFormsAPI";
import {
  BiometryImages,
  DocumentImages,
  Generalmages,
} from "./components/CoreIdImages";
import { getWholeName } from "./components/helpers/utils";
import {
  changeMenuEmergente,
  LoadingStatus,
  selectClientId,
  selectMetaClientId,
  selectRoles,
} from "../../../common/commonSlice";
import { UnifiedAlerts } from "../../alerts/alertsAPI";
import fileDownload from "js-file-download";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByEntity,
  selectAllTags,
  selectCurrentEntity,
  selectCurrentTagCreation,
  selectCurrentTagsByEntity,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByEntity,
  setTagCreation,
} from "../../tags/tagSlice";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import { AlertDot } from "../../alerts/components/AlertDot";
import { changeAlertStatus } from "../../alerts/alertsSlice";
import { ExpandibleComponent } from "../../hyperFlow/detail/ExpandibleComponent";
import { ExpandibleComponentList } from "../../hyperFlow/detail/ExpandibleComponentList";

const customStylesListDetail: Styles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1.2%",
    width: "40%",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0,0,0,0.3)",
  },
};
Modal.setAppElement("#root");
export const Registration = () => {
  let loading = useAppSelector(selectCoreIdLoading);
  let loadingImages = useAppSelector(selectRegistrationImagesLoading);
  let loadingAttatchments = useAppSelector(selectAttatchmentsLoadingStatus);
  let downloadingAttatchment = useAppSelector(
    selectAttatchmentDownloadingStatus,
  );
  let currentRegistration = useAppSelector(selectCurrentRegistration);
  let currentAttatchment = useAppSelector(selectCurrentAttatchment);
  let registrationAlerts = useAppSelector(selectRegistrationAlerts);
  let registrationAlertsLoading = useAppSelector(
    selectRegistrationAlertsLoading,
  );

  let loadingZip = useAppSelector(selectDownloadZipLoading);

  const downloadingCertificate = useAppSelector(selectDownloadingCertificate);

  let metaClientId = useAppSelector(selectMetaClientId);
  let clientId = useAppSelector(selectClientId);

  // Tag stuff
  let currentEntity = useAppSelector(selectCurrentEntity);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let allTags = useAppSelector(selectAllTags);
  let currentEntityTags = useAppSelector(selectCurrentTagsByEntity);
  let loadingEntityTags = useAppSelector(selectLoadingCurrentTagsByEntity);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState(false);
  let roles = useAppSelector(selectRoles);

  const downloadingAttatchmentZip = useAppSelector(selectLoadingAttachmentZip);
  const attachmentToVisualize = useAppSelector(selectAttachmentToVisualize);

  const [customStylesModal, setCustomStylesModal] = useState<Styles>({
    content: {
      top: "50%",
      left: "58%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(255,255,255,1)",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "1.2%",
      width: "50%",
      overflow: "auto",
    },
    overlay: {
      background: "rgba(0,0,0,0.5)",
    },
  });

  const getAttachmentIds = (attachments: Array<Attatchment>) => {
    let ids: Array<string> = [];
    attachments.forEach((att) => {
      if (ids.includes(att.filledFormId)) return;
      ids.push(att.filledFormId);
    });
    return ids;
  };

  const getAttachmentRender = (mimeType: string) => {
    switch (mimeType) {
      case "application/pdf":
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width="100%"
            height="100%"
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
      case "image/png":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );
      case "image/jpeg":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "image/jpg":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "image/gif":
        return (
          <img src={attachmentToVisualize.url} width="100%" height="auto" />
        );

      case "video/mp4":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/mp4" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/ogg":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/mpeg":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/webm":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      case "video/x-msvideo":
        return (
          <video width="100%" height="auto" controls>
            <source src={attachmentToVisualize.url} type="video/ogg" />
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </video>
        );
      default:
        return (
          <object
            data={attachmentToVisualize.url}
            type={attachmentToVisualize.mimeType}
            width="100%"
            height="100%"
          >
            <p>
              Tu navegador no permite visualizar este tipo de documentos puedes
              descargar el documento aquí
              <a
                onClick={() => {
                  fetch(attachmentToVisualize.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                      fileDownload(
                        blob,
                        attachmentToVisualize.fileName,
                        attachmentToVisualize.mimeType,
                      );
                    });
                }}
              >
                Descargar
              </a>
            </p>
          </object>
        );
    }
  };

  const getCallToAction = (alert: UnifiedAlerts) => {
    if (
      clientId !== "638ff0eb7c1dfb733a0183d0" &&
      metaClientId !== "638ff0eb7c1dfb733a0183d0"
    )
      return <></>;
    if (alert.category === "COMPLIANCE") {
      return (
        <>
          <p
            style={{
              fontSize: "10px",
              fontWeight: 600,
              color: "#F7CD00",
              width: "60%",
              margin: "0 auto 6px auto",
            }}
          >
            Debido al tipo de alerta, recomendamos que te comuniques con PPU a
            través de correo electrónico.
          </p>
          <a href="mailto:" style={{ textDecoration: "none" }}>
            <button
              style={{
                color: "#fff",
                backgroundColor: "rgba(0,0,0,0.2)",
                border: "none",
                borderRadius: "8px",
                alignItems: "center",
                justifyContent: "center",
                padding: "4px 10px",
                fontSize: "12px",
                fontWeight: 700,
                marginBottom: "10px",
              }}
            >
              Enviar correo
            </button>
          </a>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getServiceNameAlerts = (service: string) => {
    switch (service) {
      case "COREID":
        return "COREID";
      case "MAGICFORMS":
        return "MAGIC FORMS";
      case "FIRMA":
        return "FIRMA";
      case "HYPERFLOW":
        return "HYPERFLOW";
      default:
        return "";
    }
  };

  const getStatusNameAlerts = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "Activa";
      case "CLOSED":
        return "Cerrada";
      case "ACK":
        return "Revisada";
      default:
        return "Abierto";
    }
  };

  let getCategoryNameAlerts = (category: string) => {
    switch (category) {
      case "COMPLIANCE":
        return "COMPLIANCE";
      case "INCONGRUENCE":
        return "INCONGRUENCIAS";
      case "DATE":
        return "FECHAS";
      case "ATTACHMENT":
        return "ADJUNTOS";
      case "AUTHENTICITY":
        return "AUTENTICIDAD";
      default:
        return "";
    }
  };

  const getCriticidadAlertsRender = (criticidad: string) => {
    switch (criticidad) {
      case "HIGH":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Alta
            </h5>
            <AlertDot levelAlert="HIGH" />
          </div>
        );
      case "MEDIUM":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Media
            </h5>
            <AlertDot levelAlert="MEDIUM" />
          </div>
        );
      case "LOW":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );
      case "OTHER":
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Baja
            </h5>
            <AlertDot levelAlert="LOW" />
          </div>
        );

      default:
        return (
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontSize: "11px",
                fontWeight: 600,
                color: "white",
                margin: 0,
              }}
            >
              Otra
            </h5>
            <AlertDot levelAlert="OTHER" />
          </div>
        );
    }
  };

  const renderAlerts = () => {
    if (registrationAlertsLoading == "pending") {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <LoadingOverlay
            active={true}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        </div>
      );
    } else {
      return registrationAlerts &&
        registrationAlerts.alerts &&
        registrationAlerts.alerts.length > 0 ? (
        <div
          className="flex-col"
          style={{ width: "100%", height: "100%", padding: "0 0 15px 0" }}
        >
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "start",
              height: "60%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "95%", overflow: "auto", height: "100%" }}
            >
              <table style={{ borderCollapse: "collapse" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Servicio</th>
                    <th>Fecha y Hora</th>
                    <th>Categoría</th>
                    <th>Nombre</th>
                    <th>Criticidad</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {registrationAlerts.alerts.map((alert, i) => {
                    return (
                      <tr
                        key={i}
                        className="list-hover"
                        style={{ height: "30px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentAlert(alert);
                        }}
                      >
                        <td></td>
                        <td style={{ textTransform: "uppercase" }}>
                          {getServiceNameAlerts(alert.service)}
                        </td>
                        <td>{formatDateStandar(alert.date)}</td>
                        <td style={{ textTransform: "uppercase" }}>
                          {getCategoryNameAlerts(alert.category)}
                        </td>
                        <td>{`${currentRegistration?.name} ${currentRegistration?.lastName}`}</td>
                        <td>{getCriticidadAlertsRender(alert.criticality)}</td>
                        <td>{getStatusNameAlerts(alert.status)}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              marginTop: "2%",
              height: "50%",
            }}
          >
            <div
              className="flex-col"
              style={{ width: "70%", alignItems: "center" }}
            >
              <div
                className="flex-row"
                style={{
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="text-normal text-small primary-color-40"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    Detalle de la alerta
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "rgba(0,0,0,0.2)",
                      justifyContent: "center",
                      alignItems: "center",
                      boxSizing: "border-box",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    {currentAlert.id !== "" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <AlertDot levelAlert={currentAlert.criticality} />
                          <h4
                            style={{
                              margin: "0 0 0 10px",
                              color: "#fff",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {currentAlert.name}
                          </h4>
                        </div>
                        <h4
                          style={{
                            margin: "10px 0 10px 0px",
                            color: "rgba(255,255,255,0.5)",
                            fontSize: "11px",
                            fontWeight: 700,
                          }}
                        >
                          Descripción de la alerta
                        </h4>
                        <p
                          style={{
                            fontSize: "13px",
                            fontWeight: 600,
                            color: "#fff",
                          }}
                        >
                          {currentAlert.description}
                        </p>
                        {getCallToAction(currentAlert)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                changeAlertStatus({
                                  id: currentAlert.id,
                                  status:
                                    currentAlert.status === "CLOSED"
                                      ? "ACTIVE"
                                      : "CLOSED",
                                }),
                              );
                              setCurrentAlert({
                                ...currentAlert,
                                status:
                                  currentAlert.status === "CLOSED"
                                    ? "OPEN"
                                    : "CLOSED",
                              });
                              dispatch(
                                getAlertsByRegistrationId(
                                  currentRegistration?.id!,
                                ),
                              );
                              setCurrentAlert({
                                category: "",
                                criticality: "",
                                date: "",
                                description: "",
                                id: "",
                                name: "",
                                service: "",
                                status: "",
                                userName: "",
                                entityId: "",
                                userId: "",
                              });
                            }}
                            style={{
                              color: "#fff",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              border: "none",
                              borderRadius: "8px",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "4px 10px",
                              fontSize: "12px",
                              fontWeight: 700,
                            }}
                          >
                            {currentAlert.status === "CLOSED"
                              ? "Activar alerta"
                              : "Cerrar alerta"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="text-normal primary-color-40"
                        style={{
                          fontSize: "13px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        SELECCIONE UNA ALERTA PARA VER SU DESCRIPCIÓN
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "80%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {registrationAlertsLoading === "idle"
              ? ""
              : "No existen alertas actualmente"}
          </h1>
        </div>
      );
    }
  };

  const getTagsRender = () => {
    if (loadingEntityTags == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            gridRowGap: "5px",
          }}
        >
          {currentEntityTags.map((tag) => {
            return (
              <div
                key={tag.id}
                className="flex-row"
                style={{
                  backgroundColor: "rgba(0,0,0,0.25)",
                  borderRadius: "8px",
                  padding: "3px 8px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "11px",
                    fontWeight: 600,
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  {tag.tag}
                </div>
                {roles.includes("tags.edit") && (
                  <DeleteTag
                    onClick={() => {
                      dispatch(
                        disassociateTag({
                          tagId: tag.id,
                          entityId: id!,
                          service: "COREID",
                        }),
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            );
          })}
          <div>
            <div
              className="flex-row"
              style={{
                backgroundColor: "rgba(0,0,0,0.25)",
                borderRadius: "8px",
                padding: "1px 8px",
                marginRight: "5px",
                marginLeft: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (roles.includes("tags.edit")) {
                  setAddTagWindow(!addTagWindow);
                }
              }}
            >
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: 600,
                  color: "#fff",
                  marginRight: "5px",
                }}
              >
                Etiquetas
              </div>

              {roles.includes("tags.edit") && (
                <AddTag
                  style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
                />
              )}
            </div>
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: id!,
                              service: "COREID",
                            }),
                          );
                        }}
                      />
                      <button
                        style={{
                          height: "28px",
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag === "" ? "hidden" : "visible",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag,
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: id!,
                                service: "COREID",
                                tag: null,
                              }),
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: id!,
                                  service: "COREID",
                                }),
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const hasIcon = (elements: string[]) => {
    if (
      currentRegistration &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation.active
    ) {
      let hasAlert = false;
      for (let element of elements) {
        let possibleAlert =
          currentRegistration.multiFactorMatrix.userDataConfirmation.alerts.find(
            (alert) => alert.tag.includes(element),
          );
        if (possibleAlert) {
          hasAlert = true;
        }
      }
      if (hasAlert) {
        return <Warning style={{ marginLeft: "10px", height: "20px" }} />;
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const valuesHasAlert = (elements: string[]): boolean => {
    if (
      currentRegistration &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation.active
    ) {
      let hasAlert = false;
      for (let element of elements) {
        let possibleAlert =
          currentRegistration.multiFactorMatrix.userDataConfirmation.alerts.find(
            (alert) => alert.tag.includes(element),
          );
        if (possibleAlert) {
          hasAlert = true;
        }
      }
      if (hasAlert) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getDocumentValuesAlert = (
    isUpperCase: boolean,
    elements: string[],
  ): React.CSSProperties | undefined => {
    if (
      currentRegistration &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation &&
      currentRegistration.multiFactorMatrix?.userDataConfirmation.active
    ) {
      let hasAlerts = false;
      for (let element of elements) {
        let possibleAlert =
          currentRegistration.multiFactorMatrix.userDataConfirmation.alerts.find(
            (alert) => alert.tag.includes(element),
          );
        if (possibleAlert) {
          hasAlerts = true;
        }
      }
      if (hasAlerts) {
        return {
          marginLeft: "10%",
          textTransform: isUpperCase ? "uppercase" : "none",
          alignItems: "center",
          color: "#C7FF4F",
          cursor: "pointer",
        };
      } else {
        if (isUpperCase) {
          return {
            marginLeft: "10%",
            textTransform: "uppercase",
            color: "#fff",
          };
        } else {
          return { marginLeft: "10%", color: "#fff" };
        }
      }
    } else {
      if (isUpperCase) {
        return { marginLeft: "10%", textTransform: "uppercase", color: "#fff" };
      } else {
        return { marginLeft: "10%", color: "#fff" };
      }
    }
  };

  const getStatusName = (reg: RegistrationDetail) => {
    let statusProgress = "En progreso: ";

    if (reg.abandoned) {
      statusProgress = "Abandonado: ";
    }

    switch (reg.status) {
      case "ACCEPTED":
        return "Aprobado";
      case "IN_PROGRESS":
        return "En progreso";
      case "REJECTED":
        return "Rechazado";
      case "REVISION":
        return "Por revisar";
      case "ACCEPTEDTERMS":
        return `${statusProgress} Aceptó terminos y condiciones`;
      case "INITIAL_DATA":
        return `${statusProgress} Datos basicos ingresados`;
      case "CAPTURING_DOC":
        return `${statusProgress} Captura de Documento`;
      case "CAPTURING_FACE":
        return `${statusProgress} Captura del rostro`;
      case "EMAIL_CONFIRMATION":
        return `${statusProgress} Confirmación de email`;
      case "PHONE_CONFIRMATION":
        return `${statusProgress} Confirmación de celular`;
      default:
        return "";
    }
  };

  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { id } = useParams();
  const [currentSection, setCurrentSection] = useState<
    | "General"
    | "Biometria"
    | "Document"
    | "Estado"
    | "Alerta"
    | "Adjuntos"
    | "Metadata"
    | "Listas"
    | "none"
  >("General");
  const [selectedDownload, setSelectedDownload] = useState(-1);

  const [currentAlert, setCurrentAlert] = useState<UnifiedAlerts>({
    date: "",
    description: "",
    id: "",
    entityId: "",
    service: "",
    category: "",
    userId: "",
    criticality: "",
    status: "",
    name: "",
    userName: "",
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  const [listDetailOpen, setListDetailOpen] = useState(false);

  const [listDetail, setListDetail] = useState<Array<string>>([]);

  const [popUpDetail, setPopUpDetail] = useState<
    "" | "Localization" | "Devices" | "Lists" | "alerts"
  >("");

  let getListasRestrictivas = (listInfo: Array<ExternalQueryResult>) => {
    let numLists = 0;
    let numAlerts = 0;
    let inRisk: {
      name: string;
      dato: string;
      risk: string;
      warning: string;
      hasDetails: boolean;
      details: Array<string>;
      nameMatch: boolean;
      documentMatch: boolean;
    }[] = [];
    for (const element of listInfo) {
      numAlerts += element.numResultsWithRisk;
      for (const listResult of element.listResults) {
        if (listResult.risk || listResult.warning) {
          inRisk.push({
            name: listResult.listName,
            dato: element.idConsultado,
            risk: listResult.risk ? "Si" : "No",
            warning: listResult.warning ? "Si" : "No",
            hasDetails: listResult.resultDetails.length > 0,
            details: listResult.resultDetails,
            documentMatch: listResult.documentMatch,
            nameMatch: listResult.nameMatch,
          });
        }
      }
    }
    for (const element of listInfo) {
      numLists += element.numConsultedLists;

      for (const listResult of element.listResults) {
        if (!listResult.risk && !listResult.warning) {
          inRisk.push({
            name: listResult.listName,
            dato: element.idConsultado,
            risk: listResult.risk ? "Si" : "No",
            warning: listResult.warning ? "Si" : "No",
            hasDetails: listResult.resultDetails.length > 0,
            details: listResult.resultDetails,
            documentMatch: listResult.documentMatch,
            nameMatch: listResult.nameMatch,
          });
        }
      }
    }
    return { numLists, numAlerts, inRisk };
  };

  let downloadAtatchment = (
    attatchment: Attatchment,
    selected: number,
    visualized?: boolean,
  ) => {
    setSelectedDownload(selected);
    dispatch(
      getAttatchmentData({
        filledFormId: attatchment.filledFormId,
        stitchTypeId: attatchment.stitchTypeId,
        visualized: visualized,
      }),
    );
  };

  let showAttatchments = (
    currentRegistration: RegistrationDetail,
    loadingStatus: LoadingStatus,
    downloadStatus: LoadingStatus,
  ) => {
    switch (loadingStatus) {
      case "pending":
        return (
          <LoadingOverlay
            active={loadingStatus === "pending"}
            spinner={<ClipLoader color="white" size="100px" />}
          />
        );

      case "rejected":
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No pudimos encontrar adjuntos
          </div>
        );
      case "resolved":
        if (
          !currentRegistration.attatchments ||
          currentRegistration.attatchments.length === 0
        ) {
          return (
            <div
              className="flex-col text-normal text-medium primary-color"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Este registro no tiene adjuntos
            </div>
          );
        } else {
          return (
            <div
              className="flex-col"
              style={{
                background: "rgba(0,0,0,0.2)",
                paddingTop: "10px",
                margin: "15px",
                borderRadius: "8px",
                boxSizing: "border-box",
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "5%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead
                  className="text-normal text-medium primary-color-35"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre adjunto</th>
                    <th>Nombre archivo</th>
                    <th>Descargar</th>
                    <th>Visualizar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className="text-normal text-x-small primary-color"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {currentRegistration.attatchments &&
                    currentRegistration.attatchments.map((attatchment, i) => {
                      return (
                        <tr key={i} style={{ height: "30px" }}>
                          <td></td>
                          <td style={{ textTransform: "uppercase" }}>
                            {attatchment.fieldName || ""}
                          </td>
                          <td>{attatchment.fileName || ""}</td>
                          <td>
                            {downloadStatus === "pending" &&
                            selectedDownload === i ? (
                              <LoadingOverlay
                                active={downloadStatus === "pending"}
                                spinner={
                                  <ClipLoader color="white" size="20px" />
                                }
                              />
                            ) : (
                              <DownloadIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAtatchment(attatchment, i);
                                }}
                              />
                            )}
                          </td>
                          <td>
                            {downloadStatus === "pending" &&
                            selectedDownload === i ? (
                              <LoadingOverlay
                                active={downloadStatus === "pending"}
                                spinner={
                                  <ClipLoader color="white" size="20px" />
                                }
                              />
                            ) : (
                              <EyeIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  downloadAtatchment(attatchment, i, true);
                                }}
                              />
                            )}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <button
                className="flex-col"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  borderRadius: "10px",
                  background: "rgba(0,0,0,0.4)",
                  border: "none",
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: 700,
                  marginTop: "20px",
                  padding: "10px 20px",
                  width: "fit-content",
                }}
                onClick={() => {
                  if (
                    currentRegistration.attatchments &&
                    currentRegistration.attatchments.length > 0
                  ) {
                    dispatch(
                      getAttachmentsZip({
                        filledFormIds: getAttachmentIds(
                          currentRegistration.attatchments,
                        ),
                        userId: currentRegistration.id!,
                      }),
                    );
                  }
                }}
              >
                {downloadingAttatchmentZip === "pending" ? (
                  <ClipLoader color="white" size="30px" />
                ) : (
                  "Descargar todos los adjuntos"
                )}
              </button>
            </div>
          );
        }
      default:
        return (
          <div
            className="flex-col text-normal text-medium primary-color"
            style={{
              background: "rgba(0,0,0,0.2)",
              paddingTop: "10px",
              margin: "15px",
              borderRadius: "8px",
              boxSizing: "border-box",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Adjuntos
          </div>
        );
    }
  };

  let getPopupDetail = (
    currentDetailedPopup: "" | "Localization" | "Devices" | "Lists" | "alerts",
    currentRegistration: RegistrationDetail,
  ) => {
    switch (currentDetailedPopup) {
      case "Localization":
        return (
          <>
            {currentRegistration.location ? (
              <div className="flex-col" style={{ justifyContent: "start" }}>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.address && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Dirección:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.address}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.locality && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Ciudad:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.locality}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.country && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          País:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.country}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.state && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Estado/Departamento:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          {currentRegistration.location.state}
                        </div>
                      </>
                    )}
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                  }}
                >
                  {currentRegistration.location &&
                    currentRegistration.location.adminArea && (
                      <>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        >
                          Barrio:
                        </div>
                        <div
                          className="text-normal text-medium"
                          style={{ color: "black" }}
                        ></div>
                      </>
                    )}
                </div>

                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(false);
                      setPopUpDetail("");
                    }}
                    className="flex-col text-medium text-bold primary-color"
                    style={{
                      background: "#1A617D",
                      width: "20%",
                      height: "30px",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    Cerrar
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-col">
                Usuario no permitió validar localización
              </div>
            )}
          </>
        );
      case "Lists":
        return (
          <div
            className="flex-col"
            style={{ justifyContent: "start", height: "40em" }}
          >
            <table style={{ borderCollapse: "collapse" }}>
              <thead
                className="text-normal text-medium"
                style={{
                  background: "rgba(0,0,0,0.2)",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th></th>
                  <th>Lista</th>
                  <th>Dato</th>
                  <th>Riesgo</th>
                  <th>
                    Coincidencia <br /> por documento
                  </th>
                  <th>
                    Coincidencia <br /> por nombre
                  </th>
                  <th>Detalles</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                className="text-normal text-small"
                style={{
                  textAlign: "center",
                }}
              >
                {getListasRestrictivas(
                  currentRegistration.externalQueryResults,
                ).inRisk.map((list, i) => {
                  return (
                    <tr
                      key={i}
                      className="list-hover"
                      style={{ height: "30px" }}
                    >
                      <td></td>
                      <td style={{ width: "40%" }}>{list.name}</td>
                      <td>{list.dato}</td>

                      <td>{list.risk}</td>
                      <td>{list.documentMatch ? "Sí" : "No"}</td>
                      <td>{list.nameMatch ? "Sí" : "No"}</td>
                      <td>
                        {list.hasDetails ? (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setListDetail(list.details);
                              setListDetailOpen(true);
                            }}
                            className="primary-color flex-col"
                            style={{
                              boxSizing: "border-box",
                              height: "20px",
                              background: "#1A617D",
                              borderRadius: "8px",
                              width: "50%",
                              cursor: "pointer",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <EyeIcon />
                          </div>
                        ) : (
                          "No"
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                  setPopUpDetail("");
                }}
                className="flex-col text-medium text-bold primary-color"
                style={{
                  background: "#1A617D",
                  width: "20%",
                  height: "30px",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        );
      case "Devices":
        return (
          <div className="flex-col" style={{ justifyContent: "start" }}>
            {currentRegistration.devices.map((device, j) => {
              return (
                <div key={j}>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Dirección IP:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.ip || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      No. CPUs:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.numCpus || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Altura de pantalla:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.screenHeigh
                        ? `${device.screenHeigh}px`
                        : "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Ancho de pantalla:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.screenWidth
                        ? `${device.screenWidth}px`
                        : "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      De confianza:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.trusted ? "Si" : "No"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Tipo acceso:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      {device.type || "No capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black" }}
                    >
                      Sistema Operativo:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "black", textAlign: "end" }}
                    >
                      {device.os || "No Capturado"}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(false);
                        setPopUpDetail("");
                      }}
                      className="flex-col text-medium text-bold primary-color"
                      style={{
                        background: "#1A617D",
                        width: "20%",
                        height: "30px",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Cerrar
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      case "alerts":
        return (
          <div
            className="flex-col text-medium text-bold"
            style={{
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              padding: "10px",
            }}
          >
            <h5
              style={{
                fontWeight: 700,
                color: "#000",
              }}
            >
              Datos corregidos
            </h5>
            <p style={{ fontWeight: 400 }}>
              Los siguientes datos han sido corregidos manualmente por el
              usuario
            </p>
            <div
              className="flex-row"
              style={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="flex-col">
                <h6>Capturados con OCR</h6>
                {valuesHasAlert(["firstName"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Nombre:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.firstName}
                    </div>
                  </>
                )}
                {valuesHasAlert(["lastName"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Apellido:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.lastName}
                    </div>
                  </>
                )}
                {valuesHasAlert(["documentNumber"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      No. de Documento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.documentNumber}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthDate"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Fecha de Nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.birthDate &&
                        currentRegistration.capturedUserDocument.birthDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthPlace"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Ciudad de nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.birthPlace &&
                        currentRegistration.capturedUserDocument.birthPlace.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthState"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Departamento de nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.birthState &&
                        currentRegistration.capturedUserDocument.birthState.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expeditionDate"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Fecha de expedición:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument
                        .expeditionDate &&
                        currentRegistration.capturedUserDocument.expeditionDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expiration"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Fecha de vencimiento
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument
                        .expirationDate &&
                        currentRegistration.capturedUserDocument.expirationDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expeditionCity"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Lugar de expedición:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument
                        .expeditionCity &&
                        currentRegistration.capturedUserDocument.expeditionCity.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["bloodType"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Tipo de sangre (RH)
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.bloodType &&
                        currentRegistration.capturedUserDocument.bloodType.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["gender"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Sexo
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.capturedUserDocument.gender &&
                        currentRegistration.capturedUserDocument.gender.toUpperCase()}
                    </div>
                  </>
                )}
              </div>
              <div className="flex-col">
                <h6>Corregidos por el usuario</h6>
                {valuesHasAlert(["firstName"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Nombre:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.firstName}
                    </div>
                  </>
                )}
                {valuesHasAlert(["lastName"]) && (
                  <>
                    {" "}
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Apellido:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.lastName}
                    </div>
                  </>
                )}
                {valuesHasAlert(["documentNumber"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      No. de Documento:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.documentNumber}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthDate"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Fecha de Nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.birthDate &&
                        currentRegistration.userDocument.birthDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthPlace"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Ciudad de nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.birthPlace &&
                        currentRegistration.userDocument.birthPlace.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["birthState"]) && (
                  <>
                    <div
                      className="text-normal text-medium"
                      style={{ color: "#000" }}
                    >
                      Departamento de nacimiento:
                    </div>
                    <div
                      className="text-normal text-medium"
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.birthState &&
                        currentRegistration.userDocument.birthState.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expeditionDate"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Fecha de expedición:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.expeditionDate &&
                        currentRegistration.userDocument.expeditionDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expiration"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Fecha de vencimiento
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.expirationDate &&
                        currentRegistration.userDocument.expirationDate.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["expeditionCity"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Lugar de expedición:
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.expeditionCity &&
                        currentRegistration.userDocument.expeditionCity.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["bloodType"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Tipo de sangre (RH)
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.bloodType &&
                        currentRegistration.userDocument.bloodType.toUpperCase()}
                    </div>
                  </>
                )}
                {valuesHasAlert(["gender"]) && (
                  <>
                    <div
                      className="text-normal text-medium "
                      style={{ color: "#000" }}
                    >
                      Sexo
                    </div>
                    <div
                      className="text-normal text-medium "
                      style={{
                        color: "#000",
                        fontWeight: 400,
                        margin: "0px 0px 10px 0px",
                      }}
                    >
                      {currentRegistration.userDocument.gender &&
                        currentRegistration.userDocument.gender.toUpperCase()}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
                setPopUpDetail("");
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
                marginTop: "20px",
              }}
            >
              Cerrar
            </div>
          </div>
        );

      default:
        return (
          <div
            className="flex-col text-medium text-bold"
            style={{
              alignItems: "center",
              justifyContent: "center",
              color: "black",
            }}
          >
            No has seleccionado ningun detalle
          </div>
        );
    }
  };

  const getDetailData = (value: string) => {
    return (
      <div
        className="flex-col"
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className="text-medium text-bold primary-color-50"
          style={{ width: "145px", textAlign: "center", fontSize: "11px" }}
        >
          Score
        </div>
        <div className="text-huge text-bold primary-color">{value}</div>
      </div>
    );
  };

  const getMatrixDetailData = (value: MultiFactorMatrixData, name: string) => {
    return (
      <div
        className="flex-col"
        style={{ alignItems: "center", alignSelf: "end" }}
      >
        <div
          className="text-medium text-bold primary-color-50"
          style={{ width: "90%", textAlign: "center", fontSize: "11px" }}
        >
          {name}
        </div>
        <div className="text-huge text-bold primary-color">{value.score}%</div>
        <div
          className="text-medium text-bold primary-color-50"
          style={{
            width: "80%",
            textAlign: "center",
            fontSize: "11px",
            wordWrap: "break-word",
          }}
        >
          Umbral de bloqueo: {value.blockingThreshold}%
        </div>
      </div>
    );
  };

  const getDescriptionFromMatrixData = (value: MultiFactorMatrixData) => {
    if (value.alerts.length > 0) {
      return value.alerts.join("| ");
    } else {
      return "";
    }
  };

  const getMatrixAlert = (active: boolean, value?: MultiFactorMatrixData) => {
    if (!active) {
      return false;
    }
    if (!value) {
      return false;
    }
    if (value.alerts.length > 0) {
      return true;
    }
    if (!value.passes) {
      return true;
    }
    return false;
  };

  const getMatrixStatus = (active: boolean, value?: MultiFactorMatrixData) => {
    if (!active) {
      return "Desactivado";
    }
    if (!value) {
      return "Desactivado";
    }
    if (value.passes) {
      return "Pasa umbral";
    } else {
      return "NO Pasa umbral";
    }
  };

  const getListRisk = (risk: boolean, warning: boolean) => {
    if (risk) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="HIGH" />
        </div>
      );
    } else if (warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            Sí
          </h5>
          <AlertDot levelAlert="MEDIUM" />
        </div>
      );
    } else if (!risk && !warning) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5 style={{ fontSize: "14px", fontWeight: 700, color: "white" }}>
            No
          </h5>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h5
            style={{
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              margin: "0 5px 0 0",
            }}
          >
            No
          </h5>
          <AlertDot levelAlert="NONE" />
        </div>
      );
    }
  };

  const getListasSection = () => {
    let listas = currentRegistration?.externalQueryResults;
    let renderListas: ListsRenderResult = {
      lists: [],
      numConsultedLists: 0,
      numResultsWithRisk: 0,
      numResultsWithWarning: 0,
    };
    if (listas) {
      for (let listaItem of listas) {
        for (let list of listaItem.listResults) {
          if (list.risk) {
            renderListas.numResultsWithRisk++;
          }
          if (list.warning) {
            renderListas.numResultsWithWarning++;
          }
          if (list.risk || list.warning) {
            renderListas.lists.push(list);
          }
        }
      }
      for (let listaItem of listas) {
        for (let list of listaItem.listResults) {
          renderListas.numConsultedLists++;

          if (!list.risk && !list.warning) {
            renderListas.lists.push(list);
          }
        }
      }
    }
    if (
      currentRegistration?.multiFactorMatrix?.lists?.active === false ||
      !listas
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <h5 style={{ color: "white", fontSize: "18px", fontWeight: 700 }}>
            No se encontraron resultados
          </h5>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "100%",
          justifyContent: "start",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: "14px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: "white", fontSize: "13px", fontWeight: 700 }}>
              Listas consultadas
            </h5>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: 700,
                  margin: "0 5px 0 0 ",
                }}
              >
                {renderListas.numConsultedLists}
              </h5>
              {renderListas.numConsultedLists > 0 && (
                <AlertDot levelAlert="NONE" />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: "white", fontSize: "13px", fontWeight: 700 }}>
              Listas con riesgo
            </h5>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: 700,
                  margin: "0 5px 0 0 ",
                }}
              >
                {renderListas.numResultsWithRisk}
              </h5>
              {renderListas.numResultsWithRisk > 0 && (
                <AlertDot levelAlert="HIGH" />
              )}
            </div>
          </div>
        </div>

        <div
          className="flex-col"
          style={{
            width: "100%",
            overflow: "auto",
            height: "70%",
            marginBottom: "14px",
          }}
        >
          <table style={{ borderCollapse: "collapse" }}>
            <thead
              className="text-normal text-medium primary-color-35"
              style={{
                background: "rgba(0,0,0,0.4)",
                height: "30px",
                textAlign: "center",
              }}
            >
              <tr>
                <th></th>
                <th>Nombre lista</th>
                <th>Tiene riesgo</th>
                <th>Coincidencia por documento</th>
                <th>Coincidencia por nombre</th>
                <th>Detalle</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              className="text-normal text-x-small primary-color"
              style={{
                textAlign: "center",
              }}
            >
              <tr>
                <td></td>
                <td style={{ width: "40%" }}></td>
                <td></td> <td></td>
                <td></td> <td></td>
                <td></td>
                <td></td>
              </tr>
              {renderListas.lists.map((list, i) => {
                return (
                  <tr key={i} className="list-hover" style={{ height: "30px" }}>
                    <td></td>
                    <td>{list.listName}</td>
                    <td>{getListRisk(list.risk, false)}</td>
                    <td>{list.documentMatch ? "Sí" : "No"}</td>
                    <td>{list.nameMatch ? "Sí" : "No"}</td>
                    <td>
                      {list.resultDetails.length > 0 ? (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setListDetail(list.resultDetails);
                            setListDetailOpen(true);
                          }}
                          style={{
                            color: "#fff",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            border: "none",
                            borderRadius: "8px",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px 10px",
                            fontSize: "12px",
                            fontWeight: 700,
                          }}
                        >
                          Ver detalle
                        </button>
                      ) : (
                        <>No tiene</>
                      )}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if ((!currentRegistration || currentRegistration.id !== id) && id !== "") {
      dispatch(getRegistrationById(id || ""));
    }
    if (currentAttatchment.fileBase64 !== "") {
      let url = `data:application/pdf;base64,${currentAttatchment.fileBase64}`;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, currentAttatchment.fileName);
        });
      dispatch(emptyCurrentAttatchment());
    }

    if (currentRegistration && loadingImages === "idle") {
      dispatch(getImagesRegistration(currentRegistration.id));
    }

    if (currentRegistration && loadingAttatchments === "idle") {
      dispatch(getAttatchments(currentRegistration.id));
    }
    if (currentRegistration && registrationAlertsLoading === "idle") {
      dispatch(getAlertsByRegistrationId(currentRegistration.id));
    }

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentEntity !== id && id) {
      dispatch(getCurrentTagsByEntity({ entityId: id, service: "COREID" }));
    }

    dispatch(changeActiveComponent("RegistrationDetail"));
  }, [currentRegistration, id, currentAttatchment, dispatch]);

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => {
          setIsOpen(false);
          setPopUpDetail("");
        }}
        style={customStylesModal}
        contentLabel="Detalle"
        shouldCloseOnOverlayClick={true}
      >
        {getPopupDetail(popUpDetail, currentRegistration!)}
      </Modal>
      <Modal
        isOpen={listDetailOpen}
        style={customStylesListDetail}
        onRequestClose={() => {
          setListDetailOpen(false);
          setListDetail([]);
        }}
      >
        <div className="flex-col">
          <div className="text-large text-bold" style={{ textAlign: "center" }}>
            Detalle de la lista
          </div>
          {listDetail.map((detail, i) => {
            return (
              <div
                key={i}
                className="text-small text-normal"
                style={{
                  borderBottom: "0.5px solid rgba(0,0,0,0.2)",
                }}
              >
                {detail}
              </div>
            );
          })}
          <div
            className="flex-row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                setListDetailOpen(false);
                setListDetail([]);
              }}
              className="flex-col text-medium text-bold primary-color"
              style={{
                background: "#1A617D",
                width: "20%",
                height: "30px",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Modal>
      {currentRegistration && loading === "resolved" ? (
        <div
          className="flex-col"
          style={{
            width: "97%",
            height: "100%",
            boxSizing: "border-box",
            paddingLeft: "1%",
          }}
        >
          <div
            className="flex-row"
            style={{
              height: "12%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div
                className="flex-row"
                style={{
                  alignContent: "center",
                  height: "28px",
                }}
              ></div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="flex-row"
                style={{
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <LeftArrow style={{ marginRight: "15px" }} />
                <div className="text-bold text-xx-large primary-color">
                  Regresar a lista de verificaciones
                </div>
              </div>
            </div>
          </div>
          <ExpandibleContent
            title="General"
            isActive={currentSection === "General"}
            onClickFunction={() => {
              setCurrentSection("General");
            }}
            content={
              <div
                className="flex-col"
                style={{
                  height: "100%",
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "start",
                }}
              >
                <div
                  className="flex-row"
                  style={{
                    height: "15%",
                    width: "100%",
                    alignItems: "start",
                    justifyContent: "start",
                    marginBottom: "5%",
                  }}
                >
                  <TitleSubtitleBox
                    title="Estado de Registro"
                    styles={{ marginRight: "1%" }}
                    content={getStatusName(currentRegistration)}
                  />
                  <TitleSubtitleBox
                    title="Día de registro"
                    styles={{ marginRight: "1%" }}
                    content={formatDateStandar(
                      currentRegistration.startDatetime,
                    )}
                  />
                  <TitleSubtitleBox
                    title="Alertas"
                    styles={{ marginRight: "1%" }}
                    content={
                      registrationAlertsLoading === "idle"
                        ? "Esperando"
                        : registrationAlerts &&
                          registrationAlerts.alerts.length > 0
                        ? registrationAlerts.alerts.length
                        : "Ninguna"
                    }
                  />
                  {roles.includes("tags.view") && getTagsRender()}
                </div>
                <div
                  className="flex-row"
                  style={{
                    height: "60%",
                    width: "100%",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      width: "40%",
                      height: "100%",
                      marginRight: "3%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-col" style={{ width: "100%" }}>
                      <div
                        className="flex-col"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "29px",
                          textAlignLast: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          boxSizing: "border-box",
                        }}
                      >
                        <div className="text-normal text-medium primary-color-35">
                          Datos del usuario
                        </div>
                      </div>
                      <div
                        className="flex-col"
                        style={{
                          textAlign: "center",
                          color: "white",
                          background: "rgba(0,0,0,0.2)",
                          justifyContent: "center",
                          alignItems: "start",
                          boxSizing: "border-box",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="text-normal text-medium primary-color">
                            Nombre:
                          </div>
                          <div className="text-normal text-medium primary-color">
                            {currentRegistration.name}
                          </div>
                        </div>
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="text-normal text-medium primary-color">
                            Apellido:
                          </div>
                          <div className="text-normal text-medium primary-color">
                            {currentRegistration.lastName}
                          </div>
                        </div>
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="text-normal text-medium primary-color">
                            ID de registro:
                          </div>
                          <div className="text-normal text-medium primary-color">
                            {currentRegistration.id}
                          </div>
                        </div>
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="text-normal text-medium primary-color">
                            E-mail:
                          </div>
                          <div className="text-normal text-medium primary-color">
                            {currentRegistration.email}
                          </div>
                        </div>
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="text-normal text-medium primary-color">
                            No. de celular:
                          </div>
                          <div className="text-normal text-medium primary-color">
                            {currentRegistration.phone}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="text-medium text-bold primary-color flex-row"
                        style={{
                          marginTop: "4%",
                          marginRight: "2%",
                          marginLeft: "1%",
                          background: "rgba(0,0,0,0.4)",
                          paddingRight: "20px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "20px",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          width: "200px",
                          border: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            downloadImagesZip(currentRegistration?.id!!),
                          );
                        }}
                      >
                        {loadingZip === "pending" ? (
                          <ClipLoader color="white" size="25px" />
                        ) : (
                          <>
                            Descargar Imagenes{" "}
                            <DownloadIcon2 style={{ marginLeft: "5px" }} />
                          </>
                        )}
                      </button>
                      {/* {currentRegistration.result && (
                        <button
                          className="text-medium text-bold primary-color flex-row"
                          style={{
                            marginTop: "4%",
                            marginRight: "2%",
                            marginLeft: "1%",
                            marginBottom: "4%",
                            background: "rgba(0,0,0,0.4)",
                            paddingRight: "20px",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "20px",
                            borderRadius: "8px",
                            textAlign: "center",
                            cursor: "pointer",
                            width: "200px",
                            border: "none",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              generateCertificate(currentRegistration?.id!!),
                            );
                          }}
                        >
                          {downloadingCertificate === "pending" ? (
                            <ClipLoader color="white" size="25px" />
                          ) : (
                            <>
                              Descargar Certificado de ID
                              <DownloadIcon2 style={{ marginLeft: "5px" }} />
                            </>
                          )}
                        </button>
                      )} */}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      width: "40%",
                      height: "100%",
                      marginRight: "5%",
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        background: "rgba(0,0,0,0.4)",
                        height: "29px",
                        textAlignLast: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                      }}
                    >
                      <div className="text-normal text-medium primary-color-35">
                        Imagenes prueba
                      </div>
                    </div>
                    <Generalmages
                      currentRegistration={currentRegistration}
                      loadingImages={loadingImages}
                    />
                  </div>
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Biometría"
            isActive={currentSection === "Biometria"}
            onClickFunction={() => {
              setCurrentSection("Biometria");
            }}
            content={
              <div
                className="flex-row"
                style={{
                  height: "100%",
                  justifyContent: "start",
                  alignItems: "start",
                }}
              >
                <div
                  className="flex-col"
                  style={{
                    alignItems: "start",
                    justifyContent: "center",
                    marginRight: "10%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "29px",
                      width: "282px",
                      textAlignLast: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Imagenes prueba
                    </div>
                  </div>
                  <BiometryImages
                    currentRegistration={currentRegistration}
                    loadingImages={loadingImages}
                  />
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "space-evenly",
                    width: "30%",
                    marginTop: "10%",
                    height: "40%",
                  }}
                >
                  <ExpandibleComponent
                    alert={getMatrixAlert(
                      currentRegistration.unifiedChecks.facematchCheck.active,
                      currentRegistration.multiFactorMatrix?.faceMatch,
                    )}
                    description={
                      currentRegistration.unifiedChecks.facematchCheck
                        .description
                        ? currentRegistration.unifiedChecks.facematchCheck
                            .description
                        : ""
                    }
                    isActive={false}
                    detail={""}
                    title="Facematch"
                    content={
                      <div
                        className="flex-row"
                        style={{ padding: "0 0 0 5px", alignItems: "center" }}
                      >
                        <div
                          className="flex-col primary-color-50 pdd-left"
                          style={{
                            textAlign: "center",
                            justifySelf: "center",
                            padding: "1%",
                            fontSize: "11px",
                            fontWeight: "bold",
                          }}
                        >
                          {`Umbral de Bloqueo: ${currentRegistration.multiFactorMatrix?.faceMatch.blockingThreshold}%`}
                        </div>
                        {getDetailData(
                          currentRegistration.unifiedChecks.facematchCheck
                            .active
                            ? `${currentRegistration.faceMatchPercent.toFixed(
                                1,
                              )}%`
                            : "Desactivado",
                        )}
                      </div>
                    }
                  />
                  <ExpandibleComponent
                    alert={getMatrixAlert(
                      currentRegistration.multiFactorMatrix?.liveness.active ||
                        false,
                      currentRegistration.multiFactorMatrix?.liveness,
                    )}
                    description={
                      (currentRegistration.multiFactorMatrix?.liveness.active &&
                        currentRegistration.multiFactorMatrix?.liveness.alerts
                          .length > 0 &&
                        currentRegistration.multiFactorMatrix?.liveness.alerts.join(
                          "| ",
                        )) ||
                      ""
                    }
                    isActive={false}
                    detail={""}
                    title="Liveness"
                    content={
                      <div
                        className="flex-row"
                        style={{ padding: "0 0 0 5px", alignItems: "center" }}
                      >
                        <div
                          className="flex-col primary-color-50 pdd-left"
                          style={{
                            textAlign: "center",
                            justifySelf: "center",
                            padding: "1%",
                            fontSize: "11px",
                            fontWeight: "bold",
                          }}
                        >
                          {`Umbral de Bloqueo: ${currentRegistration.multiFactorMatrix?.liveness.blockingThreshold}%`}
                        </div>
                        {getDetailData(
                          currentRegistration.multiFactorMatrix?.liveness.active
                            ? `${currentRegistration.multiFactorMatrix?.liveness.score.toFixed(
                                1,
                              )}%`
                            : "Desactivado",
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Documento"
            isActive={currentSection === "Document"}
            onClickFunction={() => {
              setCurrentSection("Document");
            }}
            content={
              <div
                className="flex-row"
                style={{ height: "100%", justifyContent: "space-evenly" }}
              >
                <div
                  className="flex-col"
                  style={{
                    alignItems: "start",
                    justifyContent: "start",
                    marginRight: "5%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "29px",
                      width: "300px",
                      textAlignLast: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      borderRadius: "8px 8px 0px 0px",
                    }}
                  >
                    <div className="text-normal text-medium primary-color-35">
                      Imagenes prueba
                    </div>
                  </div>
                  <DocumentImages
                    currentRegistration={currentRegistration}
                    loadingImages={loadingImages}
                  />
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "start",
                    width: "20%",
                    height: "100%",
                    overflow: "auto",
                    boxSizing: "border-box",
                    padding: "0 2% 0 0 ",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !getWholeName(currentRegistration.userDocument) ||
                        getWholeName(
                          currentRegistration.userDocument,
                        ).trim() === ""
                          ? "unset"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Nombre y Apellido
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["firstName", "lastName"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className={"text-medium text-normal flex-row"}
                      style={getDocumentValuesAlert(false, [
                        "firstName",
                        "lastName",
                      ])}
                    >
                      {currentRegistration.userDocument &&
                        getWholeName(currentRegistration.userDocument)}{" "}
                      {hasIcon(["firstName", "lastName"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.documentNumber ||
                        currentRegistration.userDocument.documentNumber === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Número de documento
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["documentNumber"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal flex-row"
                      style={getDocumentValuesAlert(false, ["documentNumber"])}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.documentNumber}{" "}
                      {hasIcon(["documentNumber"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.birthDate ||
                        currentRegistration.userDocument.birthDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha de nacimiento
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["birthDate"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal "
                      style={getDocumentValuesAlert(false, ["birthDate"])}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.birthDate}{" "}
                      {hasIcon(["birthDate"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.birthPlace ||
                        currentRegistration.userDocument.birthPlace === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Lugar de Nacimiento
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["birthPlace", "birthState"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal flex-row"
                      style={getDocumentValuesAlert(true, [
                        "birthPlace",
                        "birthState",
                      ])}
                    >
                      {currentRegistration.userDocument &&
                      currentRegistration.userDocument.birthPlace
                        ? currentRegistration.userDocument.birthPlace
                        : ""}
                      {currentRegistration.userDocument &&
                      currentRegistration.userDocument.birthState
                        ? `, ${currentRegistration.userDocument.birthState}`
                        : ""}
                      {hasIcon(["birthPlace", "birthState"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expeditionDate ||
                        currentRegistration.userDocument.expeditionDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha de expedición
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["expeditionDate"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal  flex-row"
                      style={getDocumentValuesAlert(true, ["expeditionDate"])}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.expeditionDate}{" "}
                      {hasIcon(["expeditionDate"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expirationDate ||
                        currentRegistration.userDocument.expirationDate === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Fecha de expiración
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["expirationDate"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal"
                      style={getDocumentValuesAlert(true, ["expirationDate"])}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.expirationDate}{" "}
                      {hasIcon(["expirationDate"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.expeditionCity ||
                        currentRegistration.userDocument.expeditionCity === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35 ">
                      Lugar de expedición
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["expeditionCity"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal flex-row"
                      style={getDocumentValuesAlert(true, ["expeditionCity"])}
                    >
                      {`${
                        (currentRegistration.userDocument &&
                          currentRegistration.userDocument.expeditionCity) ||
                        "No Encontrado"
                      } ${
                        currentRegistration.userDocument &&
                        currentRegistration.userDocument.country
                      }`}{" "}
                      {hasIcon(["expeditionCity"])}
                    </div>
                  </div>

                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",
                      marginBottom: "10%",
                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.gender ||
                        currentRegistration.userDocument.gender === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Sexo
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["gender"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal "
                      style={getDocumentValuesAlert(true, ["gender"])}
                    >
                      {(currentRegistration.userDocument &&
                        currentRegistration.userDocument.gender) ||
                        "No Encontrado"}{" "}
                      {hasIcon(["gender"])}
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{
                      justifyContent: "space-evenly",

                      visibility:
                        !currentRegistration.userDocument ||
                        !currentRegistration.userDocument.bloodType ||
                        currentRegistration.userDocument.bloodType === ""
                          ? "collapse"
                          : "visible",
                    }}
                  >
                    <div className="text-medium text-normal primary-color-35">
                      Tipo de sangre (RH)
                    </div>
                    <div
                      onClick={() => {
                        if (valuesHasAlert(["bloodType"])) {
                          setPopUpDetail("alerts");
                          setIsOpen(true);
                        }
                      }}
                      className="text-medium text-normal"
                      style={getDocumentValuesAlert(true, ["bloodType"])}
                    >
                      {currentRegistration.userDocument &&
                        currentRegistration.userDocument.bloodType}{" "}
                      {hasIcon(["bloodType"])}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    justifyContent: "center",
                    height: "100%",
                    width: "40%",
                  }}
                >
                  {currentRegistration.unifiedChecks.documentValidityCheck
                    .active ? (
                    <ExpandibleComponent
                      alert={getMatrixAlert(
                        currentRegistration.unifiedChecks.documentValidityCheck
                          .active,
                        currentRegistration.multiFactorMatrix?.validity,
                      )}
                      description={
                        currentRegistration.unifiedChecks.documentValidityCheck
                          .description
                          ? currentRegistration.unifiedChecks.documentValidityCheck.description.split(
                              "Screen",
                            )[0]
                          : ""
                      }
                      isActive={false}
                      detail={""}
                      title="Validez-Autenticidad de documento"
                      content={
                        <div className="flex-row">
                          <div
                            className="flex-col primary-color-35 pdd-left"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            {`Umbral de Bloqueo: ${currentRegistration.multiFactorMatrix?.validity.blockingThreshold}%`}
                          </div>
                          {getDetailData(
                            currentRegistration.unifiedChecks
                              .documentValidityCheck.active
                              ? currentRegistration.multiFactorMatrix
                                ? `${currentRegistration.multiFactorMatrix.validity.score.toFixed(
                                    1,
                                  )}%`
                                : ""
                              : "Desactivado",
                          )}
                        </div>
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {currentRegistration.multiFactorMatrix &&
                    currentRegistration.multiFactorMatrix.screen.active && (
                      <ExpandibleComponent
                        alert={
                          getMatrixAlert(
                            currentRegistration.multiFactorMatrix.screen.active,
                            currentRegistration.multiFactorMatrix.screen,
                          ) ||
                          getMatrixAlert(
                            currentRegistration.multiFactorMatrix.impresion
                              .active,
                            currentRegistration.multiFactorMatrix.impresion,
                          )
                        }
                        description={`${getDescriptionFromMatrixData(
                          currentRegistration.multiFactorMatrix.screen,
                        )}  ${getDescriptionFromMatrixData(
                          currentRegistration.multiFactorMatrix.impresion,
                        )}`}
                        isActive={false}
                        detail={""}
                        title="Captura de documento"
                        content={
                          <div
                            className="flex-row"
                            style={{
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            {getMatrixDetailData(
                              currentRegistration.multiFactorMatrix.screen,
                              "Detección pantalla",
                            )}

                            {getMatrixDetailData(
                              currentRegistration.multiFactorMatrix.impresion,
                              "Detección impresión",
                            )}
                          </div>
                        }
                      />
                    )}
                  {currentRegistration.multiFactorMatrix &&
                  currentRegistration.multiFactorMatrix.veracity ? (
                    <ExpandibleComponentList
                      alert={
                        getMatrixAlert(
                          currentRegistration.unifiedChecks
                            .governmentDataMatchCheck.active,
                          currentRegistration.multiFactorMatrix?.govEntity,
                        ) ||
                        getMatrixAlert(
                          currentRegistration.unifiedChecks
                            .governmentDataMatchCheck.active,
                          currentRegistration.multiFactorMatrix?.veracity,
                        )
                      }
                      alerts={
                        currentRegistration.multiFactorMatrix
                          ? currentRegistration.multiFactorMatrix?.veracity.alerts.concat(
                              currentRegistration.multiFactorMatrix?.govEntity
                                .alerts,
                            )
                          : []
                      }
                      isActive={false}
                      title="Match Gubernamental"
                      content={
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          {getMatrixDetailData(
                            currentRegistration.multiFactorMatrix.veracity,
                            "Coincidencia de datos",
                          )}

                          {getMatrixDetailData(
                            currentRegistration.multiFactorMatrix.govEntity,
                            "Alertas gubernamentales",
                          )}
                        </div>
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Estado del registro"
            isActive={currentSection === "Estado"}
            onClickFunction={() => {
              setCurrentSection("Estado");
            }}
            content={
              <div
                className="flex-col"
                style={{ height: "100%", width: "100%" }}
              >
                <div className="flex-col" style={{ width: "40%" }}>
                  <div
                    className="flex-col primary-color"
                    style={{
                      borderRadius: "8px",
                      background: "rgba(0,0,0,0.3)",
                      padding: "3% 1% 3% 1%",
                      marginLeft: "10px",
                      marginTop: "10%",
                    }}
                  >
                    {currentRegistration.multiFactorMatrix?.minScore && (
                      <div className="flex-row" style={{ paddingLeft: "20px" }}>
                        {`Umbral: ${currentRegistration.multiFactorMatrix?.minScore}%`}
                      </div>
                    )}
                    <div className="flex-row" style={{ paddingLeft: "20px" }}>
                      {`Score Total Ponderado: ${currentRegistration.multiFactorMatrix?.finalScore.toFixed(
                        1,
                      )}%`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      padding: "20px",
                      justifyContent: "space-evenly",
                      width: "40%",
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        OTP
                      </div>
                      <div className="flex-row">
                        <div
                          className="text-medium text-normal primary-color-50"
                          style={{ marginRight: "5px" }}
                        >
                          {!currentRegistration.unifiedChecks.otpCheck.active
                            ? "Desactivado"
                            : currentRegistration.unifiedChecks.otpCheck.result
                            ? "Verificado"
                            : "No Verificado"}
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Correo electrónico
                      </div>
                      <div className="flex-row">
                        <div
                          className="text-medium text-normal primary-color-50"
                          style={{ marginRight: "5px" }}
                        >
                          {!currentRegistration.unifiedChecks.emailCheck.active
                            ? "Desactivado"
                            : currentRegistration.unifiedChecks.emailCheck
                                .result
                            ? "Verificado"
                            : "No Verificado"}
                        </div>
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Localización / GPS
                      </div>
                      <div className="flex-row">
                        <div
                          className="text-medium text-normal primary-color-50"
                          style={{ marginRight: "5px" }}
                        >
                          {getMatrixStatus(
                            currentRegistration.unifiedChecks.gpsCheck.active,
                            currentRegistration.multiFactorMatrix?.geolocation,
                          )}
                        </div>

                        {currentRegistration.unifiedChecks.gpsCheck.active && (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setPopUpDetail("Localization");
                              setIsOpen(true);
                            }}
                            className="flex-col text-medium text-bold primary-color-50"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              width: "90px",
                              height: "25px",
                              textAlign: "center",
                              borderRadius: "8px",
                              background: "rgba(0,0,0,0.5)",
                              cursor: "pointer",
                            }}
                          >
                            Ver más
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Device ID /Dispositivos
                      </div>
                      <div className="flex-row">
                        <div
                          className="text-medium text-normal primary-color-50"
                          style={{ marginRight: "5px" }}
                        >
                          {getMatrixStatus(
                            currentRegistration.unifiedChecks.deviceIdCheck
                              .active,
                            currentRegistration.multiFactorMatrix?.deviceId,
                          )}
                        </div>
                        {currentRegistration.unifiedChecks.deviceIdCheck
                          .active && (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setPopUpDetail("Devices");
                              setIsOpen(true);
                            }}
                            className="flex-col text-medium text-bold primary-color-50"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              width: "90px",
                              height: "25px",
                              textAlign: "center",
                              borderRadius: "8px",
                              background: "rgba(0,0,0,0.5)",
                              cursor: "pointer",
                            }}
                          >
                            Ver más
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex-row"
                      style={{
                        borderBottom: "1px solid rgba(255,255,255,0.3)",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-medium text-normal primary-color-35">
                        Listas restrictivas
                      </div>
                      <div className="flex-row">
                        <div
                          className="text-medium text-normal primary-color-50"
                          style={{ marginRight: "5px" }}
                        >
                          {getMatrixStatus(
                            currentRegistration.unifiedChecks.listsCheck.active,
                            currentRegistration.multiFactorMatrix?.lists,
                          )}
                        </div>
                        {currentRegistration.unifiedChecks.listsCheck ? (
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentSection("Listas");
                            }}
                            className="flex-col text-medium text-bold primary-color-50"
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              width: "90px",
                              height: "25px",
                              textAlign: "center",
                              borderRadius: "8px",
                              background: "rgba(0,0,0,0.5)",
                              cursor: "pointer",
                            }}
                          >
                            Ver más
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-col"
                    style={{ width: "55%", overflow: "auto" }}
                  >
                    <table
                      style={{
                        width: "90%",
                        marginLeft: "5%",
                        borderCollapse: "collapse",
                        overflow: "hidden",
                        borderRadius: "12px",
                        padding: "10px",
                      }}
                    >
                      <thead
                        className="text-normal text-small primary-color"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          height: "30px",
                          textAlign: "center",
                        }}
                      >
                        <tr style={{ height: "15px" }}>
                          <th></th>
                          <th>Factor de verificación</th>
                          <th>Score (0-100)</th>
                          <th>Umbral de Bloqueo</th>
                          <th>Pasa Umbral</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className={`text-normal text-small primary-color-50`}
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          textAlign: "center",
                          margin: "5px 0 5px 0",
                        }}
                      >
                        <tr></tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Face match"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.faceMatch
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.faceMatch
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.faceMatch
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Detección de impresiones en documento"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.impresion
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.impresion
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.impresion
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Detección de pantalla para documento"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.screen
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.screen
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.screen
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Validez-Autenticidad"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.validity
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.validity
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.validity
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Veracidad documento"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.veracity
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.veracity
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.veracity
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Listas"}</td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.lists.score}
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.lists
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.lists.passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Liveness"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.liveness
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.liveness
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.liveness
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"GPS(Geofencin)"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.geolocation
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.geolocation
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.geolocation
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Device ID"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.deviceId
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.deviceId
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.deviceId
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Match datos iniciales"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix
                                ?.initialDataMatch.score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix
                                ?.initialDataMatch.blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix
                              ?.initialDataMatch.passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr style={{ height: "25px" }}>
                          <td></td>
                          <td>{"Alertas entidades gubernamentales"}</td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.govEntity
                                .score
                            }
                          </td>
                          <td>
                            {
                              currentRegistration.multiFactorMatrix?.govEntity
                                .blockingThreshold
                            }
                          </td>
                          <td>
                            {currentRegistration.multiFactorMatrix?.govEntity
                              .passes
                              ? "SI"
                              : "NO"}
                          </td>
                          <td></td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          />
          {currentRegistration.unifiedChecks.listsCheck.active && (
            <ExpandibleContent
              title="Listas restrictivas"
              isActive={currentSection === "Listas"}
              onClickFunction={() => {
                setCurrentSection("Listas");
              }}
              content={getListasSection()}
            />
          )}
          <ExpandibleContent
            title="Alertas"
            isActive={currentSection === "Alerta"}
            onClickFunction={() => {
              setCurrentSection("Alerta");
            }}
            content={renderAlerts()}
          />
          <ExpandibleContent
            title="Adjuntos"
            isActive={currentSection === "Adjuntos"}
            contentHeight="50%"
            onClickFunction={() => {
              setCurrentSection("Adjuntos");
            }}
            content={
              <div className="flex-col">
                <div
                  className="flex-col"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "29px",
                    alignItems: "end",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    paddingRight: "10px",
                  }}
                >
                  <div className="text-normal text-medium primary-color-35">
                    Datos del usuario
                  </div>
                </div>
                <div
                  className="flex-col"
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    boxSizing: "border-box",
                  }}
                >
                  {showAttatchments(
                    currentRegistration,
                    loadingAttatchments,
                    downloadingAttatchment,
                  )}
                </div>
              </div>
            }
          />
          <ExpandibleContent
            title="Metadata"
            isActive={currentSection === "Metadata"}
            onClickFunction={() => {
              setCurrentSection("Metadata");
            }}
            content={
              <div className="flex-col" style={{ height: "100%" }}>
                <div
                  className="flex-row"
                  style={{
                    justifyContent: "center",
                    alignItems: "start",
                    height: "40%",
                  }}
                >
                  <div
                    className="flex-col"
                    style={{ width: "100%", overflow: "auto" }}
                  >
                    <table style={{ borderCollapse: "collapse" }}>
                      <thead
                        className="text-normal text-medium primary-color-35"
                        style={{
                          background: "rgba(0,0,0,0.4)",
                          height: "30px",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <th></th>
                          <th>Llave</th>
                          <th>Valor</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-normal text-x-small primary-color"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {Object.keys(currentRegistration.customData).map(
                          (metaData, i) => {
                            return (
                              <tr
                                key={i}
                                style={{ height: "30px" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <td></td>
                                <td style={{ textTransform: "uppercase" }}>
                                  {metaData}
                                </td>
                                <td>
                                  {currentRegistration?.customData[metaData]}
                                </td>
                                <td></td>
                              </tr>
                            );
                          },
                        )}
                        <tr>
                          <td>&nbsp;</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-huge text-bold primary-color">
            {loading === "rejected"
              ? "No existe el registro con id proporcionado"
              : ""}
          </h1>
        </div>
      )}
      {attachmentToVisualize.url && (
        <div
          className="flex-col"
          style={{
            position: "absolute",
            left: "1%",
            top: "5%",
            height: "90%",
            width: "97%",
          }}
        >
          <button
            onClick={() =>
              dispatch(
                setAttachmentToVisualize({
                  fileName: "",
                  mimeType: "",
                  url: "",
                }),
              )
            }
            style={{
              display: "flex",
              flexDirection: "row",
              border: "none",
              textAlign: "center",
              borderRadius: "8px",
              fontSize: "25px",
              background: "#1A617D",

              width: "100%",
              color: "white",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 20px",
            }}
          >
            <p> </p> <p style={{ margin: 0 }}> Cerrar</p>
            <p style={{ margin: 0, fontSize: "30px", fontWeight: "bold" }}>X</p>
          </button>
          {getAttachmentRender(attachmentToVisualize.mimeType)}
        </div>
      )}
    </LoadingOverlay>
  );
};
