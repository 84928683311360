import {
  AlertsResult,
  BackofficeAsesor,
  MFMAResultElement,
  StatusResult,
  StatusResultByDate,
} from "./analyticsAPI";
import { EChartsOption } from "echarts/types/dist/echarts";

export const getAsesorName = (name: string, asesors: BackofficeAsesor[]) => {
  let asesor = asesors.find((v) => v.id === name);
  return asesor ? asesor.name : name;
};

const getColorBasedOnProduct = (product: string) => {
  switch (product) {
    case "CoreId":
      return ["#A1EBA5", "#EB5F6A", "#DBA659", "#989AA2"];
    case "HyperFlow":
      return ["#989AA2", "#DBA659", "#A1EBA5", "#63B1EB", "#EB5F6A"];
    case "SmartFlow":
      return ["#989AA2", "#DBA659", "#A1EBA5", "#63B1EB", "#EB5F6A"];
    case "Firma":
      return ["#A1EBA5", "#EB5F6A", "#DBA659", "#989AA2"];
    case "MagicForms":
      return ["#A1EBA5", "#EB5F6A", "#DBA659", "#989AA2"];
    case "Pagares":
      return ["#EB5F6A", "#A1EBA5", "#DBA659", "#989AA2"];
    default:
      return ["#A1EBA5", "#EB5F6A", "#DBA659", "#989AA2"];
  }
};

export const convertAsesorToPieChart = (
  asesorResult: StatusResult[],
  percentage: boolean,
  asesors: BackofficeAsesor[]
) => {
  let listOptions = asesorResult
    .map((v) => {
      // Check if v is in asesors
      if (asesors.find((x) => x.id === v.name)) {
        return { value: v.value, name: getAsesorName(v.name, asesors) };
      } else {
        return undefined;
      }
    })
    .filter((v): v is { value: number; name: string } => v !== undefined);
  let options: EChartsOption = {
    title: {
      text: "Asesor",
      left: "center",
      textStyle: { color: "rgba(255,255,255,0.7)", width: "20px" },
      subtextStyle: { color: "rgba(255,255,255,0.7)" },
    },
    tooltip: {
      trigger: "item",
      formatter: percentage ? "{a}: {b}<br />{d}%" : undefined,
    },
    legend: {
      bottom: 0,
      orient: "horizontal",
      left: "center",
      textStyle: { color: "#fff" },
    },
    series: [
      {
        name: "Asesor",
        type: "pie",
        radius: "60%",
        data: listOptions,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          position: "inner",
          formatter: percentage ? "{d}%" : "{c}",
          fontSize: 18,
        },
      },
    ],
  };
  return options;
};

export const convertAlertsToPieChart = (
  alertsResult: AlertsResult,
  percentage: boolean
) => {
  let options: EChartsOption = {
    title: {
      text: "Alertas",
      left: "center",
      textStyle: { color: "rgba(255,255,255,0.7)", width: "20px" },
      subtextStyle: { color: "rgba(255,255,255,0.7)" },
    },
    tooltip: {
      trigger: "item",
      formatter: percentage ? "{a}: {b}<br />{d}%" : undefined,
    },
    legend: {
      bottom: 0,
      orient: "vertical",
      left: "center",
      textStyle: { color: "#fff" },
    },
    series: [
      {
        name: "Estado del factor",
        type: "pie",
        radius: "60%",
        color: ["#EB5F6A", "#A1EBA5"],
        data: [
          { value: alertsResult.has, name: "Tiene alertas", cursor: "pointer" },
          { value: alertsResult.hasNot, name: "No Tiene alertas" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          color: "#000",
          position: "inner",
          formatter: percentage ? "{d}%" : "{c}",
          fontSize: 18,
        },
      },
    ],
  };
  return options;
};

export const convertStatusToPieChart = (
  statusResult: StatusResult[],
  percentage: boolean,
  product:
    | "CoreId"
    | "HyperFlow"
    | "SmartFlow"
    | "Firma"
    | "MagicForms"
    | "Pagares",
  radius?: string
) => {
  let listOptions = statusResult.map((v) => {
    return { value: v.value, name: getStatusName(v.name, product) };
  });
  let options: EChartsOption = {
    title: {
      text: "Estado",
      left: "center",
      textStyle: { color: "rgba(255,255,255,0.7)", width: "20px" },
      subtextStyle: { color: "rgba(255,255,255,0.7)" },
    },
    tooltip: {
      trigger: "item",
      formatter: percentage ? "{a}: {b}<br />{d}%" : undefined,
    },
    legend: {
      bottom: 0,
      orient: "horizontal",
      left: "center",
      textStyle: { color: "#fff" },
    },
    color: getColorBasedOnProduct(product),

    series: [
      {
        name: "Estado del factor",
        type: "pie",
        radius: radius ? radius : "60%",
        data: listOptions,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          color: "#000",
          position: "inner",
          formatter: percentage ? "{d}%" : "{c}",
          fontSize: 18,
        },
      },
    ],
  };
  return options;
};

export const converMFMAElementToPieChart = (
  mfmaElement: MFMAResultElement,
  elementName: string,
  percentage?: boolean
) => {
  if (mfmaElement) {
    let options: EChartsOption = {
      title: {
        text: mfmaNameToTitle(elementName),
        left: "center",

        textStyle: { color: "rgba(255,255,255,0.7)", width: "20px" },
        subtextStyle: { color: "rgba(255,255,255,0.7)" },
      },
      color: ["#A1EBA5", "#EB5F6A"],
      tooltip: {
        trigger: "item",
        formatter: percentage ? "{a}: {b}<br />{d}%" : undefined,
      },
      legend: {
        bottom: 0,
        orient: "vertical",
        left: "center",
        textStyle: { color: "#fff" },
      },
      series: [
        {
          name: "Estado del factor",
          type: "pie",
          radius: "75%",
          data: [
            { value: mfmaElement.passed, name: "Encima del umbral" },
            { value: mfmaElement.failed, name: "Debajo el umbral" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            position: "inside",
            formatter: percentage ? "{d}%" : "{c}",
            fontSize: 14,
          },
        },
      ],
    };

    return options;
  } else {
    return {};
  }
};

export const convertStatusByDateToStackedBar = (
  statusByDate: StatusResultByDate,
  product:
    | "CoreId"
    | "HyperFlow"
    | "SmartFlow"
    | "Firma"
    | "MagicForms"
    | "Pagares"
    | "asesores",
  graphTitle?: string
) => {
  let result: {
    name: string;
    type: string;
    stack: string;
    emphasis: {
      focus: string;
    };
    data: number[];
  }[] = [];

  for (const statusResult of statusByDate.statusResultByDate) {
    let info = result.find(
      (x) => x.name == getStatusName(statusResult.name, product)
    );
    if (info) {
      let infoIndex = result.indexOf(info);
      let data: number[] = info.data;
      for (let i = 0; i < statusByDate.categories.length; i++) {
        if (statusResult.category === statusByDate.categories[i]) {
          data[i] = data[i] + statusResult.value;
        }
      }
      result[infoIndex] = info;
    } else {
      let data: number[] = [];
      for (let i = 0; i < statusByDate.categories.length; i++) {
        if (statusResult.category === statusByDate.categories[i]) {
          data.push(statusResult.value);
        } else {
          data.push(0);
        }
      }
      let newInfo: {
        name: string;
        type: string;
        stack: string;
        emphasis: {
          focus: string;
        };
        data: number[];
      } = {
        name: getStatusName(statusResult.name, product),
        type: "bar",
        emphasis: {
          focus: "series",
        },
        stack: statusByDate.stackId,
        data,
      };
      result.push(newInfo);
    }
  }

  let options: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    title: [
      {
        text: graphTitle ? graphTitle : "Estado de registro por fecha",
        textStyle: { color: "#fff" },
      },
    ],
    color: getColorBasedOnProduct(product),
    legend: {
      textStyle: { color: "#fff" },
      right: 30,
      top: 4,
      orient: "horizontal",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: statusByDate.categories,
        axisLabel: { color: "#fff" },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: { color: "#fff" },
      },
    ],
    series: result as any,
  };

  return options;
};

export const convertStatusByDateToStackedBarAsesores = (
  statusByDate: StatusResultByDate,
  asesores: BackofficeAsesor[],
  graphTitle?: string
) => {
  let result: {
    name: string;
    type: string;
    stack: string;
    emphasis: {
      focus: string;
    };
    data: number[];
  }[] = [];

  for (const statusResult of statusByDate.statusResultByDate) {
    if (!asesores.find((x) => x.id === statusResult.name)) {
      continue;
    }
    let info = result.find(
      (x) => x.name == getAsesorName(statusResult.name, asesores)
    );
    if (info) {
      let infoIndex = result.indexOf(info);
      let data: number[] = info.data;
      for (let i = 0; i < statusByDate.categories.length; i++) {
        if (statusResult.category === statusByDate.categories[i]) {
          data[i] = data[i] + statusResult.value;
        }
      }
      result[infoIndex] = info;
    } else {
      let data: number[] = [];
      for (let i = 0; i < statusByDate.categories.length; i++) {
        if (statusResult.category === statusByDate.categories[i]) {
          data.push(statusResult.value);
        } else {
          data.push(0);
        }
      }
      let newInfo: {
        name: string;
        type: string;
        stack: string;
        emphasis: {
          focus: string;
        };
        data: number[];
      } = {
        name: getAsesorName(statusResult.name, asesores),
        type: "bar",
        emphasis: {
          focus: "series",
        },
        stack: "asesores",
        data,
      };
      result.push(newInfo);
    }
  }

  let options: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    title: [
      {
        text: graphTitle ? graphTitle : "Estado de registro por fecha",
        textStyle: { color: "#fff" },
      },
    ],
    legend: {
      textStyle: { color: "#fff" },
      right: 30,
      top: 4,
      orient: "horizontal",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: statusByDate.categories,
        axisLabel: { color: "#fff" },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: { color: "#fff" },
      },
    ],
    series: result as any,
  };
  return options;
};

export const getStatusName = (
  status: string,
  product:
    | "CoreId"
    | "HyperFlow"
    | "SmartFlow"
    | "Firma"
    | "MagicForms"
    | "Pagares"
    | "asesores"
) => {
  switch (product) {
    case "CoreId":
      switch (status) {
        case "ACCEPTED":
          return "Aprobado";

        case "IN_PROGRESS":
          return "En progreso";
        case "REJECTED":
          return "Rechazado";
        case "REVISION":
          return "Por revisar";
        default:
          return "";
      }
    case "HyperFlow":
      switch (status) {
        case "IN_PROGRESS":
          return "En proceso";
        case "AWAITING_MANUAL_INTERACTION":
          return "En revisión";
        case "AWAITING_USER_INTERACTION":
          return "En espera a usuario";
        case "DONE":
          return "Completado";
        case "ABANDONED":
          return "Desistido";
        default:
          return "";
      }
    case "MagicForms":
      return status;
    case "Firma":
      switch (status) {
        case "Validated":
          return "Validado";
        case "Invalid":
          return "Invalido";
        case "Failed":
          return "Fallido";
        case "Signing":
          return "Firmando";
        case "Signed":
          return "Firmado";
        case "Confirmed":
          return "Empezado";
        case "Started":
          return "Empezado";
        default:
          return "";
      }
    case "Pagares":
      switch (status) {
        case "Created":
          return "Creado";
        case "Signed":
          return "Firmado";
        case "Filled":
          return "Diligenciado";
        case "Endosado":
          return "Endosado";
        case "BlockedByEndosoValidation":
          return "Bloqueado por validación de endoso";
        default:
          return "";
      }
    case "SmartFlow":
      switch (status) {
        case "IN_PROGRESS":
          return "En proceso";

        case "PENDING":
          return "Pendiente";
        case "APPROVED":
          return "Aprobado";
        case "REJECTED":
          return "Rechazado";
        default:
          return "";
      }
    default:
      return "";
  }
};

export const mfmaNameToTitle = (name: string) => {
  switch (name) {
    case "faceMatch":
      return "FaceMatch";
    case "validity":
      return "Validez";
    case "veracity":
      return "Veracidad";
    case "lists":
      return "Listas";
    case "liveness":
      return "Prueba de vida";
    case "geolocation":
      return "GPS Geofencing";
    case "deviceId":
      return "Device Id";
    case "initialDataMatch":
      return "Match \n Datos Iniciales";
    case "govEntity":
      return "Match \n Entidad \n Gubernamental";
    case "impresion":
      return "Detección de\n impresiones";
    case "screen":
      return "Detección de \n pantallas";

    default:
      return "";
  }
};
