import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct } from "../../common/commonSlice";

import { formatDateStandar } from "../../../helpers/utils";
import Modal, { Styles } from "react-modal";
import { usePagination } from "pagination-react-js";
import PaginationItem from "../../../helpers/PaginationItem";
import {
  changePageSize,
  changePageState,
  getConfigs,
  getDocumentConfigs,
  getFilteredProcesses,
  initializeProcess,
  resetProcess,
  selectConfigs,
  selectCurrentFilterRequestATK,
  selectCurrentPageATK,
  selectInitializeProcessLoadingATK,
  selectListLoadingATK,
  selectNewProcessId,
  selectPageSizeATK,
  selectProcessList,
  selectTotalPagesATK,
  setIsProcessDetail,
} from "./AuthentikatorSlice";
import { ATKProcess, ATKProcessConfig, ManualInfo } from "./AuthentikatorAPI";
import { DropDown } from "../../common/components/Btns/DropDown";

const customStylesModal3: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",

    width: "70%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const AuthentikatorProcessList = () => {
  //Selector
  let currentPage = useAppSelector(selectCurrentPageATK);
  let numPages = useAppSelector(selectTotalPagesATK);
  const pageSize = useAppSelector(selectPageSizeATK);
  let loading = useAppSelector(selectListLoadingATK);
  let loadingInitialize = useAppSelector(selectInitializeProcessLoadingATK);
  let processes = useAppSelector(selectProcessList);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequestATK);
  let configs = useAppSelector(selectConfigs);
  let newProcessId = useAppSelector(selectNewProcessId);
  // Hooks
  let dispatch = useAppDispatch();
  let navigate = useNavigate();

  //LocalStates
  const [modalInitialize, setModalInitialize] = useState(false);
  const [initialManualInfo, setInitialManualInfo] = useState<ManualInfo>({});
  const [initializeConfigId, setInitializeConfigId] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [projectError, setProjectError] = useState<string>("");
  const [currentProject, setCurrentProject] = useState<string>("");
  const [customIdError, setCustomIdError] = useState<string>("");

  //Pagination
  const Pagination = () => {
    const { pageNumbers, setActivePage, setRecordsPerPage } = usePagination({
      activePage: currentPage + 1,
      recordsPerPage: pageSize || 10,
      totalRecordsLength: numPages * 10,
      offset: 1,
      navCustomPageSteps: { prev: 5, next: 5 },
      permanentFirstNumber: true,
      permanentLastNumber: true,
    });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          width: "100%",
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changePageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role='navigation' aria-label='Paginación'>
          <ul className='pagination'>
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  //Helper functions

  const modifyNameCreate = (name: string) => {
    if (name.length === 0) {
      setNameError("El nombre no puede estar vacío");
    } else if (name.length > 50) {
      setNameError("El nombre no puede tener más de 50 caracteres");
    } else {
      setNameError("");
    }
    setInitialManualInfo({
      ...initialManualInfo,
      fullName: name,
    });
  };

  const modifyCustomIdCreate = (id: string) => {
    //verify id has no spaces
    const re = /^\S*$/;
    if (id.length > 15) {
      setCustomIdError("El Id no puede tener más de 15 caracteres");
    } else if (!re.test(id)) {
      setCustomIdError("El Id no puede tener espacios");
    } else {
      setCustomIdError("");
    }
    setInitialManualInfo({
      ...initialManualInfo,
      customId: id,
    });
  };

  const modifyEmailCreate = (email: string) => {
    //verify email regex
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError("El correo no es válido");
    } else if (email.length === 0) {
      setEmailError("El correo no puede estar vacío");
    } else if (email.length > 50) {
      setEmailError("El correo no puede tener más de 50 caracteres");
    } else {
      setEmailError("");
    }
    setInitialManualInfo({
      ...initialManualInfo,
      email: email,
    });
  };

  const getEstado = (process: ATKProcess) => {
    let done = 0;
    let total = 0;
    process.documents.forEach((doc) => {
      total++;
      if (doc.uploaded) {
        done++;
      }
    });
    return `${done}/${total}`;
  };

  const getNumberValid = (process: ATKProcess) => {
    let valid = 0;
    let total = 0;
    process.documents.forEach((doc) => {
      total++;
      if (doc.validityScore > 50) {
        valid++;
      }
    });
    return `${valid}/${total}`;
  };

  const getAlerts = (process: ATKProcess) => {
    let alerts = 0;
    process.data.forEach((dataPoint) => {
      if (!dataPoint.allValid) {
        alerts++;
      }
    });
    return alerts;
  };

  const getDropdownValuesForConfigs = (configs: ATKProcessConfig[]) => {
    return configs.map((config) => {
      return { value: config.id, label: config.name };
    });
  };

  //Effects
  useEffect(() => {
    dispatch(changeCurrentProduct("Authentikator"));
    // dispatch(changeCurrentActiveComponent("flows"));
    // dispatch(
    //   getFilteredFlow({
    //     ...currentFilteredRequest,
    //   })
    // );
    dispatch(resetProcess());
    dispatch(setIsProcessDetail(false));
    return () => {};
  }, [currentFilteredRequest]);

  useEffect(() => {
    dispatch(
      getFilteredProcesses({
        ...currentFilteredRequest,
      })
    );
  }, [currentFilteredRequest]);

  useEffect(() => {
    dispatch(getConfigs());
    dispatch(getDocumentConfigs());
  }, []);

  useEffect(() => {
    if (newProcessId && newProcessId !== "")
      navigate("/services/authentikator/processes/" + newProcessId);
  }, [newProcessId]);

  useEffect(() => {
    if (currentProject !== "") {
      setProjectError("");
    } else {
      setProjectError("El proyecto no puede estar vacío");
    }
  }, [modalInitialize, currentProject]);

  //Components
  const getCreateProcessModal = () => {
    return (
      <Modal
        isOpen={modalInitialize}
        onRequestClose={() => {
          setModalInitialize(false);
        }}
        style={customStylesModal3}
        contentLabel='Leads'
        shouldCloseOnOverlayClick={true}
      >
        {loadingInitialize === "pending" ? (
          <div
            className='flex-col'
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <ClipLoader color='#49829C' size={200} />
            <p style={{ color: "black" }}>Creando proceso...</p>
          </div>
        ) : (
          <div
            className='flex-col'
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {" "}
            <div
              className='flex-row'
              style={{
                justifyContent: "end",
                alignItems: "end",
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  setModalInitialize(false);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "24px",
                  background: "#1A617D",
                  width: "40px",
                  height: "35px",
                  color: "white",
                }}
              >
                X
              </button>
            </div>
            <div className='flex-row'>
              {" "}
              <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
                Iniciar proceso
              </h4>
            </div>
            <div className='flex-row' style={{ width: "100%" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#000",
                  width: "100%",
                }}
              >
                Ingresa la información básica del usuario para comenzar el
                proceso
              </p>{" "}
            </div>
            <div className='flex-row'>
              <div className='flex-col'>
                <strong>Nombre y Apellido</strong>
                <input
                  value={initialManualInfo.fullName ?? ""}
                  onChange={(e) => {
                    modifyNameCreate(e.target.value);
                  }}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    borderRadius: "6px",
                    border: "1px solid rgba(0,0,0,0.8)",
                    width: "400px",
                    padding: "6px 10px",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                />
                <p style={{ color: "red" }}>{nameError}</p>
              </div>
            </div>
            <div className='flex-row'>
              <div className='flex-col'>
                <strong>Correo electrónico</strong>
                <input
                  value={initialManualInfo.email ?? ""}
                  onChange={(e) => {
                    modifyEmailCreate(e.target.value);
                  }}
                  style={{
                    marginBottom: "10px",
                    // display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    borderRadius: "6px",
                    border: "1px solid rgba(0,0,0,0.8)",
                    width: "400px",
                    padding: "6px 10px",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                />
                <p style={{ color: "red" }}>{emailError}</p>
              </div>
            </div>
            <div className='flex-row'>
              <div className='flex-col'>
                <strong>Id personalizado</strong>
                <input
                  value={initialManualInfo.customId ?? ""}
                  onChange={(e) => {
                    modifyCustomIdCreate(e.target.value);
                  }}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    borderRadius: "6px",
                    border: "1px solid rgba(0,0,0,0.8)",
                    width: "400px",
                    padding: "6px 10px",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                />
                <p style={{ color: "red" }}>{customIdError}</p>
              </div>
            </div>
            <div className='flex-row'>
              <div className='flex-col' style={{ width: "500px" }}>
                <DropDown
                  titleColor='black'
                  key={"ProjectsExecute"}
                  title='Proyecto'
                  alignCenter
                  options={getDropdownValuesForConfigs(Object.values(configs))}
                  onChange={(e) => {
                    if (e) {
                      setInitializeConfigId(e.value);
                      setCurrentProject(e.value);
                    }
                  }}
                  isClearable={false}
                />
                <p style={{ color: "red" }}>{projectError}</p>
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  initializeProcess({
                    configId: initializeConfigId,
                    manualInfo: initialManualInfo,
                    manualVerifiableData: {},
                  })
                );
              }}
              disabled={
                initializeConfigId === "" ||
                initialManualInfo.fullName === "" ||
                initialManualInfo.email === "" ||
                nameError !== "" ||
                emailError !== ""
              }
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                fontSize: "24px",
                background:
                  initializeConfigId === "" ||
                  initialManualInfo.fullName === "" ||
                  initialManualInfo.email === "" ||
                  nameError !== "" ||
                  emailError !== ""
                    ? "#808080"
                    : "#1A617D",
                width: "200px",
                height: "35px",
                color: "white",
              }}
            >
              Iniciar
            </button>
          </div>
        )}
      </Modal>
    );
  };
  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color='white' size='150px' />}
    >
      {getCreateProcessModal()}
      {processes?.length > 0 ? (
        <div className='flex-col' style={{ width: "100%", height: "100%" }}>
          <div
            className='flex-row'
            style={{
              height: "13%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div
              className='text-xx-large text-bold primary-color'
              style={{ marginRight: "2%" }}
            >
              Flujos Realizados
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <button
              className='text-medium text-bold primary-color'
              style={{
                border: "none",
                marginRight: "2%",
                marginLeft: "1%",
                marginBottom: "1%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                width: "150px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setModalInitialize(true);
                // setModalLead(true);
                // dispatch(
                //   getFilteredLeads({
                //     // page: leadPage,
                //     hyperflow: false,
                //   })
                // );
              }}
            >
              Iniciar un nuevo proceso
            </button>
          </div>
          {processes ? (
            <div className='flex-col' style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className='text-normal text-medium primary-color-35'
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Id del proceso</th>
                      <th>Id Personalizado</th>
                      <th>Nombre y Apellido</th>
                      <th>E-mail</th>
                      <th>Fecha de creación</th>
                      <th>Documentos cargados</th>
                      <th>Documentos válidos</th>
                      <th>Alertas</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className='text-normal text-x-small primary-color'
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {processes.map((process, i) => {
                      return (
                        <tr
                          key={i}
                          className='list-hover'
                          style={{
                            height: "30px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(
                              `/services/authentikator/processes/${process.id}`
                            );
                          }}
                        >
                          <td></td>
                          <td>{process.id}</td>
                          <td>{process.manualInfo.customId ?? ""}</td>
                          <td style={{ textTransform: "uppercase" }}>
                            {process.manualInfo ? (
                              <div>{`${
                                process.manualInfo.fullName ?? ""
                              }`}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {process.manualInfo ? (
                              <div>{process.manualInfo.email ?? ""}</div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{formatDateStandar(process.createdDate)}</td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getEstado(process)}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getNumberValid(process)}
                          </td>
                          <td style={{ textTransform: "uppercase" }}>
                            {getAlerts(process)}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className='flex-col' style={{ height: "60%" }}>
              <h1 className='text-huge text-bold primary-color'>
                No hay más registros en esta página
              </h1>
            </div>
          )}
          <div
            className='flex-row'
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <Pagination />
          </div>
        </div>
      ) : (
        <div
          className='flex-col'
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className='text-huge text-bold primary-color'>
            {loading === "pending" || loading === "idle"
              ? ""
              : "No hay más flujos en esta página"}
          </h1>
          <button
            className='text-medium text-bold primary-color'
            style={{
              border: "none",
              marginRight: "2%",
              marginLeft: "1%",
              marginBottom: "1%",
              background: "rgba(0,0,0,0.4)",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "10px",
              borderRadius: "8px",
              textAlign: "center",
              cursor: "pointer",
              width: "150px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setModalInitialize(true);
            }}
          >
            Iniciar un nuevo proceso
          </button>
        </div>
      )}
    </LoadingOverlay>
  );
};
