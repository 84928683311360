import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingStatus } from "../../../common/commonSlice";

export interface ProcesosUnificadosProps {
  loading: LoadingStatus;
  item: ProcesosUnificados | null;
}

export interface ProcesosUnificados {
  tipoDocumentoConsulta: [
    {
      tipoDocumento: string;
      nombreDocumento: string;
    }
  ];
  presentaRiesgo: boolean;
  presentaAdvertencia: boolean;
  procesos: Array<ProcesoJudicial>;
}
export interface ProcesoJudicial {
  contenidoRadicacion: string;
  ubicacion: string;
  fechaProceso: string;
  despacho: string;
  coincidencia: string;
  ponente: string;
  isLaft: boolean;
  tipoProceso: string;
  esPrivado: boolean;
  sujetosProcesales: string;
  fechaUltimaActuacion: string;
  departamento: string;
  idProceso: number;
  claseProceso: string;
  numProceso: string;
}

export const ProcesosUnificadosResult = (props: ProcesosUnificadosProps) => {
  const getColor = (presentaRiesgo: boolean, presentaAdvertencia: boolean) => {
    if (presentaRiesgo) {
      return "rgb(248 113 113)";
    } else if (presentaAdvertencia) {
      return "rgb(250 204 21)";
    } else {
      return "rgb(74 222 128)";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {props.item ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0.5rem",
          }}
        >
          <div
            style={{
              color: getColor(
                props.item.presentaRiesgo,
                props.item.presentaAdvertencia
              ),
              fontSize: "1.875rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Procesos unificados
          </div>
          <div
            style={{
              color: "#fff",
              fontSize: "1.25rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {props.item.presentaRiesgo || props.item.presentaAdvertencia
              ? "Presenta riesgo o advertencia"
              : "No presenta riesgo"}
          </div>
          {props.item.procesos.length > 0 ? (
            <table style={{ position: "relative", width: "100%" }}>
              <thead>
                <tr>
                  <th></th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    No. Proceso
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Ponente
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Tipo de Proceso
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Sujetos procesales
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Clase
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Despacho
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Fecha
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Ubicación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Es Laft
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Contenido Radicación
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      padding: "0.5rem",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: "#52525B",
                    }}
                  >
                    Fecha última actualización
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {props.item.procesos.map((proceso, i) => (
                  <tr
                    key={i}
                    style={{
                      textAlign: "center",
                      color: "#1C1C1E",
                      fontSize: "0.75rem",
                      fontWeight: "normal",
                      height: "2.5rem",
                      backgroundColor: "rgba(242, 242, 247, 0.5)",
                      borderRadius: "0.375rem",
                    }}
                  >
                    <td></td>
                    <td>{proceso.numProceso}</td>
                    <td>{proceso.ponente}</td>
                    <td>{proceso.tipoProceso}</td>
                    <td>{proceso.sujetosProcesales}</td>
                    <td>{proceso.claseProceso}</td>
                    <td>{proceso.despacho}</td>
                    <td>{proceso.fechaProceso}</td>
                    <td>{proceso.ubicacion}</td>
                    <td>{proceso.isLaft ? "Sí" : "No"}</td>
                    <td>{proceso.contenidoRadicacion}</td>
                    <td>{proceso.fechaUltimaActuacion}</td>
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td>&nbsp;</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <div
              style={{
                color: "#1C1C1E",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No tiene procesos
            </div>
          )}
        </div>
      ) : (
        <div>
          {props.loading === "rejected" || props.loading === "resolved" ? (
            <div
              style={{
                color: "#fff",
                fontSize: "1.25rem",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              No se encontraron registros, intente de nuevo más tarde
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
