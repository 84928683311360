import { useEffect, useState } from "react";

// const getConstantsView
type UploadSupportFileComponentProps = {
  upload: (file: File, id: string, fileName: string, name: string) => void;
  id: string;
};
const UploadSupportFileComponent: React.FC<UploadSupportFileComponentProps> = ({
  upload,
  id,
}) => {
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState<string>("");

  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "10px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ fontSize: "20px" }}>Subir archivo de soporte</h1>
      <div style={{ display: "flex", flexDirection: "column", margin: "10px" }}>
        <input
          id="UploadFileModalButton"
          style={{ display: "none" }}
          type="file"
          onChange={(e) => {
            console.log(e.target.files?.[0]);
            let file = e.target.files?.[0];
            if (file) {
              setFileName(file.name);
              setFile(file);
            }
          }}
        ></input>
        <h5
          style={{
            color: "#000",
            marginTop: "20px",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          Nombre o descripción del soporte
        </h5>
        <input
          placeholder="Ej. Soporte de prueba"
          style={{
            height: "30px",
            width: "100%",
            minWidth: "300px",
            background: "rgba(0, 0, 0, 0.1)",
            borderColor: "rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            color: "black",
            border: "none",
            padding: "9px 8px",
            fontSize: "16px",
            fontWeight: 600,
          }}
          value={description}
          onChange={(e) => {
            //Regex to validate is only text and numbers
            if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
              setDescription(e.target.value);
            }
          }}
        />
        <button
          style={{
            marginTop: "20px",
            background: "#1A617D",
            color: "white",
            border: "none",
            padding: "5px 10px",
            borderRadius: "8px",
          }}
          onClick={() => {
            document.getElementById("UploadFileModalButton")?.click();
          }}
        >
          Seleccionar archivo
        </button>
        {file && fileName && (
          <h1
            className="text-medium"
            style={{ marginLeft: "10px", marginTop: "20px" }}
          >
            Archivo seleccionado:
          </h1>
        )}
        <h3 style={{ marginLeft: "10px", fontSize: "14px" }}>{fileName}</h3>
      </div>
      <button
        style={{
          marginTop: "20px",
          background: "#1a527c",
          color: "white",
          border: "none",
          padding: "10px",
          borderRadius: "8px",
        }}
        onClick={() => {
          if (file === null || fileName === "") {
            alert("Debe seleccionar un archivo");
          } else {
            if (description === "") {
              alert("Debe agregar una descripción");
            } else {
              upload(file!, id, fileName, description);
            }
          }
        }}
      >
        Subir archivo
      </button>
    </div>
  );
};

export default UploadSupportFileComponent;
