import axiosApiInstance from "../../../helpers/interceptor";

let baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const clientsMethod = "metaClients/subClientsNames";
const getFilteredClientsMethod = "metaClients/getSubClientsFiltered";
const clientDetailMethod = "metaClients/getSubClientById";
const projectMethod = "metaClients/getCopyableProjects";
const createClientsMethod = "metaClients/createSubClient";
const editClientMethod = (id: string) => `metaClients/editSubClient/${id}`;

export const getSubClientsAPI = async (): Promise<Client[]> => {
  try {
    const loginData = await axiosApiInstance.get<Client[]>(
      `${baseUrl}/${clientsMethod}`
    );

    return loginData.data;
  } catch (err: any) {
    return [];
  }
};

export const getFilteredSubclientsAPI = async (
  parameters: FilteredClientsRequest
): Promise<{ subclients: FilteredClientsResponse | null; error: string }> => {
  try {
    let subclientsResponse = await axiosApiInstance.post(
      `/${getFilteredClientsMethod}`,
      parameters
    );

    return {
      error: "",
      subclients: {
        ...subclientsResponse.data,
        currentPage: parameters.page,
      },
    };
  } catch (err: any) {
    return { subclients: null, error: "" };
  }
};

export const getClientAPI = async (
  id: string
): Promise<{ client: ClientDetail | null; error: string }> => {
  try {
    let clientResponse = await axiosApiInstance.get(
      `/${clientDetailMethod}/${id}`
    );

    return {
      error: "",
      client: clientResponse.data,
    };
  } catch (err: any) {
    return { client: null, error: "" };
  }
};

export const getClientProjectsAPI = async (): Promise<{
  projects: ClientProject[] | null;
  error: string;
}> => {
  try {
    let clientResponse = await axiosApiInstance.get(`/${projectMethod}`);
    return {
      error: "",
      projects: clientResponse.data.projects,
    };
  } catch (err: any) {
    return { projects: null, error: "" };
  }
};

export const createClientAPI = async (
  client: ClientCreateRequest
): Promise<{ client: ClientCreateResponse | null; error: string }> => {
  try {
    let clientResponse = await axiosApiInstance.post(
      `/${createClientsMethod}`,
      client
    );

    return {
      error: "",
      client: clientResponse.data,
    };
  } catch (err: any) {
    return { client: null, error: "" };
  }
};

export const editClientAPI = async (
  client: EditClientRequest
): Promise<{ error: string }> => {
  try {
    await axiosApiInstance.post(`/${editClientMethod(client.id)}`, client);

    return {
      error: "",
    };
  } catch (err: any) {
    return { error: "error" };
  }
};

export interface Client {
  clientId: string;
  name: string;
}

export interface ClientCreateRequest {
  name: string;
  nit: string;
  projectId: string;
  brandConfig: BrandConfigCreate;
  canSeeData: boolean;
}

export interface ClientCreateResponse {
  username: string;
  password: string;
  newClientId: string;
}

export interface BrandConfigCreate {
  logoBase64: string;
  color1: string;
  color2: string;
}

export interface ClientDetail {
  id: string;
  name: string;
  nit: string;
  creationDate: string;
  projects: ClientProject[];
  brandConfig: BrandConfig;
  parentCanSeeData: boolean;
}

export interface ClientProject {
  projectId: string;
  projectName: string;
}

export interface BrandConfig {
  logoUrl: string;
  color1: string;
  color2: string;
}

export interface FilteredClientsRequest {
  startDate: string | null;
  endDate: string | null;
  page: number;
  tag: string | null;
}

export interface FilteredClientsResponse {
  subClients: SubClient[];
  numPages: number;
  page: number;
}

export interface SubClient {
  id: string;
  name: string;
  nit: string;
  creationDate: string;
}

export interface ClientProject {
  projectId: string;
  projectName: string;
}

export interface EditClientRequest {
  name: string;
  nit: string;
  projectId: string;
  brandConfig: BrandConfigCreate;
  id: string;
}
