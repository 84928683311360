import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../../common/commonSlice";
import { DrawableFormTemplate } from "../dtos/DrawableFormTemplate";
import { FormTemplate } from "../dtos/FormTemplate";
import { PdfTemplate } from "../dtos/PdfTemplate";
import {
  createModalAPI,
  CreateModalConfigRequest,
  getModalByIdAPI,
  getModalsAPI,
  getTemplateByIdAPI,
  getTemplatesAPI,
  ModalConfig,
  updateFormTemplateByIdAPI,
  updateModalByIdAPI,
} from "./mfEditorAPI";
import { RootState } from "../../../../app/store";

export interface MfTemplateTouple {
  id: string;
  name: string;
}

export interface MfEditorState {
  loadingStatus: LoadingStatus;
  loadingStatusModal: LoadingStatus;
  templateId?: string;
  drawableFormTemplate?: DrawableFormTemplate;
  formTemplate?: FormTemplate;
  pdfTemplate?: PdfTemplate;
  templates: MfTemplateTouple[];
  edited: boolean;
  error?: string;
  modals: MfTemplateTouple[];
  currentModalId?: string;
  currentModal?: ModalConfig;
}

const initialState: MfEditorState = {
  loadingStatus: "idle",
  loadingStatusModal: "idle",
  templateId: undefined,
  drawableFormTemplate: undefined,
  formTemplate: undefined,
  pdfTemplate: undefined,
  templates: [],
  edited: false,
  error: undefined,
  modals: [],
  currentModalId: undefined,
  currentModal: undefined,
};

//Async Thunks

export const mfEditorGetModals = createAsyncThunk(
  "mfEditor/getModals",
  async (_, { rejectWithValue }) => {
    const resp = await getModalsAPI();
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorGetModalById = createAsyncThunk(
  "mfEditor/getModalById",
  async (id: string, { rejectWithValue }) => {
    const resp = await getModalByIdAPI(id);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorUpdateModal = createAsyncThunk(
  "mfEditor/updateModal",
  async (req: { id: string; modal: ModalConfig }, { rejectWithValue }) => {
    const resp = await updateModalByIdAPI(req.id, req.modal);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorCreateModal = createAsyncThunk(
  "mfEditor/createModal",
  async (modal: CreateModalConfigRequest, { rejectWithValue }) => {
    const resp = await createModalAPI(modal);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorGetTemplates = createAsyncThunk(
  "mfEditor/getTemplates",
  async (_, { rejectWithValue }) => {
    const resp = await getTemplatesAPI();
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorGetTemplateById = createAsyncThunk(
  "mfEditor/getTemplateById",
  async (id: string, { rejectWithValue }) => {
    const resp = await getTemplateByIdAPI(id);
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorUpdateData = createAsyncThunk(
  "mfEditor/updateData",
  async (
    {
      id,
      formTemplate,
      drawableTemplate,
      pdfTemplate,
    }: {
      id: string;
      formTemplate?: FormTemplate;
      drawableTemplate?: DrawableFormTemplate;
      pdfTemplate?: PdfTemplate;
    },
    { rejectWithValue }
  ) => {
    const resp = await updateFormTemplateByIdAPI(
      id,
      formTemplate,
      drawableTemplate,
      pdfTemplate
    );
    if (resp.success) {
      return resp.data;
    } else {
      return rejectWithValue(resp.error);
    }
  }
);

export const mfEditorSlice = createSlice({
  name: "mfEditorSlice",
  initialState,
  reducers: {
    selectActiveTemplate: (state, action: PayloadAction<string>) => {
      state.templateId = action.payload;
      state.edited = false;
    },
    updateDrawableTemplate: (
      state,
      action: PayloadAction<DrawableFormTemplate>
    ) => {
      state.drawableFormTemplate = action.payload;
      state.edited = true;
    },
    updateFormTemplate: (state, action: PayloadAction<FormTemplate>) => {
      state.formTemplate = action.payload;
      state.edited = true;
    },
    updatePdfTemplate: (state, action: PayloadAction<PdfTemplate>) => {
      state.pdfTemplate = action.payload;
      state.edited = true;
    },
    //Modals
    updateModal: (state, action: PayloadAction<ModalConfig>) => {
      state.currentModal = action.payload;
      state.edited = true;
    },
    selectActiveModal: (state, action: PayloadAction<string>) => {
      state.currentModalId = action.payload;
      state.edited = false;
    },
  },
  extraReducers: (builder) => {
    //Get All Templates
    builder.addCase(mfEditorGetTemplates.pending, (state) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(mfEditorGetTemplates.fulfilled, (state, action) => {
      state.loadingStatus = "idle";
      if (action.payload) {
        const temps = [];
        for (let key in action.payload) {
          temps.push({ id: key, name: action.payload[key] });
        }
        state.templates = temps;
      }
    });
    builder.addCase(mfEditorGetTemplates.rejected, (state, action) => {
      state.loadingStatus = "rejected";
      state.error = "Error fetching templates: " + action.payload;
    });

    //GetTemplateById
    builder.addCase(mfEditorGetTemplateById.pending, (state) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(mfEditorGetTemplateById.fulfilled, (state, action) => {
      state.loadingStatus = "idle";
      state.formTemplate = action.payload?.formTemplate;
      state.drawableFormTemplate = action.payload?.drawableTemplate;
      state.pdfTemplate = action.payload?.pdfTemplate;
    });
    builder.addCase(mfEditorGetTemplateById.rejected, (state, action) => {
      state.loadingStatus = "rejected";
      state.error = "Error fetching template: " + action.payload;
    });

    //UpdateData
    builder.addCase(mfEditorUpdateData.pending, (state) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(mfEditorUpdateData.fulfilled, (state) => {
      state.loadingStatus = "idle";
      state.edited = false;
    });
    builder.addCase(mfEditorUpdateData.rejected, (state, action) => {
      state.loadingStatus = "rejected";
      state.error = "Error updating template: " + action.payload;
    });
    //GetModals
    builder.addCase(mfEditorGetModals.pending, (state) => {
      state.loadingStatusModal = "pending";
    });
    builder.addCase(mfEditorGetModals.fulfilled, (state, action) => {
      state.loadingStatusModal = "resolved";
      if (action.payload) {
        const temps: MfTemplateTouple[] = [];
        action.payload.forEach((modal) => {
          temps.push({ id: modal.id, name: modal.name });
        });
        state.modals = temps;
      }
    });
    builder.addCase(mfEditorGetModals.rejected, (state, action) => {
      state.loadingStatusModal = "rejected";
      state.error = "Error fetching modals: " + action.payload;
    });
    //GetModalById
    builder.addCase(mfEditorGetModalById.pending, (state) => {
      state.loadingStatusModal = "pending";
    });
    builder.addCase(mfEditorGetModalById.fulfilled, (state, action) => {
      state.loadingStatusModal = "resolved";
      state.currentModal = action.payload;
      state.edited = false;
    });
    builder.addCase(mfEditorGetModalById.rejected, (state, action) => {
      state.loadingStatusModal = "rejected";
      state.error = "Error fetching modal: " + action.payload;
    });
    //UpdateModal
    builder.addCase(mfEditorUpdateModal.pending, (state) => {
      state.loadingStatusModal = "pending";
    });
    builder.addCase(mfEditorUpdateModal.fulfilled, (state) => {
      state.loadingStatusModal = "idle";
      state.edited = false;
      state.currentModalId = undefined;
    });
    builder.addCase(mfEditorUpdateModal.rejected, (state, action) => {
      state.loadingStatusModal = "rejected";
      state.error = "Error updating modal: " + action.payload;
    });
    //CreateModal
    builder.addCase(mfEditorCreateModal.pending, (state) => {
      state.loadingStatusModal = "pending";
    });
    builder.addCase(mfEditorCreateModal.fulfilled, (state) => {
      state.loadingStatusModal = "idle";
      state.edited = false;
      state.currentModalId = undefined;
    });
    builder.addCase(mfEditorCreateModal.rejected, (state, action) => {
      state.loadingStatusModal = "rejected";
      state.error = "Error creating modal: " + action.payload;
    });
  },
});

export const {
  selectActiveTemplate,
  updateDrawableTemplate,
  updateFormTemplate,
  updatePdfTemplate,
  updateModal,
  selectActiveModal,
} = mfEditorSlice.actions;

export default mfEditorSlice.reducer;

export const selectMfLoadingStatus = (state: RootState) =>
  state.mfEditor.loadingStatus;
export const selectMfTemplates = (state: RootState) => state.mfEditor.templates;
export const selectMfTemplateId = (state: RootState) =>
  state.mfEditor.templateId;
export const selectMfDrawableTemplate = (state: RootState) =>
  state.mfEditor.drawableFormTemplate;
export const selectMfFormTemplate = (state: RootState) =>
  state.mfEditor.formTemplate;
export const selectMfPdfTemplate = (state: RootState) =>
  state.mfEditor.pdfTemplate;
export const selectMfEdited = (state: RootState) => state.mfEditor.edited;
export const selectMfError = (state: RootState) => state.mfEditor.error;
//Modals
export const selectMfModals = (state: RootState) => state.mfEditor.modals;
export const selectMfCurrentModalId = (state: RootState) =>
  state.mfEditor.currentModalId;
export const selectMfCurrentModal = (state: RootState) =>
  state.mfEditor.currentModal;
export const selectMfLoadingStatusModal = (state: RootState) =>
  state.mfEditor.loadingStatusModal;
