import { useEffect, useState } from "react";
import { usePagination } from "pagination-react-js";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LoadingOverlay from "react-loading-overlay-ts";
import ClipLoader from "react-spinners/ClipLoader";
import { changeCurrentProduct, selectRoles } from "../../../common/commonSlice";
import isEmail from "validator/lib/isEmail";
import {
  changePageState,
  getFilteredFlows,
  getHyperFlowStats,
  selectCurrentFilterRequest,
  selectFlowFiltered,
  selectNumPagesHyperFlow,
  selectStatsFlow,
  selectCurrentPageHyperFlow,
  selectLoadingList,
  resetFlow,
  selectFilteredOptions,
  selectUserImages,
  selectExecuteFlowResponse,
  emptyFlowExecution,
  executeFlow,
  sendLinkByEmail,
  selectLoadingSendLink,
  selectSentFlowLink,
  selectSentFlowLinkFilters,
  selectSentFlowLinkLoading,
  setSentLinkResponse,
  changeSentLinkFilters,
  getSentFlowLink,
  sendLinkToUnregistered,
  selectDownloadingSentLinkReport,
  generateSentLinkReport,
  changePageSize,
  selectPageSizeHyperFlow,
} from "../hyperFlowSlice";
import {
  createTag,
  disassociateTag,
  getAllTags,
  getCurrentTagsByService,
  selectAllTags,
  selectCurrentService,
  selectCurrentTagCreation,
  selectCurrentTagsByService,
  selectLoadingAllTags,
  selectLoadingCurrentTagsByService,
  setTagCreation,
} from "../../tags/tagSlice";
import { formatDateStandar, getStatusFlow } from "../../../../helpers/utils";
import {
  getPossibleProjects,
  selectPossibleProjects,
} from "../../../configuration/clients/clientsSlice";
import Modal, { Styles } from "react-modal";
import { ReactComponent as DeleteTag } from "../../../../Assets/common/images/deleteTag.svg";
import { ReactComponent as AddTag } from "../../../../Assets/common/images/addTag.svg";
import { DropDown } from "../../../common/components/Btns/DropDown";
import { SendFlowLinkRequest } from "../hyperFlowAPI";
import * as XLSX from "xlsx";
import {
  getFilteredLeads,
  selectCurrentLeads,
  selectLeadsLoading,
  setLeads,
  toggleCheckedLead,
} from "../../coreid/coreIdSlice";
import PaginationItem from "../../../../helpers/PaginationItem";

const customStylesModal: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "75%",
    width: "65%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

const customStylesModal2: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",
    height: "35%",
    width: "45%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};

const customStylesModal3: Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255,255,255,1)",

    width: "80%",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "1%",
  },
  overlay: {
    background: "rgba(0,0,0,0.6)",
  },
};
Modal.setAppElement("#root");

export const FlowsList = () => {
  let numPages = useAppSelector(selectNumPagesHyperFlow);
  const pageSize = useAppSelector(selectPageSizeHyperFlow);

  let loadingList = useAppSelector(selectLoadingList);
  let flows = useAppSelector(selectFlowFiltered);
  let currentFilteredRequest = useAppSelector(selectCurrentFilterRequest);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let miniDashboardData = useAppSelector(selectStatsFlow);
  let currentPage = useAppSelector(selectCurrentPageHyperFlow);
  let filterOptions = useAppSelector(selectFilteredOptions);
  let currentImages = useAppSelector(selectUserImages);

  let possibleProjects = useAppSelector(selectPossibleProjects);
  let executeFlowResponse = useAppSelector(selectExecuteFlowResponse);
  let roles = useAppSelector(selectRoles);

  // Tag Stuff
  let currentService = useAppSelector(selectCurrentService);
  let loadingAllTags = useAppSelector(selectLoadingAllTags);
  let loadingTagsByService = useAppSelector(selectLoadingCurrentTagsByService);
  let allTags = useAppSelector(selectAllTags);
  let currentServiceTags = useAppSelector(selectCurrentTagsByService);
  let tagCreation = useAppSelector(selectCurrentTagCreation);
  const [addTagWindow, setAddTagWindow] = useState({ entityId: "" });

  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [emailsToSend, setEmailsToSend] = useState<string[]>([]);
  const [modalEmailMessage, setModalEmailMessage] = useState(false);
  const [inputSubject, setInputSubject] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const loadingSendLink = useAppSelector(selectLoadingSendLink);

  //Leads

  const leads = useAppSelector(selectCurrentLeads);
  const loadingLeads = useAppSelector(selectLeadsLoading);
  const [leadPage, setLeadPage] = useState(0);
  const [modalLead, setModalLead] = useState(false);

  const [modalExecuteFlowSent, setModalExecuteFlowSent] = useState(false);
  const [resendFlowMessageModal, setResendFlowMessageModal] = useState(false);
  const sentLinkFlow = useAppSelector(selectSentFlowLink);
  const sentLinkFlowFilters = useAppSelector(selectSentFlowLinkFilters);
  const sentLinkFlowLoading = useAppSelector(selectSentFlowLinkLoading);
  const downloadingSentFlowReport = useAppSelector(
    selectDownloadingSentLinkReport
  );

  const Pagination = () => {
    const { records, pageNumbers, setActivePage, setRecordsPerPage } =
      usePagination({
        activePage: currentPage + 1,
        recordsPerPage: pageSize || 10,
        totalRecordsLength: numPages * 10,
        offset: 1,
        navCustomPageSteps: { prev: 5, next: 5 },
        permanentFirstNumber: true,
        permanentLastNumber: true,
      });

    function updateActivePage(pageNumber: number | false) {
      pageNumber && dispatch(changePageState(pageNumber - 1));
      pageNumber && setActivePage(pageNumber);
    }

    return (
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "3%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            style={{
              width: "50px",
              height: "30px",
              background: "#0a2a39",
              color: "white",
              border: "none",
              borderRadius: "8px",
              marginRight: "5px",
            }}
            value={pageSize || 10}
            onChange={(e) => {
              dispatch(changePageSize(parseInt(e.target.value)));
              setRecordsPerPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
          <p style={{ fontSize: "13px", color: "white", margin: "0 0 0 3px " }}>
            Registros por página
          </p>
        </div>
        <nav role="navigation" aria-label="Paginación">
          <ul className="pagination">
            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&laquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto previous page ${pageNumbers.previousPage}`}
              onClick={() => updateActivePage(pageNumbers.previousPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&lsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto first page ${pageNumbers.firstPage}`}
              active={pageNumbers.firstPage === pageNumbers.activePage}
              onClick={() => updateActivePage(pageNumbers.firstPage)}
            >
              {pageNumbers.firstPage}
            </PaginationItem>

            {pageNumbers.customPreviousPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customPreviousPage}`}
                onClick={() => updateActivePage(pageNumbers.customPreviousPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.navigation.map((navigationNumber) => {
              const isFirstOrLastPage =
                navigationNumber === pageNumbers.firstPage ||
                navigationNumber === pageNumbers.lastPage;

              return isFirstOrLastPage ? null : (
                <PaginationItem
                  label={`Goto page ${navigationNumber}`}
                  key={navigationNumber}
                  active={navigationNumber === pageNumbers.activePage}
                  onClick={() => updateActivePage(navigationNumber)}
                >
                  {navigationNumber}
                </PaginationItem>
              );
            })}

            {pageNumbers.customNextPage && (
              <PaginationItem
                label={`Goto page ${pageNumbers.customNextPage}`}
                onClick={() => updateActivePage(pageNumbers.customNextPage)}
              >
                &middot;&middot;&middot;
              </PaginationItem>
            )}

            {pageNumbers.firstPage !== pageNumbers.lastPage && (
              <PaginationItem
                label={`Goto last page ${pageNumbers.lastPage}`}
                active={pageNumbers.lastPage === pageNumbers.activePage}
                onClick={() => updateActivePage(pageNumbers.lastPage)}
              >
                {pageNumbers.lastPage}
              </PaginationItem>
            )}

            <PaginationItem
              label={`Goto next page ${pageNumbers.nextPage}`}
              onClick={() => updateActivePage(pageNumbers.nextPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&rsaquo;</p>
            </PaginationItem>

            <PaginationItem
              label={`Goto last page ${pageNumbers.lastPage}`}
              onClick={() => updateActivePage(pageNumbers.lastPage)}
            >
              <p style={{ fontSize: "25px", margin: "0px" }}>&raquo;</p>
            </PaginationItem>
          </ul>
        </nav>
        <div style={{ height: "10px", width: "10px" }}></div>
      </div>
    );
  };

  const canExecute = () => {
    if (roles.includes("hyperflow.execute")) {
      return true;
    } else {
      return false;
    }
  };

  const canCheckNotCompleted = () => {
    if (
      roles.includes("hyperflow.checkleads") ||
      roles.includes("smartflow.admin") ||
      roles.includes("smartflow.edit") ||
      roles.includes("smartflow.view")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getSentFlowStatus = (status: string) => {
    switch (status) {
      case "PENDING":
        return "Pendiente de iniciar";
      case "STARTED":
        return "Empezó proceso";
      case "STARTEDCOREID":
        return "Empezó Verificación de Identidad";
      case "COREID":
        return "Identidad digital creada";
      case "COMPLETE":
        return "Completado";
      default:
        return "Pendiente de iniciar";
    }
  };

  const getLinkFromId = (type: "hyperflow" | "coreid", id?: string | null) => {
    let path = "";
    if (id) {
      if (type === "hyperflow") {
        path = `/services/hyperFlow/flows/${id}`;
      } else if (type === "coreid") {
        path = `/services/coreId/registrations/${id}`;
      }
      return (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "underline",
          }}
        >
          {id}
        </a>
      );
    } else {
      return "No se ha registrado";
    }
  };
  const getHyperFlowName = (hyperFlowId: string): string => {
    if (filterOptions?.hyperFlows) {
      if (filterOptions.hyperFlows[hyperFlowId]) {
        return filterOptions.hyperFlows[hyperFlowId];
      } else {
        return hyperFlowId;
      }
    } else {
      return hyperFlowId;
    }
  };

  const getTagsRender = (entityId: string) => {
    if (loadingTagsByService == "pending") {
      return <ClipLoader color="white" size="20px" />;
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridRowGap: "5px",
          }}
        >
          {currentServiceTags.map((tag) => {
            if (tag.associatedEntities.includes(entityId)) {
              return (
                <div
                  className="flex-row"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.25)",
                    borderRadius: "8px",
                    padding: "3px 8px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "11px",
                      fontWeight: 600,
                      color: "#fff",
                      marginRight: "5px",
                    }}
                  >
                    {tag.tag}
                  </div>
                  {roles.includes("tags.edit") && (
                    <DeleteTag
                      onClick={() => {
                        dispatch(
                          disassociateTag({
                            tagId: tag.id,
                            entityId: entityId,
                            service: "HYPERFLOW",
                          })
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              );
            } else return "";
          })}
          <div>
            {roles.includes("tags.edit") && (
              <AddTag
                onClick={() => {
                  if (addTagWindow.entityId === entityId) {
                    setAddTagWindow({ entityId: "" });
                  } else {
                    setAddTagWindow({ entityId: entityId });
                  }
                }}
                style={{ cursor: "pointer", margin: "5px 0 5px 5px" }}
              />
            )}
            <div style={{ marginLeft: "-100px" }}>
              {addTagWindow.entityId === entityId && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <div
                    style={{
                      width: "203px",
                      height: "133px",
                      background: "#1a4f64",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      padding: "8px 5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        placeholder="Agregar etiqueta"
                        style={{
                          height: "28px",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          padding: "9px 8px",
                          fontSize: "11px",
                          fontWeight: 600,
                        }}
                        value={tagCreation.tag || ""}
                        onChange={(e) => {
                          dispatch(
                            setTagCreation({
                              tagId: "",
                              tag: e.target.value,
                              entityId: entityId,
                              service: "HYPERFLOW",
                            })
                          );
                        }}
                      />
                      <button
                        style={{
                          height: "28px",
                          width: "28px",
                          background: "rgba(0, 0, 0, 0.25)",
                          borderRadius: "8px",
                          color: "white",
                          border: "none",
                          fontSize: "11px",
                          fontWeight: 600,
                          marginLeft: "5px",
                          visibility:
                            tagCreation.tag === "" ? "hidden" : "visible",
                        }}
                        onClick={() => {
                          if (tagCreation.tag === "") return;
                          let tagExists = allTags.find(
                            (tag) => tag.tag === tagCreation.tag
                          );
                          if (tagExists) {
                            dispatch(
                              setTagCreation({
                                tagId: tagExists.id,
                                entityId: entityId,
                                service: "HYPERFLOW",
                                tag: null,
                              })
                            );
                          }
                          dispatch(createTag());
                        }}
                      >
                        <AddTag style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "9px",
                        fontWeight: 600,
                        margin: "5px 0px",
                      }}
                    >
                      O seleccione una etiqueta existente
                    </p>
                    <div
                      placeholder="Agregar etiqueta"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2,1fr)",
                        gridRowGap: "5px",
                        height: "70px",
                        width: "100%",
                        background: "rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        color: "white",
                        border: "none",
                        padding: "9px 8px",
                        fontSize: "11px",
                        fontWeight: 600,
                        overflow: "scroll",
                      }}
                    >
                      {allTags.map((tag) => {
                        return (
                          <button
                            onClick={() => {
                              dispatch(
                                setTagCreation({
                                  tagId: tag.id,
                                  tag: tag.tag,
                                  entityId: entityId,
                                  service: "HYPERFLOW",
                                })
                              );
                            }}
                            style={{
                              backgroundColor: "rgba(0,0,0,0.25)",
                              borderRadius: "8px",
                              padding: "5px 8px",
                              marginRight: "5px",
                              marginLeft: "5px",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "none",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "9px",
                                fontWeight: 600,
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            >
                              {tag.tag}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  const [modalExecuteFlow, setModalExecuteFlow] = useState(false);
  const [modalExecuteFlowMultiple, setModalExecuteFlowMultiple] =
    useState(false);
  const [executeFlowOptions, setExecuteFlowOptions] =
    useState<{ value: string; label: string }[]>();
  const [projectSelected, setProjectSelected] = useState<string>("");

  const executedFlowSentStatusOptions = [
    { value: "PENDING", label: "Pendiente de iniciar" },
    { value: "STARTED", label: "Empezó proceso" },
    { value: "STARTEDCOREID", label: "Empezó Verificación de Identidad" },
    { value: "COREID", label: "Identidad digital creada" },
    { value: "COMPLETE", label: "Completado" },
  ];
  const closeModalExceuteFlow = () => {
    dispatch(emptyFlowExecution());
    setEmailsToSend([]);
    setInputSubject("");
    setInputMessage("");
    setFileErrorMessage("");
    setInputEmail("");
    setModalExecuteFlow(false);
  };

  const closeModalExecuteFlowSent = () => {
    setModalExecuteFlowSent(false);
  };

  const closeModalResendFlow = () => {
    setModalExecuteFlowSent(true);
    setResendFlowMessageModal(false);
  };
  const closeModalExecuteFlowMultiple = () => {
    setModalExecuteFlowMultiple(false);
  };

  const closeModalEmailMessage = () => {
    setModalExecuteFlow(true);
    setModalEmailMessage(false);
  };

  useEffect(() => {
    if (currentImages) {
      dispatch(resetFlow());
    }
    dispatch(changeCurrentProduct("HyperFlow"));
    dispatch(getHyperFlowStats());

    dispatch(
      getFilteredFlows({
        ...currentFilteredRequest,
      })
    );
    if (!possibleProjects || possibleProjects.length === 0) {
      dispatch(getPossibleProjects());
    }
    if (executeFlowResponse) {
      setModalExecuteFlow(true);
    }

    if (loadingAllTags == "idle") {
      dispatch(getAllTags());
    }

    if (currentService !== "HYPERFLOW") {
      dispatch(getCurrentTagsByService("HYPERFLOW"));
    }
    return () => {};
  }, [currentFilteredRequest, executeFlowResponse]);

  useEffect(() => {
    if (leads?.currentPage !== leadPage) {
      dispatch(
        getFilteredLeads({
          page: leadPage,
          hyperflow: true,
        })
      );
    }
  }, [leadPage]);

  useEffect(() => {
    if (sentLinkFlowFilters.page !== 0) {
      dispatch(getSentFlowLink());
    }
  }, [sentLinkFlowFilters]);
  useEffect(() => {
    if (loadingSendLink === "resolved") {
      setEmailsToSend([]);
      setInputSubject("");
      setInputMessage("");
      setModalEmailMessage(false);
      setResendFlowMessageModal(false);
    }
  }, [loadingSendLink]);
  return (
    <LoadingOverlay
      active={loadingList === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <Modal
        isOpen={modalLead}
        onRequestClose={() => {
          setModalLead(false);
          dispatch(setLeads(null));
          setLeadPage(0);
        }}
        style={customStylesModal3}
        contentLabel="Leads"
        shouldCloseOnOverlayClick={true}
      >
        {loadingLeads === "pending" ? (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <ClipLoader color="#49829C" size={200} />
            <p style={{ color: "black" }}>Cargando leads...</p>
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {" "}
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "end",
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  setModalLead(false);
                  dispatch(setLeads(null));
                  setLeadPage(0);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "24px",
                  background: "#1A617D",
                  width: "40px",
                  height: "35px",
                  color: "white",
                }}
              >
                X
              </button>
            </div>
            <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
              Lista de posibles leads
            </h4>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000",
                width: "80%",
              }}
            >
              Esta lista muestra los usuarios que intentaron registrarse pero
              que no completaron el flujo, aquí puede hacerle seguimiento y
              obtener información de contacto.
            </p>{" "}
            {leads && leads.registrations.length > 0 ? (
              <table
                style={{
                  width: "98%",
                  borderCollapse: "collapse",
                  marginLeft: "1%",
                  background: "#49829C",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Nombre y Apellido</th>
                    <th>Correo Electrónico</th>
                    <th>No. Celular</th>
                    <th>Identificación</th>
                    <th>Intentos</th>
                    <th>Fecha último intento</th>
                    <th>¿Revisado?</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {leads.registrations.map((lead, i) => {
                    return (
                      <tr
                        key={i}
                        className={`list-hover`}
                        style={{
                          height: "30px",
                          color: "white",
                        }}
                      >
                        <td></td>
                        <td>{lead.fullName}</td>
                        <td>{lead.email}</td>
                        <td>{lead.phoneNumber}</td>
                        <td>{lead.userIdentification}</td>
                        <td>{lead.tries}</td>
                        <td>{formatDateStandar(lead.dateTime)}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={lead.checked}
                            onChange={(e) => {
                              dispatch(
                                toggleCheckedLead({
                                  userIdentification: lead.userIdentification!,
                                })
                              );
                              dispatch(
                                setLeads({
                                  ...leads,
                                  registrations: leads.registrations.map(
                                    (item) => {
                                      if (item.email === lead.email) {
                                        return {
                                          ...item,
                                          checked: e.target.checked,
                                        };
                                      } else {
                                        return item;
                                      }
                                    }
                                  ),
                                })
                              );
                            }}
                          />
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div
                className="flex-col"
                style={{
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  color: "black",
                }}
              >
                No hay leads en esta página <br />
                Puedes revisar las páginas anteriores o siguientes para
                encontrar más leads
              </div>
            )}
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "center",
                height: "100px",
              }}
            >
              <div
                className="flex-row text-bold primary-color"
                style={{
                  fontSize: "13px",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (leadPage > 0) {
                      setLeadPage((x) => x - 1);
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                    boxSizing: "border-box",
                  }}
                >
                  {"<"}
                </button>
                Página {leadPage + 1} de {leads?.numPages || 0}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (leadPage < leads?.numPages! - 1) {
                      setLeadPage((x) => x + 1);
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={modalExecuteFlow}
        onRequestClose={closeModalExceuteFlow}
        style={customStylesModal}
        contentLabel="Ejecución de Hyperflow"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            overflow: "auto",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
            }}
          >
            <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
              Elige los correos a los cuales deseas enviar el enlace del flujo
            </h5>
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                borderRadius: "8px",
                width: "250px",
                background: "#1A617D",
                padding: "8px",
              }}
              htmlFor="fileInput"
            >
              <input
                style={{
                  display: "none",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onChange={async (e) => {
                  let file = e.target.files ? e.target.files[0] : null;
                  let type = "";
                  if (file) {
                    type = file.type;
                    const reader = new FileReader();

                    try {
                      if (
                        type === "image/csv" ||
                        type === "text/plain" ||
                        type === "application/vnd.ms-excel"
                      ) {
                        reader.onload = async (e) => {
                          if (e.target && e.target.result) {
                            const result = e.target.result as string;
                            const rows = result
                              .split("\n")
                              .map((row) => row.trim());

                            let emails: string[] = [];
                            let invalidEmails: string[] = [];

                            for (const row of rows) {
                              const rowArray = row.split(/,|;|\t|\|/);
                              for (const item of rowArray) {
                                if (isEmail(item)) {
                                  if (emails.includes(item)) continue;
                                  emails.push(item);
                                } else {
                                  if (item.includes("@")) {
                                    invalidEmails.push(item);
                                  }
                                }
                              }
                            }

                            if (invalidEmails.length > 0) {
                              setFileErrorMessage(
                                `Los siguientes correos no son válidos: ${invalidEmails.join(
                                  ", "
                                )}`
                              );
                              setEmailsToSend([...emailsToSend, ...emails]);
                            } else {
                              setEmailsToSend([...emailsToSend, ...emails]);
                            }
                          } else {
                            setFileErrorMessage("Formato no soportado");
                          }
                        };
                        reader.readAsText(file);
                        setFileErrorMessage("");
                      } else if (
                        type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      ) {
                        reader.onload = (e) => {
                          if (e.target && e.target.result) {
                            const data = new Uint8Array(
                              e.target.result as ArrayBuffer
                            );
                            const workbook = XLSX.read(data, { type: "array" });

                            // Assuming you want to read the first sheet
                            const sheetName = workbook.SheetNames[0];
                            const sheet = workbook.Sheets[sheetName];

                            // Convert sheet to array of objects
                            const jsonData = XLSX.utils.sheet_to_json(sheet, {
                              header: 1,
                            }) as any[][];

                            let emails: string[] = [];
                            let invalidEmails: string[] = [];
                            for (const row of jsonData) {
                              for (const item of row) {
                                // check if item is string
                                if (item && typeof item === "string") {
                                  const itemTrimed = item.trim();
                                  if (isEmail(itemTrimed)) {
                                    if (emails.includes(itemTrimed)) continue;
                                    emails.push(itemTrimed);
                                  } else {
                                    if (itemTrimed.includes("@")) {
                                      invalidEmails.push(itemTrimed);
                                    }
                                  }
                                }
                              }
                            }
                            console.log(emails);
                            if (invalidEmails.length > 0) {
                              if (invalidEmails.length > 10) {
                                setFileErrorMessage(
                                  `Los siguientes correos no son válidos: ${invalidEmails
                                    .slice(0, 10)
                                    .join(", ")} y otros ${
                                    invalidEmails.length - 10
                                  } correos no son válidos`
                                );
                              } else {
                                setFileErrorMessage(
                                  `Los siguientes correos no son válidos: ${invalidEmails.join(
                                    ", "
                                  )}`
                                );
                              }
                              setEmailsToSend([...emailsToSend, ...emails]);
                            } else {
                              setEmailsToSend([...emailsToSend, ...emails]);
                            }
                          } else {
                            setFileErrorMessage("Formato no soportado");
                          }
                        };

                        reader.readAsArrayBuffer(file);
                      } else {
                        console.log(type, "formato no soportado 1");
                        setFileErrorMessage("Formato no soportado");
                      }

                      reader.onerror = (e) => {
                        console.log(type, "formato no soportado 2");
                        setFileErrorMessage("Formato no soportado");
                      };
                    } catch (e) {
                      console.log(type, "formato no soportado 3");
                      setFileErrorMessage("Formato no soportado");
                    }
                  } else {
                    setFileErrorMessage("No se ha seleccionado un archivo");
                  }
                }}
                type="file"
                id="fileInput"
              />

              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "white",
                  margin: "0 0 0 14px",
                }}
              >
                Agregar desde un archivo excel/csv
              </div>
            </label>
            {fileErrorMessage && (
              <p
                style={{
                  color: "rgb(212, 0, 11)",

                  fontSize: "10px",
                }}
              >
                {fileErrorMessage}{" "}
              </p>
            )}
            <p
              style={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#000",
                margin: 0,
              }}
            >
              ó
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#000",
                margin: 0,
              }}
            >
              Ingresa un correo
            </p>
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <div
                className="flex-row"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <input
                  style={{
                    width: "70%",
                    height: "40px",
                    border:
                      inputEmail.length > 0 && !isEmail(inputEmail)
                        ? "1px solid rgba(200,0,12,0.3)"
                        : "1px solid rgba(0,0,0,0.3)",
                    boxSizing: "border-box",
                    padding: "10px",
                    borderRadius: "6px",
                    textAlign: "start",
                  }}
                  type="email"
                  value={inputEmail}
                  onChange={(e) => {
                    setInputEmail(e.target.value);
                  }}
                />
                <button
                  onClick={(e) => {
                    setEmailsToSend([...emailsToSend, inputEmail]);
                    setInputEmail("");
                  }}
                  disabled={inputEmail.length === 0 || !isEmail(inputEmail)}
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px 8px",
                    background: "rgba(13,60,80,1)",
                    paddingTop: "5px",
                    marginLeft: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    color: "#fff",
                  }}
                >
                  Agregar
                </button>
              </div>
              {inputEmail.length > 0 && !isEmail(inputEmail) && (
                <p
                  style={{
                    width: "70%",
                    fontSize: "10px",
                    color: "#cf4259",
                    margin: "2px 0 0 0",
                    textAlign: "start",
                  }}
                >
                  El correo ingresado no es válido
                </p>
              )}
            </label>
          </div>
          <div
            className="flex-col"
            style={{
              height: emailsToSend.length > 10 ? "500px" : "auto",
              overflow: "auto",
              width: "70%",
              padding: "15px",
            }}
          >
            {emailsToSend.length > 0 && (
              <p style={{ fontSize: "12px", fontWeight: 700, color: "#000" }}>
                Correos a los cuales se enviará el enlace
              </p>
            )}
            {emailsToSend.map((email, i) => {
              return (
                <div
                  key={i}
                  className="flex-row"
                  style={{ alignItems: "center" }}
                >
                  <div
                    key={email}
                    className="flex-row"
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px 10px",
                      background: "rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                      margin: "5px 0",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#000",
                      }}
                    >
                      {email}
                    </div>
                  </div>
                  <button
                    style={{
                      marginLeft: "5px",
                      border: "none",
                      textAlign: "center",
                      height: "25px",
                      width: "25px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "9999px",
                      background: "rgba(13,60,80,1)",
                      color: "#fff",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setEmailsToSend(
                        emailsToSend.filter((item) => item !== email)
                      );
                    }}
                  >
                    x
                  </button>
                </div>
              );
            })}
          </div>
          <div
            className="flex-row"
            style={{
              justifyContent: "space-evenly",
              width: "100%",
              alignItems: "center",
            }}
          >
            <button
              disabled={emailsToSend.length === 0}
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                setModalEmailMessage(true);
                setModalExecuteFlow(false);
              }}
            >
              Enviar enlace
            </button>
            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                setEmailsToSend([]);
                closeModalExceuteFlow();
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalExecuteFlowMultiple}
        onRequestClose={closeModalExecuteFlowMultiple}
        style={customStylesModal2}
        contentLabel="Ejecución de Hyperflow - Escojer proyecto"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              height: "100%",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
              Escoge el proyecto sobre el cual deseas ejecutar un flujo de
              hyperflow
            </h5>

            <DropDown
              titleColor="black"
              key={"ProjectsExecute"}
              title="Proyecto"
              alignCenter
              options={executeFlowOptions!}
              onChange={(e) => {
                if (e) {
                  setProjectSelected(e.value);
                }
              }}
              isClearable={false}
            />

            <button
              className="text-medium text-bold primary-color"
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "8px",
                background: "#1A617D",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingRight: "10%",
                paddingLeft: "10%",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(executeFlow(projectSelected));
                closeModalExecuteFlowMultiple();
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalEmailMessage}
        onRequestClose={closeModalEmailMessage}
        style={customStylesModal2}
        contentLabel="Ejecución de Hyperflow - Escojer mensaje"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              height: "100%",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
              Finalmente, escoge el asunto y descripción que tendrá el correo
            </h5>
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <input
                style={{
                  width: "70%",
                  height: "40px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "6px",
                  textAlign: "start",
                }}
                type="text"
                value={inputSubject}
                onChange={(e) => {
                  setInputSubject(e.target.value);
                }}
              />

              <p
                style={{
                  width: "70%",
                  fontSize: "10px",
                  color: "rgba(0,0,0,0.3)",
                  margin: "2px 0 0 0",
                  textAlign: "start",
                }}
              >
                Si no escoje un asunto, se enviará el asunto por defecto ("Flujo
                de vinculación")
              </p>
            </label>
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <input
                style={{
                  width: "70%",
                  height: "40px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "6px",
                  textAlign: "start",
                }}
                type="text"
                value={inputMessage}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                }}
              />

              <p
                style={{
                  width: "70%",
                  fontSize: "10px",
                  color: "rgba(0,0,0,0.3)",
                  margin: "2px 0 0 0",
                  textAlign: "start",
                }}
              >
                Si no escoje una descripción, se enviará la descripción por
                defecto ("Se le solicita que complete el siguiente flujo de
                vinculación")
              </p>
            </label>
            <div
              className="flex-row"
              style={{
                justifyContent: "space-evenly",
                width: "100%",
                alignItems: "center",
              }}
            >
              <button
                disabled={
                  emailsToSend.length === 0 || loadingSendLink === "pending"
                }
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  let sendLinkRequest: SendFlowLinkRequest = {
                    emails: emailsToSend,
                    link: executeFlowResponse?.link!,
                    message: inputMessage,
                    subject: inputSubject,
                  };
                  dispatch(sendLinkByEmail(sendLinkRequest));
                }}
              >
                {loadingSendLink === "pending" ? (
                  <ClipLoader color="#fff" size={25} />
                ) : (
                  "Confirmar"
                )}
              </button>
              <button
                disabled={loadingSendLink === "pending"}
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setModalEmailMessage(false);
                  setModalExecuteFlow(true);
                }}
              >
                {loadingSendLink === "pending" ? (
                  <ClipLoader color="#fff" size={25} />
                ) : (
                  "Cancelar"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onRequestClose={closeModalExecuteFlowSent}
        isOpen={modalExecuteFlowSent}
        style={customStylesModal3}
        contentLabel="Ejecución de Hyperflow - Seguimiento"
        shouldCloseOnOverlayClick={true}
      >
        {sentLinkFlowLoading === "pending" ? (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <ClipLoader color="#49829C" size={200} />
            <p style={{ color: "black" }}>Cargando usuarios...</p>
          </div>
        ) : (
          <div
            className="flex-col"
            style={{
              height: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "end",
                marginTop: "10px",
                marginBottom: "10px",
                marginRight: "10px",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  setModalExecuteFlowSent(false);
                  dispatch(
                    setSentLinkResponse({ numPages: 0, sentLinkFlows: [] })
                  );
                  dispatch(changeSentLinkFilters({ page: 0, status: null }));
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "24px",
                  background: "#1A617D",
                  width: "40px",
                  height: "35px",
                  color: "white",
                }}
              >
                X
              </button>
            </div>
            <h4 style={{ fontSize: "20px", fontWeight: 700, color: "#000" }}>
              Lista de usuarios con enlace enviado
            </h4>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 500,
                color: "#000",
                width: "80%",
              }}
            >
              Esta es la lista de los usuarios a los que se les ha enviado un
              correo electrónico con el enlace del flujo de vinculación, y se le
              puede realizar seguimiento
            </p>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                {" "}
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Aquí puedes filtrar por el estado actual del usuario
                </p>
                <DropDown
                  titleColor="black"
                  key={"Estado"}
                  title="Estado"
                  alignCenter
                  options={executedFlowSentStatusOptions}
                  onChange={(e) => {
                    if (e) {
                      dispatch(
                        changeSentLinkFilters({ status: e.value, page: 1 })
                      );
                    }
                  }}
                  isClearable={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  Aquí puedes reenviar el correo a los usuarios que no se han
                  registrado aún
                </p>

                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "8px",
                    background: "#1A617D",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "200px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setResendFlowMessageModal(true);
                    setModalExecuteFlowSent(false);
                  }}
                >
                  Reenviar enlaces a usuarios no registrados
                </button>
              </div>
            </div>
            {sentLinkFlow.sentLinkFlows &&
            sentLinkFlow.sentLinkFlows.length > 0 ? (
              <table
                style={{
                  width: "98%",
                  borderCollapse: "collapse",
                  marginLeft: "1%",
                  background: "#49829C",
                }}
              >
                <thead
                  className="text-normal text-medium"
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: "30px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <tr>
                    <th></th>
                    <th>Correo Electrónico</th>
                    <th>Estado</th>
                    <th>Fecha de último envío</th>
                    <th>Veces enviado</th>
                    <th>Registro CoreId</th>
                    <th>Registro Hyperflow</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  className={`text-normal text-x-small`}
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {sentLinkFlow.sentLinkFlows.map((sentLinkFlowItem, i) => {
                    return (
                      <tr
                        key={i}
                        className={`list-hover`}
                        style={{
                          height: "30px",
                          color: "white",
                        }}
                      >
                        <td></td>
                        <td>{sentLinkFlowItem.email}</td>
                        <td>{getSentFlowStatus(sentLinkFlowItem.status)}</td>
                        <td>
                          {formatDateStandar(sentLinkFlowItem.dateLastSent)}
                        </td>
                        <td>{sentLinkFlowItem.timesSent}</td>
                        <td>
                          {getLinkFromId(
                            "coreid",
                            sentLinkFlowItem.registrationId
                          )}
                        </td>
                        <td>
                          {getLinkFromId(
                            "hyperflow",
                            sentLinkFlowItem.hyperFlowId
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div
                className="flex-col"
                style={{
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign: "center",
                  color: "black",
                }}
              >
                No hay más usuarios en esta página <br />
              </div>
            )}
            {sentLinkFlow.sentLinkFlows &&
              sentLinkFlow.sentLinkFlows.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    margin: "10px 10px 0 0",
                    width: "100%",
                  }}
                >
                  <button
                    className="text-medium text-bold primary-color"
                    style={{
                      border: "none",
                      textAlign: "center",
                      borderRadius: "8px",
                      padding: "5px",
                      background: "#1A617D",
                      width: "160px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(generateSentLinkReport());
                    }}
                  >
                    {downloadingSentFlowReport === "pending" ? (
                      <ClipLoader color="#fff" size={25} />
                    ) : (
                      "Descargar reporte en PDF"
                    )}
                  </button>
                </div>
              )}
            <div
              className="flex-row"
              style={{
                justifyContent: "end",
                alignItems: "center",
                height: "100px",
              }}
            >
              <div
                className="flex-row text-bold primary-color"
                style={{
                  fontSize: "13px",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (sentLinkFlowFilters.page > 0) {
                      dispatch(
                        changeSentLinkFilters({
                          page: sentLinkFlowFilters.page - 1,
                          status: sentLinkFlowFilters.status,
                        })
                      );
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                    boxSizing: "border-box",
                  }}
                >
                  {"<"}
                </button>
                Página {sentLinkFlowFilters.page + 1} de{" "}
                {sentLinkFlow?.numPages || 0}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      sentLinkFlowFilters.page <
                      sentLinkFlow?.numPages! - 1
                    ) {
                      dispatch(
                        changeSentLinkFilters({
                          ...sentLinkFlowFilters,
                          page: sentLinkFlowFilters.page + 1,
                        })
                      );
                    }
                  }}
                  style={{
                    width: "30px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    fontSize: "15px",
                    height: "30px",
                    textAlign: "center",
                    border: "none",
                    background: "transparent",
                    color: "black",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={resendFlowMessageModal}
        onRequestClose={closeModalResendFlow}
        style={customStylesModal2}
        contentLabel="Ejecución de Hyperflow - Escojer mensaje"
        shouldCloseOnOverlayClick={true}
      >
        <div
          className="flex-col"
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="text-medium text-normal"
            style={{
              color: "black",
              height: "100%",
              marginBottom: "10px",
              paddingLeft: "5%",
              paddingRight: "5%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
              justifyContent: "space-between",
            }}
          >
            <h5 style={{ fontSize: "14px", fontWeight: 700, color: "#000" }}>
              Finalmente, escoge el asunto y descripción que tendrá el correo
            </h5>
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <input
                style={{
                  width: "70%",
                  height: "40px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "6px",
                  textAlign: "start",
                }}
                type="text"
                value={inputSubject}
                onChange={(e) => {
                  setInputSubject(e.target.value);
                }}
              />

              <p
                style={{
                  width: "70%",
                  fontSize: "10px",
                  color: "rgba(0,0,0,0.3)",
                  margin: "2px 0 0 0",
                  textAlign: "start",
                }}
              >
                Si no escoje un asunto, se enviará el asunto por defecto ("Flujo
                de vinculación")
              </p>
            </label>
            <label
              className="flex-col"
              style={{ width: "100%", alignItems: "center" }}
            >
              <input
                style={{
                  width: "70%",
                  height: "40px",
                  border: "1px solid rgba(0,0,0,0.3)",
                  boxSizing: "border-box",
                  padding: "10px",
                  borderRadius: "6px",
                  textAlign: "start",
                }}
                type="text"
                value={inputMessage}
                onChange={(e) => {
                  setInputMessage(e.target.value);
                }}
              />

              <p
                style={{
                  width: "70%",
                  fontSize: "10px",
                  color: "rgba(0,0,0,0.3)",
                  margin: "2px 0 0 0",
                  textAlign: "start",
                }}
              >
                Si no escoje una descripción, se enviará la descripción por
                defecto ("Se le solicita que complete el siguiente flujo de
                vinculación")
              </p>
            </label>
            <div
              className="flex-row"
              style={{
                justifyContent: "space-evenly",
                width: "100%",
                alignItems: "center",
              }}
            >
              <button
                disabled={loadingSendLink === "pending"}
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  let sendLinkRequest: SendFlowLinkRequest = {
                    message: inputMessage,
                    subject: inputSubject,
                  };
                  dispatch(sendLinkToUnregistered(sendLinkRequest));
                }}
              >
                {loadingSendLink === "pending" ? (
                  <ClipLoader color="#fff" size={25} />
                ) : (
                  "Confirmar"
                )}
              </button>
              <button
                disabled={loadingSendLink === "pending"}
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  textAlign: "center",
                  borderRadius: "8px",
                  background: "#1A617D",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setResendFlowMessageModal(false);
                  setModalExecuteFlowSent(true);
                }}
              >
                {loadingSendLink === "pending" ? (
                  <ClipLoader color="#fff" size={25} />
                ) : (
                  "Cancelar"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {flows && flows.length > 0 ? (
        <div className="flex-col" style={{ width: "100%", height: "100%" }}>
          {miniDashboardData && (
            <div
              className="flex-row"
              style={{
                height: "15%",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginLeft: "0px",
                marginRight: "20px",
                marginBottom: "3.5%",
                marginTop: "2%",
              }}
            >
              <div
                className="flex-col"
                style={{
                  alignItems: "center",
                  alignSelf: "end",
                  marginRight: "5%",
                }}
              >
                <div className="text-huge text-bold primary-color">
                  {miniDashboardData.total}
                </div>
                <div
                  className="text-medium text-bold primary-color-30"
                  style={{ width: "145px", textAlign: "center" }}
                >
                  Registros <br /> Totales
                </div>
              </div>
              <div
                className="flex-col"
                style={{ alignItems: "center", alignSelf: "end" }}
              >
                <div className="text-huge text-bold primary-color">
                  {miniDashboardData.rejected}
                </div>
                <div
                  className="text-medium text-bold primary-color-30"
                  style={{ width: "145px", textAlign: "center" }}
                >
                  Registros <br /> Rechazados
                </div>
              </div>
              <div
                className="flex-col"
                style={{ alignItems: "center", alignSelf: "end" }}
              >
                <div className="text-huge text-bold primary-color">
                  {miniDashboardData.accepted}
                </div>
                <div
                  className="text-medium text-bold primary-color-30"
                  style={{ width: "145px", textAlign: "center" }}
                >
                  Registros <br /> Aceptados
                </div>
              </div>
              <div
                className="flex-col"
                style={{ alignItems: "center", alignSelf: "end" }}
              >
                <div className="text-huge text-bold primary-color">
                  {miniDashboardData.pending}
                </div>
                <div
                  className="text-medium text-bold primary-color-30"
                  style={{ width: "145px", textAlign: "center" }}
                >
                  Registros <br />
                  Pendientes
                </div>
              </div>
              <div
                className="flex-col"
                style={{ alignItems: "center", alignSelf: "end" }}
              >
                <div className="text-huge text-bold primary-color">
                  {miniDashboardData.new}
                </div>
                <div
                  className="text-medium text-bold primary-color-30"
                  style={{ width: "145px", textAlign: "center" }}
                >
                  Nuevos <br />
                  Registros
                </div>
              </div>
            </div>
          )}
          <div
            className=" text-bold primary-color"
            style={{
              marginTop: "auto",
              marginRight: "2%",
              marginLeft: "10px",
              paddingRight: "30px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "30px",
              fontSize: "14px",
              width: "100%",
              textAlign: "center",
            }}
          >
            LISTA DE FLUJOS
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {canExecute() ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "1%",
                }}
              >
                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: "2%",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "30px",
                    borderRadius: "8px",
                    textAlign: "center",
                    width: "180px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (possibleProjects.length > 1) {
                      let projectOptions: { value: string; label: string }[] =
                        [];
                      for (const project of possibleProjects) {
                        let label = project.projectName;
                        let value = project.projectId;
                        projectOptions.push({ label, value });
                      }
                      setProjectSelected(possibleProjects[0].projectId);
                      setExecuteFlowOptions(projectOptions);
                      setModalExecuteFlowMultiple(true);
                    } else {
                      dispatch(executeFlow(possibleProjects[0].projectId));
                    }
                  }}
                >
                  Ejecutar Flujo
                </button>
                <button
                  className="text-medium text-bold primary-color"
                  style={{
                    border: "none",
                    marginRight: "2%",
                    marginLeft: "2%",
                    background: "rgba(0,0,0,0.4)",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "30px",
                    borderRadius: "8px",
                    textAlign: "center",
                    width: "230px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setModalExecuteFlowSent(true);
                    dispatch(getSentFlowLink());
                  }}
                >
                  Hacer Seguimiento
                </button>
              </div>
            ) : (
              <div></div>
            )}
            {canCheckNotCompleted() && (
              <button
                className="text-medium text-bold primary-color"
                style={{
                  border: "none",
                  marginRight: "2%",
                  marginLeft: "1%",
                  marginBottom: "1%",
                  background: "rgba(0,0,0,0.4)",
                  paddingRight: "10px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  borderRadius: "8px",
                  textAlign: "center",
                  cursor: "pointer",
                  width: "150px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setModalLead(true);
                  dispatch(
                    getFilteredLeads({
                      page: leadPage,
                      hyperflow: true,
                    })
                  );
                }}
              >
                Destacar No Completados
              </button>
            )}
          </div>
          {flows.length > 0 ? (
            <div className="flex-col" style={{ height: "60%" }}>
              <div style={{ overflow: "auto" }}>
                <table
                  style={{
                    width: "98%",
                    borderCollapse: "collapse",
                    marginLeft: "1%",
                  }}
                >
                  <thead
                    className="text-normal text-medium primary-color-35"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      height: "30px",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Nombre y Apellido</th>
                      <th>ID de flujo</th>
                      <th>Fecha de flujo</th>
                      <th>Nombre flujo</th>
                      <th>Estado</th>
                      {roles.includes("tags.view") ? (
                        <th>Etiquetas</th>
                      ) : (
                        <th>Alerta</th>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    className={`text-normal text-x-small`}
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {flows.map((flow, i) => {
                      if (flow) {
                        return (
                          <tr
                            key={i}
                            className={`list-hover ${
                              flow.registration && flow.registration.hasAlerts
                                ? "alert-color"
                                : "primary-color"
                            }`}
                            style={{
                              height: "30px",
                            }}
                          >
                            <td></td>
                            <td
                              style={{
                                textTransform: "uppercase",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFlow());
                                navigate(
                                  `/services/hyperFlow/flows/${flow.id}`
                                );
                              }}
                            >
                              {flow.registration && flow.registration.name
                                ? `${flow.registration.name} ${flow.registration.lastName}`
                                : flow.provisionalData?.name ||
                                  flow.provisionalData?.lastName
                                ? `${flow.provisionalData?.name} ${flow.provisionalData?.lastName}`
                                : ``}
                            </td>
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFlow());
                                navigate(
                                  `/services/hyperFlow/flows/${flow.id}`
                                );
                              }}
                            >
                              {flow ? flow.id : ""}
                            </td>
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFlow());
                                navigate(
                                  `/services/hyperFlow/flows/${flow.id}`
                                );
                              }}
                            >
                              {flow.registration &&
                              flow.registration.startDatetime
                                ? formatDateStandar(flow.startDatetime)
                                : ""}
                            </td>
                            <td
                              style={{
                                textTransform: "uppercase",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFlow());
                                navigate(
                                  `/services/hyperFlow/flows/${flow.id}`
                                );
                              }}
                            >
                              {getHyperFlowName(flow.hyperFlowId)}
                            </td>
                            <td
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFlow());
                                navigate(
                                  `/services/hyperFlow/flows/${flow.id}`
                                );
                              }}
                            >
                              {flow.flowStatus
                                ? getStatusFlow(
                                    flow.flowStatus,
                                    flow.acceptanceStatus
                                  )
                                : ""}
                            </td>
                            {roles.includes("tags.view") ? (
                              <td>{getTagsRender(flow.id)}</td>
                            ) : (
                              <td style={{ textTransform: "uppercase" }}>
                                {flow.registration &&
                                flow.registration.hasAlerts
                                  ? "SI"
                                  : "NO"}
                              </td>
                            )}
                            <td></td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      }
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex-col" style={{ height: "60%" }}>
              <h1 className="text-huge text-bold primary-color">
                {!(loadingList === "pending" || loadingList === "idle") &&
                  "No hay más registros en esta página"}
              </h1>
            </div>
          )}
          <Pagination />

          <div
            className="flex-row"
            style={{
              justifyContent: "end",
              alignItems: "center",
              height: "10%",
            }}
          >
            <div
              className="text-medium text-bold primary-color"
              style={{
                marginRight: "2%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetFlow());
                dispatch(changeCurrentProduct("HyperFlow"));
                navigate("/services/hyperFlow/flows/flowsrevision");
              }}
            >
              INGRESAR A <br /> MODO REVISIÓN
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex-col"
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {canExecute() && (
            <div
              className="text-medium text-bold primary-color"
              style={{
                marginRight: "2%",
                marginLeft: "1%",
                marginBottom: "1%",
                background: "rgba(0,0,0,0.4)",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
                width: "180px",
                visibility: canExecute() ? "visible" : "hidden",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (possibleProjects.length > 1) {
                  let projectOptions: { value: string; label: string }[] = [];
                  for (const project of possibleProjects) {
                    let label = project.projectName;
                    let value = project.projectId;
                    projectOptions.push({ label, value });
                  }
                  setProjectSelected(possibleProjects[0].projectId);
                  setExecuteFlowOptions(projectOptions);
                  setModalExecuteFlowMultiple(true);
                } else {
                  dispatch(executeFlow(possibleProjects[0].projectId));
                }
              }}
            >
              Ejecutar Flujo
            </div>
          )}
          <h1 className="text-huge text-bold primary-color">
            {loadingList !== "pending" &&
              flows &&
              flows.length === 0 &&
              "No hay más registros en esta página"}
          </h1>
        </div>
      )}
    </LoadingOverlay>
  );
};
