import axiosApiInstance from "../../../../helpers/interceptor";

let getMessagesPath = "api/Backoffice/editor/hyperflow/getMessages";
let getMessageByIdPath = (id: string) =>
  `api/Backoffice/editor/hyperflow/getMessageById/${id}`;
let updateMessagebyIdPath = (id: string) =>
  `api/Backoffice/editor/hyperflow/updateMessageById/${id}`;

const getMessagesAPI = async (): Promise<
  HfEditorResponseWrapper<HfMessageModel[]>
> => {
  try {
    let templates = await axiosApiInstance.get(getMessagesPath);
    if (templates.status !== 200) {
      return { success: false, data: [], error: `error: ${templates.status}` };
    }
    return { success: true, data: templates.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const getMessageAPI = async (
  id: string
): Promise<HfEditorResponseWrapper<HfMessageModel>> => {
  try {
    let template = await axiosApiInstance.get(getMessageByIdPath(id));
    if (template.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${template.status}`,
      };
    }
    return { success: true, data: template.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

const updateMessageAPI = async (
  id: string,
  message: HfMessageModel
): Promise<HfEditorResponseWrapper<HfMessageModel>> => {
  try {
    let template = await axiosApiInstance.put(
      updateMessagebyIdPath(id),
      message
    );
    if (template.status !== 200) {
      return {
        success: false,
        data: undefined,
        error: `error: ${template.status}`,
      };
    }
    return { success: true, data: template.data, error: "" };
  } catch (e: any) {
    return { success: false, data: undefined, error: e };
  }
};

export interface HfEditorResponseWrapper<T> {
  success: boolean;
  data?: T;
  error: string;
}

export interface HfMessageModel {
  id: string;
  clientId: string;
  hasConfetti: boolean;
  hasSadFace: boolean;
  isPopUp: boolean;
  messages: HfEditorMessage[];
  continueMessage: string;
}

export interface HfEditorMessage {
  message: string;
  color: string;
  size: string;
  stateIds: string[];
  fontWeight: string;
  linkUnderlined: boolean;
  linkColor?: string;
  align: "LEFT" | "RIGHT" | "CENTER" | "JUSTIFY";
}

export { getMessagesAPI, getMessageAPI, updateMessageAPI };
