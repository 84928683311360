import axiosApiInstance from "../../../helpers/interceptor";
import { RegistrationDetail } from "../coreid/coreIdAPI";

let filledFormsFilterOptionsMethod = "magicForms/filledForm/filterOptions";
let getFilledFormsByIdMethod = "magicForms/filledForm/getById";
let filledFormsGetFilteredMethod = "magicForms/filledForm/getFiltered";
let getTemplateByIdMethod = "magicForms/template/byId";
let getAllTemplatesMethod = "magicForms/template/getAll";
let getAttatchments = (filledFormId: string, stitchTypeId: string) =>
  `magicForms/filledForm/${filledFormId}/getFile/${stitchTypeId}`;
let getAttachmentZipPath = `magicForms/filledForm/getFilesZip`;
let getUserAttatchments = "magicForms/getUserAttachments";
let getPDFFilledFormsMethod = (id: string) =>
  `magicForms/filledForm/${id}/getPdf`;

let getPdfFilledFormsAPI = async (
  id: string
): Promise<{
  pdf: PdfBase64 | null;
  error: string;
}> => {
  let pdfInfo = await axiosApiInstance.get(`/${getPDFFilledFormsMethod(id)}`);
  if (pdfInfo.status !== 200) {
    return { pdf: null, error: "" };
  } else {
    return {
      error: "",
      pdf: pdfInfo.data,
    };
  }
};

let getFilledFormsFilterOptionsAPI = async (): Promise<{
  filterOptions: FilledFormFilterOptions | null;
  error: string;
}> => {
  let filterOptions = await axiosApiInstance.get(
    `/${filledFormsFilterOptionsMethod}`
  );
  if (filterOptions.status !== 200) {
    return { filterOptions: null, error: "" };
  } else {
    return {
      error: "",
      filterOptions: filterOptions.data,
    };
  }
};

let getFilledFormsFilteredAPI = async (
  params: FilledFormFilteredRequest
): Promise<{ forms: FilledFormFiltered | null; error: string }> => {
  let filledFormsResponse = await axiosApiInstance.post(
    `/${filledFormsGetFilteredMethod}`,
    params
  );
  if (filledFormsResponse.status !== 200) {
    return { forms: null, error: "error" };
  } else {
    return {
      forms: { ...filledFormsResponse.data, currentPage: params.page },
      error: "",
    };
  }
};

let getAllTemplatesAPI = async (): Promise<{
  templates: Array<Template>;
  error: string;
}> => {
  let allTemplates = await axiosApiInstance.get(`/${getAllTemplatesMethod}`);
  if (allTemplates.status !== 200) {
    return { error: "err", templates: [] };
  } else {
    return { error: "", templates: allTemplates.data };
  }
};

let getFilledFormByIdAPI = async (
  id: string
): Promise<{ filledForm: FilledForm | null; error: string }> => {
  let filledForm = await axiosApiInstance.get(
    `/${getFilledFormsByIdMethod}/${id}`
  );

  if (filledForm.status !== 200) {
    return { error: "error", filledForm: null };
  } else {
    return { error: "", filledForm: filledForm.data };
  }
};

let getTemplateByIdAPI = async (
  id: string
): Promise<{ template: Template | null; error: string }> => {
  let templateResponse = await axiosApiInstance.get(
    `/${getTemplateByIdMethod}/${id}`
  );

  if (templateResponse.status !== 200) {
    return { error: "err", template: null };
  } else {
    return { error: "", template: templateResponse.data };
  }
};

let getUserAttatchmentsAPI = async (
  userId: string
): Promise<Array<Attatchment>> => {
  let attatchmentsInfo = await axiosApiInstance.get(
    `/${getUserAttatchments}/${userId}`
  );
  if (attatchmentsInfo.status !== 200) {
    return [];
  } else {
    return attatchmentsInfo.data;
  }
};

let getAttatchmentAPI = async (
  filledFormId: string,
  stitchTypeId: string
): Promise<AttatchmentData | null> => {
  let encoded = encodeURI(stitchTypeId);
  let attatchmentData = await axiosApiInstance.get(
    `/${getAttatchments(filledFormId, encoded)}`
  );
  if (attatchmentData.status !== 200) {
    return null;
  } else {
    return attatchmentData.data;
  }
};

let getAttatchmentZipAPI = async (filledFormIds: string[], userId: string) => {
  try {
    let attatchmentData = await axiosApiInstance.post(
      `/${getAttachmentZipPath}`,
      { filledFormIds: filledFormIds, userId: userId },
      { responseType: "blob" }
    );
    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([attatchmentData.data]));
    link.download = `${userId}.zip`;

    link.click();

    window.URL.revokeObjectURL(link.href);
    return true;
  } catch {
    return false;
  }
};

export interface FilledFormFilterOptions {
  templates: { [key: string]: string };
}

//Request para obtener la lista de registros
export interface FilledFormFilteredRequest {
  page: number;
  pageSize?: number;
  templateId: string | null;
  tagId: string | null;
  searchQuery: string | null;
  // endDate: string | null;
  // startDate: string | null;
}
// Registros filtrados
export interface FilledFormFiltered {
  filledForms: Array<FilledForm>;
  numPages: number; // ignorar este en todos porque este es para manejo interno del front
  currentPage: number;
}
// Solo un registro
export interface FilledForm {
  id: string;
  userId: string;
  formTemplateId: string;
  clientId: string;
  data: { [key: string]: string };
  requirements: Requirement[];
  conditionals: Conditionals;
  electronicSignatureId: string;
  metadata: Metadata;
  done: boolean;
  redirectionUrl: string;
  startDatetime: string;
  pdfName: string;
  attatchments: Attatchment[];
  document?: string;
  userData?: RegistrationDetail | null;
  cancelled?: boolean;
  cancellationReason?: string;
  cancellationDetail?: string;
}

export interface PdfBase64 {
  pdfBase64: string;
}

// Los templates encontrados filtrados por página
export interface TemplatesFiletered {
  templates: Array<Template>;
  numPages: number;
  currentPage: number;
}

export interface TemplatesFilteredRequest {
  page: number;
}
// Template unico
export interface Template {
  id: string;
  name: string;
  nombre: string;
  document: string; // base64
}

export interface Requirement {
  stitchTypeId: string;
  name: string;
  source: string;
  conditionalSourceId: string;
  conditionalDestinationId: string[];
}

export interface ValueMapper {
  additionalProp1: boolean;
  additionalProp2: boolean;
  additionalProp3: boolean;
}

export interface AdditionalProp1 {
  id: string;
  status: boolean;
  valueMapper: ValueMapper;
}

export interface ValueMapper2 {
  additionalProp1: boolean;
  additionalProp2: boolean;
  additionalProp3: boolean;
}

export interface AdditionalProp2 {
  id: string;
  status: boolean;
  valueMapper: ValueMapper2;
}

export interface ValueMapper3 {
  additionalProp1: boolean;
  additionalProp2: boolean;
  additionalProp3: boolean;
}

export interface AdditionalProp3 {
  id: string;
  status: boolean;
  valueMapper: ValueMapper3;
}

export interface Conditionals {
  additionalProp1: AdditionalProp1;
  additionalProp2: AdditionalProp2;
  additionalProp3: AdditionalProp3;
}

export interface Metadata {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface Attatchment {
  fileName: string;
  stitchTypeId: string;
  fieldName: string;
  filledFormId: string;
  authentikatorData?: AuthentikatorData;
}

export interface AuthentikatorData {
  extractedData: { [key: string]: string };
  validityScore: number;
  alterationsScore: number;
}

export interface AttatchmentData {
  fileBase64: string;
  fileName: string;
}

export {
  getFilledFormsFilteredAPI,
  getAllTemplatesAPI,
  getFilledFormByIdAPI,
  getTemplateByIdAPI,
  getFilledFormsFilterOptionsAPI,
  getPdfFilledFormsAPI,
  getUserAttatchmentsAPI,
  getAttatchmentZipAPI,
  getAttatchmentAPI,
};
