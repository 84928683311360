import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DisassociateTagRequest,
  SimpleTag,
  TagsByEntityRequest,
  TagsByEntityResponseItem,
  TagsByService,
  associateTagAPI,
  disassociateTagAPI,
  getTagsAPI,
  getTagsByEntityAPI,
  getTagsByServiceAPI,
} from "./tagAPI";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";

export interface TagState {
  loadingAllTags: LoadingStatus;
  loadingCurrentTagsByService: LoadingStatus;
  loadingCurrentTagsByEntity: LoadingStatus;
  allTags: SimpleTag[];
  currentTagsByService: TagsByService[];
  currentTagsByEntity: TagsByEntityResponseItem[];
  currentService: string;
  currentEntity: string;
  currentTagCreation: {
    tag: string | null;
    tagId: string;
    service: string;
    entityId: string;
  };
}

const initialState: TagState = {
  loadingAllTags: "idle",
  loadingCurrentTagsByEntity: "idle",
  loadingCurrentTagsByService: "idle",
  allTags: [],
  currentTagsByService: [],
  currentTagsByEntity: [],
  currentEntity: "",
  currentService: "",
  currentTagCreation: {
    tag: "",
    tagId: "",
    entityId: "",
    service: "",
  },
};

export const getAllTags = createAsyncThunk(
  "tags/getAllTags",
  async (_, { rejectWithValue }) => {
    let tags = await getTagsAPI();
    if (tags.error === "") {
      return tags.tags;
    } else {
      return rejectWithValue(tags.error);
    }
  }
);

export const getCurrentTagsByService = createAsyncThunk(
  "tags/getCurrentTagsByService",
  async (service: string, { rejectWithValue }) => {
    let tags = await getTagsByServiceAPI(service);
    if (tags.error === "") {
      return tags.tags;
    } else {
      return rejectWithValue(tags.error);
    }
  }
);

export const getCurrentTagsByEntity = createAsyncThunk(
  "tags/getCurrentTagsByEntity",
  async (req: TagsByEntityRequest, { rejectWithValue }) => {
    let tags = await getTagsByEntityAPI(req);
    if (tags.error === "") {
      return tags.tags;
    } else {
      return rejectWithValue(tags.error);
    }
  }
);

export const createTag = createAsyncThunk(
  "tags/createTag",
  async (_, { rejectWithValue, getState }) => {
    let state = getState() as RootState;
    let tag = state.tags.currentTagCreation;
    let tags = await associateTagAPI({
      entityId: tag.entityId,
      service: tag.service,
      tag: tag.tag,
      tagId: tag.tagId,
    });

    if (tags.error === "") {
      return true;
    } else {
      return rejectWithValue(tags.error);
    }
  }
);

export const disassociateTag = createAsyncThunk(
  "tags/disassociateTag",
  async (req: DisassociateTagRequest, { rejectWithValue, getState }) => {
    let result = await disassociateTagAPI(req);
    if (result.error === "") {
      return true;
    } else {
      return rejectWithValue(result.error);
    }
  }
);

const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setEntity: (state, action: PayloadAction<string>) => {
      state.currentEntity = action.payload;
    },
    setService: (state, action: PayloadAction<string>) => {
      state.currentService = action.payload;
    },
    resetServiceAndEntityData: (state) => {
      state.currentEntity = "";
      state.currentService = "";
      state.currentTagsByEntity = [];
      state.currentTagsByService = [];
    },
    setTagCreation: (
      state,
      action: PayloadAction<{
        tag: string | null;
        tagId: string;
        service: string;
        entityId: string;
      }>
    ) => {
      state.currentTagCreation = action.payload;
    },
  },
  extraReducers: (builder) => {
    // loadAllTags
    builder
      .addCase(getAllTags.pending, (state) => {
        state.loadingAllTags = "pending";
      })
      .addCase(getAllTags.fulfilled, (state, action) => {
        state.loadingAllTags = "resolved";
        state.allTags = action.payload;
      })
      .addCase(getAllTags.rejected, (state) => {
        state.loadingAllTags = "rejected";
      });

    // loadCurrentTagsByService
    builder
      .addCase(getCurrentTagsByService.pending, (state) => {
        state.loadingCurrentTagsByService = "pending";
      })
      .addCase(getCurrentTagsByService.fulfilled, (state, action) => {
        state.loadingCurrentTagsByService = "resolved";
        state.currentTagsByService = action.payload;
        state.currentService = action.meta.arg;
      })
      .addCase(getCurrentTagsByService.rejected, (state) => {
        state.loadingCurrentTagsByService = "rejected";
      });

    // loadCurrentTagsByEntity
    builder
      .addCase(getCurrentTagsByEntity.pending, (state) => {
        state.loadingCurrentTagsByEntity = "pending";
      })
      .addCase(getCurrentTagsByEntity.fulfilled, (state, action) => {
        state.loadingCurrentTagsByEntity = "resolved";
        state.currentTagsByEntity = action.payload;
        state.currentEntity = action.meta.arg.entityId;
        state.currentService = action.meta.arg.service;
      })
      .addCase(getCurrentTagsByEntity.rejected, (state) => {
        state.loadingCurrentTagsByEntity = "rejected";
      });

    // createTag
    builder.addCase(createTag.fulfilled, (state) => {
      let currentTag = { ...state.currentTagCreation };
      if (currentTag.tag) {
        let allTags = state.allTags;

        allTags.push({
          id: state.currentTagCreation.tagId,
          tag: state.currentTagCreation.tag,
        });

        state.allTags = allTags;

        let currentTagsByService = state.currentTagsByService;
        let associatedEntities: string[] = [state.currentTagCreation.entityId];
        currentTagsByService.push({
          id: currentTag.tagId,
          tag: currentTag.tag,
          associatedEntities,
        });
        state.currentTagsByService = currentTagsByService;
      }

      if (!currentTag.tag) {
        let allTags = state.allTags;
        let currentTagFromAllTags = allTags.find(
          (tag) => tag.id === currentTag.tagId
        );
        if (currentTagFromAllTags) {
          currentTag.tag = currentTagFromAllTags.tag;
        }
        let currentTagsByService = state.currentTagsByService;
        let tagFromService = currentTagsByService.find(
          (tag) => tag.id === currentTag.tagId
        );
        if (tagFromService) {
          tagFromService.associatedEntities.push(currentTag.entityId);
          currentTagsByService = currentTagsByService.filter(
            (tag) => tag.id !== currentTag.tagId
          );
          currentTagsByService.push(tagFromService);
          state.currentTagsByService = currentTagsByService;
        }
      }

      let currentTagsByEntity = state.currentTagsByEntity;
      currentTagsByEntity.push({
        id: currentTag.tagId,
        tag: currentTag.tag,
      });

      state.currentTagsByEntity = currentTagsByEntity;
      state.currentTagCreation = {
        tag: "",
        tagId: "",
        entityId: "",
        service: "",
      };
    });

    // disassociateTag
    builder.addCase(disassociateTag.fulfilled, (state, action) => {
      let entityId = action.meta.arg.entityId;
      let tagId = action.meta.arg.tagId;
      let currentTagsByService = state.currentTagsByService;
      // find and delete the element that has the tagId and entityId
      let index = currentTagsByService.findIndex((tag) => tag.id === tagId);
      if (index !== -1) {
        let associatedEntities = currentTagsByService[index].associatedEntities;
        let newAssociatedEntities = associatedEntities.filter(
          (entity) => entity !== entityId
        );
        currentTagsByService[index].associatedEntities = newAssociatedEntities;
        state.currentTagsByService = currentTagsByService;
      }

      let currentTagsByEntity = state.currentTagsByEntity;
      let newCurrentTagsByEntity = currentTagsByEntity.filter(
        (tag) => tag.id !== tagId
      );
      state.currentTagsByEntity = newCurrentTagsByEntity;
    });
  },
});

export const {
  resetServiceAndEntityData,
  setEntity,
  setService,
  setTagCreation,
} = tagSlice.actions;

export const selectAllTags = (state: RootState) => state.tags.allTags;
export const selectCurrentTagsByService = (state: RootState) =>
  state.tags.currentTagsByService;
export const selectCurrentTagsByEntity = (state: RootState) =>
  state.tags.currentTagsByEntity;
export const selectCurrentService = (state: RootState) =>
  state.tags.currentService;
export const selectCurrentEntity = (state: RootState) =>
  state.tags.currentEntity;
export const selectLoadingAllTags = (state: RootState) =>
  state.tags.loadingAllTags;
export const selectLoadingCurrentTagsByService = (state: RootState) =>
  state.tags.loadingCurrentTagsByService;
export const selectLoadingCurrentTagsByEntity = (state: RootState) =>
  state.tags.loadingCurrentTagsByEntity;
export const selectCurrentTagCreation = (state: RootState) =>
  state.tags.currentTagCreation;

export default tagSlice.reducer;
