import axiosApiInstance from "../../../helpers/interceptor";
import { UnifiedAlertsFiltered } from "../alerts/alertsAPI";
import { ExternalQueryResult, RegistrationDetail } from "../coreid/coreIdAPI";
import { GetSignatureResponse } from "../firmanew/firmaNewAPI";
import { FilledForm } from "../magicforms/magicFormsAPI";
import { GetPagareResponse } from "../pagares/pagareAPI";

const getReg = (regId: string) => "liveMonitoring/getReg/" + regId;
const getPics = (regId: string) => "liveMonitoring/getPics/" + regId;
const getCurrentIds = "liveMonitoring/getCurrentIds";
const getClientIdName = (name: string) => "liveMonitoring/getClientName/" + name;


let getCurrentIdsApi = async (): Promise<{
  ids: string[] | null;
  error: string;
}> => {
  try {
    let ids = await axiosApiInstance.get<string[]>(getCurrentIds);
    return {
      ids: ids.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      ids: null,
    };
  }
};


let getClientNameApi = async (
  name: string
): Promise<{ clientName: string | null; error: string }> => {
  try {
    let clientId = await axiosApiInstance.get<ClientNameResponse>(getClientIdName(name));
    return {
      clientName: clientId.data.clientName,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      clientName: null,
    };
  }
}

let getRegApi = async (
  regId: string
): Promise<{ reg: RegistrationDetail | null; error: string }> => {
  try {
    let reg = await axiosApiInstance.get<RegistrationDetail>(getReg(regId));
    return {
      reg: reg.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      reg: null,
    };
  }
};

let getPicsApi = async (
  regId: string
): Promise<{ pics: RegPicResponse | null; error: string }> => {
  try {
    let pics = await axiosApiInstance.get<RegPicResponse>(getPics(regId));
    return {
      pics: pics.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      pics: null,
    };
  }
};

export interface RegPicResponse {
  facePics: string[];
  docPics: string[];
}

export interface ClientNameResponse {
  clientName: string;
}

export { getCurrentIdsApi, getRegApi, getPicsApi, getClientNameApi };
